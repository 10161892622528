import { useContext } from 'react';

import { UploadPipelineContext } from '../contexts';

export function useUploadPipeline() {
  const uploadPipeline = useContext(UploadPipelineContext);

  if (uploadPipeline == null) throw new Error('<UploadPipelineProvider /> has not been defined as an ancestor.');

  return uploadPipeline;
}
