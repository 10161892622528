import { memo, useEffect, useMemo, useState } from 'react';

import cn from 'classnames';
import styled from 'styled-components';

import { useDataStream, useEvent, useEventStream, useValidatedParam } from 'core/hooks';
import { PAGE_GRID_CLASSES, Page, PageHeader } from 'core/ui';

import { useSessionLocation } from 'features/location';
import { PatientEditHeader } from 'features/patient';
import { useUploadPipeline } from 'features/upload-pipeline/hooks/useUploadPipeline';

import { UploadExamsPageContext } from '../contexts';
import { useUploadView } from '../hooks/useUploadView';
import { WizardStepKey } from '../types';
import { UploadExamsPageContextType } from '../types/UploadExamsPageContextType';
import { ReviewExamsStep } from './ReviewExamsStep';
import { SelectFilesStep } from './SelectFilesStep';
import { UploadStep } from './UploadStep';

export const UploadExamsPage = memo(() => {
  const uploadPipeline = useUploadPipeline();
  const uploadView = useUploadView();
  const { sessionLocation } = useSessionLocation();
  const patientIdParam = useValidatedParam('patientId', 'integer', false);
  const examIdParam = useValidatedParam('examId', 'integer', false);

  const [matchedPatientId, setMatchedPatientId] = useState<number | null>(null);
  const queryPatientId = patientIdParam ?? matchedPatientId;
  const uploadGroups = useDataStream(uploadView.streams.groups);
  const [currentStep, setCurrentStep] = useState<WizardStepKey>(WizardStepKey.SelectFiles);

  const handleSelectFilesNext = useEvent(() => {
    setCurrentStep(WizardStepKey.ReviewExams);
  });

  const handleReviewExamsNext = useEvent(() => {
    setCurrentStep(WizardStepKey.UploadExams);
  });

  useEventStream(uploadPipeline.recordMatcher.streams.onMatchComplete, (event) => {
    if (event.result === 'processed' && event.patientId != null) {
      setMatchedPatientId((prev) => prev ?? event.patientId ?? null);
    }
  });

  useEffect(() => {
    uploadPipeline.setFixedEntities(patientIdParam ?? null, examIdParam ?? null, sessionLocation?.id ?? null);
  }, [examIdParam, patientIdParam, sessionLocation?.id, uploadPipeline, uploadView]);

  const uploadContext: UploadExamsPageContextType = useMemo(
    () => ({
      patientId: queryPatientId,
      isFixedPatient: patientIdParam != null,
      isFixedExam: examIdParam != null,
      uploadGroups,
      uploadView,
    }),
    [queryPatientId, patientIdParam, examIdParam, uploadGroups, uploadView],
  );

  return (
    <UploadExamsPageContext.Provider value={uploadContext}>
      <StyledPage>
        <PageHeader title="Upload Exams" showSessionLocation omitAllLocationsOption />

        <StyledStepsDiv className={PAGE_GRID_CLASSES.Flush}>
          <StyledStepsItemDiv className={cn({ selected: currentStep === WizardStepKey.SelectFiles })}>1. Select Files</StyledStepsItemDiv>
          <StyledStepsItemDiv className={cn({ selected: currentStep === WizardStepKey.ReviewExams })}>2. Review Exams</StyledStepsItemDiv>
          <StyledStepsItemDiv className={cn({ selected: currentStep === WizardStepKey.UploadExams })}>3. Upload Exams</StyledStepsItemDiv>
        </StyledStepsDiv>

        {patientIdParam == null && currentStep === WizardStepKey.SelectFiles ? <div /> : <PatientEditHeader patientId={queryPatientId} />}

        {currentStep === WizardStepKey.SelectFiles && <SelectFilesStep onNext={handleSelectFilesNext} />}
        {currentStep === WizardStepKey.ReviewExams && <ReviewExamsStep onNext={handleReviewExamsNext} />}
        {currentStep === WizardStepKey.UploadExams && <UploadStep />}
      </StyledPage>
    </UploadExamsPageContext.Provider>
  );
});

UploadExamsPage.displayName = 'UploadExamsPage';

const StyledPage = styled(Page)`
  grid-template-rows: min-content min-content min-content 1fr min-content;
  row-gap: 0;
  container-type: inline-size;
`;

const StyledStepsDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.palette.white};
  user-select: none;

  @container (min-width: 500px) {
    display: grid;
    grid-template-columns: repeat(3, min-content);
    justify-content: center;
    column-gap: ${({ theme }) => theme.space.spacing80};
    margin-bottom: ${({ theme }) => theme.space.spacing20};

    --unselected-border-bottom: 3px solid transparent;
    --selected-border-bottom: 3px solid ${({ theme }) => theme.colors.primary};
  }

  @container (max-width: 499.999px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    padding-left: ${({ theme }) => theme.space.spacing20};

    --unselected-border-right: 3px solid transparent;
    --selected-border-right: 3px solid ${({ theme }) => theme.colors.primary};
  }
`;

const StyledStepsItemDiv = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.heading1};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  line-height: ${({ theme }) => theme.lineHeights.heading1};
  white-space: nowrap;
  overflow: hidden;
  padding: ${({ theme }) => theme.space.spacing40} ${({ theme }) => theme.space.spacing20} ${({ theme }) => theme.space.spacing40} 0;
  //border-bottom: 3px solid transparent;
  border-bottom: var(--unselected-border-bottom, none);
  border-right: var(--unselected-border-right, none);
  color: ${({ theme }) => theme.colors.textPrimary};

  &.selected {
    color: ${({ theme }) => theme.colors.primary};
    //border-bottom-color: ${({ theme }) => theme.colors.primary};
    border-bottom: var(--selected-border-bottom, none);
    border-right: var(--selected-border-right, none);
  }
`;
