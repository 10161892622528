import { memo, useEffect, useId, useState } from 'react';

import styled from 'styled-components';

import { PatientModel } from 'models';

import { apiClient } from 'core/api/globals';
import { useEvent } from 'core/hooks';
import { BreakpointSelectors, Button, TabButton, TabsContainer, Window, WindowActionsBar, withConditionalRender } from 'core/ui';
import { hasText } from 'core/utils';

import { useSessionLocation } from 'features/location';
import { useUserSettings } from 'features/settings';

import { ShareType } from '../constants';
import { ShareService } from '../services';
import { ShareEmailFormValues, ShareLinkFormValues, ShareModalProps } from '../types';
import { ShareEmailForm } from './ShareEmailForm';
import { ShareLinkForm } from './ShareLinkForm';
import { ShareLinkSummary } from './ShareLinkSummary';

const ShareModalInner = memo<Omit<ShareModalProps, 'patientId'> & { patient: PatientModel }>(({ patient, examId, onHide, onPatientChange }) => {
  const isOpo = patient?.locationType === 'OPO';
  const patientVerbiage = isOpo ? 'Donor' : 'Patient';
  const { sessionLocation } = useSessionLocation(false);

  const linkFormId = `${ShareModalInner.displayName}__link-form__${useId()}`;
  const emailFormId = `${ShareModalInner.displayName}__email-form__${useId()}`;
  const [activeTab, setActiveTab] = useState<'link' | 'email'>('link');
  const [linkFormValues, setLinkFormValues] = useState<ShareLinkFormValues>(() =>
    ShareService.initializeLinkFormValues(patient, sessionLocation?.isOpo ?? false),
  );
  const [emailFormValues, setEmailFormValues] = useState<ShareEmailFormValues>(() =>
    ShareService.initializeEmailFormValues(patient, sessionLocation?.isOpo ?? false),
  );
  const [shareUrl, setShareUrl] = useState<string | null>(null);
  const { reactSharePage } = useUserSettings(true);

  const handleLinkTabClick = useEvent(() => {
    setActiveTab('link');
  });

  const handleEmailTabClick = useEvent(() => {
    setActiveTab('email');
  });

  const handleLinkFormChange = useEvent((values: ShareLinkFormValues) => {
    setLinkFormValues(values);

    // Synchronize certain fields between the link and the email forms.
    setEmailFormValues({
      ...emailFormValues,
      unosId: values.unosId,
      isUnosUnavailable: values.isUnosUnavailable,
      description: values.description,
      accessCode: values.accessCode,
      expiration: values.expiration,
    });
  });

  const handleEmailFormChange = useEvent((values: ShareEmailFormValues) => {
    setEmailFormValues(values);

    // Synchronize certain fields between the link and the email forms.
    setLinkFormValues({
      ...linkFormValues,
      unosId: values.unosId,
      isUnosUnavailable: values.isUnosUnavailable,
      description: values.description,
      accessCode: values.accessCode,
      expiration: values.expiration,
    });
  });

  const handleLinkFormSubmit = useEvent(async () => {
    if (linkFormValues.expiration == null) {
      throw new Error('Cannot proceed because the expiration field is null or undefined.');
    }

    const linkId = crypto.randomUUID();
    const expiration = ShareService.calculateShareExpiration(linkFormValues.expiration.value)?.toISOString();

    if ((patient.unosID ?? '') !== linkFormValues.unosId) {
      const newPatient: PatientModel = {
        ...patient,
        unosID: hasText(linkFormValues.unosId) ? linkFormValues.unosId : null,
      };
      await apiClient.patientClient.updatePatient(newPatient);
      onPatientChange(newPatient);
    }

    await apiClient.studyShare.addStudyShare({
      id: 0,
      linkId,
      email: null,
      shareType: ShareType.Link,
      studyShareExams: examId == null ? [] : [{ exam_id: examId }],
      sharePassword: hasText(linkFormValues.accessCode) ? linkFormValues.accessCode : null,
      sharePatientId: examId == null ? patient.id : null, // Note that for patient-wide shares we need to populate the SharePatientId property.  For exam-specific shares however we need to OMIT the SharePatientId field.  This is for compatibility reasons with the legacy application.
      expireOn: expiration ?? null,
      dateCreated: null,
      message: linkFormValues.description,
    });
    const shareUrl = ShareService.createShareLink(reactSharePage, linkId);
    setShareUrl(shareUrl);
  });

  const handleEmailFormSubmit = useEvent(async () => {
    if (emailFormValues.expiration == null) {
      throw new Error('Cannot proceed because the expiration field is null or undefined.');
    }

    const linkId = crypto.randomUUID();
    const expiration = ShareService.calculateShareExpiration(emailFormValues.expiration.value)?.toISOString();

    if ((patient.unosID ?? '') !== emailFormValues.unosId) {
      const newPatient: PatientModel = {
        ...patient,
        unosID: hasText(emailFormValues.unosId) ? emailFormValues.unosId : null,
      };
      await apiClient.patientClient.updatePatient(newPatient);
      onPatientChange(newPatient);
    }

    await apiClient.studyShare.addStudyShare({
      id: 0,
      linkId,
      email: emailFormValues.email,
      shareType: ShareType.Email,
      studyShareExams: examId == null ? [] : [{ exam_id: examId }],
      sharePassword: hasText(emailFormValues.accessCode) ? emailFormValues.accessCode : null,
      sharePatientId: examId == null ? patient.id : null, // Note that for patient-wide shares we need to populate the SharePatientId property.  For exam-specific shares however we need to OMIT the SharePatientId field.  This is for compatibility reasons with the legacy application.
      expireOn: expiration ?? null,
      dateCreated: null,
      message: emailFormValues.description,
    });
    const shareUrl = ShareService.createShareLink(reactSharePage, linkId);
    setShareUrl(shareUrl);
  });

  return (
    <Window title={!examId ? `Share ${patientVerbiage}` : 'Share Exam'} initialWidth={350} onClose={onHide}>
      {shareUrl == null && (
        <>
          <TabsContainer>
            <TabButton type="button" selected={activeTab === 'link'} roundedCorners="left" onClick={handleLinkTabClick}>
              Link
            </TabButton>
            <TabButton type="button" selected={activeTab === 'email'} roundedCorners="right" onClick={handleEmailTabClick}>
              Email
            </TabButton>
          </TabsContainer>
          <StyledFormContainer $activeTab={activeTab}>
            {activeTab === 'link' && (
              <StyledShareLinkForm
                formId={linkFormId}
                initialValues={linkFormValues}
                onChange={handleLinkFormChange}
                onSubmit={handleLinkFormSubmit}
                $activeTab={activeTab}
              />
            )}
            {activeTab === 'email' && (
              <StyledShareEmailForm
                formId={emailFormId}
                initialValues={emailFormValues}
                onChange={handleEmailFormChange}
                onSubmit={handleEmailFormSubmit}
                $activeTab={activeTab}
              />
            )}
          </StyledFormContainer>
        </>
      )}
      {shareUrl != null && (
        <>
          <ShareLinkSummary shareUrl={shareUrl} />
        </>
      )}
      <WindowActionsBar>
        {shareUrl != null && (
          <Button type="button" onClick={onHide}>
            Close
          </Button>
        )}
        {shareUrl == null && activeTab === 'link' && (
          <Button iconClass="fa fa-link" type="submit" form={linkFormId}>
            Generate Link
          </Button>
        )}
        {shareUrl == null && activeTab === 'email' && (
          <Button iconClass="fa fa-envelope" type="submit" form={emailFormId}>
            Send Email
          </Button>
        )}
      </WindowActionsBar>
    </Window>
  );
});

ShareModalInner.displayName = 'ShareModalInner';

export const ShareModal = memo(
  withConditionalRender<ShareModalProps>(({ patientId, examId, onHide, onPatientChange }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks -- TODO: This modal needs to be refactored so it isn't nesting so many HOCs over each other.
    const [patient, setPatient] = useState<PatientModel | null>(null);

    // eslint-disable-next-line react-hooks/rules-of-hooks -- TODO: This modal needs to be refactored so it isn't nesting so many HOCs over each other.
    const initialize = useEvent(async () => {
      const newPatient = await apiClient.patientClient.getPatient(patientId);
      setPatient(newPatient);
    });

    // eslint-disable-next-line react-hooks/rules-of-hooks -- TODO: This modal needs to be refactored so it isn't nesting so many HOCs over each other.
    useEffect(() => {
      initialize();
    }, [patientId, initialize]);

    return patient == null ? null : <ShareModalInner patient={patient} examId={examId} onHide={onHide} onPatientChange={onPatientChange} />;
  }),
);

ShareModal.displayName = 'ShareModal';

const StyledFormContainer = styled.div<{ $activeTab: 'link' | 'email' }>`
  display: grid;
`;

const StyledShareLinkForm = styled(ShareLinkForm)<{
  $activeTab: 'link' | 'email';
}>`
  ${({ $activeTab }) => ($activeTab === 'email' ? 'visibility: hidden;' : '')}
`;

const StyledShareEmailForm = styled(ShareEmailForm)<{
  $activeTab: 'link' | 'email';
}>`
  ${({ $activeTab }) => ($activeTab === 'link' ? 'visibility: hidden;' : '')}
`;
