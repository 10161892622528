import { memo, useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';

import { SettingDefinitionModel } from 'models';

import { apiClient } from 'core/api/globals';
import { useEvent } from 'core/hooks';
import { NotificationsService } from 'core/notifications';
import { Button, Page, PageHeader } from 'core/ui';
import { findOrThrow } from 'core/utils';

import { PortalSettingsService } from '../services';
import { EditDefinitionForm } from './EditDefinitionForm';
import { SettingsList } from './SettingsList';

export const PortalSettingsPage = memo(() => {
  const [nextId, setNextId] = useState(0); // These will go in the negative direction - they are used to keep track of new settings that have not yet been saved.
  const [definitions, setDefinitions] = useState<SettingDefinitionModel[] | null>(null);
  const [selectedSettingId, setSelectedSettingId] = useState<number | null>(null);

  const selectedSetting = useMemo(
    () => (definitions == null || selectedSettingId == null ? null : findOrThrow(definitions, (d) => d.id === selectedSettingId)),
    [selectedSettingId, definitions],
  );

  const handleDefinitionClick = useEvent((settingId: number) => {
    if (definitions == null) {
      throw new Error('Setting definitions cannot be null or undefined.');
    }

    setSelectedSettingId(settingId);
  });

  const handleAddNewClick = useEvent(() => {
    setDefinitions((prev) => {
      if (prev == null) {
        throw new Error('Cannot proceed because the list of setting definitions is null or undefined.');
      }

      const newDefinitions = [...prev];

      newDefinitions.push({
        id: nextId,
        name: '',
        description: null,
        value: null,
        system: false,
        active: true,
      });

      return newDefinitions;
    });

    setSelectedSettingId(nextId);

    setNextId((prev) => prev - 1);
  });

  const handleDeleteClick = useEvent(() => {
    if (selectedSettingId == null) {
      throw new Error('Cannot delete setting because no setting is selected.');
    }
    if (definitions == null) {
      throw new Error('Setting definitions cannot be null or undefined.');
    }

    const newDefinitions = [...definitions];

    const selectedIndex = newDefinitions.findIndex((d) => d.id === selectedSettingId);

    if (selectedIndex >= 0) {
      newDefinitions.splice(selectedIndex, 1);
    }

    setDefinitions(newDefinitions);
    setSelectedSettingId(null);
  });

  const handleFormClose = useEvent(() => {
    setSelectedSettingId(null);
  });

  const handleFormChange = useEvent((newValues: SettingDefinitionModel) => {
    setDefinitions((prevDefinitions) => {
      const newDefinitions = prevDefinitions == null ? [] : [...prevDefinitions];

      const index = newDefinitions.findIndex((d) => d.id === newValues.id);

      if (index >= 0) {
        newDefinitions[index] = newValues;
      }

      return newDefinitions;
    });
  });

  const handleFormSubmit = useEvent(async () => {
    if (selectedSetting == null) {
      throw new Error('Cannot submit form because no setting is selected.');
    }
    if (definitions == null) {
      throw new Error('Setting definitions cannot be null or undefined.');
    }

    // We get new Ids for newly inserted settings.
    const newDefinitions = await apiClient.settingsClient.updateSettingDefinitions(definitions);
    setDefinitions(newDefinitions);

    // Explicitly retain the currently selected setting because the ids will change for newly inserted settings.
    const selectedIndex = definitions.findIndex((s) => s.id === selectedSettingId);

    if (selectedIndex >= 0) {
      setSelectedSettingId(newDefinitions[selectedIndex].id);
    }

    NotificationsService.displaySuccess('Settings updated successfully.');
  });

  useEffect(() => {
    (async () => {
      const results = await PortalSettingsService.initialize();

      setDefinitions(results.definitions);
    })();
  }, []);

  if (definitions == null) {
    return null;
  }

  return (
    <Page>
      <PageHeader title="Portal Settings" />

      <StyledColumnsDiv>
        <StyledLeftDiv>
          <StyledToolbarDiv>
            <Button onClick={handleAddNewClick}>+ Add New</Button>
          </StyledToolbarDiv>
          <StyledSettingsList definitions={definitions} selectedDefinition={selectedSetting} onSettingClick={handleDefinitionClick} />
        </StyledLeftDiv>

        {selectedSetting == null ? null : (
          <EditDefinitionForm
            values={selectedSetting}
            onClose={handleFormClose}
            onChange={handleFormChange}
            onSubmit={handleFormSubmit}
            onDeleteClick={handleDeleteClick}
          />
        )}
      </StyledColumnsDiv>
    </Page>
  );
});

PortalSettingsPage.displayName = 'PortalSettingsPage';

const StyledColumnsDiv = styled.div`
  display: grid;
  grid-template-columns: 400px 1fr;
`;

const StyledLeftDiv = styled.div`
  display: grid;
  overflow: hidden;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
`;

const StyledToolbarDiv = styled.div`
  display: flex;
  overflow: hidden;
  justify-content: end;
  padding: 0 0 8px 0;
`;

const StyledSettingsList = styled(SettingsList)`
  flex: 1 1 0;
`;
