import { FunctionComponent } from 'react';

import { QueryClientProvider } from '@tanstack/react-query';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider } from 'react-redux';

import { globalQueryClient } from 'core/api';
import { AlertProvider } from 'core/notifications';
import { ThemeProvider } from 'core/ui';

import { ApiClientProvider } from 'features/api';
import { AuthenticationProvider, CurrentUserProvider } from 'features/auth';
import { ExamStatusCacheProvider } from 'features/exam-status/fragments';
import { AppSessionLocationProvider } from 'features/location/fragments/AppSessionLocationProvider';
import { AppSessionLocationSelectModalProvider } from 'features/location/fragments/AppSessionLocationSelectModalProvider';
import { UserSettingsProvider } from 'features/settings';

import { store } from '../redux';
import { AppRouter } from './AppRouter';

export const AppProvider: FunctionComponent = () => {
  return (
    <ApiClientProvider>
      <AuthenticationProvider>
        <QueryClientProvider client={globalQueryClient}>
          <CurrentUserProvider>
            <AppSessionLocationProvider>
              <UserSettingsProvider>
                <Provider store={store}>
                  <ThemeProvider>
                    <DndProvider backend={HTML5Backend}>
                      <AppSessionLocationSelectModalProvider>
                        <ExamStatusCacheProvider>
                          <AppRouter />
                          <AlertProvider />
                        </ExamStatusCacheProvider>
                      </AppSessionLocationSelectModalProvider>
                    </DndProvider>
                  </ThemeProvider>
                </Provider>
              </UserSettingsProvider>
            </AppSessionLocationProvider>
          </CurrentUserProvider>
        </QueryClientProvider>
      </AuthenticationProvider>
    </ApiClientProvider>
  );
};

AppProvider.displayName = 'AppProvider';
