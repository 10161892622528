import { memo, useEffect, useMemo, useState } from 'react';

import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { pickBy } from 'lodash';
import styled from 'styled-components';

import { PhysicianModel } from 'models';

import { apiClient } from 'core/api/globals';
import { useEvent } from 'core/hooks';
import { Button, Dropdown, Modal } from 'core/ui';

import { BulkAssignModalProps } from '../types';

export const BulkAssignModal = memo<BulkAssignModalProps>(({ show, selectedExams, onClose, onSubmit }) => {
  const [selectedPhysician, setSelectedPhysician] = useState<PhysicianModel | null>(null);
  const [physicians, setPhysicians] = useState<PhysicianModel[]>([]);

  const examIds = useMemo(() => Object.keys(pickBy(selectedExams, (isSelected) => isSelected)), [selectedExams]);

  const handlePhysicianChange = useEvent((event: DropDownListChangeEvent) => {
    setSelectedPhysician(event.value);
  });

  const handleConfirmClick = useEvent(() => {
    if (selectedPhysician == null) throw new Error('Cannot bulk assign to physician because the selectedPhysician is null or undefined.');

    onSubmit?.(selectedPhysician.id);
  });

  useEffect(() => {
    (async () => {
      const newPhysicians = await apiClient.physicianClient.getAll();
      setPhysicians(newPhysicians);
    })();
  }, []);

  return (
    <Modal show={show} onHide={onClose} title="Bulk Assign">
      <StyledDialogBodyDiv>
        <div>
          <Dropdown
            data={physicians}
            dataItemKey="id"
            label="Select Physician"
            filterable={false}
            onChange={handlePhysicianChange}
            textField="fullName"
            value={selectedPhysician}
            valueField="id"
            valid
            required
          />
        </div>

        <StyledDescriptionDiv>
          {selectedPhysician != null && (
            <p>
              {`Exam${examIds.length === 1 ? '' : 's'} with ID: [${examIds.join(', ')}] will be assigned to `}
              <b>{`${selectedPhysician.firstName} ${selectedPhysician.lastName}`}</b>.
            </p>
          )}
          {selectedPhysician == null && (
            <p>
              {`Exam${examIds.length === 1 ? '' : 's'} with ID: [${examIds.join(', ')}] will be assigned to `}
              <i>select physician</i>.
            </p>
          )}
        </StyledDescriptionDiv>

        <StyledConfirmDiv>
          <Button disabled={!selectedPhysician} onClick={handleConfirmClick}>
            Confirm
          </Button>
        </StyledConfirmDiv>
      </StyledDialogBodyDiv>
    </Modal>
  );
});

BulkAssignModal.displayName = 'BulkAssignModal';

const StyledDialogBodyDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 500px;
  padding: 24px;
`;

const StyledDescriptionDiv = styled.div`
  padding: 24px 0;

  p {
    margin: 0;
  }

  i {
    color: ${({ theme }) => theme.colors.textDisabled};
  }
`;

const StyledConfirmDiv = styled.div`
  padding: 0;
  display: flex;
  justify-content: flex-end;
`;
