import dayjs from 'dayjs';
import { z } from 'zod';

import { ExamModel } from 'models';

export const examFormSchema = z.object({
  service: z.number().nullable().default(null),
  description: z.string().default(''),
  customerUid: z.string().default(''),
  studyDate: z.string().default(''),
  studyTime: z.string().default(''),
  notes: z.string().default(''),
  active: z.boolean().default(false),
  share: z.boolean().default(false),
  // Pathology-specific fields now default to null.
  organ: z.number().nullable().default(null),
  laterality: z.number().nullable().default(null),
  biopsyType: z.number().nullable().default(null),
  slidePreparation: z.number().nullable().default(null),
});

export type ExamFormType = z.infer<typeof examFormSchema>;

export const initializeExamFormValues = (exam: ExamModel): ExamFormType => {
  return examFormSchema.parse({
    ...exam,
    // Map service to its id (or null)
    service: exam.service ? exam.service.id : null,
    customerUid: exam.customerUID ?? '',
    studyDate: exam.studyDate ? dayjs(exam.studyDate).format('MM/DD/YYYY') : '',
    studyTime: exam.studyTime ? dayjs(exam.studyTime).format('HH:mm:ss') : '',
    organ: exam.organ ?? null,
    laterality: exam.laterality ?? null,
    biopsyType: exam.biopsyType ?? null,
    slidePreparation: exam.slidePreparation ?? null,
  });
};

export const ExamFormService = {
  initializeExamFormValues,
};
