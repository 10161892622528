import { memo } from 'react';

import styled, { ExecutionContext } from 'styled-components';

import { StatusBoxLargeProps } from './StatusBoxLargeProps';

export const StatusBoxLarge = memo<StatusBoxLargeProps>(({ className, status, children }) => {
  return (
    <StyledComponentDiv className={className} $status={status}>
      {children}
    </StyledComponentDiv>
  );
});

StatusBoxLarge.displayName = 'StatusBoxLarge';

function resolveBorder({ theme, $status }: ExecutionContext & { $status: StatusBoxLargeProps['status'] }) {
  switch ($status) {
    case 'success':
      return `1px solid ${theme.colors.palette.greens[2]}`;
    case 'warning':
      return `1px solid ${theme.colors.palette.yellows[2]}`;
    case 'error':
      return `1px solid ${theme.colors.palette.reds[2]}`;
    case 'neutral':
      return `1px solid ${theme.colors.palette.grayscale[5]}`;
    default:
      return `1px solid transparent`;
  }
}

function resolveIconColor({ theme, $status }: ExecutionContext & { $status: StatusBoxLargeProps['status'] }) {
  switch ($status) {
    case 'success':
      return theme.colors.palette.greens[2];
    case 'warning':
      return theme.colors.palette.yellows[4];
    case 'error':
      return theme.colors.palette.reds[2];
    case 'neutral':
      return theme.colors.palette.grayscale[5];
    default:
      return theme.colors.palette.grayscale[5];
  }
}

function resolveBackgroundColor({ theme, $status }: ExecutionContext & { $status: StatusBoxLargeProps['status'] }) {
  switch ($status) {
    case 'success':
      return theme.colors.palette.greens[0];
    case 'warning':
      return theme.colors.palette.yellows[0];
    case 'error':
      return theme.colors.palette.reds[0];
    case 'neutral':
      return theme.colors.palette.grayscale[0];
    default:
      return 'transparent';
  }
}

const StyledComponentDiv = styled.div<{ $status: StatusBoxLargeProps['status'] }>`
  border: ${resolveBorder};
  background-color: ${resolveBackgroundColor};
  border-radius: 4px;

  .icon-container {
    color: ${resolveIconColor};
  }
`;
