import { ComponentType, FunctionComponent, MouseEvent, memo, useCallback, useEffect, useMemo, useState } from 'react';

import { GridCellProps, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { useNavigate } from 'react-router-dom';

import { TagsGridModel } from 'models';

import {
  ActionListCell,
  DEFAULT_DATA_TABLE_DATA_STATE,
  DEFAULT_PAGE_SIZES,
  DataTable,
  GridColumn,
  HeaderCell,
  Page,
  PageHeader,
  Toolbar,
  faPen,
} from 'core/ui';

import { useApiClient } from 'features/api';
import { useCurrentUser } from 'features/auth';

import { TagsGridService } from '../services';
import { TagsGridHeaderCell } from './TagsGridHeaderCell';

const PageableSettings = {
  pageSizes: DEFAULT_PAGE_SIZES,
};

export const TagsFieldTemplateGrid: FunctionComponent = memo(() => {
  const apiClient = useApiClient();
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser(true);

  const [tags, setTags] = useState<TagsGridModel[]>([]);
  const [tagsTotal, setTagsTotal] = useState(0);
  const [dataState, setDataState] = useState(DEFAULT_DATA_TABLE_DATA_STATE);

  const handleAddNewClick = useCallback(() => navigate('/tags/add'), [navigate]);

  const handleEditClick = useCallback((dataItem: TagsGridModel) => navigate(`/tags/edit/${dataItem.id}`), [navigate]);

  // set the event for data state change
  const handleDataStateChange = useCallback((changeEvent: GridDataStateChangeEvent) => {
    setDataState(changeEvent.dataState);
  }, []);

  useEffect(() => {
    (async () => {
      const queryResult = await apiClient.tagsClient.getAllTagsForKendoGrid(dataState);

      setTags(queryResult.data);
      setTagsTotal(queryResult.total);
    })();
  }, [dataState]);

  const gridActions = useMemo(() => {
    return [
      {
        key: 'edit-form',
        title: 'Edit Form',
        icon: faPen,
        disabled: !currentUser.isSystemAdmin,
        onClick: (_: MouseEvent<HTMLButtonElement>, dataItem: TagsGridModel) => handleEditClick(dataItem),
      },
    ];
  }, [handleEditClick, currentUser.isSystemAdmin]);

  const { gridColumns, filterColumns } = useMemo(() => {
    return {
      gridColumns: TagsGridService.getGridColumns(),
      filterColumns: TagsGridService.getColumns(),
    };
  }, []);

  return (
    <Page>
      <PageHeader title="Tag Field Template" />
      <DataTable
        data={tags}
        filterable
        sortable
        pageable={PageableSettings}
        total={tagsTotal}
        onDataStateChange={handleDataStateChange}
        actions={gridActions}
        {...dataState}
      >
        <Toolbar onAddNewClick={handleAddNewClick} />
        <GridColumn
          title="Action"
          filterable={false}
          sortable={false}
          headerCell={HeaderCell}
          cell={ActionListCell as ComponentType<GridCellProps>}
          width="80px"
        />
        {gridColumns.map((column) => {
          return <GridColumn key={column.field} {...column} headerCell={TagsGridHeaderCell} cell={column.cell} filter={column.columnFilter} />;
        })}
      </DataTable>
    </Page>
  );
});

TagsFieldTemplateGrid.displayName = 'TagsFieldTemplateGrid';
