import { memo, useEffect, useRef, useState } from 'react';

import dayjs from 'dayjs';
import styled from 'styled-components';

import { ComponentSizes, ProgressBar, StatusBox, SuccessMessage } from 'core/ui';

import { UploadGroup } from '../types/UploadGroup';

type UploadGroupProgressCardProps = {
  className?: string;
  uploadGroup: UploadGroup;
};

export const UploadGroupProgressCard = memo<UploadGroupProgressCardProps>(({ className, uploadGroup }) => {
  const intervalRef = useRef<number | null>(null);
  const [now, setNow] = useState(() => performance.timeOrigin + performance.now());

  const totalFiles = uploadGroup.files.length + uploadGroup.attachments.length;
  const uploadPercentage =
    uploadGroup.uploadEndTime || uploadGroup.totalSize === 0 ? 100.0 : Math.min(100.0, (100.0 * uploadGroup.weightedProgressSize) / uploadGroup.totalSize);

  let throughputText = '';
  let remainingTimeText = '';
  if (uploadGroup.uploadStartTime) {
    const effectiveEndTime = uploadGroup.uploadEndTime ?? now;
    const elapsedSeconds = (effectiveEndTime - uploadGroup.uploadStartTime) / 1000;
    if (elapsedSeconds > 0) {
      const throughput = uploadGroup.progressSize / elapsedSeconds;
      const weightedThroughput = uploadGroup.weightedProgressSize / elapsedSeconds;
      throughputText = formatBytes(throughput) + '/s';
      remainingTimeText = dayjs.duration((uploadGroup.totalSize - uploadGroup.weightedProgressSize) / weightedThroughput, 'seconds').humanize() + ' remaining';
      remainingTimeText = remainingTimeText.length > 0 ? remainingTimeText.charAt(0).toUpperCase() + remainingTimeText.slice(1) : remainingTimeText;
    }
  }

  useEffect(() => {
    // We can halt periodic updates if the upload is complete.

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    if (uploadGroup.uploadEndTime) return () => {};

    intervalRef.current = window.setInterval(() => {
      setNow(performance.timeOrigin + performance.now());
    }, 1000);

    return () => {
      if (intervalRef.current) {
        window.clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [uploadGroup.uploadEndTime]);

  return (
    <StyledComponentDiv className={className}>
      <StyledComponentHeaderDiv>
        <StyledCardHeaderTitleDiv>{uploadGroup.dicomData?.StudyDescription}</StyledCardHeaderTitleDiv>
      </StyledComponentHeaderDiv>

      <StyledDetailDiv>
        {uploadGroup.completedFiles !== totalFiles && (
          <>
            <StyledProgressBar value={uploadPercentage} size={ComponentSizes.LARGE} labelResolver={progressText} />
            <StyledProgressDiv>
              {uploadGroup.progressSize === 0 ? (
                <StyledProgressNotStartedSpan>Not started yet</StyledProgressNotStartedSpan>
              ) : (
                <>
                  <span>{remainingTimeText}</span>
                  <span>{throughputText}</span>
                </>
              )}
            </StyledProgressDiv>
          </>
        )}

        {uploadGroup.completedFiles === totalFiles && <StyledStatusBox status="success">Complete</StyledStatusBox>}
      </StyledDetailDiv>
    </StyledComponentDiv>
  );
});

UploadGroupProgressCard.displayName = 'UploadGroupProgressCard';

function progressText(value: number | null | undefined): string {
  return value == null ? '' : `${Math.floor(value)} %`;
}

function formatBytes(bytes: number): string {
  if (bytes === 0) {
    return '0 B';
  }
  const k = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const formatted = parseFloat((bytes / Math.pow(k, i)).toFixed(2));
  return `${formatted} ${sizes[i]}`;
}

const StyledComponentDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: min-content;
  align-content: center;
  padding: ${({ theme }) => theme.space.spacing40};
  column-gap: ${({ theme }) => theme.space.spacing40};
  row-gap: ${({ theme }) => theme.space.spacing40};
  background-color: ${({ theme }) => theme.colors.palette.white};
  border: 1px solid ${({ theme }) => theme.colors.palette.grayscale[4]};
  border-radius: 6px;
  height: calc(2 * ${({ theme }) => theme.space.spacing40} + 50px);
`;

const StyledComponentHeaderDiv = styled.div`
  display: flex;
`;

const StyledCardHeaderTitleDiv = styled.div`
  flex: 1 0 min-content;
  display: flex;
  align-items: center;
  user-select: none;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: ${({ theme }) => theme.fontSizes.heading2};
  line-height: ${({ theme }) => theme.lineHeights.heading2};
  white-space: nowrap;

  .k-checkbox-wrap {
    margin-right: ${({ theme }) => theme.space.spacing20};
  }
`;

const StyledDetailDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  flex-wrap: wrap;
`;

const StyledProgressBar = styled(ProgressBar)``;

const StyledProgressDiv = styled.div`
  display: flex;
  justify-content: space-between;
  user-select: none;
  padding-top: ${({ theme }) => theme.space.spacing10};
`;

const StyledProgressNotStartedSpan = styled.div`
  color: ${({ theme }) => theme.colors.palette.grayscale[6]};
`;

const StyledThroughputText = styled.div`
  grid-column: 1 / -1;
  font-size: ${({ theme }) => theme.fontSizes.body};
  color: ${({ theme }) => theme.colors.palette.grayscale[6]};
  text-align: right;
`;

const StyledStatusBox = styled(StatusBox)`
  margin: 0 0 0 auto;
`;

const StyledCompleteDiv = styled.div`
  display: flex;
  justify-content: end;
`;
