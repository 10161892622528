import { useEffect } from 'react';

import { UseSpringProps, config as springPresets, useSpringRef, useSprings } from '@react-spring/web';
import useMeasure from 'react-use-measure';

import { useEvent } from 'core/hooks';

import { UseAccordionItemViewResults } from './UseAccordionItemViewResults';

/**
 * Hook to manage the animation states of an accordion item.  This is used by the react-spring library to animate the expand/collapse of the
 * accordion body and the rotation of the expand/collapse icon.
 */
export function useAccordionNgItemView(mode: 'vertical' | 'horizontal', isExpanded: boolean, toggleExpanded: () => void): UseAccordionItemViewResults {
  const [accordionItemBodyRef, { width, height }] = useMeasure();

  const springApi = useSpringRef();
  const [animationStyles] = useSprings(
    2,
    (springIndex) => {
      // Initialization.
      const config: UseSpringProps = {
        ref: springApi,
        config: {
          ...springPresets.stiff,
          clamp: true,
        },
      };

      if (springIndex === 0) {
        // Accordion body.
        config.to =
          mode === 'vertical'
            ? {
                width: 'initial',
                height: isExpanded ? 'initial' : 0,
              }
            : {
                width: isExpanded ? 'initial' : 0,
                height: 'initial',
              };
      } else {
        // Accordion expand/collapse icon.
        config.to =
          mode === 'vertical'
            ? {
                rotate: isExpanded ? 0 : 180,
              }
            : {
                rotate: isExpanded ? 180 : 0,
              };
      }

      return config;
    },
    [],
  );

  const handleToggleClick = useEvent(() => {
    toggleExpanded();

    springApi.start((springIndex: number) => {
      const config: UseSpringProps = {
        ref: springApi,
        config: {
          ...springPresets.stiff,
          clamp: true,
        },
      };

      if (springIndex === 0) {
        // Accordion body.
        if (mode === 'vertical') {
          config.from = {
            height: isExpanded ? height : 0,
          };
          config.to = [
            {
              height: isExpanded ? 0 : height,
            },
            {
              height: isExpanded ? 0 : 'initial',
            },
          ];
        } else {
          config.from = {
            width: isExpanded ? width : 0,
          };
          config.to = [
            {
              width: isExpanded ? 0 : width,
            },
            {
              width: isExpanded ? 0 : 'initial',
            },
          ];
        }
      } else {
        // Accordion expand/collapse icon.
        config.from = {
          rotate: isExpanded ? 0 : 180,
        };
        config.to = {
          rotate: isExpanded ? 180 : 0,
        };
      }

      return config;
    });
  });

  const handleOrientationChange = useEvent(() => {
    springApi.start((springIndex: number) => {
      const config: UseSpringProps = {
        ref: springApi,
        config: {
          ...springPresets.stiff,
          clamp: true,
        },
      };

      if (springIndex === 0) {
        // Accordion body.
        config.to =
          mode === 'vertical'
            ? {
                width: 'initial',
                height: isExpanded ? 'initial' : 0,
              }
            : {
                width: isExpanded ? 'initial' : 0,
                height: 'initial',
              };
      } else {
        // Accordion expand/collapse icon.
        config.to =
          mode === 'vertical'
            ? {
                rotate: isExpanded ? 0 : 180,
              }
            : {
                rotate: isExpanded ? 180 : 0,
              };
      }

      return config;
    });
  });

  useEffect(() => {
    handleOrientationChange();
  }, [handleOrientationChange, mode]);

  return {
    accordionItemBodyRef,
    animationStyles: animationStyles as UseAccordionItemViewResults['animationStyles'],
    handleToggleClick,
  };
}
