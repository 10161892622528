import { PatientModel } from 'models';

import { hasText } from 'core/utils';

/**
 * Validates if a patient has all required fields filled out
 * @param patient The patient to validate
 * @param patientIdRequired Whether patient ID is required
 * @returns true if patient is valid, false otherwise
 */
export const validatePatient = (patient: PatientModel | null, patientIdRequired: boolean): boolean => {
  if (!patient) return false;

  // Check required fields
  if (!hasText(patient.firstName) || !hasText(patient.lastName)) {
    return false;
  }

  // Check patientNumber if required
  if (patientIdRequired && !hasText(patient.patientNumber)) {
    return false;
  }

  return true;
};
