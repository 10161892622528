import { memo } from 'react';

import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { LocationLiteModel } from 'models';

import { FieldContainer } from 'core/forms';
import { useEvent } from 'core/hooks';
import { Button, Modal } from 'core/ui';
import { DropdownNgField } from 'core/ui/DropdownNg';

import { useSessionLocation } from 'features/location';

const EMPTY_OPTIONS: LocationLiteModel[] = [];

type FormValuesType = { location: LocationLiteModel | null };

export const SessionLocationRequiredModal = memo(() => {
  const { sessionLocation, sessionLocationOptions, setSessionLocation } = useSessionLocation();

  const rhfContext = useForm<FormValuesType>({
    defaultValues: { location: sessionLocation },
  });

  const handleSubmit: SubmitHandler<FormValuesType> = useEvent((data) => {
    setSessionLocation(data.location);
  });

  return (
    <Modal show backdrop="static" keyboard={false} title="Select Location">
      <FormProvider {...rhfContext}>
        <StyledDialogBodyForm
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="none"
          spellCheck="false"
          noValidate
          onSubmit={rhfContext.handleSubmit(handleSubmit)}
        >
          <p>A location must be selected to continue.</p>

          <FieldContainer $hideLabel>
            <StyledLocationDropdownNg
              name="location"
              data={sessionLocationOptions ?? EMPTY_OPTIONS}
              dataItemKey="id"
              textField="name"
              tabIndex={0}
              textResolver={resolveLocationText as (dataItem: Record<string, unknown>) => string}
              filterDescriptorResolver={filterLocations}
              required
            />
          </FieldContainer>

          <StyledActionButtonsDiv>
            <Button type="submit">Proceed</Button>
          </StyledActionButtonsDiv>
        </StyledDialogBodyForm>
      </FormProvider>
    </Modal>
  );
});

SessionLocationRequiredModal.displayName = 'SessionLocationRequiredModal';

function filterLocations(filterText: string): CompositeFilterDescriptor {
  return {
    logic: 'or',
    filters: [
      { field: 'code', operator: 'contains', value: filterText },
      { field: 'name', operator: 'contains', value: filterText },
    ],
  };
}

function resolveLocationText(location: LocationLiteModel): string {
  return location.id == null ? location.name : `${location.code} | ${location.name}`;
}

const StyledDialogBodyForm = styled.form`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
  width: 400px;
  padding: 24px;
`;

const StyledLocationDropdownNg = styled(DropdownNgField)`
  && {
    width: 200px;
  }
`;

const StyledActionButtonsDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 24px 0 0 0;
`;
