import { memo, useCallback } from 'react';

import { CheckboxBlurEvent, CheckboxChangeEvent } from '@progress/kendo-react-inputs';
import { useController } from 'react-hook-form';

import { Checkbox } from './Checkbox';
import { CheckboxFieldProps } from './CheckboxFieldProps';

export const CheckboxField = memo<CheckboxFieldProps>(({ name, validator, onChange, onBlur, ...rest }) => {
  const {
    field: { onChange: rhfOnChange, onBlur: rhfOnBlur, value, ...fieldRest },
    formState,
    fieldState: { isTouched, invalid, error },
  } = useController({ name, rules: { validate: validator } });

  const handleChange = useCallback(
    (event: CheckboxChangeEvent) => {
      rhfOnChange(event);
      onChange?.(event);
    },
    [onChange, rhfOnChange],
  );

  const handleBlur = useCallback(
    (event: CheckboxBlurEvent) => {
      rhfOnBlur();
      onBlur?.(event);
    },
    [onBlur, rhfOnBlur],
  );

  return (
    <Checkbox
      {...fieldRest}
      {...rest}
      value={formState.isLoading ? false : value}
      name={name}
      valid={!invalid}
      visited={formState.isSubmitted || isTouched}
      validationMessage={error?.message}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
});

CheckboxField.displayName = 'CheckboxField';
