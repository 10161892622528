import { memo } from 'react';

import { Label } from '@progress/kendo-react-labels';
import styled from 'styled-components';

import { SuccessMessageProps } from './SuccessMessageProps';

export const SuccessMessage = memo<SuccessMessageProps>(({ children, className, id }) => {
  return (
    <StyledSuccessBase className={className} id={id}>
      {children}
    </StyledSuccessBase>
  );
});

SuccessMessage.displayName = 'SuccessMessage';

const StyledSuccessBase = styled(Label)`
  flex: 1 1 auto;
  font-size: ${({ theme }) => theme.fontSizes.body};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  line-height: ${({ theme }) => theme.lineHeights.body};
  color: ${({ theme }) => theme.colors.success};
  background-color: ${({ theme }) => theme.colors.palette.greens[0]};
  border: 1px solid ${({ theme }) => theme.colors.palette.greens[1]};
  margin-top: 0;
  display: flex;
  overflow: hidden;
  align-items: center;
  padding: ${({ theme }) => theme.space.spacing10};
`;
