import { FunctionComponent } from 'react';

import styled from 'styled-components';

import { FileModel } from 'models';

import { useAccessTokenSnapshot } from 'features/auth';
import { FileUrlService } from 'features/exam/services/file-url-service';

import { AliveCorSingleLeadViewerProps } from '../types';

export const AliveCorSingleLeadViewer: FunctionComponent<AliveCorSingleLeadViewerProps> = ({ className, subFiles }) => {
  const { accessToken } = useAccessTokenSnapshot();

  if (subFiles == null || accessToken == null) return null;

  return (
    <StyledScrollContainer className={className}>
      {subFiles.map((f: FileModel) => (
        <img key={f.id} src={FileUrlService.resolveFileUrl(f, accessToken)} alt={f.fileName ?? ''} />
      ))}
    </StyledScrollContainer>
  );
};

AliveCorSingleLeadViewer.displayName = 'AliveCorSingleLeadViewer';

const StyledScrollContainer = styled.div`
  overflow: auto;
`;
