import { memo, useId, useState } from 'react';

import { Popup } from '@progress/kendo-react-popup';
import cn from 'classnames';
import styled, { ExecutionContext } from 'styled-components';

import { useBoolean } from 'core/hooks';

import { StatusBoxProps } from './StatusBoxProps';

export const StatusBox = memo<StatusBoxProps>(({ className, status, tooltip, children }) => {
  const statusDivId = `StatusBox_${useId()}`;
  const [statusEle, setStatusEle] = useState<HTMLElement | null>(null);
  const [isTooltipVisible, { setTrue: setTooltipVisible, setFalse: setTooltipHidden }] = useBoolean(false);

  return (
    <>
      {tooltip != null && (
        <StyledPopup
          anchor={statusEle}
          show={isTooltipVisible}
          onClose={setTooltipHidden}
          animate={false}
          onMouseDownOutside={setTooltipHidden}
          $status={status}
        >
          {tooltip}
        </StyledPopup>
      )}
      <StyledComponentDiv ref={setStatusEle} id={statusDivId} className={cn(className, status)} $status={status} onClick={setTooltipVisible}>
        {children}
      </StyledComponentDiv>
    </>
  );
});

StatusBox.displayName = 'StatusBox';

function resolveBorder({ $status, theme }: ExecutionContext & { $status: StatusBoxProps['status'] }) {
  switch ($status) {
    case 'success':
      return `1px solid ${theme.colors.palette.greens[2]}`;
    case 'info':
      return `1px solid ${theme.colors.palette.blues[5]}`;
    case 'warning':
      return `1px solid ${theme.colors.palette.yellows[2]}`;
    case 'error':
      return `1px solid ${theme.colors.palette.reds[2]}`;
    case 'neutral':
      return `1px solid ${theme.colors.palette.grayscale[5]}`;
    default:
      return `1px solid transparent`;
  }
}

function resolveBackgroundColor({ $status, theme }: ExecutionContext & { $status: StatusBoxProps['status'] }) {
  switch ($status) {
    case 'success':
      return theme.colors.palette.greens[0];
    case 'info':
      return theme.colors.palette.blues[0];
    case 'warning':
      return theme.colors.palette.yellows[0];
    case 'error':
      return theme.colors.palette.reds[0];
    case 'neutral':
      return theme.colors.palette.grayscale[0];
    default:
      return 'transparent';
  }
}

function resolveIconColor({ theme, $status }: ExecutionContext & { $status: StatusBoxProps['status'] }) {
  switch ($status) {
    case 'success':
      return theme.colors.palette.greens[2];
    case 'info':
      return theme.colors.palette.blues[5];
    case 'warning':
      return theme.colors.palette.yellows[4];
    case 'error':
      return theme.colors.palette.reds[2];
    case 'neutral':
      return theme.colors.palette.grayscale[5];
    default:
      return theme.colors.palette.grayscale[5];
  }
}

const StyledComponentDiv = styled.div<{ $status: StatusBoxProps['status'] }>`
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: ${resolveBorder};
  background-color: ${resolveBackgroundColor};
  user-select: none;
  font-size: ${({ theme }) => theme.fontSizes.body};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  line-height: ${({ theme }) => theme.lineHeights.body};
  height: ${({ theme }) => theme.sizes.large};
  padding: 0 ${({ theme }) => theme.space.spacing30};

  .icon-container {
    color: ${resolveIconColor};
  }
`;

const StyledPopup = styled(Popup)<{ $status: StatusBoxProps['status'] }>`
  .k-popup {
    background-color: ${({ theme }) => theme.colors.palette.grayscale[8]};
    color: ${({ theme }) => theme.colors.palette.white};
    border: none;
    padding: ${({ theme }) => theme.space.spacing20};
  }
`;
