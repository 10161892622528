import { AuthenticationScheme } from '../constants';
import { useAuthentication } from './useAuthentication';

/** Simple helper to perform the common case of ensuring that the authentication system is ready for API calls to be made and that
 * the intended scheme is active before performing a query.  This will NOT throw if the authentication is still initializing which
 * is indicated by `activeScheme` being `undefined`.  This WILL throw once authentication is initialized, but the active scheme is
 * not specified in `allowedSchemes`. */
export function useAssertFetchPermitted<TAllowedSchemes extends AuthenticationScheme[]>(allowedSchemes: TAllowedSchemes) {
  const { activeScheme } = useAuthentication();

  // Validate that the active scheme is allowed (if it exists)
  if (activeScheme != null && !allowedSchemes.includes(activeScheme)) {
    throw new Error(`Invalid authentication scheme. Allowed schemes: ${allowedSchemes.join(', ')}.`);
  }

  return activeScheme as TAllowedSchemes[number] | undefined;
}
