import { memo, useState } from 'react';

import { faCircleInfo, faFileLines, faPaperclip, faUser } from '@fortawesome/pro-solid-svg-icons';
import { CheckboxChangeEvent } from '@progress/kendo-react-inputs';
import styled from 'styled-components';

import { useDataStream, useEvent } from 'core/hooks';
import { BreakpointSelectors, Button, Checkbox, ComponentSizes, Icon, PAGE_GRID_CLASSES, StatusBox } from 'core/ui';

import { useQueryPatient } from 'features/api';
import { PatientUtils } from 'features/patient';
import { useUploadPipeline } from 'features/upload-pipeline/hooks/useUploadPipeline';

import { useUploadExamsPageContext } from '../hooks';
import { Footer } from './Footer';
import { ReviewExamCard } from './ReviewExamCard';

type ReviewExamsStepProps = {
  onNext: () => void;
};

export const ReviewExamsStep = memo<ReviewExamsStepProps>(({ onNext }) => {
  const { uploadGroups, uploadView, patientId } = useUploadExamsPageContext();
  const uploadPipeline = useUploadPipeline();
  const [selectAll, setSelectAll] = useState<boolean>(() => uploadGroups.filter((g) => g.checked).length === uploadGroups.length);
  const [{ data: patient, isSuccess: isPatientQuerySuccess }] = useQueryPatient(patientId);

  const scanProgress = useDataStream(uploadView.streams.scanProgress);
  const checkedUploadGroups = uploadGroups.filter((g) => g.checked);

  const handleSelectAllChange = useEvent((event: CheckboxChangeEvent) => {
    setSelectAll(event.value);

    for (const group of uploadGroups) {
      uploadView.updateGroup(group.uploadGroupId, { checked: event.value });
    }
  });

  const handleNextClick = useEvent(() => {
    uploadPipeline.startUpload(uploadGroups.filter((g) => g.checked).flatMap((g) => [...g.files, ...g.attachments]));
    onNext();
  });

  const handleUploadGroupCheckedChange = useEvent((uploadGroupId: string, checked: boolean) => {
    const tempGroups = uploadView.updateGroup(uploadGroupId, { checked });
    const checkedCount = tempGroups.filter((g) => g.checked).length;
    setSelectAll(checkedCount === tempGroups.length ? true : false);
  });

  return (
    <>
      <StyledPrimaryDiv>
        <StyledHeaderDiv>
          <StyledScanSummaryDiv>Exams Detected: {uploadGroups.length}</StyledScanSummaryDiv>
        </StyledHeaderDiv>

        <StyledUploadGroupsListOuterDiv>
          <StyledUploadGroupsListHeaderDiv>
            <StyledUploadGroupsListHeaderColumnDiv>
              <Checkbox onChange={handleSelectAllChange} value={selectAll} />
            </StyledUploadGroupsListHeaderColumnDiv>

            <StyledUploadGroupsListHeaderColumnDiv>
              <Icon icon={faFileLines} fixedWidth={false} />
              Exam Data
            </StyledUploadGroupsListHeaderColumnDiv>

            <StyledUploadGroupsListHeaderColumnDiv>
              <Icon icon={faUser} fixedWidth={false} />
              Patient Data
            </StyledUploadGroupsListHeaderColumnDiv>

            <StyledUploadGroupsListHeaderColumnDiv>
              <Icon icon={faPaperclip} fixedWidth={false} />
              Attachments
            </StyledUploadGroupsListHeaderColumnDiv>
          </StyledUploadGroupsListHeaderDiv>

          <StyledUploadGroupsListInnerDiv>
            {uploadGroups.map((item) => (
              <StyledReviewExamCard key={item.uploadGroupId} uploadGroup={item} onUploadGroupCheckedChange={handleUploadGroupCheckedChange} />
            ))}
          </StyledUploadGroupsListInnerDiv>
        </StyledUploadGroupsListOuterDiv>
      </StyledPrimaryDiv>

      <Footer className={PAGE_GRID_CLASSES.Flush}>
        {isPatientQuerySuccess && (
          <StyledFooterStatusBox status="info">
            <Icon icon={faCircleInfo} fixedWidth={false} />
            Selected {checkedUploadGroups.length === 1 ? 'exam' : 'exams'} will be uploaded to {PatientUtils.formatName(patient.firstName, patient.lastName)}
          </StyledFooterStatusBox>
        )}

        <Button
          size={ComponentSizes.LARGE}
          onClick={handleNextClick}
          disabled={scanProgress.complete !== scanProgress.total || scanProgress.total === 0 || uploadGroups.filter((g) => g.checked).length === 0}
        >
          Upload Selected ({checkedUploadGroups.length}/{uploadGroups.length})
        </Button>
      </Footer>
    </>
  );
});

ReviewExamsStep.displayName = 'ReviewExamsStep';

const StyledPrimaryDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
  overflow: hidden;
  row-gap: ${({ theme }) => theme.space.spacing10};
  padding: ${({ theme }) => theme.space.spacing40} 0 0 0;
`;

const StyledHeaderDiv = styled.div`
  display: grid;
  grid-template-columns: min-content;
  justify-content: space-between;
  user-select: none;
`;

const StyledScanSummaryDiv = styled.div`
  white-space: nowrap;
  font-size: ${({ theme }) => theme.fontSizes.heading1};
  line-height: ${({ theme }) => theme.lineHeights.heading1};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  color: ${({ theme }) => theme.colors.textPrimary};
`;

const StyledUploadGroupsListOuterDiv = styled.div`
  ${BreakpointSelectors.Desktop} & {
    display: grid;
    overflow: hidden;
    grid-template-columns: 50px 1fr 1fr 1fr 1fr;
    grid-template-rows: min-content 1fr;
    column-gap: ${({ theme }) => theme.space.spacing40};
    row-gap: 16px;
    padding: ${({ theme }) => theme.space.spacing40} 0 0 0;
  }

  ${BreakpointSelectors.Mobile} & {
    display: grid;
    overflow: hidden;
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr;
  }
`;

const StyledUploadGroupsListInnerDiv = styled.div`
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: subgrid;
  grid-auto-rows: min-content;
  row-gap: ${({ theme }) => theme.space.spacing20};
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 0 ${({ theme }) => theme.space.spacing20} 0;
`;

const StyledUploadGroupsListHeaderDiv = styled.div`
  display: grid;
  grid-template-columns: subgrid;
  grid-template-rows: min-content;
  grid-column: 1 / -1;
  padding: 0 ${({ theme }) => theme.space.spacing40};
  align-items: center;
`;

const StyledUploadGroupsListHeaderColumnDiv = styled.div`
  display: grid;
  grid-template-columns: min-content min-content;
  align-items: center;
  white-space: nowrap;
  user-select: none;
  column-gap: ${({ theme }) => theme.space.spacing40};
  font-size: ${({ theme }) => theme.fontSizes.heading2};
  line-height: ${({ theme }) => theme.lineHeights.heading2};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};

  // Hide headers except for the "Select All" checkbox on mobile.
  ${BreakpointSelectors.Mobile} &:not(:first-child) {
    display: none;
  }

  .icon-container {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const StyledReviewExamCard = styled(ReviewExamCard)`
  grid-column: 1 / -1;
`;

const StyledNextButtonContainer = styled.div`
  flex: 0 0 min-content;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  align-items: center;
  column-gap: ${({ theme }) => theme.space.spacing40};
`;

const StyledFooterStatusBox = styled(StatusBox)`
  column-gap: ${({ theme }) => theme.space.spacing20};
`;
