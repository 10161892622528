import { FunctionComponent, useCallback, useEffect, useState } from 'react';

import { initial, result } from 'lodash';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { LocationModel, UserModel, WorkListViewUserModel, WorklistViewAccessListModel, WorklistViewAccessModel, WorklistViewModel } from 'models';

import { apiClient } from 'core/api/globals';
import { Field, FieldContainer, GridColumn, createStyledRhfForm } from 'core/forms';
import { RHF_FULL_RESET } from 'core/forms/constants';
import { useEvent, useValidatedParam } from 'core/hooks';
import { NotificationsService } from 'core/notifications';
import { Button, ButtonVariants, DropdownField, Label, MultiSelectChangeEvent, MultiSelectField, Page, PageHeader, SwitchField } from 'core/ui';

import { WorklistViewUserEditService } from '../services';
import { EditFormValues } from '../types';

export const WorklistViewUserForm: FunctionComponent = () => {
  const id = useValidatedParam('id', 'integer', false);
  const navigate = useNavigate();

  const [model, setModel] = useState<WorkListViewUserModel | null>(null);
  const [accessModel, setAccessModel] = useState<WorklistViewAccessListModel | null>(null);
  const [allUsers, setAllUsers] = useState<UserModel[] | null>(null);
  const [allAccessModes, setAllAccessModes] = useState<WorklistViewAccessModel[] | null>(null);
  const [allWorklists, setAllWorklists] = useState<WorklistViewModel[] | null>(null);
  const [selectedLocations, setSelectedLocations] = useState<LocationModel[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<UserModel[]>([]);

  const [locations, setLocations] = useState<LocationModel[]>([]);

  const isInitializing = (accessModel == null && id != null) || allUsers == null || allAccessModes == null || allWorklists == null;

  const rhfContext = useForm<EditFormValues>({
    defaultValues: {
      worklist: null,
      users: [],
      access: null,
      active: false,
      default: false,
      locations: [],
    },
  });
  const { reset } = rhfContext;

  const handleSubmit: SubmitHandler<EditFormValues> = useCallback(
    async (data, event) => {
      // const newModel = await WorklistViewUserEditService.save({ ...data, locations: selectedLocations, users: selectedUsers }, accessModel);

      // const newFormModel: WorklistViewAccessListModel = ;
      const newModel = await WorklistViewUserEditService.save(data, accessModel);
      // const newModel = await WorklistViewUserEditService.save(WorklistViewUserEditService.copyFormToModel(accessModel, data), accessModel);

      setAccessModel(newModel);
      NotificationsService.displaySuccess('Worklist access saved');
      navigate('/worklist-view-user');
    },
    [accessModel, navigate],
  );

  const handleDeleteClick = useCallback(async () => {
    if (accessModel == null) {
      throw new Error('Cannot delete access because the model is null or undefined.');
    }

    WorklistViewUserEditService.deleteAccess(accessModel.id);
    NotificationsService.displaySuccess('Worklist access removed');
    navigate('/worklist-view-user');
  }, [accessModel, navigate]);

  useEffect(() => {
    (async () => {
      const results = await WorklistViewUserEditService.initialize(id);

      setAccessModel(results.model);

      setAllUsers(results.allUsers);
      setAllAccessModes(results.allWorklistAccessType);
      setAllWorklists(results.allWorklists);

      // reset(results.form, RHF_FULL_RESET);

      const [newLocations] = await Promise.all([apiClient.locationClient.getAllLocations()]);
      setLocations(newLocations);

      reset(
        WorklistViewUserEditService.copyModelToForm(results.model, results.allWorklistAccessType, results.allUsers, results.allWorklists, newLocations),
        RHF_FULL_RESET,
      );
    })();
  }, [id, reset]);

  const handleFormLocationChange = useEvent((data: MultiSelectChangeEvent) => {
    if (data) {
      const selectedValues = data.value as LocationModel[];

      if (selectedValues) {
        setSelectedLocations(selectedValues);
      }
    }
  });

  const handleFormUserChange = useEvent((data: MultiSelectChangeEvent) => {
    if (data) {
      const selectedValues = data.value as UserModel[];

      if (selectedValues) {
        setSelectedUsers(selectedValues);
      }
    }
  });

  if (isInitializing) return null;

  return (
    <FormProvider {...rhfContext}>
      <StyledPage>
        <PageHeader title={id == null ? 'New Worklist Access Rule' : 'Edit Worklist Access Rule'} />
        <StyledForm autoComplete="off" autoCorrect="off" autoCapitalize="none" spellCheck="false" noValidate onSubmit={rhfContext.handleSubmit(handleSubmit)}>
          <GridColumn columnStart="1" isLabelColumn>
            <Label required>Worklist</Label>
          </GridColumn>
          <GridColumn columnStart="2">
            <FieldContainer $hideLabel>
              <DropdownField id="worklist" name="worklist" data={allWorklists} dataItemKey="id" textField="name" required />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label required>Access</Label>
          </GridColumn>
          <GridColumn columnStart="2">
            <FieldContainer $hideLabel>
              <DropdownField name="access" data={allAccessModes} dataItemKey="id" textField="name" required filterable={false} />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Default</Label>
          </GridColumn>
          <GridColumn columnStart="2">
            <FieldContainer $hideLabel>
              <SwitchField name="default" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Active</Label>
          </GridColumn>
          <GridColumn columnStart="2">
            <FieldContainer $hideLabel>
              <SwitchField name="active" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label required>User</Label>
          </GridColumn>
          <GridColumn columnStart="2">
            <FieldContainer $hideLabel>
              <MultiSelectField
                name="users"
                onChange={handleFormUserChange}
                data={allUsers}
                dataItemKey="id"
                textField="email"
                required
                defaultValue={accessModel?.users}
              />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label required>Location</Label>
          </GridColumn>
          <GridColumn columnStart="2">
            <FieldContainer $hideLabel>
              <MultiSelectField
                name="locations"
                data={locations}
                onChange={handleFormLocationChange}
                dataItemKey="id"
                textField="name"
                defaultValue={accessModel?.locations}
              />
            </FieldContainer>
          </GridColumn>

          <StyledActionsColumn columnStart="1" columnEnd="span 2">
            <Button variant={ButtonVariants.SECONDARY} disabled={model == null} onClick={handleDeleteClick}>
              Delete
            </Button>
            <Button type="submit">Save</Button>
          </StyledActionsColumn>
        </StyledForm>
      </StyledPage>
    </FormProvider>
  );
};

WorklistViewUserForm.displayName = 'WorklistViewUserForm';

const StyledForm = createStyledRhfForm('min-content 400px min-content');

const StyledActionsColumn = styled(GridColumn)`
  display: flex;
  justify-content: space-between;
`;

const StyledPage = styled(Page)`
  font-size: 14px;

  .k-chip-content {
    font-size: 14px;
  }
`;
