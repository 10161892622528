import { FunctionComponent } from 'react';

import styled, { DefaultTheme } from 'styled-components';

import { ComponentSizes } from '../constants';
import { ProgressBarLabelProps } from './ProgressBarLabelProps';
import { ProgressBarType } from './constants';

export const ProgressBarLabel: FunctionComponent<ProgressBarLabelProps> = ({
  size = ComponentSizes.MEDIUM,
  subType = ProgressBarType.DEFAULT,
  value = 0,
  labelResolver,
}) => {
  if (subType === ProgressBarType.DEFAULT) {
    return (
      <StyledSpanLabel $size={size} $subType={subType}>
        {labelResolver != null ? labelResolver(value) : `${value} %`}
      </StyledSpanLabel>
    );
  }

  if (subType === ProgressBarType.SUCCESS) {
    return <StyledSpanIcon className="fa fa-circle-check" $size={size} $subType={subType} />;
  }

  if (subType === ProgressBarType.ERROR) {
    return <StyledSpanIcon className="fa fa-circle-xmark" $size={size} $subType={subType} />;
  }

  return <></>;
};

ProgressBarLabel.displayName = 'ProgressBarLabel';

function resolvePalette(theme: DefaultTheme, subType: ProgressBarType) {
  switch (subType) {
    case ProgressBarType.DEFAULT: {
      return {
        fill: theme.colors.secondary,
        text: theme.colors.textPrimary,
      };
    }
    case ProgressBarType.SUCCESS: {
      return {
        fill: theme.colors.success,
        text: theme.colors.textPrimary,
      };
    }
    case ProgressBarType.ERROR: {
      return {
        fill: theme.colors.error,
        text: theme.colors.textPrimary,
      };
    }
    default:
      return {
        fill: theme.colors.secondary,
        text: theme.colors.textPrimary,
      };
  }
}

function resolveSize(size: ComponentSizes, theme: DefaultTheme) {
  switch (size) {
    case ComponentSizes.SMALL:
    case ComponentSizes.MEDIUM: {
      return {
        fontSize: theme.fontSizes.body,
        lineHeight: theme.lineHeights.body,
        iconSize: theme.sizes.progressBarLabelIconDefault,
      };
    }
    case ComponentSizes.LARGE:
      return {
        fontSize: theme.fontSizes.subheading,
        lineHeight: theme.lineHeights.subheading,
        iconSize: theme.sizes.progressBarLabelIconLarge,
      };
    default:
      return {
        fontSize: theme.fontSizes.body,
        lineHeight: theme.lineHeights.body,
        iconSize: theme.sizes.progressBarLabelIconDefault,
      };
  }
}

function resolveTextColor(theme: DefaultTheme, subType: ProgressBarType) {
  const { text } = resolvePalette(theme, subType);

  return text;
}

function resolveFillColor(theme: DefaultTheme, type: ProgressBarType) {
  const { fill } = resolvePalette(theme, type);

  return fill;
}

function resolveFontSize(size: ComponentSizes, theme: DefaultTheme) {
  const { fontSize } = resolveSize(size, theme);

  return fontSize;
}

function resolveLineHeight(size: ComponentSizes, theme: DefaultTheme) {
  const { lineHeight } = resolveSize(size, theme);

  return lineHeight;
}

function resolveIconSize(size: ComponentSizes, theme: DefaultTheme) {
  const { iconSize } = resolveSize(size, theme);

  return iconSize;
}

const StyledSpanLabel = styled.span<{
  $size: ComponentSizes;
  $subType: ProgressBarType;
}>`
  color: ${({ theme, $subType }) => resolveTextColor(theme, $subType)};
  font-size: ${({ $size, theme }) => resolveFontSize($size, theme)};
  line-height: ${({ $size, theme }) => resolveLineHeight($size, theme)};
  white-space: nowrap;
  user-select: none;
  min-width: 5ch; // Give a minimum size to fit the text "100 %"
`;

const StyledSpanIcon = styled.span<{
  $size: ComponentSizes;
  $subType: ProgressBarType;
}>`
  color: ${({ theme, $subType }) => resolveFillColor(theme, $subType)};
  font-size: ${({ $size, theme }) => resolveIconSize($size, theme)};
`;
