import { datadogLogs } from '@datadog/browser-logs';
import cornerstone from 'cornerstone-core';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import dayjs from 'dayjs';
import dicomParser from 'dicom-parser';

import { EventStream, IEventStreamConsumer, MIME_TYPES, equalsInsensitive, getFileExtension, hasText, lookupMimeType } from 'core/utils';

import {
  DATE_PARSE_FORMATS,
  DICOM_UNCOMPRESSED_TRANSFER_SYNTAXES,
  NORMALIZED_DATE_FORMAT,
  NORMALIZED_TIME_FORMAT,
  PARSE_DICOM_CARET_DELIMITED_TAGS,
  PARSE_DICOM_DATE_TAGS,
  PARSE_DICOM_METADATA_FILE_DATA_OPTIONS,
  PARSE_DICOM_METADATA_OPTIONS,
  PARSE_DICOM_TAGS,
  PARSE_DICOM_TIME_TAGS,
  TIME_PARSE_FORMATS,
  UNCOMPRESSED_DICOM_WARNING_MINIMUM_SIZE,
} from '../constants';
import { FileUploadContext, ParseCompleteEvent, ParsedDicomMetadata, ProcessJobEvent, UploadLockRequest, UploadLockRequestType } from '../types';
import { ImageDataCache } from './ImageDataCache';
import { PriorityLock } from './PriorityLock';

const cornerstoneConfig = {
  maxWebWorkers: navigator.hardwareConcurrency || 1,
  startWebWorkersOnDemand: true,
  taskConfiguration: {
    decodeTask: {
      initializeCodecsOnStartup: false,
    },
  },
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
cornerstoneWADOImageLoader.webWorkerManager.initialize(cornerstoneConfig);
cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;

export class FileScanner {
  static [Symbol.toStringTag]() {
    return 'FileScanner';
  }

  /** The key is the SeriesInstanceUID.  The value is the browser generated random id assigned to a series. */
  private _dicomSeriesIdLookup = new Map<string, string>();

  private _uploadSessionId: string | null = null;

  private _streams = {
    onParseComplete: new EventStream<ProcessJobEvent<ParseCompleteEvent>>(),
  };

  public get streams(): {
    onParseComplete: IEventStreamConsumer<ProcessJobEvent<ParseCompleteEvent>>;
  } {
    return this._streams;
  }

  /** DICOM file scanner and thumbnail generator.
   * @param _thumbnailCache The cache used to store generated thumbnails.
   * @param _dicomCache The cache used to store parsed DICOM metadata.  The fileId is the key.
   */
  constructor(private _thumbnailCache: ImageDataCache) {
    this.initialize = this.initialize.bind(this);
    this.reset = this.reset.bind(this);
    this.process = this.process.bind(this);
    this.scanNonDicomFile = this.scanNonDicomFile.bind(this);
    this.scanDicomFile = this.scanDicomFile.bind(this);
    this.extractDicomMetadata = this.extractDicomMetadata.bind(this);
  }

  public initialize(uploadSessionId: string) {
    // Treat the FileScanner as already initialized and leave it unchanged if it has already been initialized.
    if (this._uploadSessionId != null) throw new Error('FileScanner has already been initialized.  Call reset() before initializing again.');

    this._uploadSessionId = uploadSessionId;
  }

  public reset() {
    // Treat a null uploadSessionId as indicitative of the FileScanner not being initialized or having been already reset, and therefore we do not need to do anything.
    if (this._uploadSessionId == null) return;

    this._uploadSessionId = null;
    this._dicomSeriesIdLookup.clear();
  }

  public process(file: Readonly<FileUploadContext>, lock: PriorityLock<UploadLockRequest>) {
    if (file.classification === 'dicom') {
      // Updated call with fileId and file parameters
      this.scanDicomFile(file.fileId, file.file, lock);
    } else {
      this.scanNonDicomFile(file.fileId, file.file);
    }
  }

  private scanNonDicomFile(fileId: string, file: File) {
    // Attempt to get a canonical mime type for the file.  This will help normalize things like capitilization and things like .JPG vs .JPEG.
    // Helpful because the backend does a lot of hardcoding for things like this.
    const mime = lookupMimeType(file.name);

    this._streams.onParseComplete.emit({
      result: 'processed',
      fileId,
      file,
      dicomData: null,
      uncompressedDicomWarning: false,
      startTime: performance.now(),
      duration: 0,
      fileType: mime?.extensions?.[0] ?? getFileExtension(file.name),
      didReadFile: false,
    });
  }

  // Updated scanDicomFile method signature and internal references:
  private async scanDicomFile(fileId: string, file: File, lock: PriorityLock<UploadLockRequest>) {
    if (this._uploadSessionId == null) throw new Error('Cannot scan a DICOM file without an upload session ID.');

    let releaseLock: (() => void) | undefined;

    try {
      let endMark: number | null = null;
      let measure: PerformanceMeasure | null = null;
      let success = false;

      releaseLock = await lock.requestLock({ type: UploadLockRequestType.ScanFile });

      const startMark = performance.now();

      try {
        const dicomData = await this.extractDicomMetadata(fileId, file);

        endMark = performance.now();

        success = true;

        // Skip files that are not DICOM.
        if (dicomData == null) {
          this._streams.onParseComplete.emit({ result: 'skipped', fileId });
          return;
        }

        // Check if the file is an uncompressed DICOM file.  We only want to warn the user if the file is relatively large.
        const uncompressedDicomWarning =
          file.size >= UNCOMPRESSED_DICOM_WARNING_MINIMUM_SIZE && DICOM_UNCOMPRESSED_TRANSFER_SYNTAXES.includes(dicomData.TransferSyntaxUID!);

        let seriesId = this._dicomSeriesIdLookup.get(dicomData.SeriesInstanceUID!) ?? null;

        if (seriesId == null && hasText(dicomData.SeriesInstanceUID)) {
          seriesId = crypto.randomUUID();
          this._dicomSeriesIdLookup.set(dicomData.SeriesInstanceUID, seriesId);
          this._thumbnailCache.set(seriesId, null);
        }

        measure = performance.measure(`parse-dicom:${fileId}`, {
          detail: {
            fileId,
            fileName: file.name,
            fileSize: file.size,
            devtools: {
              dataType: 'track-entry',
              trackGroup: 'Upload Pipeline',
              track: 'DICOM Parsing',
              color: 'primary',
              properties: [
                ['fileId', fileId],
                ['fileName', file.name],
                ['fileSize', file.size],
              ],
            },
          },
          start: startMark,
          end: endMark,
        });

        datadogLogs.logger.info('parse-dicom', {
          uploadSessionId: this._uploadSessionId,
          fileId,
          date: performance.timeOrigin + startMark,
          end_date: performance.timeOrigin + startMark + measure.duration,
          duration: measure.duration * 1000000, // Datadog expects durations to be in nanoseconds.
          success,
        });

        this._streams.onParseComplete.emit({
          result: 'processed',
          fileId,
          file,
          dicomData: dicomData,
          uncompressedDicomWarning,
          startTime: performance.timeOrigin + startMark,
          duration: endMark - startMark,
          fileType: MIME_TYPES.DCM.extensions[0],
          didReadFile: true,
        });
      } catch (error) {
        // File is not DICOM.  For now our intendend behavior is to skip such files.  This may change in the future.
        datadogLogs.logger.error(
          'Error parsing file.',
          {
            uploadSessionId: this._uploadSessionId,
            fileId,
          },
          error as Error | undefined,
        );

        this._streams.onParseComplete.emit({ result: 'skipped', fileId });
      }
    } finally {
      releaseLock?.();
    }
  }

  private async readFileSlice(file: File, start: number, length: number): Promise<Uint8Array> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target?.result instanceof ArrayBuffer) {
          resolve(new Uint8Array(event.target.result));
        } else {
          reject(new Error('Unexpected result type'));
        }
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file.slice(start, start + length));
    });
  }

  private async extractDicomMetadata(fileId: string, file: File): Promise<ParsedDicomMetadata | null> {
    if (this._uploadSessionId == null) throw new Error('Cannot extract DICOM metadata without an upload session ID.');

    // Do an initial check to see if the file is a DICOM file based on the file extension.  This won't catch everything because
    // some DICOM files don't have a file extension.
    const fileExtension = getFileExtension(file.name);
    if (fileExtension != null && !(lookupMimeType('.dcm')?.extensions ?? []).some((ext) => equalsInsensitive(ext, fileExtension))) {
      return null;
    }

    const chunkSize = 4000; // Adjust as needed.
    let readBuffer: Uint8Array;
    try {
      // Read only the first chunk.
      readBuffer = await this.readFileSlice(file, 0, chunkSize);
    } catch (err) {
      datadogLogs.logger.error('Error reading file slice.', { uploadSessionId: this._uploadSessionId, fileId }, err as Error | undefined);
      return null;
    }

    let dataSet;
    try {
      // Attempt a partial parse, reading until a specified tag.
      dataSet = dicomParser.parseDicom(readBuffer, { untilTag: 'x0020000e' });
      // Check if a key tag is present (for example, StudyInstanceUID should be in x0020000d).
      if (!dataSet.elements.x0020000d) {
        // If not, read the entire file.
        readBuffer = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (event) => {
            if (event.target?.result instanceof ArrayBuffer) {
              resolve(new Uint8Array(event.target.result));
            } else {
              reject(new Error('Unexpected result type'));
            }
          };
          reader.onerror = reject;
          reader.readAsArrayBuffer(file);
        });
        dataSet = dicomParser.parseDicom(readBuffer, PARSE_DICOM_METADATA_OPTIONS);
      }

      // Convert the dataset into a JavaScript object.
      const fileData = dicomParser.explicitDataSetToJS(dataSet, PARSE_DICOM_METADATA_FILE_DATA_OPTIONS);

      if (typeof fileData === 'string' || Array.isArray(fileData)) {
        throw new Error(
          `Unexpected data structure encountered during DICOM file parsing.  Data contains a ${
            typeof fileData === 'string' ? 'string' : 'array'
          } when an object was expected.`,
        );
      }

      const parsed: ParsedDicomMetadata = {
        AccessionNumber: null,
        BodyPartExamined: null,
        StudyDescription: null,
        PatientID: null,
        PatientBirthDate: null,
        PatientSex: null,
        PatientName: [],
        StudyDate: null,
        StudyTime: null,
        StudyInstanceUID: null,
        Modality: null,
        SeriesInstanceUID: null,
        SeriesDescription: null,
        TransferSyntaxUID: null,
      };

      for (const tag of PARSE_DICOM_TAGS) {
        const tagKey = FileScanner.formatDicomTagKey(tag.tag, 'leading-x');
        const tagValue = (fileData as unknown as Record<string, string>)[tagKey] ?? null; // Type cast because the dicomParser library doesn't have accurate typings.

        if (tag.name in parsed) {
          if (PARSE_DICOM_CARET_DELIMITED_TAGS.some((t) => FileScanner.formatDicomTagKey(t.tag, 'leading-x') === tagKey)) {
            // Parse caret delimited values into an array of strings.
            Object.assign(parsed, {
              [tag.name]: hasText(tagValue)
                ? tagValue
                    .split('^')
                    .map((v) => v.trim())
                    .filter((v) => hasText(v))
                : [],
            });
          } else if (PARSE_DICOM_DATE_TAGS.some((t) => FileScanner.formatDicomTagKey(t.tag, 'leading-x') === tagKey)) {
            // Parse date-only values into a normalized format.
            const parsedDate = dayjs(tagValue, DATE_PARSE_FORMATS, true);

            if (parsedDate.isValid()) {
              Object.assign(parsed, { [tag.name]: parsedDate.format(NORMALIZED_DATE_FORMAT) });
            } else {
              // Intentionally throwing away the value if it cannot be parsed as a valid date.  The user will have to manually enter the correct date.
              //datadogLogs.logger.error(`Could not parse date for tag: ${tag.tag} ${tag.name}.`);
            }
          } else if (PARSE_DICOM_TIME_TAGS.some((t) => FileScanner.formatDicomTagKey(t.tag, 'leading-x') === tagKey)) {
            // Parse time-only values into a normalized format.
            const parsedTime = dayjs(tagValue, TIME_PARSE_FORMATS, true);

            if (parsedTime.isValid()) {
              Object.assign(parsed, { [tag.name]: parsedTime.format(NORMALIZED_TIME_FORMAT) });
            } else {
              // Intentionally throwing away the value if it cannot be parsed as a valid time.  The user will have to manually enter the correct time.
              datadogLogs.logger.error(`Could not parse time for tag: ${tag.tag} ${tag.name}.`, {
                uploadSessionId: this._uploadSessionId,
                fileId,
                dicomTag: tag,
                dicomTagName: tag.name,
                rawValue: tagValue,
              });
            }
          } else {
            // Parse all other values with only basic whitespace trimming.
            Object.assign(parsed, { [tag.name]: hasText(tagValue) ? tagValue.trim() : null });
          }
        } else {
          throw new Error(`Could not find a matching parse destination property for tag: ${tag.tag} ${tag.name}.`);
        }
      }

      return parsed;
    } catch (exception) {
      datadogLogs.logger.error('Error while reading file.', { uploadSessionId: this._uploadSessionId, fileId }, exception as Error | undefined);
      return null;
    }
  }

  /** Format a DICOM tag key.  Note that "leading-x" is produces all lowercase, but "parenthesis" results in all uppercase.  This is intentional and is required by third party libraries and our own logic that depend on exact casing for comparisons. */
  private static formatDicomTagKey(tagKey: string, outputFormat: 'leading-x' | 'parenthesis') {
    let group: string, element: string;

    if (tagKey.length === 11) {
      // Tags with format: (0020,000D)
      group = tagKey.substring(1, 5);
      element = tagKey.substring(6, 10);
    } else if (tagKey.length === 9) {
      // Tags with format: x0020000d
      group = tagKey.substring(1, 5);
      element = tagKey.substring(5, 9);
    } else {
      throw new Error(`Unable to determine DICOM tag format: "${tagKey}".`);
    }

    // Note the difference in capitalization between the two formats.  This is intentional due to third party library requirements.
    const result = outputFormat === 'leading-x' ? `x${group}${element}`.toLowerCase() : `(${group},${element})`.toUpperCase();

    return result;
  }
}
