import { forwardRef } from 'react';

import styled, { css } from 'styled-components';

import { ItemHeaderDivProps } from './ItemHeaderDivProps';

export const ItemHeaderDiv = forwardRef<HTMLDivElement, ItemHeaderDivProps>(({ preset, ...divProps }, ref) => {
  return <StyledAccordionItemHeaderDiv ref={ref} $preset={preset} {...divProps} />;
});

ItemHeaderDiv.displayName = 'ItemHeaderDiv';

type StyledElementProps = {
  $preset: ItemHeaderDivProps['preset'];
};

function resolvePreset({ $preset }: StyledElementProps) {
  switch ($preset) {
    case 'desktop-primary':
      return PresetDesktopPrimary;
    default:
      return null;
  }
}

const StyledAccordionItemHeaderDiv = styled.div<StyledElementProps>`
  flex: 0 0 auto; // Flex basis of 'auto' will differ sizing along the main axis to the 'width' or 'height' property (directly below).
  display: flex;
  overflow: hidden;

  ${resolvePreset}
`;

const PresetDesktopPrimary = css<StyledElementProps>`
  height: 46px;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  color: ${({ theme }) => theme.colors.palette.white};

  .accordion-item-collapse-icon {
    color: ${({ theme }) => theme.colors.palette.white};
  }
`;
