import { memo } from 'react';

import dayjs from 'dayjs';
import styled from 'styled-components';

import { useBoolean } from 'core/hooks';
import { Accordion } from 'core/ui';
import { HeaderButton, HeaderCollapseIcon } from 'core/ui/Accordion';
import { findOrThrow, hasText } from 'core/utils';

import { useQueryPatient, useQueryPatientAgeRanges } from 'features/api';
import { usePatientVerbiage } from 'features/patient';
import { PatientDobLabel } from 'features/patient/fragments/PatientDobLabel';
import { PatientUtils } from 'features/patient/services/patient-utils';

import { SharePatientInfoAccordionProps } from '../types';

export const SharePatientInfoAccordion = memo<SharePatientInfoAccordionProps>(({ className, patientId }) => {
  const [{ data: patient }] = useQueryPatient(patientId);
  const { data: ageRanges, isSuccess: isQueryAgeRangesSuccess } = useQueryPatientAgeRanges();
  const patientVerbiage = usePatientVerbiage();

  const [isExpanded, { toggle: toggleIsExpanded }] = useBoolean(false);

  const showUnosId = patientVerbiage === 'Donor' || hasText(patient?.unosID);
  const genderInfoValue = patient?.gender ? `(${patient?.gender})` : '';
  const dobDate = patient?.dob ? dayjs(patient?.dob).toString() : null;

  return (
    <StyledAccordian activeKey={isExpanded ? 'patient-accordion' : undefined} flush className={className}>
      <StyledAccordionItem eventKey="patient-accordion">
        <StyledHeaderButton type="button" onClick={toggleIsExpanded}>
          <HeaderCollapseIcon variant="primary" eventKey="patient-accordion" />
          <StyledInfoDiv className="testing-patient-edit-header-expand-button-1">
            <StyledInfoBlock>
              <StyledInfoLabel>NAME (GENDER):</StyledInfoLabel>
              <StyledInfoValue>{`${PatientUtils.formatName(patient?.firstName, patient?.lastName) ?? ''} ${genderInfoValue}`}</StyledInfoValue>
            </StyledInfoBlock>
            {showUnosId && (
              <>
                <StyledInfoBlock>
                  <StyledInfoLabel>UNOS ID:</StyledInfoLabel>
                  <StyledInfoValue>{patient?.unosID}</StyledInfoValue>
                </StyledInfoBlock>
              </>
            )}
            <StyledInfoBlock>
              <StyledInfoLabel>MRN/{patientVerbiage.toUpperCase()} ID:</StyledInfoLabel>
              <StyledInfoValue>{patient?.patientNumber}</StyledInfoValue>
            </StyledInfoBlock>
            <StyledInfoBlock>
              <StyledInfoLabel>CASE ID:</StyledInfoLabel>
              <StyledInfoValue>{patient?.caseID}</StyledInfoValue>
            </StyledInfoBlock>
            <StyledInfoBlock>
              <StyledInfoLabel>DOB (AGE):</StyledInfoLabel>
              <StyledInfoValue>
                <PatientDobLabel dob={dobDate} showAge />
              </StyledInfoValue>
            </StyledInfoBlock>
            <StyledInfoBlock>
              <StyledInfoLabel>HOSPITAL:</StyledInfoLabel>
              <StyledInfoValue>{patient?.hospital}</StyledInfoValue>
            </StyledInfoBlock>
          </StyledInfoDiv>
        </StyledHeaderButton>
        <StyledAccordionBody>
          <StyledPropertyDiv>
            <StyledPropertyNameDiv>Unos ID:</StyledPropertyNameDiv>
            <StyledPropertyValueDiv>{patient?.unosID}</StyledPropertyValueDiv>
          </StyledPropertyDiv>

          <StyledPropertyDiv>
            <StyledPropertyNameDiv>Case ID:</StyledPropertyNameDiv>
            <StyledPropertyValueDiv>{patient?.caseID}</StyledPropertyValueDiv>
          </StyledPropertyDiv>

          <StyledPropertyDiv>
            <StyledPropertyNameDiv>First Name:</StyledPropertyNameDiv>
            <StyledPropertyValueDiv>{patient?.firstName}</StyledPropertyValueDiv>
          </StyledPropertyDiv>

          <StyledPropertyDiv>
            <StyledPropertyNameDiv>Last Name:</StyledPropertyNameDiv>
            <StyledPropertyValueDiv>{patient?.lastName}</StyledPropertyValueDiv>
          </StyledPropertyDiv>

          <StyledPropertyDiv>
            <StyledPropertyNameDiv>DOB:</StyledPropertyNameDiv>
            <StyledPropertyValueDiv>{patient?.dob}</StyledPropertyValueDiv>
          </StyledPropertyDiv>

          <StyledPropertyDiv>
            <StyledPropertyNameDiv>Age Range:</StyledPropertyNameDiv>
            <StyledPropertyValueDiv>
              {patient?.ageRange_id == null || ageRanges == null
                ? ''
                : PatientUtils.formatAgeRange(findOrThrow(ageRanges, (r) => r.id === patient.ageRange_id))}
            </StyledPropertyValueDiv>
          </StyledPropertyDiv>

          <StyledPropertyDiv>
            <StyledPropertyNameDiv>Weight:</StyledPropertyNameDiv>
            <StyledPropertyValueDiv>{patient?.weight}</StyledPropertyValueDiv>
          </StyledPropertyDiv>

          <StyledPropertyDiv>
            <StyledPropertyNameDiv>MRN:</StyledPropertyNameDiv>
            <StyledPropertyValueDiv>{patient?.patientNumber}</StyledPropertyValueDiv>
          </StyledPropertyDiv>

          <StyledPropertyDiv>
            <StyledPropertyNameDiv>Height:</StyledPropertyNameDiv>
            <StyledPropertyValueDiv>{patient?.height}</StyledPropertyValueDiv>
          </StyledPropertyDiv>

          <StyledPropertyDiv>
            <StyledPropertyNameDiv>Gender:</StyledPropertyNameDiv>
            <StyledPropertyValueDiv>{patient?.gender}</StyledPropertyValueDiv>
          </StyledPropertyDiv>

          <StyledPropertyDiv>
            <StyledPropertyNameDiv>Cross Clamp Time:</StyledPropertyNameDiv>
            <StyledPropertyValueDiv>{patient?.crossClampDateTime}</StyledPropertyValueDiv>
          </StyledPropertyDiv>

          <StyledPropertyDiv>
            <StyledPropertyNameDiv>Hospital:</StyledPropertyNameDiv>
            <StyledPropertyValueDiv>{patient?.hospital}</StyledPropertyValueDiv>
          </StyledPropertyDiv>

          <StyledPropertyDiv>
            <StyledPropertyNameDiv>Active:</StyledPropertyNameDiv>
            <StyledPropertyValueDiv>{patient?.active ? 'Yes' : 'No'}</StyledPropertyValueDiv>
          </StyledPropertyDiv>

          <StyledNotesPropertyDiv>
            <StyledPropertyNameDiv>Notes:</StyledPropertyNameDiv>
            <StyledNotesValueDiv>{patient?.notes}</StyledNotesValueDiv>
          </StyledNotesPropertyDiv>
        </StyledAccordionBody>
      </StyledAccordionItem>
    </StyledAccordian>
  );
});

SharePatientInfoAccordion.displayName = 'SharePatientInfoAccordion';

const StyledAccordian = styled(Accordion)`
  overflow: hidden;
  background: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;

  .container {
    margin-left: 0;
  }
`;

const StyledAccordionItem = styled(Accordion.Item)`
  display: flex;
  flex-direction: column;
  border-radius: 6px 6px 0 0 !important;
  justify-content: space-between;
  overflow: hidden;
  background-color: inherit;

  .accordion-collapse {
    overflow: hidden;
    border-radius: 0 !important;
  }
`;

const StyledHeaderButton = styled.button`
  all: unset; // Remove native browser styling.
  box-sizing: border-box;
  display: flex;
  min-height: 43px;
  background-color: ${({ theme }) => theme.colors.primary};
  border: none;
  outline: none;
  padding-left: ${({ theme }) => theme.space.spacing40};

  && .icon-container {
    margin-top: auto;
    margin-bottom: auto;
  }
`;

const StyledInfoDiv = styled.div`
  display: flex;
  overflow: hidden;
  column-gap: ${({ theme }) => theme.space.spacing60};
  color: ${({ theme }) => theme.colors.palette.white};
  padding: ${({ theme }) => theme.space.spacing20};
`;

const StyledInfoBlock = styled.div`
  text-align: left;
  grid-row: 1;
  padding-left: ${({ theme }) => theme.space.spacing20};
`;

const StyledInfoLabel = styled.div`
  text-align: left;
`;

const StyledInfoValue = styled.div`
  font-size: 12px;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;

const StyledAccordionBody = styled(Accordion.Body)`
  && {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30ch, 1fr));
    row-gap: ${({ theme }) => theme.space.spacing40};
    background: ${({ theme }) => theme.colors.palette.white};
    border-radius: 0 0 6px 6px;
    border: 1px solid ${({ theme }) => theme.colors.palette.blues[5]};
    border-top: none;
    padding: ${({ theme }) => theme.space.spacing50};
  }
`;

const StyledPropertyDiv = styled.div`
  display: grid;
  grid-template-columns: min-content min-content;
`;

const StyledPropertyNameDiv = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  text-align: right;
  padding-right: ${({ theme }) => theme.space.spacing20};
  white-space: nowrap;
`;

const StyledPropertyValueDiv = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledNotesPropertyDiv = styled.div`
  display: grid;
  grid-template-columns: min-content minmax(30ch, 1fr);
  grid-column: 1 / -1;
`;

const StyledNotesValueDiv = styled.div``;
