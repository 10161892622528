import { memo } from 'react';

import { faFileLines, faPaperclip, faUser, faWarning } from '@fortawesome/pro-solid-svg-icons';
import { CheckboxChangeEvent } from '@progress/kendo-react-inputs';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { useBoolean, useEvent } from 'core/hooks';
import { BreakpointSelectors, Button, Checkbox, Icon, StatusBoxLarge, StatusText } from 'core/ui';
import { equalsInsensitive, hasText } from 'core/utils';

import { useQueryExam, useQueryFilesByExamId, useQueryPatient } from 'features/api';
import { UploadWindow } from 'features/file/fragments';
import { FileTypeHelper } from 'features/file/services/FileTypeHelper';
import { PatientUtils } from 'features/patient';

import { useUploadExamsPageContext } from '../hooks';
import { UploadGroup } from '../types';

type ReviewExamCardProps = {
  className?: string;
  uploadGroup: UploadGroup;
  onUploadGroupCheckedChange?: (uploadGroupId: string, checked: boolean) => void;
};

export const ReviewExamCard = memo<ReviewExamCardProps>(({ className, uploadGroup, onUploadGroupCheckedChange }) => {
  if (uploadGroup.examId == null) throw new Error('Exam ID is required for ReviewExamCard.');

  const { patientId } = useUploadExamsPageContext();
  const [isAttachmentsWindowVisible, { setTrue: setAttachmentsWindowVisible, setFalse: setAttachmentsWindowHidden }] = useBoolean(false);
  const [{ data: patient }] = useQueryPatient(patientId);
  const { data: examFiles, isSuccess: isExamFilesFetchSuccess } = useQueryFilesByExamId(uploadGroup.examId);

  // Preload the exam data to avoid a loading state when the exam modal is opened.
  useQueryExam(uploadGroup.examId);

  const attachmentFiles = isExamFilesFetchSuccess ? examFiles.filter((file) => FileTypeHelper.getUppercaseFileType(file) !== 'LINK') : null;

  const mismatches = {
    firstName:
      patient != null && hasText(uploadGroup.dicomData?.PatientName?.[1]) && !equalsInsensitive(patient.firstName, uploadGroup.dicomData?.PatientName[1]),
    lastName:
      patient != null && hasText(uploadGroup.dicomData?.PatientName?.[0]) && !equalsInsensitive(patient.lastName, uploadGroup.dicomData?.PatientName[0]),
    mrn: patient != null && hasText(uploadGroup.dicomData?.PatientID) && !equalsInsensitive(patient.patientNumber, uploadGroup.dicomData?.PatientID),
    dob:
      patient != null &&
      hasText(uploadGroup.dicomData?.PatientBirthDate) &&
      patient.dob &&
      !dayjs(patient.dob).format('MM/DD/YYYY').includes(uploadGroup.dicomData?.PatientBirthDate),
    gender: patient != null && hasText(uploadGroup.dicomData?.PatientSex) && !equalsInsensitive(patient.gender, uploadGroup.dicomData?.PatientSex),
  };

  const warningCount =
    (mismatches.firstName || mismatches.lastName ? 1 : 0) + (mismatches.mrn ? 1 : 0) + (mismatches.dob ? 1 : 0) + (mismatches.gender ? 1 : 0);

  const handleCheckedChange = useEvent((event: CheckboxChangeEvent) => {
    onUploadGroupCheckedChange?.(uploadGroup.uploadGroupId, event.value);
  });

  return (
    <StyledComponentDiv className={className}>
      {isAttachmentsWindowVisible && uploadGroup.examId != null && <UploadWindow examId={uploadGroup.examId} onClose={setAttachmentsWindowHidden} />}

      <StyledCheckboxColumnDiv>
        <Checkbox value={uploadGroup.checked} onChange={handleCheckedChange} />
      </StyledCheckboxColumnDiv>

      <StyledSectionDiv>
        <StyledSectionHeaderDiv>
          <Icon icon={faFileLines} fixedWidth={false} />
          <div>Exam Data</div>
        </StyledSectionHeaderDiv>

        <StyledSectionTableDiv>
          <StyledInfoLabel>Description:</StyledInfoLabel>
          <StyledInfoValue>{uploadGroup.dicomData?.StudyDescription}</StyledInfoValue>

          <StyledInfoLabel>Modality:</StyledInfoLabel>
          <StyledInfoValue>{uploadGroup.dicomData?.Modality}</StyledInfoValue>

          <StyledInfoLabel>Body Part:</StyledInfoLabel>
          <StyledInfoValue>{uploadGroup.dicomData?.BodyPartExamined}</StyledInfoValue>

          <StyledInfoLabel>Date & Time:</StyledInfoLabel>
          <StyledInfoValue>
            {uploadGroup.dicomData?.StudyDate} {uploadGroup.dicomData?.StudyTime}
          </StyledInfoValue>

          <StyledInfoLabel>Images:</StyledInfoLabel>
          <StyledInfoValue>{uploadGroup.files.length}</StyledInfoValue>
        </StyledSectionTableDiv>
      </StyledSectionDiv>

      <StyledSectionDiv>
        <StyledSectionHeaderDiv $showSeparator>
          <Icon icon={faUser} fixedWidth={false} />
          <div>Patient Data</div>
        </StyledSectionHeaderDiv>

        <StyledSectionTableDiv>
          <StyledInfoLabel>Name:</StyledInfoLabel>
          <StyledInfoValue>
            <StatusText status={mismatches.firstName || mismatches.lastName ? 'warning' : 'neutral'}>
              {uploadGroup.dicomData?.PatientName?.join?.(' ')}
            </StatusText>
          </StyledInfoValue>

          <StyledInfoLabel>Birth Date:</StyledInfoLabel>
          <StyledInfoValue>
            <StatusText status={mismatches.dob ? 'warning' : 'neutral'}>{uploadGroup.dicomData?.PatientBirthDate}</StatusText>
          </StyledInfoValue>

          <StyledInfoLabel>ID:</StyledInfoLabel>
          <StyledInfoValue>{uploadGroup.dicomData?.PatientID}</StyledInfoValue>

          <StyledInfoLabel>Gender:</StyledInfoLabel>
          <StyledInfoValue>
            <StatusText status={mismatches.gender ? 'warning' : 'neutral'}>{uploadGroup.dicomData?.PatientSex}</StatusText>
          </StyledInfoValue>
        </StyledSectionTableDiv>
      </StyledSectionDiv>

      <StyledAttachmentsSectionDiv>
        <StyledSectionHeaderDiv $showSeparator>
          <Icon icon={faPaperclip} fixedWidth={false} />
          <div>Attachments</div>
        </StyledSectionHeaderDiv>

        <StyledAttachmentsButtonDiv id="foo">
          {attachmentFiles == null && <span>&nbsp;</span>}
          {attachmentFiles != null && (
            <span>
              {attachmentFiles.length} {attachmentFiles.length === 1 ? 'attachment' : 'attachments'} added
            </span>
          )}
          <Button onClick={setAttachmentsWindowVisible}>View/Add Attachments</Button>
        </StyledAttachmentsButtonDiv>
      </StyledAttachmentsSectionDiv>

      {warningCount > 0 && (
        <StyledWarningsSection status="warning">
          {Object.values(mismatches).some((v) => v) && (
            <StyledWarningsTitleDiv>
              <Icon icon={faWarning} />
              <div>Patient exam data doesn&apos;t match selected patient, please make sure you are uploading to the correct patient.</div>
            </StyledWarningsTitleDiv>
          )}

          <StyledWarningsLinesDiv>
            {(mismatches.firstName || mismatches.lastName) && (
              <div>
                <b>Name:</b> {uploadGroup.dicomData?.PatientName.join(' ')} does not match: {PatientUtils.formatName(patient?.firstName, patient?.lastName)}.
              </div>
            )}

            {mismatches.dob && (
              <div>
                <b>Birth Date:</b> {uploadGroup.dicomData?.PatientBirthDate} does not match: {PatientUtils.formatDob(patient?.dob)}.
              </div>
            )}

            {mismatches.mrn && (
              <div>
                <b>MRN:</b> {uploadGroup.dicomData?.PatientID} does not match: {patient?.patientNumber}.
              </div>
            )}

            {mismatches.gender && (
              <div>
                <b>Gender:</b> {uploadGroup.dicomData?.PatientSex} does not match: {patient?.gender}.
              </div>
            )}
          </StyledWarningsLinesDiv>
        </StyledWarningsSection>
      )}
    </StyledComponentDiv>
  );
});

ReviewExamCard.displayName = 'ReviewExamCard';

const StyledComponentDiv = styled.div`
  display: grid;
  padding: ${({ theme }) => theme.space.spacing40};
  row-gap: ${({ theme }) => theme.space.spacing40};
  background-color: ${({ theme }) => theme.colors.palette.white};
  border-radius: 6px;

  ${BreakpointSelectors.Desktop} & {
    grid-template-columns: subgrid;
    grid-template-rows: min-content;
  }

  ${BreakpointSelectors.Mobile} & {
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
  }
`;

const StyledCheckboxColumnDiv = styled.div``;

const StyledSectionDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
`;

const StyledSectionHeaderDiv = styled.div<{ $showSeparator?: boolean }>`
  ${BreakpointSelectors.Desktop} & {
    display: none;
  }

  ${BreakpointSelectors.Mobile} & {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: min-content 1fr;
    row-gap: ${({ theme }) => theme.space.spacing40};
    column-gap: ${({ theme }) => theme.space.spacing40};
    user-select: none;
    font-size: ${({ theme }) => theme.fontSizes.heading2};
    line-height: ${({ theme }) => theme.lineHeights.heading2};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    padding: 0 0 ${({ theme }) => theme.space.spacing20} 0;
    justify-self: stretch;

    .icon-container {
      color: ${({ theme }) => theme.colors.primary};
    }

    &::before {
      content: '';
      grid-column: 1 / -1;
      display: ${({ $showSeparator }) => ($showSeparator ? 'block' : 'none')};
      height: 1px;
      background-color: ${({ theme }) => theme.colors.palette.grayscale[4]};
    }
  }
`;

const StyledAttachmentsSectionDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
  justify-items: start;
`;

const StyledSectionTableDiv = styled.div`
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: min-content 1fr;
  column-gap: 16px;
  row-gap: 8px;
  align-items: center;
`;

const StyledInfoLabel = styled.div`
  white-space: nowrap;
  user-select: none;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;

const StyledInfoValue = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
`;

const StyledAttachmentsButtonDiv = styled.div`
  align-self: center;
  display: grid;
  grid-template-columns: min-content;
  grid-template-rows: min-content min-content;
  row-gap: 8px;
  justify-content: center;
  justify-items: center;
  user-select: none;
  white-space: nowrap;
`;

const StyledWarningsSection = styled(StatusBoxLarge)``;

const StyledWarningsTitleDiv = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  column-gap: ${({ theme }) => theme.space.spacing20};
  padding: ${({ theme }) => theme.space.spacing20};
`;

const StyledWarningsLinesDiv = styled.div`
  padding: 0 ${({ theme }) => theme.space.spacing20};
`;
