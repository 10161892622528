import { memo, useEffect, useRef, useState } from 'react';

import { faEllipsis, faEye, faMobileAlt, faPlus, faSpinner, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { faPencil } from '@fortawesome/pro-thin-svg-icons';
import { Button as KendoButton } from '@progress/kendo-react-buttons';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { OverlayTrigger, Popover, Spinner } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { styled } from 'styled-components';

import { FileModel } from 'models';

import { useEvent } from 'core/hooks';
import { NotificationsService } from 'core/notifications';
import { Button, ButtonVariants, Icon, Input, Window } from 'core/ui';

import { useApiClient } from 'features/api';
import { ApiWebSocketProvider } from 'features/api/fragments/ApiWebSocketProvider';
import { AuthenticationScheme, useAuthentication } from 'features/auth';
import { useApiWebSocketConnection } from 'features/auth/hooks/useApiWebSocketConnection';
import { DocumentCategories } from 'features/exam/constants';
import { SupportedFiles, ViewFileWindow } from 'features/file';
import { ShareType } from 'features/patient/constants';
import { UploadStatusConstants } from 'features/share/constants';
import { QRScanModal } from 'features/upload-exams/fragments';

import { FileService } from '../services';
import { FileTypeHelper } from '../services/FileTypeHelper';
import { UploadWindowProps } from '../types';

const UploadWindowInner = memo<UploadWindowProps>(({ examId, onClose, onChange }) => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const { activeScheme } = useAuthentication();
  const [files, setFiles] = useState<FileModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const filesInputRef = useRef<HTMLInputElement | null>(null);
  const [activePopoverId, setActivePopoverId] = useState<number | null>(null);
  const [activeEditFile, setActiveEditFile] = useState<{ fileId: number | null; fileName: string | null }>({ fileId: null, fileName: null });
  const [editFileName, setEditFileName] = useState<string>('');
  const [renamingId, setRenamingId] = useState<number | null>(null);
  const [deletingId, setDeletingId] = useState<number | null>(null);
  const [fileViewWindowId, setFileViewWindowId] = useState<number | null>(null);
  const [linkId, setLinkId] = useState<string | null>();
  const [isQrModalVisible, setIsQrModalVisible] = useState<boolean>(false);
  const connection = useApiWebSocketConnection();

  const fetchFiles = useEvent(async (skipLoading?: boolean) => {
    if (!skipLoading) {
      setIsLoading(true);
    }
    const files = await apiClient.filesClient.getFilesByExamId(examId, activeScheme === AuthenticationScheme.OIDC ? 'msal-required' : 'share-required');
    setFiles(files);
    setIsLoading(false);
  });

  const attachmentFiles = files
    ?.filter((file) => file.categoryId !== DocumentCategories.EXAM.value && FileTypeHelper.getUppercaseFileType(file) !== 'LINK')
    .sort((a, b) => b.id - a.id);
  const formatDateString = (dateString: string) => dayjs(dateString).format('MM-DD-YYYY hh:mm A');

  const onUploadFilesChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setIsFileUploading(true);
      const selectedFiles = event.target.files;
      if (selectedFiles == null) throw new Error('No files selected');

      const invalidFiles = FileService.validateFormFiles(selectedFiles);
      if (invalidFiles.length > 0) {
        NotificationsService.displayError(`${FileService.invalidFileMessage()}: ${invalidFiles.map((file) => file.name).join(', ')}`);
        setIsFileUploading(false);
        return;
      }

      const formData = new FormData();
      for (const file of Array.from(selectedFiles)) {
        formData.append('files', file);
      }
      const uploadedFiles = await apiClient.filesClient.add(
        formData,
        false,
        examId,
        null,
        DocumentCategories.DOCUMENT.value,
        activeScheme === AuthenticationScheme.OIDC ? 'msal-required' : 'share-required',
      );

      if (uploadedFiles && uploadedFiles.length > 0) {
        queryClient.invalidateQueries({ queryKey: ['files', 'by-exam-id', examId] });
        await fetchFiles(true);
        if (onChange) {
          onChange();
        }
      }
    } catch (error) {
      NotificationsService.displayError('Error uploading file');
    } finally {
      setIsFileUploading(false);
    }
  };

  const onFileDelete = async (fileId: number) => {
    try {
      setActivePopoverId(null);
      setDeletingId(fileId);
      await apiClient.filesClient.deleteFile(fileId.toString(), activeScheme === AuthenticationScheme.OIDC ? 'msal-required' : 'share-required');
      queryClient.invalidateQueries({ queryKey: ['files', 'by-exam-id', examId] });
      await fetchFiles(true);
      if (onChange) {
        onChange();
      }
    } catch (error) {
      NotificationsService.displayError('Error deleting file');
    } finally {
      setDeletingId(null);
    }
  };

  const handleQrModalClose = useEvent(() => {
    setIsQrModalVisible(false);
    setLinkId(null);
  });

  const handleViewFileWindowClosed = useEvent(() => {
    setFileViewWindowId(null);
  });

  const onQrClick = async () => {
    const linkId = crypto.randomUUID();
    if (examId && examId > 0) {
      const expireOn = dayjs().add(1, 'day').format('YYYY-MM-DDTHH:mm:ss');
      await apiClient.studyShare.addStudyShare({
        id: 0,
        linkId: linkId,
        email: null,
        sharePassword: null,
        shareType: ShareType.Link,
        sharePatientId: null,
        studyShareExams: [{ exam_id: examId }],
        expireOn: expireOn,
        dateCreated: '',
        message: null,
        bypassSplashPage: true,
      });
    }
    setLinkId(linkId);
    setIsQrModalVisible(true);
  };

  const disableActions = isFileUploading || !!activeEditFile.fileId;

  const renderFileCards = () => {
    if (isLoading)
      return (
        <StyledSpinnerContainer>
          <Spinner />
        </StyledSpinnerContainer>
      );

    const fileNameTitle = (file: FileModel) => {
      const handleRenameFile = async () => {
        try {
          if (activeEditFile.fileName !== file.fileName && activeEditFile.fileName) {
            setRenamingId(file.id);
            await apiClient.filesClient.renameFile(file.id, [{ op: 'replace', path: '/fileName', value: activeEditFile.fileName }]);
          }
        } catch (error) {
          NotificationsService.displayError('Error renaming file');
        } finally {
          // Reset editing state and renamingId, fetch updated files
          setActiveEditFile({ fileId: null, fileName: null });
          await fetchFiles(true);
          setRenamingId(null);
        }
      };

      const handleCancel = () => {
        // Reset editing state and file name
        setActiveEditFile({ fileId: null, fileName: null });
      };

      return activeEditFile.fileId === file.id ? (
        <div>
          <Input
            type="text"
            value={activeEditFile.fileName ?? ''}
            onChange={(e) => setActiveEditFile({ ...activeEditFile, fileName: e.target.value })}
            autoFocus
          />
          <StyledRenameContainer>
            <Button onClick={handleRenameFile}>Rename</Button>
            <Button variant={ButtonVariants.SECONDARY} onClick={handleCancel}>
              Cancel
            </Button>
          </StyledRenameContainer>
        </div>
      ) : (
        <>
          {renamingId === file.id ? (
            <>
              <Spinner size="sm" />
              &nbsp;<span>Renaming...</span>
            </>
          ) : deletingId === file.id ? (
            <>
              <Spinner size="sm" />
              &nbsp;<span>Deleting...</span>
            </>
          ) : (
            <Card.Title onClick={() => setActiveEditFile({ fileId: file.id, fileName: file.fileName || '' })}>{file.fileName || 'Unnamed File'}</Card.Title>
          )}
          {file.created && <Card.Text>{`Added ${formatDateString(file.created)}`}</Card.Text>}
        </>
      );
    };

    if (attachmentFiles.length > 0) {
      return (
        <div>
          {attachmentFiles.map((file) => (
            <Card key={file.id}>
              <StyledCardBody>
                <StyledFileNameTitleContainer>{fileNameTitle(file)}</StyledFileNameTitleContainer>
                <StyledCardBodyActionContainer>
                  <KendoButton fillMode="link" type="button" disabled={disableActions} onClick={() => setFileViewWindowId(file.id)}>
                    <StyledIcon icon={faEye}></StyledIcon>
                  </KendoButton>
                  {activeScheme === AuthenticationScheme.OIDC && (
                    <OverlayTrigger
                      rootClose={true}
                      trigger="click"
                      placement="bottom-end"
                      overlay={editPopover(file.id, file.fileName)}
                      onToggle={(isOpen) => {
                        setActivePopoverId(isOpen ? file.id : null);
                      }}
                      show={activePopoverId === file.id}
                    >
                      <KendoButton fillMode="link" type="button" disabled={disableActions}>
                        <StyledIcon icon={faEllipsis}></StyledIcon>
                      </KendoButton>
                    </OverlayTrigger>
                  )}
                </StyledCardBodyActionContainer>
              </StyledCardBody>
            </Card>
          ))}
        </div>
      );
    }

    return <StyledNoFilesFoundContainer>No files found</StyledNoFilesFoundContainer>;
  };

  const editPopover = (fileId: number, fileName: string | undefined) => (
    <Popover className="popover-custom">
      <StyledPopoverBody>
        <label htmlFor="fileNameEdit">File Name</label>
        <Input id="fileNameEdit" name="fileNameEdit" onChange={(e: any) => setEditFileName(e.target?.value || e.value || '')} defaultValue={fileName || ''} />
        <div className="mt-3 d-flex justify-content-end">
          <Button onClick={() => handleFileRename(fileId)} className="mr-2" variant={ButtonVariants.PRIMARY}>
            Save
          </Button>
          <Button onClick={() => setActiveEditFile({ fileId: null, fileName: null })}>Cancel</Button>
        </div>
      </StyledPopoverBody>
    </Popover>
  );

  const onRenameClick = (fileId: number, fileName: string) => {
    setActiveEditFile({ fileId, fileName });
    setActivePopoverId(null);
  };

  const onDeleteClick = async (fileId: number) => {
    await onFileDelete(fileId);
    setActivePopoverId(null);
  };

  const handleFileRename = async (fileId: number) => {
    try {
      setRenamingId(fileId);
      await apiClient.filesClient.renameFile(fileId, [{ op: 'replace', path: '/fileName', value: editFileName }]);
      await fetchFiles(true);
      setActivePopoverId(null);
      if (onChange) {
        onChange();
      }
    } catch (error) {
      NotificationsService.displayError('Error renaming file');
    } finally {
      setRenamingId(null);
    }
  };

  useEffect(() => {
    fetchFiles();
  }, [examId, fetchFiles]);

  useEffect(() => {
    if (examId) {
      const handler = (message: string) => {
        if (examId && message === UploadStatusConstants.uploaded) {
          fetchFiles();
        }
      };

      connection.on('uploadStatus', handler);

      return () => {
        connection.off('uploadStatus', handler);
      };
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, [connection, examId, fetchFiles]);

  return (
    <Window title={'Attachments'} onClose={onClose} initialHeight={500} initialWidth={450}>
      <StyledWindowContainer>
        <StyledFilesCardContainer>{renderFileCards()}</StyledFilesCardContainer>
        <StyledActionsContainer>
          <StyledUploadContainer>
            <Button variant={ButtonVariants.PRIMARY} onClick={() => filesInputRef.current?.click()} disabled={disableActions} title="Upload Files">
              {isFileUploading ? <Icon icon={faSpinner} spin={isFileUploading}></Icon> : <Icon icon={faPlus}></Icon>}Upload File(s)
            </Button>
            <SupportedFiles placement="bottom" />
          </StyledUploadContainer>
          <Button variant={ButtonVariants.PRIMARY} title="Mobile Upload" disabled={disableActions} onClick={onQrClick}>
            <Icon icon={faMobileAlt}></Icon> Mobile Upload
          </Button>
        </StyledActionsContainer>
      </StyledWindowContainer>
      <input
        accept={FileService.getAcceptedUploadFiles()}
        id="file"
        multiple
        onChange={onUploadFilesChange}
        ref={filesInputRef}
        style={{ display: 'none' }}
        type="file"
      />
      {!!fileViewWindowId && <ViewFileWindow title="View Attachment" fileId={fileViewWindowId} onClose={handleViewFileWindowClosed}></ViewFileWindow>}
      {linkId && examId && <QRScanModal show={isQrModalVisible} onClose={handleQrModalClose} linkId={linkId} examId={examId} />}
    </Window>
  );
});

UploadWindowInner.displayName = 'UploadWindowInner';

export const UploadWindow = memo<UploadWindowProps>((props) => {
  return (
    <ApiWebSocketProvider>
      <UploadWindowInner {...props} />
    </ApiWebSocketProvider>
  );
});

UploadWindow.displayName = 'UploadWindow';

const StyledWindowContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledActionsContainer = styled.div`
  display: flex;
  align-self: flex-end;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  border-top: 1px solid ${({ theme }) => theme.colors.palette.grayscale[3]};
`;

const StyledFilesCardContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.palette.grayscale[2]};
  overflow: auto;
`;

const StyledNoFilesFoundContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const StyledCardBodyActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledCardBody = styled(Card.Body)`
  display: flex;
  justify-content: space-between;
`;

const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.primary};
`;

const StyledSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const StyledPopover = styled(Popover)`
  z-index: 10002;
  width: 150px;
`;

const EditPopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .k-button-text {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
`;

const StyledDeleteIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.palette.reds[3]};
`;

const StyledRenameContainer = styled.div`
  display: flex;
  gap: 10px;
  padding-top: 10px;
`;

const StyledFileNameTitleContainer = styled.div`
  width: 75%;
`;

const StyledUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: ${({ theme }) => theme.space.spacing20};
`;

const StyledPopoverBody = styled(Popover.Body)`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
