import React, { useEffect, useState } from 'react';

import { Window } from '@progress/kendo-react-dialogs';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { ExamGridModel, FileModel, PatientModel } from 'models';

import { Button, ButtonVariants, Card, ComponentSizes } from 'core/ui';

import { useApiClient } from 'features/api';
import { DocumentCategories } from 'features/exam/constants';
import { FileViewerPage } from 'features/file/fragments/FileViewerPage';
import { ImgProxy } from 'features/file/fragments/ImgProxy';

import {
  CATEGORY_COLORS,
  categorizeByDescription,
  getCategoryKeyById,
  getCategoryNameById,
  getFileIcon,
  sortFilesByDate,
} from '../services/file-category-helper';

interface PatientDashboardProps {
  patientId: number | null;
  gridData: ExamGridModel[];
  patient: PatientModel | null;
  onEditPatient?: () => void;
}

const PatientDashboard: React.FC<PatientDashboardProps> = ({ patientId, gridData, patient, onEditPatient }) => {
  const apiClient = useApiClient();
  const [files, setFiles] = useState<FileModel[]>([]);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState<Record<string, FileModel[]>>({});
  const [completionStats, setCompletionStats] = useState({
    totalRequired: 10, // Example value
    completed: 0,
    percent: 0,
  });

  // State for file viewer
  const [viewFileState, setViewFileState] = useState<{
    isOpen: boolean;
    files: FileModel[];
    selectedFile: FileModel | null;
    title: string;
  }>({
    isOpen: false,
    files: [],
    selectedFile: null,
    title: '',
  });

  // State for exam viewer
  const [selectedExamId, setSelectedExamId] = useState<number | null>(null);

  useEffect(() => {
    if (selectedExamId) {
      window.open(`/file-viewer/${selectedExamId}?feature=exams`, '_blank');
      setSelectedExamId(null);
    }
  }, [selectedExamId]);

  useEffect(() => {
    const fetchFiles = async () => {
      if (patientId) {
        setLoading(true);
        try {
          const response = await apiClient.filesClient.getFilesByPatientId(patientId, 'msal-required');
          setFiles(response);

          // Organize files by category using the new helper functions
          const categorized: Record<string, FileModel[]> = {};

          // First, organize files that have explicit categoryId
          response.forEach((file) => {
            if (file.categoryId) {
              const categoryKey = getCategoryKeyById(file.categoryId);
              if (categoryKey) {
                if (!categorized[categoryKey]) {
                  categorized[categoryKey] = [];
                }
                categorized[categoryKey].push(file);
              }
            }
          });

          // Then, organize exam files that might have implicit categories based on descriptions
          if (gridData?.length > 0) {
            gridData.forEach((exam) => {
              if (exam.serviceDescription) {
                const categoryKey = categorizeByDescription(exam.serviceDescription);
                if (categoryKey && !['DOCUMENT', 'EXAM'].includes(categoryKey)) {
                  if (!categorized[categoryKey]) {
                    categorized[categoryKey] = [];
                  }

                  // Check if this exam has associated files
                  const examFiles = response.filter((file) => file.examId === exam.id);
                  if (examFiles.length > 0) {
                    categorized[categoryKey].push(...examFiles);
                  } else {
                    // If no files but there's a thumbnail, create a virtual file entry
                    categorized[categoryKey].push({
                      id: 0,
                      examId: exam.id,
                      fileName: exam.serviceDescription || 'Exam file',
                      fileType: 'dicom',
                      categoryId: DocumentCategories[categoryKey as keyof typeof DocumentCategories]?.value || null,
                      created: exam.uploadedDate || '',
                      location: '',
                      url: null,
                      file: null,
                      modified: null,
                      thumbnailLocation: null,
                      patient_id: patientId,
                      index: null,
                      fileId: null,
                      fileSize: null,
                      externalSource: null,
                      source: null,
                      viewerId: null,
                      subFiles: [],
                      user: null,
                    });
                  }
                }
              }
            });
          }

          // Files without a category go to "Other"
          const uncategorizedFiles = response.filter((file) => !file.categoryId);
          if (uncategorizedFiles.length > 0) {
            if (!categorized.DOCUMENT) {
              categorized.DOCUMENT = [];
            }
            categorized.DOCUMENT.push(...uncategorizedFiles);
          }

          setCategories(categorized);

          // Calculate completion stats
          const completed = Object.keys(categorized).length;
          setCompletionStats({
            totalRequired: 10, // This could be dynamically determined
            completed,
            percent: Math.round((completed / 10) * 100),
          });
        } catch (error) {
          console.error('Error fetching files:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchFiles();
  }, [patientId, apiClient.filesClient, gridData]);

  // Function to open file viewer
  const handleFileClick = (file: FileModel, categoryKey: string) => {
    if (file.examId) {
      // If this is an exam file, set the exam ID to open the DICOM viewer
      setSelectedExamId(file.examId);
    } else {
      // Otherwise open the file in the document viewer
      const categoryFiles = categories[categoryKey] || [];
      const sortedFiles = sortFilesByDate(categoryFiles);

      setViewFileState({
        isOpen: true,
        files: sortedFiles,
        selectedFile: file,
        title: getCategoryNameById(file.categoryId ?? null) || 'File Viewer',
      });
    }
  };

  // Function to handle clicking on an exam
  const handleExamClick = (exam: ExamGridModel) => {
    if (exam.id) {
      // Open exam in the DICOM viewer
      window.open(`/file-viewer/${exam.id}?feature=exams`, '_blank');
    }
  };

  // Function to view all files in a category
  const handleViewAllFiles = (categoryKey: string) => {
    if (categories[categoryKey]?.length > 0) {
      const sortedFiles = sortFilesByDate(categories[categoryKey]);
      setViewFileState({
        isOpen: true,
        files: sortedFiles,
        selectedFile: sortedFiles[0],
        title: `${DocumentCategories[categoryKey as keyof typeof DocumentCategories]?.name || categoryKey} Files`,
      });
    }
  };

  const renderExamThumbnails = (exam: ExamGridModel) => {
    const sortedFiles = sortFilesByDate(exam.files || []);
    return (
      <StyledExamThumbnailsContainer>
        {sortedFiles.slice(0, 4).map((file, index) => (
          <StyledExamThumbnail key={`${file.id}-${index}`}>
            {file.thumbnailLocation ? (
              <ImgProxy fileId={file.id} thumbnailUrl={file.thumbnailLocation} dateModified={file.modified} isReadOnly height="100%" width="100%" />
            ) : (
              <StyledFileIcon>{getFileIcon(file.fileType)}</StyledFileIcon>
            )}
          </StyledExamThumbnail>
        ))}
        {sortedFiles.length > 4 && <StyledMoreFiles>+{sortedFiles.length - 4}</StyledMoreFiles>}
      </StyledExamThumbnailsContainer>
    );
  };

  return (
    <StyledDashboardContainer>
      {/* AI Summary Card - Moved to the top */}
      <StyledSectionCard>
        <StyledCardHeader>AI Summary</StyledCardHeader>
        <StyledCardContent>
          <StyledAISummary>
            <StyledSummarySection>
              <StyledSectionTitle>Key Findings</StyledSectionTitle>
              <p>
                This is a summary of the patient&apos;s condition based on all available files and examination results. The AI analysis has identified the
                following key points:
              </p>
              <StyledBulletList>
                <li>Patient documentation is {completionStats.percent}% complete</li>
                <li>Latest exam was on {gridData.length > 0 ? dayjs(gridData[0].uploadedDate).format('MM/DD/YYYY h:mm A') : 'N/A'}</li>
                <li>There are {Object.keys(categories).length} document categories with data</li>
              </StyledBulletList>
            </StyledSummarySection>

            <StyledSummarySection>
              <StyledSectionTitle>Recommendations</StyledSectionTitle>
              <StyledBulletList>
                {completionStats.percent < 100 && (
                  <li>Complete missing documentation ({completionStats.totalRequired - completionStats.completed} categories remaining)</li>
                )}
                {gridData.length === 0 && <li>Schedule initial examination</li>}
                <li>Regular follow-up recommended</li>
              </StyledBulletList>
            </StyledSummarySection>
          </StyledAISummary>
        </StyledCardContent>
      </StyledSectionCard>

      {/* Patient Overview Card */}
      <StyledSectionCard>
        <StyledCardHeader>Patient Overview</StyledCardHeader>
        <StyledCardContent>
          <StyledPatientInfo>
            <StyledInfoItem>
              <StyledLabel>Name:</StyledLabel>
              <StyledValue>
                {patient?.firstName} {patient?.lastName}
              </StyledValue>
            </StyledInfoItem>
            <StyledInfoItem>
              <StyledLabel>DOB:</StyledLabel>
              <StyledValue>{patient?.dob ? dayjs(patient.dob).format('MM/DD/YYYY') : 'Unknown'}</StyledValue>
            </StyledInfoItem>
            <StyledInfoItem>
              <StyledLabel>MRN:</StyledLabel>
              <StyledValue>{patient?.patientNumber || 'Unknown'}</StyledValue>
            </StyledInfoItem>
            {patient?.unosID && (
              <StyledInfoItem>
                <StyledLabel>UNOS ID:</StyledLabel>
                <StyledValue>{patient.unosID}</StyledValue>
              </StyledInfoItem>
            )}
            <StyledInfoItem>
              <StyledLabel>Gender:</StyledLabel>
              <StyledValue>{patient?.gender || 'Unknown'}</StyledValue>
            </StyledInfoItem>
          </StyledPatientInfo>
          <StyledActionsBox>
            <Button size={ComponentSizes.SMALL} variant={ButtonVariants.PRIMARY} onClick={onEditPatient}>
              Edit Patient
            </Button>
          </StyledActionsBox>
        </StyledCardContent>
      </StyledSectionCard>

      {/* Completion Progress Card */}
      <StyledSectionCard>
        <StyledCardHeader>Documentation Completion</StyledCardHeader>
        <StyledCardContent>
          <StyledProgressContainer>
            <StyledProgressCircle percent={completionStats.percent}>
              <StyledProgressInner>{completionStats.percent}%</StyledProgressInner>
            </StyledProgressCircle>
            <StyledProgressInfo>
              <div>
                {completionStats.completed} of {completionStats.totalRequired} categories completed
              </div>
              <StyledProgressText>
                {completionStats.percent < 50
                  ? 'More documents needed'
                  : completionStats.percent < 100
                    ? 'Good progress, but still incomplete'
                    : 'All documents submitted!'}
              </StyledProgressText>
            </StyledProgressInfo>
          </StyledProgressContainer>
        </StyledCardContent>
      </StyledSectionCard>

      {/* All Exams Card - Showing all exams with thumbnails */}
      <StyledSectionCard fullWidth>
        <StyledCardHeader>All Exams</StyledCardHeader>
        <StyledCardContent>
          {gridData.length === 0 ? (
            <StyledEmptyMessage>No exams found</StyledEmptyMessage>
          ) : (
            <StyledExamGrid>
              {gridData.map((exam, index) => (
                <StyledExamCard key={`exam-${exam.id}-${index}`} onClick={() => handleExamClick(exam)} clickable>
                  <StyledExamCardHeader>
                    <StyledExamTitle>
                      {exam.serviceDescription} {exam.description && `- ${exam.description}`}
                    </StyledExamTitle>
                    <StyledExamStatusTag status={exam.statusType || ''}>{exam.statusType}</StyledExamStatusTag>
                  </StyledExamCardHeader>
                  <StyledExamCardContent>
                    {renderExamThumbnails(exam)}
                    <StyledExamDetails>
                      <StyledExamInfoItem>
                        <StyledExamInfoLabel>Service:</StyledExamInfoLabel>
                        <StyledExamInfoValue>{exam.serviceDescription || 'N/A'}</StyledExamInfoValue>
                      </StyledExamInfoItem>
                      <StyledExamInfoItem>
                        <StyledExamInfoLabel>Study Date:</StyledExamInfoLabel>
                        <StyledExamInfoValue>{exam.studyDate ? dayjs(exam.studyDate).format('MM/DD/YYYY h:mm A') : 'N/A'}</StyledExamInfoValue>
                      </StyledExamInfoItem>
                      <StyledExamInfoItem>
                        <StyledExamInfoLabel>Created:</StyledExamInfoLabel>
                        <StyledExamInfoValue>{exam.uploadedDate ? dayjs(exam.uploadedDate).format('MM/DD/YYYY h:mm A') : 'N/A'}</StyledExamInfoValue>
                      </StyledExamInfoItem>
                    </StyledExamDetails>
                  </StyledExamCardContent>
                </StyledExamCard>
              ))}
            </StyledExamGrid>
          )}
        </StyledCardContent>
      </StyledSectionCard>

      {/* Files & Documents Card with Improved Layout */}
      <StyledSectionCard fullWidth>
        <StyledCardHeader>Files & Documents by Category</StyledCardHeader>
        <StyledCardContent>
          {loading ? (
            <StyledLoadingIndicator>Loading files...</StyledLoadingIndicator>
          ) : (
            <StyledCategoriesGrid>
              {Object.entries(categories).map(([categoryKey, categoryFiles]) => {
                // Sort files by date, newest first
                const sortedFiles = sortFilesByDate(categoryFiles);
                const newestFile = sortedFiles[0] || null;
                const olderFiles = sortedFiles.slice(1);

                return (
                  <StyledCategoryCard key={categoryKey}>
                    <StyledCategoryHeader
                      style={{
                        backgroundColor: '#f0f0f0', // CATEGORY_COLORS[categoryKey] || '#f0f0f0',
                        //color: '#fff',
                      }}
                    >
                      {DocumentCategories[categoryKey as keyof typeof DocumentCategories]?.name || categoryKey}
                      <StyledCategoryCount>
                        {categoryFiles.length} file{categoryFiles.length !== 1 ? 's' : ''}
                      </StyledCategoryCount>
                    </StyledCategoryHeader>

                    {newestFile && (
                      <StyledNewestFileBanner>
                        Latest - {newestFile.created ? dayjs(newestFile.created).format('MM/DD/YYYY h:mm A') : 'No date'}
                      </StyledNewestFileBanner>
                    )}

                    {/* Display newest file prominently */}
                    {newestFile && (
                      <StyledNewestFileContainer onClick={() => handleFileClick(newestFile, categoryKey)}>
                        {newestFile.thumbnailUrl ? (
                          <ImgProxy
                            fileId={newestFile.id}
                            thumbnailUrl={newestFile.thumbnailUrl}
                            dateModified={newestFile.modified}
                            isReadOnly
                            height="100%"
                            width="100%"
                          />
                        ) : (
                          <StyledFileIcon>{getFileIcon(newestFile.fileType)}</StyledFileIcon>
                        )}
                      </StyledNewestFileContainer>
                    )}

                    {/* Display other files in a grid */}
                    {olderFiles.length > 0 && (
                      <>
                        <StyledOlderFilesDivider />
                        <StyledOlderFilesGrid filesCount={Math.min(olderFiles.length, 4)}>
                          {olderFiles.slice(0, 4).map((file, index) => (
                            <StyledThumbnail key={`${file.id}-${index}`} onClick={() => handleFileClick(file, categoryKey)} clickable>
                              {file.thumbnailUrl ? (
                                <ImgProxy
                                  fileId={file.id}
                                  thumbnailUrl={file.thumbnailUrl}
                                  dateModified={file.modified}
                                  isReadOnly
                                  height="100%"
                                  width="100%"
                                />
                              ) : (
                                <StyledFileIcon>{getFileIcon(file.fileType)}</StyledFileIcon>
                              )}
                              <StyledFileDate>{file.created ? dayjs(file.created).format('MM/DD/YY') : ''}</StyledFileDate>
                            </StyledThumbnail>
                          ))}
                        </StyledOlderFilesGrid>
                      </>
                    )}

                    {/* View All button when there are more files */}
                    {categoryFiles.length > 4 && (
                      <StyledViewAllButton onClick={() => handleViewAllFiles(categoryKey)}>View All {categoryFiles.length} Files</StyledViewAllButton>
                    )}
                  </StyledCategoryCard>
                );
              })}

              {Object.keys(categories).length === 0 && <StyledEmptyMessage>No files found</StyledEmptyMessage>}
            </StyledCategoriesGrid>
          )}
        </StyledCardContent>
      </StyledSectionCard>

      {/* File Viewer */}
      {viewFileState.isOpen && <FileViewerPage {...viewFileState} />}
    </StyledDashboardContainer>
  );
};

// Styled components
const StyledDashboardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
`;

const StyledSectionCard = styled(Card)<{ fullWidth?: boolean }>`
  ${(props) =>
    props.fullWidth &&
    `
    grid-column: 1 / -1;
  `}
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const StyledCardHeader = styled.div`
  font-size: 18px;
  font-weight: 600;
  padding: 15px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.palette.grayscale[3]};
  background-color: ${({ theme }) => theme.colors.palette.grayscale[1]};
`;

const StyledCardContent = styled.div`
  padding: 20px;
  flex-grow: 1;
`;

const StyledPatientInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 0.5em;
`;

const StyledInfoItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledLabel = styled.span`
  font-weight: 600;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.palette.grayscale[7]};
`;

const StyledValue = styled.span`
  font-size: 16px;
`;

const StyledActionsBox = styled.div`
  margin-top: 15px;
  display: flex;
  gap: 10px;
`;

const StyledProgressContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

const StyledProgressCircle = styled.div<{ percent: number }>`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: conic-gradient(
    ${({ theme }) => theme.colors.primary} ${(props) => props.percent}%,
    ${({ theme }) => theme.colors.palette.grayscale[3]} ${(props) => props.percent}%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const StyledProgressInner = styled.div`
  background: white;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.primary};
`;

const StyledProgressInfo = styled.div`
  flex: 1;
`;

const StyledProgressText = styled.div`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.palette.grayscale[8]};
  margin-top: 10px;
`;

const StyledExamGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 20px;
`;

const StyledExamCard = styled.div<{ clickable?: boolean }>`
  border: 1px solid ${({ theme }) => theme.colors.palette.grayscale[3]};
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  ${(props) =>
    props.clickable &&
    `
    cursor: pointer;
    transition: all 0.2s ease;
    &:hover {
      background-color: ${props.theme.colors.palette.grayscale[1]};
      transform: translateY(-2px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
  `}
`;

const StyledExamCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px;
  background-color: ${({ theme }) => theme.colors.palette.grayscale[1]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.palette.grayscale[3]};
`;

const StyledExamTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 280px;
`;

const StyledExamCardContent = styled.div`
  display: flex;
  padding: 15px;
  gap: 15px;
  background-color: white;
`;

const StyledExamDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledExamInfoItem = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 5px;
`;

const StyledExamInfoLabel = styled.span`
  font-weight: 600;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.palette.grayscale[7]};
  width: 90px;
  flex-shrink: 0;
`;

const StyledExamInfoValue = styled.div`
  flex: 1;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledFileIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 24px;
  background-color: ${({ theme }) => theme.colors.palette.grayscale[1]};
`;

const StyledFileDate = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.palette.grayscale[7]};
`;

const StyledFileName = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 4px;
  font-size: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledCategoriesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
`;

const StyledCategoryCard = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.palette.grayscale[3]};
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`;

const StyledCategoryHeader = styled.div`
  padding: 10px 15px;
  background-color: ${({ theme }) => theme.colors.palette.grayscale[2]};
  font-weight: 600;
  border-bottom: 1px solid ${({ theme }) => theme.colors.palette.grayscale[3]};
`;

const StyledNewestFileBanner = styled.div`
  padding: 5px 10px;
  background-color: ${({ theme }) => theme.colors.palette.grayscale[2]};
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 600;
  margin: 10px 0;
`;

const StyledNewestFileContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.colors.palette.grayscale[3]};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.palette.grayscale[1]};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.palette.grayscale[2]};
  }
`;

const StyledNewestFileName = styled.div`
  flex: 1;
`;

const StyledOlderFilesDivider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.palette.grayscale[3]};
  margin: 10px 0;
`;

const StyledOlderFilesGrid = styled.div<{ filesCount: number }>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.filesCount}, 1fr);
  gap: 8px;
  padding: 10px;
`;

const StyledThumbnail = styled.div<{ clickable?: boolean }>`
  aspect-ratio: 1;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.palette.grayscale[3]};
  position: relative;
  ${(props) =>
    props.clickable &&
    `
    cursor: pointer;
    transition: all 0.2s ease;
    &:hover {
      transform: scale(1.05);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    }
  `}

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const StyledMoreFiles = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
  background-color: ${({ theme }) => theme.colors.palette.grayscale[2]};
  border-radius: 4px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.palette.grayscale[3]};
  }
`;

const StyledCategoryCount = styled.span`
  margin-left: 10px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.palette.grayscale[7]};
`;

const StyledViewAllButton = styled.div`
  margin-top: 15px;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledEmptyMessage = styled.div`
  text-align: center;
  padding: 30px;
  color: ${({ theme }) => theme.colors.palette.grayscale[6]};
  font-style: italic;
`;

const StyledLoadingIndicator = styled.div`
  text-align: center;
  padding: 30px;
  color: ${({ theme }) => theme.colors.palette.grayscale[7]};
`;

const StyledAISummary = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
`;

const StyledSummarySection = styled.div`
  p {
    margin-bottom: 15px;
    line-height: 1.5;
  }
`;

const StyledSectionTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.primary};
`;

const StyledBulletList = styled.ul`
  padding-left: 20px;

  li {
    margin-bottom: 8px;
    line-height: 1.4;
  }
`;

const StyledFileViewerContainer = styled.div`
  height: 100%;
  min-height: 600px;
`;

export default PatientDashboard;

const StyledExamStatusTag = styled.div<{ status: string }>`
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  background-color: ${({ status, theme }) => {
    // Color coding based on status
    if (status.toLowerCase().includes('complete')) {
      return '#4caf50'; // Green for completed
    }
    if (status.toLowerCase().includes('read')) {
      return '#2196f3'; // Blue for read
    }
    if (status.toLowerCase().includes('new')) {
      return '#ff9800'; // Orange for new
    }
    if (status.toLowerCase().includes('pending')) {
      return '#9c27b0'; // Purple for pending
    }
    if (status.toLowerCase().includes('cancel')) {
      return '#f44336'; // Red for cancelled
    }
    return theme.colors.palette.grayscale[5]; // Default gray
  }};
  color: white;
  white-space: nowrap;
`;

const StyledExamThumbnailsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 8px;
  margin-right: 15px;
  flex-shrink: 0;
  width: 120px;
  height: 120px;
`;

const StyledExamThumbnail = styled.div`
  aspect-ratio: 1;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.palette.grayscale[3]};
  position: relative;
  background-color: ${({ theme }) => theme.colors.palette.grayscale[1]};
`;
