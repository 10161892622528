import { memo, useEffect, useMemo, useRef, useState } from 'react';

import { State } from '@progress/kendo-data-query';
import { toDataSourceRequestString } from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Splitter, SplitterOnChangeEvent, SplitterPaneProps } from '@progress/kendo-react-layout';
import { cloneDeep, isEqual, sortBy, uniq } from 'lodash';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ChangeExamStatusModel, ExamGridModel, FileModel, PatientModel, WorklistViewModel } from 'models';

import { DataResult } from 'core/api';
import { apiClient } from 'core/api/globals';
import { useAsyncCallback, useBoolean, useDebounceEmitter, useEvent, useInterval } from 'core/hooks';
import { NotificationsService } from 'core/notifications';
import { ColumnState, Page, PageHeader, SelectedState } from 'core/ui';
import { downloadBlobAsFile, findOrThrow, hasText } from 'core/utils';

import { AuthenticationScheme, useAuthentication, useCurrentUser } from 'features/auth';
import { ExamEditWindow, ExamGrid } from 'features/exam/fragments';
import { useExamEditWindow } from 'features/exam/hooks';
import { UploadWindow, ViewFileWindow } from 'features/file/fragments';
import { useSessionLocation } from 'features/location';
import { DEFAULT_FINAL_REPORT_VALUES } from 'features/patient/constants';
import { ShareModal } from 'features/patient/fragments';
import { useUserSettings } from 'features/settings';
import { WorklistSidebar } from 'features/worklist';
import { WorklistSidebarCollapsed } from 'features/worklist/fragments/WorklistSidebarCollapsed';

import { DocumentCategories } from '../constants';
import { ExamsGridService } from '../services';
import { BaseExamGridPageProps, ExamGridColumnsStateChangeEvent, ExamGridDataStateChangeEvent, ExamWorklistSidebarLocalStorageState } from '../types';

const AUTO_REFRESH_INTERVAL = 60 * 1000; // 60 sec

export const BaseExamGridPage = memo<BaseExamGridPageProps>(({ featureView, title, selectable = true, showExamToolbar = false, pickDefaultWorklist }) => {
  const navigate = useNavigate();
  const { reactPatientFormPage, legacyBaseUrl, reactUploadPage } = useUserSettings(true);
  const { currentUser } = useCurrentUser(true);
  const gridExcelExport = useRef<ExcelExport | null>(null);
  const { activeScheme } = useAuthentication();

  const [isLoading, setIsLoading] = useState(false);
  const [exams, setExams] = useState<null | DataResult<ExamGridModel>>(null);
  const [selectedExams, setSelectedExams] = useState<Record<number, boolean>>({});
  const [allWorklists, setAllWorklists] = useState<WorklistViewModel[] | null>(null);
  const [selectedWorklistId, setSelectedWorklistId] = useState<number | null>(null);
  const [panes, setPanes] = useState<SplitterPaneProps[] | null>(null);
  const [dataState, setDataState] = useState<State | null>(null);
  const [columnsState, setColumnsState] = useState<Record<string, ColumnState> | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [selectedExamId, setSelectedExamId] = useState(0);
  const [selectedExamStatus, setSelectedExamStatus] = useState('');
  const [isShareModalVisible, { setTrue: setShareModalVisible, setFalse: setShareModalHidden }] = useBoolean(false);
  const [shareExamId, setShareExamId] = useState<number | null>(null);
  const [sharePatientId, setSharePatientId] = useState<number | null>(null);
  const [isDonorViewFinalReportVisible, setIsDonorViewFinalReportVisible] = useState(false);
  const [allCounts, setAllCounts] = useState<Record<string, number>>({});
  const { sessionLocation, setSessionLocation, sessionLocationOptions } = useSessionLocation(false);
  const [isDonorViewFilesVisible, setIsDonorViewFilesVisible] = useState(false);
  const [finalReport, setFinalReport] = useState<FileModel>(DEFAULT_FINAL_REPORT_VALUES);

  const [fetchExams] = useAsyncCallback(async (signal, showLoadingSpinner: boolean, dataStateOverride?: State, searchValueOverride?: string) => {
    if (dataStateOverride == null && dataState == null) {
      throw new Error('Cannot fetch exams because the dataState is null and a dataStateOverride was not specified.');
    }

    const querySearchValue = searchValueOverride ?? searchValue;

    const queryDataState = hasText(querySearchValue)
      ? ExamsGridService.getQueryDataState(dataStateOverride ?? dataState!, querySearchValue) // Non-null assertion because TS doesn't seem to be able to infer that both dataStateOverride and dataState cannot both be null.
      : (dataStateOverride ?? dataState!);

    try {
      if (showLoadingSpinner) {
        setIsLoading(true);
      }

      //default to active
      if (queryDataState?.filter?.filters == null || queryDataState.filter.filters.length === 0) {
        queryDataState.filter = {
          logic: 'and',
          filters: [
            {
              field: 'active',
              operator: 'eq',
              value: true,
            },
          ],
        };
      }

      //filter exams by selected location
      if (queryDataState?.filter?.filters) {
        queryDataState.filter.filters = queryDataState.filter.filters.filter((f) => ('field' in f && f.field != 'location_Id') || 'filters' in f);

        if (sessionLocation?.id != null) {
          queryDataState.filter.filters.push({
            field: 'location_Id',
            operator: 'eq',
            value: sessionLocation?.id,
          });
        }
      }

      const newExams = await apiClient.exams.getAllForKendoGrid(
        queryDataState,
        activeScheme == null || activeScheme === AuthenticationScheme.OIDC ? 'msal-required' : 'share-required',
        signal,
      );

      setExams(newExams);
    } finally {
      if (showLoadingSpinner) {
        setIsLoading(false);
      }
    }
  });

  const [fetchCounts] = useAsyncCallback(async (signal, clearExistingCounts: boolean, worklistIdsOverride?: number[]) => {
    if (clearExistingCounts) setAllCounts({});

    const worklistIds = worklistIdsOverride ?? allWorklists?.map((w) => w.id) ?? [];

    if (worklistIds.length === 0) {
      setAllCounts({});
      return {};
    }

    const results = await apiClient.workListViewClient.getExamStatusCount(worklistIds, signal);
    setAllCounts(results);
    return results;
  });

  const initialize = useEvent(async () => {
    const newWorklists = await apiClient.workListViewClient.getViews(false, sessionLocation?.id);

    const { newSelectedWorklist, newDataState, newColumnsState, newSearchValue } = ExamsGridService.initializeExamGridState(
      newWorklists,
      currentUser.role,
      pickDefaultWorklist,
    );

    const initialPanesState = ExamsGridService.initializeExamSplitterState(newWorklists == null || newWorklists.length === 0);

    setAllWorklists(newWorklists);
    setPanes(initialPanesState);
    setSelectedWorklistId(newSelectedWorklist?.id ?? null);
    setDataState(newDataState);
    setColumnsState(newColumnsState);
    setSearchValue(newSearchValue);

    // Immediately start fetching exams and counts.  We do not need to await for them to complete.
    fetchExams(true, newDataState);
    fetchCounts(
      true,
      newWorklists.map((w) => w.id),
    );
  });

  const { emitDebounce, clearDebounce } = useDebounceEmitter(() => {
    fetchExams(true);
  }, 500);

  const editWindow = useExamEditWindow({ onBaseGridUpdate: fetchExams });

  useEffect(() => {
    if (dataState) {
      const oDataFilterString = toDataSourceRequestString(dataState);
      localStorage.setItem('currentWorklistViewODataStr', oDataFilterString);
    } else {
      localStorage.removeItem('currentWorklistViewODataStr');
    }
  }, [dataState]);

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.data === 'examClosed') {
        fetchExams(true);
      }
    });
  }, []);

  const handleReadClick = useEvent(async (dataItem: ExamGridModel) => {
    // open exam edit in new window for dual screen work and to not lose grid state
    window.open(`/exam/${dataItem.id}/read`, 'ReadExam', 'menubar=0,status=0,fullscreen=1');
  });

  const handleAddNewClick = useEvent(() => {
    // open exam add in new window for dual screen work and to not lose grid state
    window.open(`/exam/add`, 'AddExam', 'menubar=0,status=0,fullscreen=1');
  });

  const handleUploadClick = useEvent(() => {
    if (reactUploadPage) {
      navigate('/upload/' + (sessionLocation == null ? '' : `?sessionLocationId=${sessionLocation.id}`));
    } else {
      window.location.href = `${legacyBaseUrl}/DICOM/UploadDICOM`;
    }
  });

  const handleEditClick = useEvent((dataItem: ExamGridModel) => {
    // open exam edit in new window for dual screen work and to not lose grid state
    window.open(`/exam/${dataItem.id}/edit`, 'EditExam', 'menubar=0,status=0,fullscreen=1');
  });

  const handleViewDicomImages = useEvent((dataItem: ExamGridModel) => {
    window.open(`/file-viewer/${dataItem.id}?feature=exams`, '_blank');
  });

  const handleShareGridClick = useEvent((exam: ExamGridModel | null | undefined) => {
    if (exam == null) return;
    if (exam.id == null) throw new Error('Cannot share an exam that does not have an ID.');
    if (exam.patientId == null) throw new Error('Cannot share an exam that does not have a patient ID.');

    setShareModalVisible();
    setShareExamId(exam.id);
    setSharePatientId(exam.patientId);
  });

  const handleSharePatientChange = useEvent((newPatient: PatientModel) => {
    setExams((prev) => {
      if (prev == null) return prev;

      const newExams: DataResult<ExamGridModel> = {
        total: prev.total,
        data: prev.data.map((exam) => (exam.patientId === newPatient.id ? { ...exam, unosID: newPatient.unosID ?? undefined } : exam)),
      };

      return newExams;
    });
  });

  const handleViewAttachments = useEvent((dataItem?: ExamGridModel) => {
    setSelectedExamId(dataItem?.id ?? 0);
    setSelectedExamStatus(dataItem?.statusType ?? '');
    // toggle the donor diaglog
    setIsDonorViewFilesVisible(!isDonorViewFilesVisible);
  });

  const handleViewFinalReport = useEvent((dataItem: ExamGridModel) => {
    const finalReport =
      dataItem.files?.find(
        (fileItem: FileModel) => fileItem.fileName?.toLowerCase() === 'finalreport.pdf' || fileItem.categoryId === DocumentCategories.FINAL_REPORT.value,
      ) ?? null;
    if (finalReport) setFinalReport(finalReport);
    setIsDonorViewFinalReportVisible(!isDonorViewFinalReportVisible);
  });

  const handleReadRequestClick = useEvent((dataItem: ExamGridModel) => {
    if (featureView === 'Exam') {
      navigate(`/exam/${dataItem.id}/read-request`);
    } else {
      throw new Error(`handleReadRequestClick() is not implemented for featureView: "${featureView as string}".`);
    }
  });

  const handleManagePatientClick = useEvent((dataItem: ExamGridModel) => {
    if (reactPatientFormPage) {
      navigate(`/patient-2/edit/${dataItem.patientId}`);
    } else {
      window.location.href = `${legacyBaseUrl}/patient/edit/${dataItem.patientId}`;
    }
  });

  const handleRowDoubleClick = useEvent((dataItem: ExamGridModel) => {
    if (featureView === 'Exam') {
      handleEditClick(dataItem);
    } else if (featureView === 'Physician') {
      handleReadClick(dataItem);
    } else {
      throw new Error(`handleRowDoubleClick() is not implemented for featureView: "${featureView as string}".`);
    }
  });

  const handleDataStateChange = useEvent((event: ExamGridDataStateChangeEvent) => {
    setDataState(event.dataState);

    if (event.refreshExpectation === 'debounce') {
      emitDebounce();
    } else if (event.refreshExpectation === 'now') {
      clearDebounce();
      fetchExams(true, event.dataState);
    }

    if (['browser-only', 'browser-and-worklist'].includes(event.saveExpectation)) {
      ExamsGridService.saveExamGridState({
        worklistViewId: selectedWorklistId,
        dataState: event.dataState,
        columnsState,
        search: hasText(searchValue) ? searchValue.trim() : null,
      });
    }

    // TODO: Implement this.
    if (event.saveExpectation === 'browser-and-worklist') {
      throw new Error('TODO: Implement saving data state to worklist.');
    }
  });

  const handleColumnsStateChange = useEvent(async (event: ExamGridColumnsStateChangeEvent) => {
    setColumnsState(event.columnsState);

    if (['browser-only', 'browser-and-worklist'].includes(event.saveExpectation)) {
      ExamsGridService.saveExamGridState({
        worklistViewId: selectedWorklistId,
        dataState,
        columnsState: event.columnsState,
        search: hasText(searchValue) ? searchValue.trim() : null,
      });
    }

    // TODO: Implement this.
    if (event.saveExpectation === 'browser-and-worklist') {
      throw new Error('TODO: Implement saving columns state to worklist.');
    }
  });

  const handleExportCSVClick = useEvent(async () => {
    if (dataState == null) {
      throw new Error('Cannot export CSV because the dataState is null.');
    }

    const csvBlob = await apiClient.exams.exportExamsForKendoGrid(dataState);
    downloadBlobAsFile(csvBlob, 'csv', 'Exams');
  });

  const handleBulkCancel = useEvent(async (data: SelectedState, cancelReason: string | null) => {
    const changes: ChangeExamStatusModel[] = Object.keys(data)
      .filter((key) => data[key])
      .map((key) => ({
        id: parseInt(key, 10),
        status: null,
        cancelReason: hasText(cancelReason) ? cancelReason.trim() : null,
      }));

    await apiClient.exams.bulkCancel(changes);

    NotificationsService.displaySuccess(`${changes.length === 1 ? 'Exam' : 'Exams'} cancelled successfully.`);
    clearDebounce();
    fetchExams(true);
    fetchCounts(false);
  });

  const handleBulkAssignSubmit = useEvent(async (physicianId: number) => {
    const examIds = Object.entries(selectedExams)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_key, isSelected]) => isSelected)
      .map(([key]) => parseInt(key, 10));

    await apiClient.exams.bulkAssignDoctor(examIds, physicianId);
    NotificationsService.displaySuccess(`${examIds.length === 1 ? 'Exam' : 'Exams'} assigned successfully.`);

    clearDebounce();
    fetchExams(true);
    fetchCounts(false);
  });

  const handleChangeStatusSubmit = useEvent(async (change: ChangeExamStatusModel) => {
    await apiClient.exams.changeStatus(change);

    NotificationsService.displaySuccess('Exam status changed successfully.');
    clearDebounce();
    fetchExams(true);
    fetchCounts(false);
  });

  const handleSearchChange = useEvent((newSearchValue: string) => {
    ExamsGridService.saveExamGridState({
      worklistViewId: selectedWorklistId,
      dataState,
      columnsState,
      search: hasText(searchValue) ? searchValue.trim() : null,
    });

    setSearchValue(newSearchValue);
    emitDebounce();
  });

  const handleSearchSubmit = useEvent(async () => {
    clearDebounce();
    fetchExams(true);
  });

  const handleWorklistsRefreshClick = useEvent(async () => {
    // For better responsiveness we are going to immediately update the worklist counts, but we also need to refresh the lists of worklists in case a different user added one.
    // We do not need to await for the fetchCounts() to complete to go ahead and fetch the new worklists because they are independent operations.
    fetchCounts(true);

    const newWorklists = await apiClient.workListViewClient.getViews(false, sessionLocation?.id);

    setAllWorklists(newWorklists);

    // Check if any worklists were added or removed.  Because we will have to refresh the counts an additional time to make sure we have counts for the new worklists.
    const oldWorklistIds = sortBy(uniq(allWorklists?.map((w) => w.id) ?? []));
    const newWorklistIds = sortBy(uniq(newWorklists.map((w) => w.id)));

    if (!isEqual(oldWorklistIds, newWorklistIds)) {
      fetchCounts(
        true,
        newWorklists.map((w) => w.id),
      );
    }
  });

  const handleSelectedWorklistIdChange = useEvent((worklistId: number | null) => {
    if (allWorklists == null) throw new Error('Cannot change selected worklist because the array of worklists is null or undefined.');

    const { newDataState, newColumnsState, newSearchValue } = ExamsGridService.updateStateFromWorklist(worklistId, currentUser.role, allWorklists, dataState);

    setDataState(newDataState);
    setColumnsState(newColumnsState);
    setSearchValue(newSearchValue);
    setSelectedWorklistId(worklistId);
    setSelectedExams({});

    clearDebounce();
    fetchExams(true, newDataState);
  });

  const handleCreateWorklist = useEvent(async (model: WorklistViewModel) => {
    if (allWorklists == null) return; // Don't do anything if the worklists haven't been loaded yet.

    const newWorklist = await apiClient.workListViewClient.createView(model);

    const { newDataState, newColumnsState, newSearchValue } = ExamsGridService.updateStateFromWorklist(newWorklist.id, currentUser.role, [newWorklist], null);

    setSelectedWorklistId(newWorklist.id);
    setDataState(newDataState);
    setColumnsState(newColumnsState);
    setSearchValue(newSearchValue);

    clearDebounce();
    fetchCounts(false, [...allWorklists.map((w) => w.id), newWorklist.id]);
    fetchExams(true, newDataState); // We don't need to await for the fetch to complete because refreshing the list of worklists and performing the grid query are independent operations.

    // Refresh the list of worklists.
    const newWorklists = await apiClient.workListViewClient.getViews(false, sessionLocation?.id);
    setAllWorklists(newWorklists);
  });

  const handleUpdateWorklist = useEvent(async (worklists: WorklistViewModel[]) => {
    await Promise.all(worklists.map((w) => apiClient.workListViewClient.updateView(w)));

    // Refresh the exams grid if the selected worklist was modified.
    const selectedWorklist = worklists.find((w) => w.id === selectedWorklistId);

    if (selectedWorklist != null) {
      const { newDataState, newColumnsState, newSearchValue } = ExamsGridService.updateStateFromWorklist(
        selectedWorklist.id,
        currentUser.role,
        [selectedWorklist],
        null,
      );

      setDataState(newDataState);
      setColumnsState(newColumnsState);
      setSearchValue(newSearchValue);

      clearDebounce();
      fetchExams(true, newDataState); // We don't need to await for the fetch to complete because refreshing the list of worklists and performing the grid query are independent operations.
    }

    // Refresh the worklists.
    const newWorklists = await apiClient.workListViewClient.getViews(false, sessionLocation?.id);

    setAllWorklists(newWorklists);

    fetchCounts(
      false,
      newWorklists.map((w) => w.id),
    ); // We don't need to await for the fetch counts to complete.
  });

  const handleDeleteWorklist = useEvent(async (worklist: WorklistViewModel) => {
    await apiClient.workListViewClient.deleteView(worklist.id);

    if (selectedWorklistId === worklist.id) {
      // The user deleted the currently selected worklist.  Reset the grid state.
      const { newDataState, newColumnsState, newSearchValue } = ExamsGridService.updateStateFromWorklist(null, currentUser.role, [], null);

      ExamsGridService.saveExamGridState(null);
      setSelectedWorklistId(null);
      setDataState(newDataState);
      setColumnsState(newColumnsState);
      setSearchValue(newSearchValue);

      clearDebounce();
      fetchExams(true, newDataState); // We don't need to await for the fetch to complete because refreshing the list of worklists and performing the grid query are independent operations.
    }

    setAllWorklists((prev) => prev?.filter((w) => w.id !== worklist.id) ?? []);
  });

  const handleSidebarExpandChange = useEvent((isExpanded: boolean) => {
    if (panes == null) throw new Error('Cannot proceed because panes is null.');

    const newPanesState = cloneDeep(panes);
    newPanesState[0].collapsed = !isExpanded;

    setPanes(newPanesState);
    ExamsGridService.saveExamSplitterState(newPanesState);
  });

  const handleSidebarExpandClick = useEvent(() => {
    handleSidebarExpandChange(true);
  });

  const handleFinalReportClosed = useEvent(() => {
    setIsDonorViewFinalReportVisible(false);
  });

  const handleSplitterChange = useEvent((event: SplitterOnChangeEvent) => {
    setPanes(event.newState);

    ExamsGridService.saveExamSplitterState(event.newState);
  });

  const SlotToolbarPrefix = useMemo(() => <WorklistSidebarCollapsed handleShowSidebar={handleSidebarExpandClick} />, [handleSidebarExpandClick]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const isDataStateInitialized = dataState != null;

  useEffect(() => {
    if (!isDataStateInitialized) return;

    fetchExams(true);
  }, [isDataStateInitialized, fetchExams, sessionLocation?.id]);

  useInterval(() => {
    clearDebounce();
    fetchExams(false);
    fetchCounts(false);
  }, AUTO_REFRESH_INTERVAL);

  if (dataState == null || columnsState == null || panes == null) {
    return null;
  }

  return (
    <Page>
      <PageHeader showSessionLocation title={title} />
      <ExcelExport data={exams?.data} ref={gridExcelExport} />
      <StyledSplitter panes={panes} orientation="horizontal" onChange={handleSplitterChange}>
        <WorklistSidebar
          autoRefreshInterval={AUTO_REFRESH_INTERVAL}
          allWorklists={allWorklists}
          allCounts={allCounts}
          showSidebar={true}
          selectedWorklistId={selectedWorklistId}
          currentDataState={dataState}
          currentColumnsState={columnsState}
          onShowSidebarChange={handleSidebarExpandChange}
          onSelectedWorklistIdChange={handleSelectedWorklistIdChange}
          onRefreshClick={handleWorklistsRefreshClick}
          onCreateWorklist={handleCreateWorklist}
          onUpdateWorklist={handleUpdateWorklist}
          onDeleteWorklist={handleDeleteWorklist}
        />
        <ExamGrid
          featureView={featureView}
          columnsState={columnsState}
          dataState={dataState}
          data={exams}
          showActionColumn
          showExamToolbar={showExamToolbar}
          isLoading={isLoading}
          slotToolbarPrefix={panes[0].collapsed ? SlotToolbarPrefix : undefined}
          selectable={selectable}
          selectedExams={selectedExams}
          filterSearch={searchValue}
          onExamReadClick={handleReadClick}
          onDataStateChange={handleDataStateChange}
          onColumnsStateChange={handleColumnsStateChange}
          onAddNewClick={handleAddNewClick}
          onSelectedExamsChange={setSelectedExams}
          onExamEditClick={handleEditClick}
          onExamReadRequestClick={handleReadRequestClick}
          onExamManagePatientClick={handleManagePatientClick}
          onExamDoubleClick={handleRowDoubleClick}
          onExportCsvClick={handleExportCSVClick}
          onBulkCancelSubmit={handleBulkCancel}
          onBulkChangeStatusSubmit={handleChangeStatusSubmit}
          onBulkAssignSubmit={handleBulkAssignSubmit}
          onFilterSearchChange={handleSearchChange}
          onFilterSearchSubmit={handleSearchSubmit}
          onExamViewClick={handleViewDicomImages}
          onExamViewAttachmentsClick={handleViewAttachments}
          onExamShareClick={handleShareGridClick}
          onExamViewFinalReportClick={handleViewFinalReport}
          onExamEditWindowClick={editWindow.openExamEditWindow}
          onUploadClick={handleUploadClick}
        />
      </StyledSplitter>
      {isDonorViewFilesVisible && <UploadWindow onClose={handleViewAttachments} examId={selectedExamId} onChange={() => fetchExams(false)}></UploadWindow>}
      {isDonorViewFinalReportVisible && <ViewFileWindow title="Final Report" file={finalReport} onClose={handleFinalReportClosed} />}
      {sharePatientId && (
        <ShareModal
          patientId={sharePatientId}
          examId={shareExamId}
          show={isShareModalVisible}
          onHide={setShareModalHidden}
          onPatientChange={handleSharePatientChange}
        />
      )}
      {editWindow?.selectedExamId && editWindow.showExamEditWindow && editWindow.selectedPatientId && (
        <ExamEditWindow
          onClosed={editWindow.closeExamEditWindow}
          patientId={editWindow.selectedPatientId}
          examId={editWindow.selectedExamId}
          onDeleteShareClick={editWindow.handleDeleteShareClick}
          onExamFormSubmit={editWindow.handleFormSubmit}
        />
      )}
    </Page>
  );
});

BaseExamGridPage.displayName = 'BaseExamGridPage';
const StyledSplitter = styled(Splitter)`
  --kendo-color-primary: ${({ theme }) => theme.colors.primary};

  overflow: hidden;

  .k-pane.k-hidden + .k-splitbar {
    display: none;
  }

  .grid-pane {
    display: grid;
    overflow: hidden;
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr;
  }
`;
