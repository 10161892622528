import { Col, Row } from 'react-bootstrap';

import { Field, FormValidatorsService } from 'core/forms';
import { Accordion, DropdownWithValuesField, TextArea } from 'core/ui';

import { SATISFACTORY_SAMPLE_SIZE_OPTIONS } from '../constants';

type PathologyTargetedAccordionBodyProps = {
  isUninterpretable: boolean;
  valueGetter: (key: string) => any;
};

export const PathologyTargetedAccordionBody = ({ isUninterpretable, valueGetter }: PathologyTargetedAccordionBodyProps) => {
  const satisfactorySampleSize = SATISFACTORY_SAMPLE_SIZE_OPTIONS.find((option) => option.value === valueGetter('Results.satisfactorySampleSize'));

  return (
    <Accordion.Body>
      <Row>
        <Col className="col-6">
          <Field
            component={DropdownWithValuesField}
            data={SATISFACTORY_SAMPLE_SIZE_OPTIONS}
            dataItemKey="value"
            filterable={false}
            isForPrimitiveValues
            label="Satisfactory Sample"
            name="Results.satisfactorySampleSize"
            required={!isUninterpretable}
            validator={!isUninterpretable ? undefined : FormValidatorsService.required}
            valueField="value"
          />
        </Col>
        <Col className="col-12">
          <Field
            component={TextArea}
            label="Interpretation"
            name="Results.comments"
            rows={10}
            warning={satisfactorySampleSize?.requiresCommentsMessage ?? undefined}
            required={isUninterpretable || satisfactorySampleSize?.requiresComments}
            validator={isUninterpretable || satisfactorySampleSize?.requiresComments ? FormValidatorsService.required : undefined}
          />
        </Col>
      </Row>
    </Accordion.Body>
  );
};
