import { FunctionComponent, Key, useEffect, useState } from 'react';

import { MultiSelectChangeEvent } from '@progress/kendo-react-dropdowns';
import { FieldRenderProps } from '@progress/kendo-react-form';
import { CheckboxChangeEvent } from '@progress/kendo-react-inputs';
import _map from 'lodash/map';
import _sortBy from 'lodash/sortBy';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

import { ServiceModel } from 'models';

import { apiClient } from 'core/api/globals';
import { Field, FormValidatorsService } from 'core/forms';
import { Checkbox, Label, Tooltip } from 'core/ui';

import { CTOrganVolumes, ExamTypes } from 'features/exam/constants';

type CheckBoxServiceModel = Partial<ServiceModel> & { checked: boolean };

export const ReadRequestService: FunctionComponent<{
  service: ServiceModel | null | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueGetter: (key: string) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueSetter: (key: string, value: any) => void;
  examId?: number;
  fieldOnly?: boolean | undefined;
  onChange?: (event: MultiSelectChangeEvent) => void;
}> = ({ service, valueGetter, valueSetter, examId, fieldOnly = false, onChange }) => {
  {
    const [serviceSubtypes, setServiceSubtypes] = useState<ServiceModel[]>([]);
    const [isDefaultTagSet, setIsDefaultTagSet] = useState(false);

    useEffect(() => {
      const fetchData = async () => {
        if (service?.id) {
          setServiceSubtypes([]); //clear the list before fetching new data
          try {
            let selectedServices: ServiceModel[] = [];
            if (examId !== undefined && fieldOnly) {
              selectedServices = await apiClient.servicesClient.getServicesByExam(examId);
            }
            const subtypes = await apiClient.servicesClient.getServiceSubtypes(service.id);
            // set children for each subtype
            for (const subtype of subtypes) {
              const children = await apiClient.servicesClient.getServiceSubtypes(subtype.id || 0);
              subtype.children = children;
            }

            const services = subtypes.map((subtype) => {
              const selectedService = selectedServices.find((s) => s.id === subtype.id);
              // TODO consider making recursive function to set checked for children
              const children = subtype.children?.map((child) => {
                const selectedChild = selectedServices.find((s) => s.id === child.id);
                return { ...child, checked: !!selectedChild };
              });
              return { ...subtype, checked: !!selectedService, children };
            });

            valueSetter('services', { value: services.filter((s) => s.checked) });
            valueSetter('services_services', { value: services.flatMap((s) => s.children?.filter((c) => c.checked)) });
            setServiceSubtypes(services);
          } catch (error) {
            console.error('Failed to fetch service subtypes:', error);
          }
        }
      };

      fetchData();
    }, [examId, fieldOnly, service?.id, valueSetter]);

    if (!fieldOnly && service?.description && ExamTypes.CT.includes(service?.description)) {
      const ctDefaultSubtype = serviceSubtypes.find((s) => s.description?.toLowerCase() === 'ct interpretation');
      if (ctDefaultSubtype?.description && !isDefaultTagSet) {
        const services = serviceSubtypes.map((s) => ({ ...s, checked: s.id === ctDefaultSubtype.id }));
        setServiceSubtypes(services);
        valueSetter('services', { value: services.filter((s) => s.checked) });
        setIsDefaultTagSet(true);
      }
    }

    const serviceSubtypeSort = (serviceSubtypes: ServiceModel[]) => {
      return serviceSubtypes.sort((a, b) => {
        const descA = a.description || ''; // Default to empty string if null
        const descB = b.description || ''; // Default to empty string if null

        if (descA === 'CT Interpretation') return -1;
        if (descB === 'CT Interpretation') return 1;

        return descA.localeCompare(descB);
      });
    };

    return (
      service?.description &&
      serviceSubtypes?.length > 0 &&
      ExamTypes.CT.includes(service.description) && (
        <>
          <Field
            allowCustom
            required={true}
            validator={FormValidatorsService.required}
            component={CheckboxServiceList}
            textField="description"
            dataItemKey="id"
            data={serviceSubtypeSort(serviceSubtypes)}
            name="services"
            label="Services"
            id="services"
            onChange={onChange}
          />
        </>
      )
    );
  }
};

const CheckboxServiceList = (fieldRenderProps: FieldRenderProps) => {
  const { data, label, id, valid, disabled, optional, onChange } = fieldRenderProps;
  const [dynamicKey, setDynamicKey] = useState(new Date());
  const [value, setValue] = useState<CheckBoxServiceModel[]>(data);

  useEffect(() => {
    setValue(data);
  }, [data]);

  const onCheckboxChange = (event: CheckboxChangeEvent) => {
    const checked = !!event.target.value;
    const currentItemValue = event.target.name;

    const newValue = value.map((d) => {
      return { ...d, checked: d.id?.toString() === currentItemValue ? checked : !!d.checked };
    });
    setValue(newValue);

    fieldRenderProps.onChange({
      value: newValue.filter((d: CheckBoxServiceModel) => d.checked),
    });
  };

  return (
    <div key={dynamicKey.toString()}>
      {!!value.length && (
        <Label editorId={id} editorValid={valid} editorDisabled={disabled} optional={optional} required={true}>
          {label}
        </Label>
      )}
      {value.map((d) => {
        const name = `services_${id}`;
        return (
          <>
            <StyledCheckboxContainer key={d.id}>
              <Checkbox key={d.id} name={d.id?.toString()} value={d.checked} onChange={onCheckboxChange} />
              <span>{d.description}</span>
            </StyledCheckboxContainer>
            {d.checked && d.children && d.children.length > 0 && (
              <StyledRow>
                <Col className="col-8">
                  <Field allowCustom component={CheckboxServiceList} textField="description" dataItemKey="id" data={d.children} name={name} id={name} />
                </Col>
              </StyledRow>
            )}
          </>
        );
      })}
    </div>
  );
};

const StyledCheckboxContainer = styled.div`
  display: flex;
  margin: 5px 0;
  gap: 5px;
`;

const StyledRow = styled(Row)`
  margin-left: ${(props) => props.theme.space.spacing50};
`;
