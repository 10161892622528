import React, { memo, useEffect, useId, useRef, useState } from 'react';

import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';

import { ExamModel } from 'models';

import { apiClient } from 'core/api/globals';
import { useEvent } from 'core/hooks';
import { NotificationsService } from 'core/notifications';
import { Button, TabButton, TabsContainer, Window, WindowActionsBar } from 'core/ui';

import { ShareGrid } from 'features/patient/fragments';
import { HippaLogs } from 'features/share/fragments';

import { ExamForm } from './ExamForm';

type ExamEditWindowProps = {
  examId: number;
  patientId: number;
  onExamFormSubmit: () => void;
  onDeleteShareClick: (studyShareId: number) => void;
  onClosed: () => void;
};

export const ExamEditWindow: React.FC<ExamEditWindowProps> = memo(({ examId, patientId, onExamFormSubmit, onDeleteShareClick, onClosed }) => {
  const formId = useId();
  const [tab, setTab] = useState<'EXAM_INFO' | 'HIPPA_LOGS' | 'SHARES_OUT'>('EXAM_INFO');
  const [exam, setExam] = useState<ExamModel | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const hasFetchedExam = useRef(false);

  const fetchExam = useEvent(async () => {
    if (hasFetchedExam.current) return;
    try {
      const examData = await apiClient.exams.getExamById(examId);
      setExam(examData);
      hasFetchedExam.current = true;
    } catch (error) {
      NotificationsService.displayError('Failed to fetch exam');
    } finally {
      setIsLoading(false);
    }
  });

  useEffect(() => {
    fetchExam();
  }, [examId, fetchExam]);

  return (
    <Window modal title="Edit Exam" onClose={onClosed} initialWidth={575}>
      <TabsContainer>
        <TabButton type="button" selected={tab === 'EXAM_INFO'} roundedCorners="left" onClick={() => setTab('EXAM_INFO')}>
          Exams
        </TabButton>
        <TabButton type="button" selected={tab === 'HIPPA_LOGS'} roundedCorners="left" onClick={() => setTab('HIPPA_LOGS')}>
          HIPPA Logs
        </TabButton>
        <TabButton type="button" selected={tab === 'SHARES_OUT'} roundedCorners="right" onClick={() => setTab('SHARES_OUT')}>
          Shares Out
        </TabButton>
      </TabsContainer>
      <StyledMainContentContainer>
        {tab === 'EXAM_INFO' && exam && <ExamForm exam={exam} onSaveSuccess={onExamFormSubmit} formId={formId} />}
        {tab === 'HIPPA_LOGS' && <HippaLogs examId={examId} />}
        {tab === 'SHARES_OUT' && <ShareGrid patientId={patientId} examId={examId} onDeleteShareClick={onDeleteShareClick} />}
      </StyledMainContentContainer>{' '}
      <WindowActionsBar>
        <Button form={formId} type="submit" title="Save Exam">
          Save Exam
        </Button>
      </WindowActionsBar>
    </Window>
  );
});

ExamEditWindow.displayName = 'ExamEditWindow';

const StyledMainContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-top: 10px;
  overflow: auto;
`;

const StyledTableContainer = styled.div`
  width: 95%;
  height: 100%;
`;

const StyledSpinner = styled(Spinner)`
  flex: 1 0 auto;
  margin: auto;
`;

export default ExamEditWindow;
