import { ComponentPropsWithRef, FunctionComponent, useState } from 'react';

import { faCheck, faCopy } from '@fortawesome/pro-solid-svg-icons';
import { QRCode } from '@progress/kendo-react-barcodes';
import { Button as KendoButton } from '@progress/kendo-react-buttons';
import { Window } from '@progress/kendo-react-dialogs';
import Card from 'react-bootstrap/Card';
import styled from 'styled-components';

import { Icon, Input, Tooltip } from 'core/ui';

type QRScanModalProps = {
  onClose: () => void;
  examId: number;
  linkId: string;
  show: boolean;
};

export const QRScanModal: FunctionComponent<QRScanModalProps> = ({ onClose, linkId }) => {
  const uploadLink = `${window.location.origin}/share/${linkId}/upload`;

  const [showCopiedCheckmark, setShowCopiedCheckmark] = useState<boolean>(false);
  const addToClipboard = async () => {
    await navigator.clipboard.writeText(uploadLink);
    setShowCopiedCheckmark(true);
    console.log(showCopiedCheckmark);
    setTimeout(() => {
      setShowCopiedCheckmark(false);
    }, 2000);
  };

  return (
    <>
      <Window title="Mobile Upload" onClose={onClose} initialHeight={500} initialWidth={300}>
        <StyledWindowContainer>
          <StyledQRCode value={uploadLink} style={qrcodeStyles} />

          <Card>
            <Card.Body>
              <StyledCardLayout>
                <div>
                  <StyledSpan className="fa-regular fa-circle-info" />
                </div>
                <div>
                  <StyledCardTitle>Scan QR Code to Upload</StyledCardTitle>
                  <StyledCardText>
                    Scan the QR code to upload files from your phone&apos;s camera roll or saved files, or use your camera to securely attach a new photo
                    <StyledBoldCardText>&nbsp;without saving to camera roll.</StyledBoldCardText>
                  </StyledCardText>
                </div>
              </StyledCardLayout>
            </Card.Body>
          </Card>
          <StyledCopyLinkContainer>
            <StyledInputUrlDiv>
              <Tooltip text={uploadLink}>
                <span>
                  <Input disabled value={uploadLink}></Input>
                </span>
              </Tooltip>
            </StyledInputUrlDiv>

            <Tooltip text="Copy Link">
              <span>
                <StyledCopyButton fillMode="link" onClick={addToClipboard} type="button">
                  {<StyledIcon icon={showCopiedCheckmark ? faCheck : faCopy}></StyledIcon>}
                </StyledCopyButton>
              </span>
            </Tooltip>
          </StyledCopyLinkContainer>
        </StyledWindowContainer>
      </Window>
    </>
  );
};

QRScanModal.displayName = 'QRScanModal';

const qrcodeStyles = {
  width: '100%',
  'min-width': '100px',
  'aspect-ratio': '1/1',
};

const StyledQRCode = styled(QRCode)`
  padding-bottom: 10px;
  width: 100%;
`;

const StyledSpan = styled.span`
  padding-right: 10px;
  color: ${({ theme }) => theme.colors.palette.aquas[4]};
`;

const StyledCardLayout = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledCardTitle = styled(Card.Title)`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
`;

const StyledCopyLinkContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const StyledInputUrlDiv = styled.div`
  width: 100%;
  padding-left: ${({ theme }) => theme.space.spacing10};
`;

const StyledCardText = styled(Card.Text)`
  max-width: 400px;
`;

const StyledCopyButton: FunctionComponent<ComponentPropsWithRef<typeof KendoButton>> = styled(KendoButton)`
  padding: 0 ${({ theme }) => theme.space.spacing10};
`;

const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.primary};
`;

const StyledWindowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const StyledBoldCardText = styled.span`
  font-weight: bold;
`;
