import { memo, useEffect, useRef, useState } from 'react';

import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useDataStream, useEvent } from 'core/hooks';
import { Button, ButtonVariants, ComponentSizes, ProgressBar, StatusBox } from 'core/ui';
import { formatFileSize } from 'core/utils';

import { useQueryPatient } from 'features/api';
import { PatientUtils } from 'features/patient';

import { useUploadExamsPageContext } from '../hooks';
import { Footer } from './Footer';
import { UploadGroupProgressCard } from './UploadGroupProgressCard';

export const UploadStep = memo(() => {
  const navigate = useNavigate();
  const { uploadGroups, patientId, uploadView } = useUploadExamsPageContext();
  const [{ data: patient }] = useQueryPatient(patientId);
  const { totalSize, progressSize, weightedProgressSize, startTime, endTime, completedFiles, totalFiles } = useDataStream(uploadView.streams.uploadProgress);

  const intervalIdRef = useRef<number | null>(null);
  const [now, setNow] = useState(() => performance.timeOrigin + performance.now());

  const targetUploadGroups = uploadGroups.filter((group) => group.checked);
  const uploadPercentage = endTime || totalSize === 0 ? 100.0 : Math.min(100.0, (100.0 * weightedProgressSize) / totalSize);

  let overallThroughputText = '';
  let remainingTimeText = '';
  if (startTime) {
    const effectiveEndTime = endTime ?? now;
    const elapsedSeconds = (effectiveEndTime - startTime) / 1000;
    if (elapsedSeconds > 0) {
      const overallThroughput = progressSize / elapsedSeconds;
      const weightedThroughput = weightedProgressSize / elapsedSeconds;
      overallThroughputText = formatBytes(overallThroughput) + '/s';
      remainingTimeText = dayjs.duration((totalSize - weightedProgressSize) / weightedThroughput, 'seconds').humanize() + ' remaining';
      remainingTimeText = remainingTimeText.length > 0 ? remainingTimeText.charAt(0).toUpperCase() + remainingTimeText.slice(1) : remainingTimeText;
    }
  }

  const progressHeaderText =
    targetUploadGroups.length.toString() +
    (targetUploadGroups.length === 1 ? ' exam ' : ' exams ') +
    'uploading to ' +
    (PatientUtils.formatName(patient?.firstName, patient?.lastName) ?? '');

  const completeHeaderText = `${targetUploadGroups.length} ${targetUploadGroups.length === 1 ? 'exam' : 'exams'} uploaded to ${PatientUtils.formatName(
    patient?.firstName,
    patient?.lastName,
  )}`;

  const handleGoToExamsClick = useEvent(() => {
    navigate('/exam/');
  });

  const handleUploadAnotherClick = useEvent(() => {
    window.location.reload();
  });

  const handleGoToDonorClick = useEvent(() => {
    navigate(`/patient-2/edit/${patientId}`);
  });

  useEffect(() => {
    // We can halt periodic updates if the upload is complete.

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    if (endTime) return () => {};

    intervalIdRef.current = window.setInterval(() => {
      setNow(performance.timeOrigin + performance.now());
    }, 1000);

    return () => {
      if (intervalIdRef.current) {
        window.clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
    };
  }, [endTime]);

  return (
    <>
      <StyledComponentBodyDiv>
        {completedFiles < totalFiles && (
          <StyledHeaderDiv>
            <StyledHeaderTitleRowDiv>
              <StyledOverallTextDiv>{progressHeaderText}</StyledOverallTextDiv>
              <StatusBox status="neutral">
                Speed: <StyledThroughputSpan>{overallThroughputText}</StyledThroughputSpan>
              </StatusBox>
            </StyledHeaderTitleRowDiv>

            <StyledProgressBar value={uploadPercentage} size={ComponentSizes.LARGE} labelResolver={progressText} />

            <StyledProgressDetailsDiv>
              <StyledProgressDetailsCol1>{remainingTimeText}</StyledProgressDetailsCol1>
              <StyledProgressDetailsCol2>
                {completedFiles} of {totalFiles} files uploaded &#183; {formatFileSize(weightedProgressSize)} of {formatFileSize(totalSize)} uploaded
              </StyledProgressDetailsCol2>
            </StyledProgressDetailsDiv>
          </StyledHeaderDiv>
        )}

        {completedFiles === totalFiles && (
          <>
            <StyledCompleteDiv>
              <StyledOverallTextDiv>{completeHeaderText}</StyledOverallTextDiv>
              <StatusBox status="success">Upload Complete</StatusBox>
            </StyledCompleteDiv>
          </>
        )}

        <StyledUploadGroupsListDiv>
          {targetUploadGroups.map((group) => (
            <UploadGroupProgressCard key={group.uploadGroupId} uploadGroup={group} />
          ))}
        </StyledUploadGroupsListDiv>
      </StyledComponentBodyDiv>

      <Footer>
        <StyledButtonsDiv>
          <Button variant={ButtonVariants.SECONDARY} size={ComponentSizes.LARGE} onClick={handleGoToExamsClick} disabled={uploadPercentage < 100}>
            Go To Exam List
          </Button>
          <Button variant={ButtonVariants.SECONDARY} size={ComponentSizes.LARGE} onClick={handleUploadAnotherClick} disabled={uploadPercentage < 100}>
            Upload Another Study
          </Button>
          <Button size={ComponentSizes.LARGE} onClick={handleGoToDonorClick} disabled={uploadPercentage < 100}>
            Go To Donor
          </Button>
        </StyledButtonsDiv>
      </Footer>
    </>
  );
});

UploadStep.displayName = 'UploadStep';

function progressText(value: number | null | undefined): string {
  return value == null ? '' : `${Math.floor(value)} %`;
}

function formatBytes(bytes: number): string {
  if (bytes === 0) {
    return '0 B';
  }
  const k = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const formatted = parseFloat((bytes / Math.pow(k, i)).toFixed(2));
  return `${formatted} ${sizes[i]}`;
}

const StyledComponentBodyDiv = styled.div`
  display: grid;
  overflow: hidden;
  grid-template-columns: minmax(0, 600px);
  grid-template-rows: min-content 1fr;
  justify-content: center;
  row-gap: ${({ theme }) => theme.space.spacing60};
  padding: ${({ theme }) => theme.space.spacing40} 0;
`;

const StyledHeaderDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: ${({ theme }) => theme.space.spacing80};
  flex-wrap: wrap;
  align-items: center;
  border: 1px solid transparent; // Add a 1px border so that the header aligns with the content below.
`;

const StyledHeaderTitleRowDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: ${({ theme }) => theme.space.spacing40};
`;

const StyledOverallTextDiv = styled.div`
  flex: 1 0 min-content;
  white-space: nowrap;
  font-size: ${({ theme }) => theme.fontSizes.heading1};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  line-height: ${({ theme }) => theme.lineHeights.heading1};
  color: ${({ theme }) => theme.colors.textPrimary};
  user-select: none;
`;

const StyledProgressBar = styled(ProgressBar)`
  grid-column: 1 / -1;
  margin: ${({ theme }) => theme.space.spacing40} 0 0 0;
`;

const StyledProgressDetailsDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: ${({ theme }) => theme.space.spacing40};
  font-size: ${({ theme }) => theme.fontSizes.body};
  line-height: ${({ theme }) => theme.lineHeights.body};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  user-select: none;
`;

const StyledProgressDetailsCol1 = styled.div`
  flex: 1 0 max-content;
`;

const StyledProgressDetailsCol2 = styled.div`
  flex: 0 0 max-content;
`;

const StyledCompleteDiv = styled.div`
  display: flex;
  column-gap: ${({ theme }) => theme.space.spacing40};
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;

const StyledUploadGroupsListDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  overflow-x: hidden;
  overflow-y: auto;
  column-gap: ${({ theme }) => theme.space.spacing20};
  row-gap: ${({ theme }) => theme.space.spacing20};
`;

const StyledButtonsDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.space.spacing40};
`;

const StyledThroughputSpan = styled.span`
  min-width: 11ch; // Allocate enough space to fit the string "100.00 MB/s".
  text-align: right;
`;
