import { ComponentPropsWithRef, FunctionComponent, useEffect, useState } from 'react';

import { Button as KendoButton } from '@progress/kendo-react-buttons';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import styled from 'styled-components';

import { Button, ButtonVariants, Dialog } from 'core/ui';

import { useAccessTokenSnapshot } from 'features/auth';
import { FileUrlService } from 'features/exam/services/file-url-service';

import { ImgProxyProps } from '../types';
import { ThumbnailPlaceholder } from './ThumbnailPlaceholder';

export const ImgProxy: FunctionComponent<ImgProxyProps> = ({ className, isReadOnly, onRemove, fileId, thumbnailUrl, dateModified, height, width }) => {
  const { accessToken } = useAccessTokenSnapshot();
  const [imgSrc, setImgSrc] = useState<string | null>(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  useEffect(() => {
    (async () => {
      if (!accessToken) return;

      setImgSrc(FileUrlService.getFileUrl(fileId, thumbnailUrl, dateModified, accessToken));
    })();
  }, [fileId, thumbnailUrl, dateModified, accessToken]);

  const handleRemove = () => {
    if (onRemove && fileId !== undefined) {
      onRemove(fileId);
    }
  };

  const toggleDialog = () => {
    setShowDeleteDialog(!showDeleteDialog);
  };

  if (imgSrc == null) return <ThumbnailPlaceholder className={className} />;

  return (
    <StyledDiv>
      {!isReadOnly && (
        <StyledButton fillMode="outline" themeColor="error" className="buttons-container-button float-end" iconClass="fa fa-trash" onClick={toggleDialog} />
      )}

      <img className={className} alt="preview" src={imgSrc} width={width} height={height} />
      {showDeleteDialog && (
        <Dialog title="Please Confirm" onClose={toggleDialog}>
          <span>Delete file</span>
          <DialogActionsBar>
            <Button onClick={toggleDialog} variant={ButtonVariants.SECONDARY}>
              No
            </Button>
            <Button onClick={handleRemove}>Yes</Button>
          </DialogActionsBar>
        </Dialog>
      )}
    </StyledDiv>
  );
};

ImgProxy.displayName = 'ImgProxy';

const StyledDiv = styled.div`
  position: relative;
  display: inline-block;
`;

const StyledButton: FunctionComponent<ComponentPropsWithRef<typeof KendoButton>> = styled(KendoButton)`
  position: absolute;
  top: 0;
  right: 0;
`;
