import { useQuery } from '@tanstack/react-query';

import { apiClient } from 'core/api/globals';

export function useQueryServices() {
  const ageRangesQuery = useQuery({
    queryKey: ['services'],
    queryFn: apiClient.servicesClient.getAllServices,
    staleTime: Infinity,
  });

  return ageRangesQuery;
}
