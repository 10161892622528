import React from 'react';

import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';

import { FormStates } from 'core/forms';
import { Button, Icon, LinkButton } from 'core/ui';

import PenIcon from '../assets/pen.svg?react';

interface PatientData {
  firstName?: string;
  lastName?: string;
  id?: number;
}

interface PatientFormHeaderProps {
  patient: PatientData | null;
  patientFormState: FormStates;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isCollapsed: boolean;
}

const noop = () => {
  // Intentionally empty function
};

const PatientFormHeader: React.FC<PatientFormHeaderProps> = ({
  patient = null,
  patientFormState = FormStates.DISABLED,
  onClick = noop,
  isCollapsed = false,
}) => {
  // Type-safe handler that matches the expected button event type
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick(event);
  };

  if (!patient && patientFormState !== FormStates.ADD) {
    return null;
  }

  // When collapsed, we show either the patient name or a button depending on form state
  if (isCollapsed) {
    if (patientFormState === FormStates.DISABLED) {
      return (
        <StyledPatientNameDiv>
          {patient?.firstName} {patient?.lastName}
        </StyledPatientNameDiv>
      );
    } else {
      // Show a placeholder for the save/edit button even when collapsed
      return (
        <StyledButtonContainer>
          {patientFormState === FormStates.EDIT && (
            <Button form="patient-form" onClick={handleClick} type="submit">
              Save Patient
            </Button>
          )}
          {patientFormState === FormStates.ADD && (
            <Button form="patient-form" onClick={handleClick} type="submit">
              <StyledIcon icon={faPlus}></StyledIcon>
              Create New Patient
            </Button>
          )}
        </StyledButtonContainer>
      );
    }
  }

  // When expanded, always show either the edit button or save button
  return (
    <StyledButtonContainer>
      {patientFormState === FormStates.DISABLED ? (
        <StyledLinkButton form="patient-form" onClick={handleClick} type="submit">
          <PenIcon />
        </StyledLinkButton>
      ) : (
        <Button form="patient-form" onClick={handleClick} type="submit">
          {patientFormState === FormStates.ADD && <StyledIcon icon={faPlus}></StyledIcon>}
          {patientFormState === FormStates.EDIT && 'Save Patient'}
          {patientFormState === FormStates.ADD && 'Create New Patient'}
        </Button>
      )}
    </StyledButtonContainer>
  );
};

const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  z-index: 2;
  position: relative;
`;

const StyledIcon = styled(Icon)`
  && svg {
    color: ${({ theme }) => theme.colors.palette.grayscale[0]};
  }
`;

const StyledPatientNameDiv = styled.div`
  background: ${({ theme }) => theme.colors.palette.grayscale[1]};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.palette.grayscale[4]};
  color: ${({ theme }) => theme.colors.palette.grayscale[9]};
  font-size: ${({ theme }) => theme.fontSizes.footnote};
  padding: 1px 8px;
`;

const StyledLinkButton = styled(LinkButton)`
  &,
  &:hover {
    background-color: transparent !important;
  }
`;

export { PatientFormHeader };
