import { memo, useCallback, useEffect, useState } from 'react';

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { FormModel } from 'models';

import { apiClient } from 'core/api/globals';
import { FieldContainer, GridColumn, createStyledRhfForm } from 'core/forms';
import { useAsyncCallback, useEvent, useValidatedParam } from 'core/hooks';
import { NotificationsService } from 'core/notifications';
import { Button, InputField, Label, PrimaryCard, SwitchField } from 'core/ui';

import { AddUpdateFormService } from '../services';
import { EditFormValues } from '../type';

const StyledForm = createStyledRhfForm('min-content 400px');

export const AddUpdateForm = memo(() => {
  const formId = useValidatedParam('id', 'integer', false);
  const navigate = useNavigate();
  const [form, setForm] = useState<FormModel | null>(null);

  const rhfContext = useForm<EditFormValues>({
    defaultValues: { ...AddUpdateFormService.EditFormDefaults },
  });
  const {
    reset,
    formState: { isSubmitting },
  } = rhfContext;

  const [initializeData] = useAsyncCallback(async () => {
    const [newForm] = await Promise.all([
      formId == null
        ? new Promise<FormModel>((resolve) => {
            resolve(AddUpdateFormService.createDefaultSource());
          })
        : apiClient.formClient.getFormById(formId),
    ]);

    setForm(newForm);

    reset(AddUpdateFormService.copyModelToForm(newForm), {
      keepValues: false,
      keepDefaultValues: false,
    });
  });

  useEffect(() => {
    initializeData();
  }, [initializeData]);

  const handleSubmit: SubmitHandler<EditFormValues> = useCallback(
    async (values, event) => {
      event?.preventDefault();

      const newFormId = typeof formId === 'number' ? formId : 0;

      const newForm: FormModel = AddUpdateFormService.copyFormToModel(newFormId, values);

      //check edit mode
      if (newForm.id === 0) {
        await apiClient.formClient.createForm(newForm);
      } else {
        await apiClient.formClient.updateForm(newForm);
      }

      reset(AddUpdateFormService.copyModelToForm(newForm), {
        keepValues: true,
        keepDefaultValues: false,
      });
      NotificationsService.displaySuccess(formId == null ? 'Form created.' : 'Form saved.');
      navigate('/form');
    },
    [formId, navigate, reset],
  );

  return (
    <PrimaryCard>
      <PrimaryCard.Header>
        <PrimaryCard.HeaderText>{formId ? 'Edit' : 'New'} Form</PrimaryCard.HeaderText>
      </PrimaryCard.Header>
      <PrimaryCard.Body>
        <FormProvider {...rhfContext}>
          <StyledForm autoComplete="off" autoCorrect="off" autoCapitalize="none" spellCheck="false" noValidate onSubmit={rhfContext.handleSubmit(handleSubmit)}>
            <GridColumn columnStart="1" isLabelColumn>
              <Label required>Name</Label>
            </GridColumn>
            <GridColumn columnStart="2" columnEnd="span 1">
              <FieldContainer $hideLabel>
                <InputField name="name" required />
              </FieldContainer>
            </GridColumn>
            <GridColumn columnStart="1" isLabelColumn>
              <Label>Description</Label>
            </GridColumn>
            <GridColumn columnStart="2" columnEnd="span 1">
              <FieldContainer $hideLabel>
                <InputField name="description" />
              </FieldContainer>
            </GridColumn>
            <GridColumn columnStart="1" isLabelColumn>
              <Label>UI</Label>
            </GridColumn>
            <GridColumn columnStart="2" columnEnd="span 1">
              <FieldContainer $hideLabel>
                <InputField name="ui" />
              </FieldContainer>
            </GridColumn>
            <GridColumn columnStart="1" isLabelColumn>
              <Label>Data</Label>
            </GridColumn>
            <GridColumn columnStart="2" columnEnd="span 1">
              <FieldContainer $hideLabel>
                <InputField name="data" />
              </FieldContainer>
            </GridColumn>
            <GridColumn columnStart="1" isLabelColumn>
              <Label required>Report Template Path</Label>
            </GridColumn>
            <GridColumn columnStart="2" columnEnd="span 1">
              <FieldContainer $hideLabel>
                <InputField name="reportTemplatePath" required />
              </FieldContainer>
            </GridColumn>
            <GridColumn columnStart="1" isLabelColumn>
              <Label>Generate Report</Label>
            </GridColumn>
            <GridColumn columnStart="2" columnEnd="span 1">
              <FieldContainer $hideLabel>
                <SwitchField name="generateReport" />
              </FieldContainer>
            </GridColumn>
            <GridColumn columnStart="1" isLabelColumn>
              <Label>Auto Send Report</Label>
            </GridColumn>
            <GridColumn columnStart="2" columnEnd="span 1">
              <FieldContainer $hideLabel>
                <SwitchField name="autoSendReport" />
              </FieldContainer>
            </GridColumn>
            <GridColumn columnStart="1" isLabelColumn>
              <Label>Active</Label>
            </GridColumn>
            <GridColumn columnStart="2" columnEnd="span 1">
              <FieldContainer $hideLabel>
                <SwitchField name="active" />
              </FieldContainer>
            </GridColumn>
            <GridColumn columnStart="2" columnEnd="span 1">
              <div>
                <Button disabled={isSubmitting} type="submit">
                  Save
                </Button>
              </div>
            </GridColumn>
          </StyledForm>
        </FormProvider>
      </PrimaryCard.Body>
    </PrimaryCard>
  );
});

AddUpdateForm.displayName = 'AddUpdateForm';
