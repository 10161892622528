import { ApiRouteService } from 'core/api';

const getBaseRoute = () => `${ApiRouteService.getCompumedApiBaseRoute()}/api/files`;

const getByIdRoute = (id) => `${getBaseRoute()}/${id}`;

const getAmbraRoute = (id) => `${getByIdRoute(id)}/ambra`;

const getChangeCategoryRoute = (id) => `${getByIdRoute(id)}/change-category`;

const getRotateFileRoute = () => `${getBaseRoute()}/rotate-file`;

const generateAzureBlobUploadSasRoute = () => `${getBaseRoute()}/GenerateAzureBlobUploadSas`;

const getFinalReportPreviewRoute = (examId, accessToken) =>
  `${getBaseRoute()}/final-report-preview?examId=${encodeURIComponent(examId)}&access_token=${encodeURIComponent(accessToken)}`;

export const FileRouteService = {
  getAmbraRoute,
  getBaseRoute,
  getByIdRoute,
  getChangeCategoryRoute,
  getRotateFileRoute,
  generateAzureBlobUploadSasRoute,
  getFinalReportPreviewRoute,
};
