import { createContext } from 'react';

import { UploadView } from './services/UploadView';
import { UploadExamsPageContextType } from './types/UploadExamsPageContextType';

export const UploadExamsPageContext = createContext<UploadExamsPageContextType | null>(null);

UploadExamsPageContext.displayName = 'UploadExamsPageContext';

export const UploadViewContext = createContext<UploadView | null>(null);

UploadViewContext.displayName = 'UploadViewContext';
