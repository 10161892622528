import { ImageQualityModel } from 'models';

import { ApiRouteService } from '../services';
import { HttpClient } from './HttpClient';

export class ImageQualityClient {
  constructor(private httpClient: HttpClient) {
    this.getAll = this.getAll.bind(this);
  }

  public async getAll() {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const imageQualities = await this.httpClient.fetchAndParse<ImageQualityModel[]>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/image-qualities`, options),
    );
    return imageQualities;
  }
}
