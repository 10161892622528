import { memo, useMemo } from 'react';

import { MaskedTextBoxChangeEvent, MaskedTextBoxEvent } from '@progress/kendo-react-inputs';
import { Validate, useController } from 'react-hook-form';

import { RhfValidators } from 'core/forms';
import { useEvent } from 'core/hooks';

import { MaskedDateTimeInput } from './MaskedDateTimeInput';
import { MaskedDateTimeInputFieldProps } from './MaskedDateTimeInputFieldProps';
import { MASKED_DATETIME_INPUT_MODES, MASKED_DATETIME_INPUT_PROPS, NORMALIZED_VALUE_FORMAT } from './constants';

export const MaskedDateTimeInputField = memo<MaskedDateTimeInputFieldProps>(
  ({ name, required, mode = MASKED_DATETIME_INPUT_MODES.DATE, validator, onBlur, onChange, ...rest }) => {
    const { format } = MASKED_DATETIME_INPUT_PROPS[mode];

    const validatorInternal = useMemo(() => {
      const newValidators: Validate<unknown, unknown>[] = [];

      if (required) newValidators.push(RhfValidators.required);
      if (validator) newValidators.push(validator);

      newValidators.push(RhfValidators.maskedDateTimeInputFormat(`Must be in ${format} format.`, [NORMALIZED_VALUE_FORMAT, format]));

      return newValidators.length === 0 ? undefined : newValidators.length === 1 ? newValidators[0] : RhfValidators.combine(newValidators);
    }, [required, format, validator]);

    const {
      field: { onChange: rhfOnChange, onBlur: rhfOnBlur, value, ...fieldRest },
      formState,
      fieldState: { isTouched, invalid, error },
    } = useController({
      name,
      rules: {
        validate: validatorInternal, // Intentionally using a custom validator for "required" because the browser native version will allow string values with all whitespace.
      },
    });

    const handleChange = useEvent((event: MaskedTextBoxChangeEvent) => {
      rhfOnChange(event.value);
      onChange?.(event);
    });

    const handleBlur = useEvent((event: MaskedTextBoxEvent) => {
      rhfOnBlur();
      onBlur?.(event);
    });

    return (
      <MaskedDateTimeInput
        {...fieldRest}
        {...rest}
        value={formState.isLoading ? '' : value}
        mode={mode}
        valid={!invalid}
        visited={formState.isSubmitted || isTouched}
        required={required}
        validationMessage={error?.message}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    );
  },
);

MaskedDateTimeInputField.displayName = 'MaskedDateTimeInputField';
