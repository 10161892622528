import { map as _map } from 'lodash';

import { LocationFinalReportTemplateModel, LocationModel, ServiceModel } from 'models';

import { findOrThrow } from 'core/utils';

import { Organs } from 'features/exam';

import { EditFormValues } from '../types';

const EditFormDefaults: EditFormValues = {
  active: false,
  location: null,
  service: null,
  organ: null,
  templatePath: '',
  version: '',
};

function copyModelToForm(model: LocationFinalReportTemplateModel, allLocations: LocationModel[], allServices: ServiceModel[]): EditFormValues {
  // find location
  const location =
    model.locationId != null ? findOrThrow(allLocations, (t) => t.id === model.locationId, `Could not find location with id: ${model.locationId}.`) : null;

  // find location
  const service =
    model.serviceId != null && model.serviceId != 0
      ? findOrThrow(allServices, (t) => t.id === model.serviceId, `Could not find service with id: ${model.serviceId}.`)
      : null;

  // Make sure organ is either a number or null, not undefined
  let organ: number | null = null;
  if (model.organ !== undefined && model.organ !== null) {
    const organItem = Object.values(_map(Organs)).find((o) => o.value === model.organ);
    organ = organItem?.value !== undefined ? Number(organItem.value) : null;
  }

  return {
    templatePath: model.templatePath || '',
    version: model.version || '',
    active: model.active || false,
    location,
    service,
    organ,
  };
}

function copyFormToModel(locationFinalReportTemplateId: number, form: EditFormValues): LocationFinalReportTemplateModel {
  return {
    id: locationFinalReportTemplateId,
    templatePath: form.templatePath,
    version: form.version,
    active: form.active,
    locationId: form.location?.id || null,
    organ: form.organ,
    serviceId: form.service?.id || null,
  };
}

function createDefaultSource(): LocationFinalReportTemplateModel {
  return copyFormToModel(0, EditFormDefaults);
}

export const LocationFinalReportTemplateEditService = {
  EditFormDefaults,
  copyModelToForm,
  copyFormToModel,
  createDefaultSource,
};
