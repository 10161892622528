import { FileModel } from 'models';

import { DocumentCategories } from 'features/exam/constants';

/**
 * Gets category name from category ID
 * @param categoryId - The category ID (e.g., 17 for AI Summary)
 * @returns The category name or 'Unknown' if not found
 */
export const getCategoryNameById = (categoryId: number | null): string => {
  if (categoryId === null) return 'Unknown';

  const category = Object.values(DocumentCategories).find((cat) => cat.value === categoryId);
  return category?.name || 'Unknown';
};

/**
 * Gets category key from category ID
 * @param categoryId - The category ID
 * @returns The category key (e.g., 'AISummary') or null if not found
 */
export const getCategoryKeyById = (categoryId: number | null): string | null => {
  if (categoryId === null) return null;

  const categoryEntry = Object.entries(DocumentCategories).find(([_, cat]) => cat.value === categoryId);

  return categoryEntry ? categoryEntry[0] : null;
};

/**
 * Checks if a file is an image based on its file type
 * @param fileType - The file type string
 * @returns Whether the file is an image
 */
export const isImageFile = (fileType: string | undefined | null): boolean => {
  if (!fileType) return false;

  const imageFileTypes = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'tiff', 'svg', 'dicom'];
  return imageFileTypes.some((type) => fileType.toLowerCase().includes(type));
};

/**
 * Checks if a file is a PDF
 * @param fileType - The file type string
 * @returns Whether the file is a PDF
 */
export const isPdfFile = (fileType: string | undefined | null): boolean => {
  if (!fileType) return false;
  return fileType.toLowerCase().includes('pdf');
};

/**
 * Gets appropriate icon for file based on file type
 * @param fileType - The file type string
 * @returns The icon emoji to use
 */
export const getFileIcon = (fileType: string | undefined | null): string => {
  if (!fileType) return '📄';

  if (isPdfFile(fileType)) return '📑';
  if (isImageFile(fileType)) return '🖼️';
  if (fileType.toLowerCase().includes('dicom')) return '🔬';
  if (fileType.toLowerCase().includes('video')) return '🎬';

  // Special case for specific document types
  if (fileType.toLowerCase().includes('ekg')) return '💓';

  return '📄';
};

/**
 * Categorizes a service description into the appropriate category
 * @param serviceDescription - The service description
 * @returns The matching category key
 */
export const categorizeByDescription = (serviceDescription: string | null): string | null => {
  if (!serviceDescription) return null;

  const desc = serviceDescription.toLowerCase();

  if (desc.includes('chest') && (desc.includes('x-ray') || desc.includes('xray') || desc.includes('cr'))) {
    return 'ChestXRay';
  }

  if (desc.includes('ct') || desc.includes('computed tomography')) {
    return 'CT';
  }

  if (desc.includes('ekg') || desc.includes('ecg') || desc.includes('electrocardiogram')) {
    return 'EKG';
  }

  if (desc.includes('pathology') || desc.includes('biopsy')) {
    return 'Pathology';
  }

  if (desc.includes('report') || desc.includes('final')) {
    return 'FINAL_REPORT';
  }

  if (desc.includes('ventilator')) {
    return 'Ventilator';
  }

  if (desc.includes('monitor')) {
    return 'Monitoring';
  }

  if (desc.includes('ai') || desc.includes('summary')) {
    return 'AISummary';
  }

  return null;
};

/**
 * Sorts files by date (newest first)
 * @param files - Array of FileModel objects
 * @returns Sorted array of files
 */
export const sortFilesByDate = (files: FileModel[]): FileModel[] => {
  return [...files].sort((a, b) => {
    const dateA = a.created ? new Date(a.created).valueOf() : 0;
    const dateB = b.created ? new Date(b.created).valueOf() : 0;
    return dateB - dateA;
  });
};

// Category colors mapping
export const CATEGORY_COLORS: Record<string, string> = {
  EXAM: '#4CAF50',
  COVER_PAGE: '#03A9F4',
  FINAL_REPORT: '#FF9800',
  KEY_IMAGE: '#E91E63',
  ORDER: '#673AB7',
  DATA: '#4CAF50',
  DOCUMENT: '#673AB7',
  EXAMAI: '#4CAF50',
  Worksheet: '#03A9F4',
  Ventilator: '#E91E63',
  Monitoring: '#673AB7',
  EKG: '#FF9800',
  Pathology: '#4CAF50',
  Link: '#03A9F4',
  ChestXRay: '#4CAF50',
  CT: '#03A9F4',
  AISummary: '#8E44AD',
  EMR: '#009688',
};
