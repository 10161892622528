import { apiClient } from 'core/api/globals';
import { EventStream, IEventStreamConsumer, MIME_TYPES, ParallelQueue } from 'core/utils';

import { DocumentCategories } from 'features/exam';

import { Classification, FileAttachedEvent, FileAttacherJob, FileUploadContext, ProcessJobEvent } from '../types';

export class FileAttacher {
  static [Symbol.toStringTag]() {
    return 'FileAttacher';
  }

  private _streams = {
    onFileAttached: new EventStream<ProcessJobEvent<FileAttachedEvent>>(),
  };

  public get streams(): {
    onFileAttached: IEventStreamConsumer<ProcessJobEvent<FileAttachedEvent>>;
  } {
    return this._streams;
  }

  private _queue: ParallelQueue<FileAttacherJob>;

  private _authMode: 'msal-required' | 'share-required' | null = null;

  constructor() {
    this.initialize = this.initialize.bind(this);
    this.reset = this.reset.bind(this);
    this.process = this.process.bind(this);
    this.attach = this.attach.bind(this);

    this._queue = new ParallelQueue({ key: 'fileId', run: this.attach, maxRunners: 50 });
  }

  public initialize(authMode: 'msal-required' | 'share-required') {
    if (this._authMode != null) throw new Error('FileAttacher has already been initialized.  Call reset() before initializing again.');

    this._authMode = authMode;
  }

  public reset() {
    if (this._authMode == null) return;

    this._authMode = null;
    this._queue.clear();
  }

  public async process(file: Readonly<FileUploadContext>) {
    if (file.classification === Classification.Dicom) {
      this._streams.onFileAttached.emit({ result: 'skipped', fileId: file.fileId });
      return;
    }

    // If there is not an upload URL for the file, we cannot attach it.  This shouldn't even get here, so if it does it indicates a bug somewhere else in the pipeline.
    if (file.uploadUrl == null) {
      throw new Error('Cannot attach file without an upload URL.');
    }

    this.attach({
      fileId: file.fileId,
      fileUrl: file.uploadUrl,
      fileName: file.file.name,
      fileSize: file.file.size,
      fileType: file.fileType,
      classification: file.classification,
      examId: file.metadata?.examId ?? null,
      patientId: file.metadata?.patientId ?? null,
      category: file.metadata?.category ?? null,
    });
  }

  private async attach(job: FileAttacherJob) {
    if (this._authMode == null) throw new Error('Cannot attach files without an authentication mode configured.');

    if (
      job.classification === Classification.Pathology &&
      (job.fileType === MIME_TYPES.SVS.extensions[0] || job.fileType === MIME_TYPES.SZI.extensions[0] || job.fileType === MIME_TYPES.TIFF.extensions[0])
    ) {
      if (job.examId == null) throw new Error('Cannot attach file without an exam ID.');

      await apiClient.filesClient.completePathologyUpload(
        {
          examId: job.examId,
          fileName: `${job.fileId}${job.fileType == null ? '' : `.${job.fileType}`}`,
          originalFileName: job.fileName,
          categoryId: DocumentCategories.EXAM.value,
        },
        this._authMode,
      );
    } else if (
      job.classification == null ||
      job.classification === Classification.Ekg ||
      job.classification === Classification.Attachment ||
      job.classification === Classification.Document ||
      job.classification === Classification.Pathology // Pathology files that are in non-techcyte files (pdf, jpg, etc.) get attached normally.
    ) {
      // Handle the QR_UPLOAD case where we might not have an exam but have a patient ID
      if (job.examId == null && job.patientId == null) {
        throw new Error('Cannot attach file without either an exam ID or patient ID.');
      }

      // Get category ID from the specified category if it exists
      const categoryId = job.category
        ? DocumentCategories[job.category as keyof typeof DocumentCategories]?.value || DocumentCategories.DOCUMENT.value
        : job.classification === Classification.Ekg || job.classification === Classification.Document || job.classification === Classification.Pathology
          ? DocumentCategories.EXAM.value
          : DocumentCategories.DOCUMENT.value;

      await apiClient.filesClient.attachFiles(
        [
          {
            fileId: job.fileId,
            fileType: job.fileType ?? 'ERROR - UNKNOWN FILE TYPE',
            fileName: job.fileName,
            location: job.fileUrl,
            fileSize: job.fileSize,
            examId: job.examId,
            patient_id: job.patientId,
            categoryId: categoryId,
          },
        ],
        this._authMode,
      );
    }

    this._streams.onFileAttached.emit({ result: 'processed', fileId: job.fileId });
  }
}
