import { cloneElement } from 'react';

import { DropdownProps } from './DropdownProps';

/** Create a component that allows customization of the text that is rendered in the dropdown list.  Visually this is for the items in the "popup" part of the list.  Note that a `null` item is possible depending on how the dropdown list is configured. */
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Allowing `any` here because the caller must ensure that the textTransformFn is compatible with the data type of the items in the Kendo DropdownList.
export function createDropdownItemRender(textTransformFn: (item: any) => string) {
  const newItemRender: NonNullable<DropdownProps['itemRender']> = (li, itemProps) => {
    const itemText = textTransformFn(itemProps.dataItem);
    return cloneElement(li, li.props, itemText);
  };

  return newItemRender;
}
