import { useQuery } from '@tanstack/react-query';

import { apiClient } from 'core/api/globals';

export function useQueryPatientAgeRanges() {
  const ageRangesQuery = useQuery({
    queryKey: ['patient-age-ranges'],
    queryFn: apiClient.patientClient.getPatientAgeRange,
    staleTime: Infinity,
  });

  return ageRangesQuery;
}
