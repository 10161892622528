import { useContext } from 'react';

import { useEvent } from 'core/hooks';

import { AccordionNgContextType } from './AccordionNgContextType';
import { AccordionNgContext } from './contexts';

/**
 * Hook to access the state and actions of an accordion item.
 * @param itemKey - The key of the accordion item.
 * @param accordionContext - Optional context to use instead of the nearest AccordionNgProvider.
 * @returns An object containing the state and actions of the accordion item.
 */
export function useAccordionNgItem(itemKey: string, accordionContext?: AccordionNgContextType) {
  const contextFromHook = useContext(AccordionNgContext);
  const accordion = accordionContext ?? contextFromHook;

  if (accordion == null) {
    throw new Error(
      'useAccordionItem() cannot be invoked unless <AccordionNgProvider /> is a parent in the component tree or is passed via the accordionContext parameter.',
    );
  }

  const isExpanded = accordion.expandedItemKeys.includes(itemKey);

  const setExpanded = useEvent(() => accordion.setExpanded(itemKey));

  const setCollapsed = useEvent(() => accordion.setCollapsed(itemKey));

  const toggleExpanded = useEvent(() => accordion.toggleExpanded(itemKey));

  return {
    isExpanded,
    toggleExpanded,
    setExpanded,
    setCollapsed,
  };
}
