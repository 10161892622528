import { skipToken, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { ExamModel } from 'models';

import { apiClient } from 'core/api/globals';

import { AuthenticationScheme, useAssertFetchPermitted } from 'features/auth';

import { UseQueryReadWriteResult } from '../types';

export function useQueryExam(examId: number | null | undefined): UseQueryReadWriteResult<ExamModel> {
  const queryClient = useQueryClient();
  const activeScheme = useAssertFetchPermitted([AuthenticationScheme.OIDC, AuthenticationScheme.SHARE]);

  const examQuery = useQuery({
    queryKey: ['exam', examId],
    queryFn:
      examId == null || examId === 0 || activeScheme == null
        ? skipToken
        : () => apiClient.exams.getExamById(examId, true, activeScheme === AuthenticationScheme.OIDC ? 'msal-required' : 'share-required'),
    staleTime: 1000 * 60 * 5,
  });

  const examMutation = useMutation({
    mutationFn: async (exam: ExamModel) => {
      const newExam = {
        ...exam,
      };

      if (newExam.id === 0) {
        newExam.id = await apiClient.exams.saveExam(newExam);
      } else {
        await apiClient.exams.saveExam(newExam);
      }

      queryClient.setQueryData(['exam', newExam.id], newExam);

      return newExam;
    },
  });

  return [examQuery, examMutation];
}
