import { DataSourceRequestState, toDataSourceRequestString } from '@progress/kendo-data-query';

import { QueryModel } from 'models';
import { DCMStudyInstanceModel } from 'models/DCMStudyInstanceModel';
import { UploadModel } from 'models/UploadModel';

import { ApiRouteService } from '../services';
import { DataResult } from '../types';
import { HttpClient } from './HttpClient';

export class DICOMClient {
  constructor(private httpClient: HttpClient) {
    this.getAllSourcesForKendoGrid = this.getAllSourcesForKendoGrid.bind(this);
    this.testConn = this.testConn.bind(this);
    this.multipleNewEntryUploadDICOM = this.multipleNewEntryUploadDICOM.bind(this);
    this.multipleUpdateEntryUploadDICOM = this.multipleUpdateEntryUploadDICOM.bind(this);
    this.getDestinationBySUID = this.getDestinationBySUID.bind(this);
    this.uploadAttachment = this.uploadAttachment.bind(this);
    this.triggerDestination = this.triggerDestination.bind(this);
  }

  public async getAllSourcesForKendoGrid(dataState: DataSourceRequestState) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const queryStr = toDataSourceRequestString(dataState);
    const result = await this.httpClient.fetchAndParse<DataResult<DCMStudyInstanceModel>>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/DICOM/grid?${queryStr}`, options),
    );
    return result;
  }

  public async query(query: QueryModel) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    console.log('query', query);

    const queryValues = Object.fromEntries(Object.entries(query).filter(([_, value]) => value !== undefined));
    console.log('queryValues', queryValues);

    const queryStr = new URLSearchParams(queryValues as Record<string, string>).toString();
    const result = await this.httpClient.fetchAndParse<QueryModel>(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/DICOM/query?${queryStr}`, options));
    return result;
  }

  public async import(destinationId: number, studyInstanceUids: string[]) {
    const options = await this.httpClient.createStandardOptions('POST', { destinationId, studyInstanceUids });
    console.log('import', destinationId, studyInstanceUids);

    const result = await this.httpClient.fetchAndParse<QueryModel>(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/DICOM/import`, options));
    return result;
  }

  /**
   * Tests the connection for the DICOM upload page.
   */
  public async testConn(): Promise<void> {
    const options = await this.httpClient.createStandardOptions('GET', null);
    await this.httpClient.fetch(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/DICOM/TestConn`, options));
  }

  /**
   * Uploads multiple new DICOM entries.
   * @param list List of entries to add.
   */
  public async multipleNewEntryUploadDICOM(list: UploadModel[]): Promise<UploadModel[]> {
    const options = await this.httpClient.createStandardOptions('POST', list);
    const result = await this.httpClient.fetchAndParse<UploadModel[]>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/DICOM/MultipleNewEntryUploadDICOM`, options),
    );
    return result;
  }

  /**
   * Updates multiple DICOM entries.
   * @param list List of entries to update.
   */
  public async multipleUpdateEntryUploadDICOM(list: UploadModel[]): Promise<UploadModel[]> {
    const options = await this.httpClient.createStandardOptions('POST', list);
    const result = await this.httpClient.fetchAndParse<UploadModel[]>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/DICOM/MultipleUpdateEntryUploadDICOM`, options),
    );
    return result;
  }

  /**
   * Retrieves the destination for a given Study Instance UID.
   * @param suid The Study Instance UID.
   */
  public async getDestinationBySUID(suid: string): Promise<string> {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const url = `${ApiRouteService.getCompumedApiBaseRoute()}/api/DICOM/GetDestination?suid=${encodeURIComponent(suid)}`;
    const result = await this.httpClient.fetchAndParse<string>(fetch(url, options));
    return result;
  }

  /**
   * Uploads an attachment file for a given study.
   * @param suid The Study Instance UID.
   * @param category The attachment category.
   * @param file The file to upload.
   */
  public async uploadAttachment(suid: string, category: string, file: File): Promise<void> {
    const formData = new FormData();
    formData.append('file', file);

    // Indicate that the request body is FormData so headers are set appropriately.
    const options = await this.httpClient.createStandardOptions('POST', formData);
    const url = `${ApiRouteService.getCompumedApiBaseRoute()}/api/DICOM/UploadAttachment?suid=${encodeURIComponent(suid)}&category=${encodeURIComponent(category)}`;
    await this.httpClient.fetch(fetch(url, options));
  }

  /**
   * Triggers a destination for the specified Study Instance UID.
   * @param suid The Study Instance UID.
   * @param destinationName The destination name.
   */
  public async triggerDestination(suid: string, destinationName: string): Promise<void> {
    const options = await this.httpClient.createStandardOptions('POST', null);
    const url = `${ApiRouteService.getCompumedApiBaseRoute()}/api/DICOM/trigger-destination?suid=${encodeURIComponent(suid)}&destinationName=${encodeURIComponent(destinationName)}`;
    await this.httpClient.fetch(fetch(url, options));
  }

  public async testConnection(): Promise<boolean> {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const response = await fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/DICOM/TestConn`, options);
    return response.ok;
  }
}
