export type PriorityLockQueueItem<TRequest> = TRequest & {
  requestOrder: number;
};

export type PriorityLockOptions<TRequest> = {
  maxConcurrent: number;
  /** Executed immediately prior to granting a lock request.  If omitted the fallback will be based on request order. */
  priorityCompareFn?: (requestA: PriorityLockQueueItem<TRequest>, requestB: PriorityLockQueueItem<TRequest>) => number;
};

export type PendingRequest<TRequest> = {
  request: PriorityLockQueueItem<TRequest>;
  resolve: (releaseFn: () => void) => void;
};

export class PriorityLock<TRequest = void> {
  static [Symbol.toStringTag]() {
    return 'PriorityLock';
  }

  private _queue: PendingRequest<TRequest>[];
  private _options: Required<PriorityLockOptions<TRequest>>;
  private _currentLocks: number;
  private _requestOrderCounter: number;

  constructor(options: PriorityLockOptions<TRequest>) {
    this.setMaxConcurrent = this.setMaxConcurrent.bind(this);
    this.requestLock = this.requestLock.bind(this);
    this.processQueue = this.processQueue.bind(this);

    this._queue = [];
    this._options = { ...options, priorityCompareFn: options.priorityCompareFn ?? ((a, b) => a.requestOrder - b.requestOrder) };
    this._currentLocks = 0;
    this._requestOrderCounter = 0;
  }

  public setMaxConcurrent(maxConcurrent: number) {
    this._options.maxConcurrent = maxConcurrent;
    // Process the queue in case we can grant more locks due to increased concurrency.
    this.processQueue();
  }

  /** Acquire a lock. Will await until the lock is available.
   * @returns A function that releases the lock.
   */
  public async requestLock(request: TRequest): Promise<() => void> {
    const requestOrder = this._requestOrderCounter++;
    const queueItem: PriorityLockQueueItem<TRequest> = {
      ...request,
      requestOrder,
    };

    if (this._currentLocks < this._options.maxConcurrent) {
      // Lock is available, grant it immediately.
      this._currentLocks++;

      // Release function to be called by the consumer when done.
      const releaseFn = () => {
        this._currentLocks--;
        this.processQueue();
      };

      return Promise.resolve(releaseFn);
    } else {
      // Lock is not available, add request to the queue.
      return new Promise<() => void>((resolve) => {
        this._queue.push({
          request: queueItem,
          resolve,
        });
      });
    }
  }

  private processQueue() {
    while (this._currentLocks < this._options.maxConcurrent && this._queue.length > 0) {
      // Sort the queue according to the priority comparison function.  This could probably be optimized.
      this._queue
        .sort((a, b) => (a.request.requestOrder === a.request.requestOrder ? 0 : a.request.requestOrder < b.request.requestOrder ? -1 : 1))
        .sort((a, b) => this._options.priorityCompareFn(a.request, b.request));

      // Take the highest priority request.
      const nextRequest = this._queue.shift();
      if (nextRequest) {
        this._currentLocks++;

        // Release function for the next request.
        const releaseFn = () => {
          this._currentLocks--;
          this.processQueue();
        };

        // Resolve the promise, granting the lock to the next request.
        nextRequest.resolve(releaseFn);
      }
    }
  }
}
