import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';

import { MaskedTextBoxHandle as KendoMaskedTextBoxHandle, MaskedTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import dayjs from 'dayjs';

import { useEvent } from 'core/hooks';

import { MaskedInput } from '../MaskedInput';
import { MaskedDateTimeInputHandle } from './MaskedDateTimeInputHandle';
import { MaskedDateTimeInputProps } from './MaskedDateTimeInputProps';
import { MASKED_DATETIME_INPUT_MODES, MASKED_DATETIME_INPUT_PROPS, NORMALIZED_VALUE_FORMAT } from './constants';
import { normalizeMaskedDateTimeInputValue } from './normalizeMaskedDateTimeInputValue';

export const MaskedDateTimeInput = forwardRef<MaskedDateTimeInputHandle, MaskedDateTimeInputProps>(
  ({ mode = MASKED_DATETIME_INPUT_MODES.DATE, value, valid, required, onChange, ...rest }, ref) => {
    const kendoInputRef = useRef<KendoMaskedTextBoxHandle | null>(null);

    const { format, placeholder } = MASKED_DATETIME_INPUT_PROPS[mode];

    const valueParsed = useMemo(() => {
      // remove milliseconds from times to avoid parsing errors
      let formattedValue = value;
      if (value?.includes('.')) {
        formattedValue = value.split('.')[0];
      }
      const parsedByNormalizedFormat = dayjs(formattedValue, NORMALIZED_VALUE_FORMAT, true);

      if (parsedByNormalizedFormat.isValid()) return parsedByNormalizedFormat;

      const parsedByModeFormat = dayjs(formattedValue, format, true);

      return parsedByModeFormat.isValid() ? parsedByModeFormat : null;
    }, [format, value]);

    const handleChange = useEvent((event: MaskedTextBoxChangeEvent) => {
      const normalizedValue = normalizeMaskedDateTimeInputValue(event.value, format, placeholder);

      onChange?.({
        ...event,
        value: normalizedValue,
      });
    });

    useImperativeHandle(
      ref,
      () => ({
        focus: () => {
          kendoInputRef.current?.focus?.();
        },
        get value() {
          return normalizeMaskedDateTimeInputValue(kendoInputRef.current?.value, format, placeholder);
        },
      }),
      [format, placeholder],
    );

    return (
      <MaskedInput
        {...rest}
        ref={kendoInputRef}
        defaultValue={MASKED_DATETIME_INPUT_PROPS[mode].defaultValue}
        mask={MASKED_DATETIME_INPUT_PROPS[mode].mask}
        onChange={handleChange}
        rules={MASKED_DATETIME_INPUT_PROPS[mode].rules}
        value={valueParsed?.format?.(format)?.toString() ?? value?.toString()}
        valid={valid}
        required={required}
      />
    );
  },
);

MaskedDateTimeInput.displayName = 'MaskedDateTimeInput';
