import { memo } from 'react';

import { toDataSourceRequestString } from '@progress/kendo-data-query';
import { cloneDeep } from 'lodash';
import styled from 'styled-components';

import { useEvent } from 'core/hooks';
import { Accordion, Window } from 'core/ui';

import { useCurrentUser } from 'features/auth';
import { DefaultColumnsState } from 'features/exam/constants';
import { DEFAULT_FILTER_DATA_STATE } from 'features/exam/services/exam-grid-service';

import { WorklistAddEditModalProps } from '../types';
import { WorklistFilterAccordionItem } from './WorklistFilterAccordionItem';
import { WorklistNameInput } from './WorklistNameInput';

export const WorklistAddEditModal = memo<WorklistAddEditModalProps>(
  ({ allWorklists, currentDataState, currentColumnsState, onClose, onCreateWorklist, onUpdateWorklist, onDeleteWorklist }) => {
    const { currentUser } = useCurrentUser(true);

    const handleCreateWorklistClick = useEvent((name: string, parentViewId?: number | null) => {
      const newDataState = cloneDeep(currentDataState ?? DEFAULT_FILTER_DATA_STATE);
      const newColumnsState = cloneDeep(currentColumnsState ?? DefaultColumnsState);

      onCreateWorklist({
        id: 0,
        user: currentUser.email,
        name,
        parentViewId: parentViewId ?? null,
        ordinal: allWorklists.length + 1,
        dataState: JSON.stringify(newDataState),
        columnState: JSON.stringify(newColumnsState),
        oDataResourceString: toDataSourceRequestString(newDataState),
      });
    });

    return (
      <Window onClose={onClose} title="WORKLIST" initialWidth={700}>
        <StyledDialogBodyDiv>
          <StyledAccordion alwaysOpen>
            {allWorklists
              .filter((w) => w.parentViewId == null)
              .map((worklist) => (
                <WorklistFilterAccordionItem
                  key={worklist.id}
                  worklist={worklist}
                  allWorklists={allWorklists}
                  isTopLevel
                  currentDataState={currentDataState}
                  currentColumnsState={currentColumnsState}
                  onCreateWorklist={(name, parentViewId) => handleCreateWorklistClick(name, parentViewId ?? worklist.id)}
                  onUpdateWorklist={onUpdateWorklist}
                  onDeleteWorklist={onDeleteWorklist}
                />
              ))}
          </StyledAccordion>
          <StyledWorklistNameInput onSave={handleCreateWorklistClick} saveButtonText="Create Worklist" />
        </StyledDialogBodyDiv>
      </Window>
    );
  },
);

WorklistAddEditModal.displayName = 'WorklistAddEditModal';

const StyledWorklistNameInput = styled(WorklistNameInput)`
  margin-left: 23px;
  margin-right: 27px;
  margin-top: ${({ theme }) => theme.space.spacing30};
`;

const StyledDialogBodyDiv = styled.div`
  width: 650px;
  padding: 24px;
`;

const StyledAccordion = styled(Accordion)`
  max-height: 60vh;
  overflow: auto;
`;
