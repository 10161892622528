import { ReactNode, memo, useEffect, useRef, useState } from 'react';

import { useUploadPipeline } from 'features/upload-pipeline/hooks/useUploadPipeline';

import { UploadViewContext } from '../contexts';
import { UploadView } from '../services/UploadView';

export const UploadViewProvider = memo<{ children?: ReactNode }>(({ children }) => {
  // Note: This has some very tedious logic for initialization and cleanup.  It's necessary in order to avoid losing state between HMR
  // refreshes which causes useEffect() calls to be fired every time - even if the changes are on an unrelated component.  So we have to
  // manually track whether the component is mounted and whether the initialization has already been done.

  const uploadPipeline = useUploadPipeline();

  const uploadViewRef = useRef<UploadView | null>(null);
  const unmountTimeoutRef = useRef<number | null>(null);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (unmountTimeoutRef.current) {
      window.clearTimeout(unmountTimeoutRef.current);
      unmountTimeoutRef.current = null;
    }

    const cleanup = () => {
      unmountTimeoutRef.current = window.setTimeout(() => {
        if (uploadViewRef.current) {
          uploadViewRef.current.destroy();
          uploadViewRef.current = null;
        }
      }, 1000);
    };

    if (uploadViewRef.current) {
      return cleanup;
    }

    uploadViewRef.current = new UploadView(uploadPipeline);
    uploadViewRef.current.initialize();
    setIsInitialized(true);

    return cleanup;
  }, [uploadPipeline]);

  return !isInitialized ? null : <UploadViewContext.Provider value={uploadViewRef.current}>{children}</UploadViewContext.Provider>;
});

UploadViewProvider.displayName = 'UploadViewProvider';
