import { memo, useEffect, useState } from 'react';

import Card from 'react-bootstrap/Card';
import { styled } from 'styled-components';

import { FileModel } from 'models';

import { usePercentageWindowSize } from 'core/hooks';
import { Window } from 'core/ui';

import { useApiClient } from 'features/api';
import { AuthenticationScheme, useAuthentication } from 'features/auth';

import { FileViewer } from './FileViewer';

export type ViewFileWindowProps = {
  file?: FileModel;
  fileId?: number;
  title: string;
  onClose: () => void;
};

export const ViewFileWindow = memo<ViewFileWindowProps>(({ title, file, fileId, onClose }) => {
  const apiClient = useApiClient();
  const { activeScheme } = useAuthentication();
  const [currentFile, setCurrentFile] = useState<FileModel | null>(file ?? null);
  const { width, height } = usePercentageWindowSize(80, 100, {
    minWidth: 600,
    maxWidth: 1200,
    minHeight: 600,
    maxHeight: 1600,
  });
  // Fetch the file only if fileId is provided and file is not already passed
  useEffect(() => {
    if (fileId && !file) {
      const fetchFile = async () => {
        const fetchedFile = await apiClient.filesClient.getById(fileId, activeScheme === AuthenticationScheme.OIDC ? 'msal-required' : 'share-required');
        setCurrentFile(fetchedFile);
      };
      fetchFile();
    }
  }, [apiClient.filesClient, fileId, file, activeScheme]);

  return (
    <Window title={title} initialTop={0} onClose={onClose} initialWidth={width} initialHeight={height} resizable={false} draggable={false} modal>
      {currentFile && <StyledFileViewer file={currentFile} overrideMultiMonitor />}
    </Window>
  );
});

ViewFileWindow.displayName = 'ViewFileWindow';

const StyledFileViewer = styled(FileViewer)`
  width: 100%;
  height: 100%;
  // display: flex;
`;
