import { ComponentType, FunctionComponent, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';

import { faPen } from '@fortawesome/pro-solid-svg-icons';
import { GridCellProps, GridColumn, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { DestinationModel } from 'models';

import { apiClient } from 'core/api/globals';
import { ActionListCell, DEFAULT_DATA_TABLE_DATA_STATE, DEFAULT_PAGE_SIZES, DataTable, HeaderCell, Page, PageHeader, TextCell, Toolbar } from 'core/ui';

import { useCurrentUser } from 'features/auth';

const PageableSettings = {
  pageSizes: DEFAULT_PAGE_SIZES,
};

export const DestinationHome: FunctionComponent = () => {
  const navigate = useNavigate();

  const [destinations, setDestinations] = useState<DestinationModel[]>([]);
  const [destinationsTotal, setDestinationsTotal] = useState(0);
  const [dataState, setDataState] = useState(DEFAULT_DATA_TABLE_DATA_STATE);

  const { currentUser } = useCurrentUser(true);

  const handleDataStateChange = useCallback((changeEvent: GridDataStateChangeEvent) => {
    setDataState(changeEvent.dataState);
  }, []);

  const handleAddNewClick = useCallback(() => navigate('/destination/add'), [navigate]);

  const handleEditClick = useCallback((dataItem: DestinationModel) => navigate(`/destination/edit/${dataItem.id}`), [navigate]);

  const gridActions = useMemo(() => {
    return [
      {
        key: 'edit-destination',
        title: 'Edit Destination',
        icon: faPen,
        disabled: !currentUser.isSystemAdmin,
        onClick: (_: MouseEvent<HTMLButtonElement>, dataItem: DestinationModel) => handleEditClick(dataItem),
      },
    ];
  }, [handleEditClick, currentUser.isSystemAdmin]);

  const activeCellRender = useCallback((props: GridCellProps) => <TextCell {...props} valueTransform={(value) => (value ? 'Active' : 'Inactive')} />, []);

  useEffect(() => {
    (async () => {
      const queryResult = await apiClient.destinationClient.getAllDestinationsForKendoGrid(dataState);

      setDestinations(queryResult.data);
      setDestinationsTotal(queryResult.total);
    })();
  }, [dataState]);

  return (
    <Page>
      <PageHeader title="Destinations" />
      <StyledDataTable
        data={destinations}
        filterable
        sortable
        pageable={PageableSettings}
        total={destinationsTotal}
        onDataStateChange={handleDataStateChange}
        actions={gridActions}
        {...dataState}
      >
        <Toolbar onAddNewClick={handleAddNewClick} />
        <GridColumn
          title="Action"
          filterable={false}
          sortable={false}
          headerCell={HeaderCell}
          cell={ActionListCell as ComponentType<GridCellProps>}
          width="80px"
        />
        <GridColumn title="Id" field="id" filter="numeric" headerCell={HeaderCell} cell={TextCell} width={80} />
        <GridColumn title="Name" field="name" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="Description" field="description" filter="text" headerCell={HeaderCell} cell={TextCell} />
        <GridColumn title="Host" field="host" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="Port" field="port" filter="numeric" headerCell={HeaderCell} cell={TextCell} width={100} />
        <GridColumn title="Active" field="active" filter="boolean" headerCell={HeaderCell} filterable={false} cell={activeCellRender} width={80} />
      </StyledDataTable>
    </Page>
  );
};

DestinationHome.displayName = 'DestinationHome';

const StyledDataTable = styled(DataTable)`
  max-height: initial;
`;
