import { memo, useCallback } from 'react';

import { MultiSelectBlurEvent, MultiSelectChangeEvent } from '@progress/kendo-react-dropdowns';
import { useController } from 'react-hook-form';

import { MultiSelect } from './MultiSelect';
import { MultiSelectFieldProps } from './MultiSelectFieldProps';

export const MultiSelectField = memo<MultiSelectFieldProps>(({ name, validator, onChange, onBlur, ...rest }) => {
  const {
    field: { onChange: rhfOnChange, onBlur: rhfOnBlur, ...fieldRest },
    formState,
    fieldState: { isTouched, invalid, error },
  } = useController({ name, rules: { validate: validator } });

  const handleChange = useCallback(
    (event: MultiSelectChangeEvent) => {
      rhfOnChange(event);
      onChange?.(event);
    },
    [onChange, rhfOnChange],
  );

  const handleBlur = useCallback(
    (event: MultiSelectBlurEvent) => {
      rhfOnBlur();
      onBlur?.(event);
    },
    [onBlur, rhfOnBlur],
  );

  return (
    <MultiSelect
      {...fieldRest}
      {...rest}
      name={name}
      valid={!invalid}
      visited={formState.isSubmitted || isTouched}
      validationMessage={error?.message}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
});

MultiSelectField.displayName = 'MultiSelectField';
