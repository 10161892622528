import { ChangeEvent, forwardRef, memo, useEffect, useImperativeHandle, useState } from 'react';

import styled from 'styled-components';

import { useEvent } from 'core/hooks';

import { FilePromptHandle } from './FilePromptHandle';
import { FilePromptProps } from './FilePromptProps';

/** Provides an API to programatically open a file select dialog.  Note this **requires** being invoked by the `ref.open()` call.  The results are then
 * emitted via the `onFilesSelected` property. */
export const FilePrompt = memo(
  forwardRef<FilePromptHandle, FilePromptProps>(
    (
      {
        id,
        multiple, // Don't set the default value for 'multiple' here because the default is dependant on the 'directory' property.
        directory = false,
        accept = '*',
        onFilesSelected,
      },
      ref,
    ) => {
      const [inputEle, setInputEle] = useState<HTMLInputElement | null>(null);

      const effectiveMultiple = typeof multiple === 'undefined' ? directory : multiple;

      const handleFilesChange = useEvent((event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files != null && event.target.files.length > 0) {
          const files = Array.from(event.target.files);
          event.target.value = ''; // Clear the value so that the user can select the same files again.  Otherwise, the onChange event won't fire.
          onFilesSelected(files);
        }
      });

      const open = useEvent(() => {
        inputEle?.click();
      });

      // Make the folder select upload an entire directory.  This has to manually apply the attributes because
      // TypeScript doesn't define the "directory" and "webkitdirectory" props on the <input> element.
      useEffect(() => {
        if (inputEle != null) {
          if (directory) {
            inputEle.setAttribute('directory', '');
            inputEle.setAttribute('webkitdirectory', '');
          } else {
            inputEle.removeAttribute('directory');
            inputEle.removeAttribute('webkitdirectory');
          }
        }
      }, [directory, inputEle]);

      useImperativeHandle(
        ref,
        () => ({
          open,
        }),
        [open],
      );

      return <StyledHiddenInput ref={setInputEle} id={id} type="file" multiple={effectiveMultiple} accept={accept} onChange={handleFilesChange} />;
    },
  ),
);

FilePrompt.displayName = 'FilePrompt';

const StyledHiddenInput = styled.input`
  display: none;
`;
