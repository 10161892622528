import { ReactNode, memo } from 'react';

import { useQueryPatient } from 'features/api';
import { useParsedShareAccessToken } from 'features/auth';
import { useSessionLocationInitSync } from 'features/location';
import { PatientVerbiageProvider } from 'features/patient';

export const ShareInit = memo<{ children?: ReactNode }>(({ children }) => {
  const shareToken = useParsedShareAccessToken(true);
  const [{ data: patient }] = useQueryPatient(shareToken.sharePatientId);
  const sessionLocation = useSessionLocationInitSync(patient?.location_Id);

  return sessionLocation ? <PatientVerbiageProvider mode={sessionLocation?.isOpo ? 'Donor' : 'Patient'}>{children}</PatientVerbiageProvider> : null;
});

ShareInit.displayName = 'ShareInit';
