import { FunctionComponent, useCallback, useEffect, useState } from 'react';

// Import react-diff-viewer for displaying diffs as HTML.
import ReactDiffViewer from 'react-diff-viewer-continued';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { TemplateModel } from 'models';

import { apiClient } from 'core/api/globals';
import { FieldContainer, GridColumn, createStyledRhfForm } from 'core/forms';
import { useEvent, useValidatedParam } from 'core/hooks';
import { NotificationsService } from 'core/notifications';
import { Button, ButtonVariants, ComponentSizes, Dropdown, InputField, Label, Page, PageHeader, Input } from 'core/ui';
import { EditorField } from 'core/ui/Editor/EditorField';
import { SwitchField } from 'core/ui/Switch';
import { Window } from 'core/ui/Window';

import { TemplateEditService } from '../services';
import { EditFormValues } from '../types';
import { get } from 'lodash';

export const TemplateForm: FunctionComponent = () => {
  const templateId = useValidatedParam('id', 'integer', false);
  const navigate = useNavigate();
  const [template, setTemplate] = useState<TemplateModel | null>(null);
  const [isHistoryOpen, setHistoryOpen] = useState(false);
  const [isTestingOpen, setTestingOpen] = useState(false);
  const [selectedHistoryId, setSelectedHistoryId] = useState<number | null>(null);
  const [testingResult, setTestingResult] = useState<string>('');
  const [isRendering, setIsRendering] = useState<boolean>(false);
  const [examId, setExamId] = useState<string>('');

  const openHistory = useEvent((open: boolean) => setHistoryOpen(open));
  const openTesting = useEvent((open: boolean) => setTestingOpen(open));

  const rhfContext = useForm<EditFormValues>({
    defaultValues: { ...TemplateEditService.EditFormDefaults },
    mode: 'onChange',
  });
  const { reset, watch, setValue } = rhfContext;

  const handleSubmit: SubmitHandler<EditFormValues> = useCallback(
    async (values, event) => {
      event?.preventDefault();

      if (template == null) {
        throw new Error('Cannot proceed because template is null or undefined.');
      }

      const newTemplate: TemplateModel = TemplateEditService.copyFormToModel(template.id, values);

      if (typeof templateId === 'number') {
        await apiClient.templateClient.updateTemplate(newTemplate);
      } else {
        await apiClient.templateClient.createTemplate(newTemplate);
      }

      reset(TemplateEditService.copyModelToForm(newTemplate), {
        keepValues: true,
        keepDefaultValues: false,
      });
      NotificationsService.displaySuccess(templateId == null ? 'Template created.' : 'Template saved.');
      navigate('/template');
    },
    [navigate, reset, template, templateId],
  );

  useEffect(() => {
    (async () => {
      const newTemplate = templateId == null ? TemplateEditService.createDefaultTemplate() : await apiClient.templateClient.getTemplateById(templateId);
      setTemplate(newTemplate);
      const lastVersion = newTemplate.templateHistories && newTemplate.templateHistories.length > 0 ? newTemplate.templateHistories[0].version : null;
      setSelectedHistoryId(lastVersion);
      reset(TemplateEditService.copyModelToForm(newTemplate), {
        keepValues: false,
        keepDefaultValues: false,
      });
    })();
  }, [reset, templateId]);

  if (template == null) return null;

  const historyDropdown = template.templateHistories.map((history) => ({
    name: `v.${history.version.toString()}`,
    id: history.version,
  }));

  // Get the current content from the form and the selected history's content.
  const currentContent = watch('content');
  const selectedHistory = template.templateHistories.find((history) => history.version === selectedHistoryId);

  const revertHistory = () => {
    if (selectedHistory) {
      setValue('content', selectedHistory.content);
      NotificationsService.displaySuccess('Template content reverted to selected history.');
      setHistoryOpen(false);
    }
  };

  const renderTemplate = async () => {
    if (!template) return;
    setIsRendering(true);
    try {
      const rendered = await apiClient.exams.renderTemplate(examId, template.name);
      setTestingResult(rendered);
    } catch (error) {
      setTestingResult('Error rendering template.');
      NotificationsService.displayError('Error rendering template.');
    } finally {
      setIsRendering(false);
    }
  };

  const getTestingWindow = () => {
    return (
      <StyledTestingDiv>
        <h2>Test Template</h2>
        <StyledTestingActionBar>
          <Input type="number" placeholder="Enter Exam ID" value={examId} onChange={(e) => setExamId(e.target.value)}></Input>
          <Button type="button" onClick={renderTemplate}>
            Test
          </Button>
        </StyledTestingActionBar>
        {isRendering ? <div>Rendering...</div> : testingResult && <div>{testingResult}</div>}
      </StyledTestingDiv>
    );
  };

  const getVersionHistoryWindow = () => {
    return (
      <>
        {template && template.templateHistories.length > 0 ? (
          <>
            <h1>V.{selectedHistoryId}</h1>
            <div>Modified by: {selectedHistory?.userModified ?? ''}</div>
            <div>Date: {new Date(selectedHistory?.dateModified ?? '').toLocaleDateString() ?? ''}</div>
            <Dropdown
              data={historyDropdown}
              value={selectedHistoryId}
              isForPrimitiveValues
              valueField="id"
              onChange={(e) => setSelectedHistoryId(Number(e.target.value.id))}
            />
            <ReactDiffViewer oldValue={selectedHistory?.content ?? ''} newValue={currentContent || ''} splitView={true} />
            <Button type="button" onClick={revertHistory} style={{ marginTop: '10px' }}>
              Revert to Selected
            </Button>
          </>
        ) : (
          <div>No history available.</div>
        )}
      </>
    );
  };

  return (
    <Page>
      <PageHeader title={`${templateId ? 'Edit' : 'New'} Template`} />
      <FormProvider {...rhfContext}>
        <StyledForm autoComplete="off" autoCorrect="off" autoCapitalize="none" spellCheck="false" noValidate onSubmit={rhfContext.handleSubmit(handleSubmit)}>
          <GridColumn columnStart="1" isLabelColumn>
            <Label required>Type</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <InputField name="type" required />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label required>Name</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <InputField name="name" required />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Description</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <InputField name="description" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label required>Content</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 2">
            <FieldContainer $hideLabel>
              <EditorField required name="content" height="50vh" defaultLanguage="json" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Active</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <SwitchField name="active" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="2" columnEnd="span 2">
            <div style={{ display: 'flex', gap: '10px' }}>
              <Button type="submit">Save</Button>
            </div>
          </GridColumn>
        </StyledForm>
      </FormProvider>
      <StyledActionBar>
        <Button variant={ButtonVariants.SECONDARY} size={ComponentSizes.LARGE} type="button" onClick={() => openHistory(true)}>
          Version History
        </Button>
        <Button variant={ButtonVariants.SECONDARY} size={ComponentSizes.LARGE} type="button" onClick={() => openTesting(true)}>
          Testing
        </Button>
      </StyledActionBar>
      {isHistoryOpen && (
        <Window title="Template Version History" onClose={() => openHistory(false)} width={1000}>
          {getVersionHistoryWindow()}
        </Window>
      )}
      {isTestingOpen && (
        <Window title="Test Template" onClose={() => openTesting(false)} width={1000}>
          {getTestingWindow()}
        </Window>
      )}
    </Page>
  );
};

TemplateForm.displayName = 'TemplateForm';

const StyledForm = createStyledRhfForm('min-content 400px 1fr');

const StyledActionBar = styled.div`
  display: flex;
  gap: 10px;
`;

const StyledTestingActionBar = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: row;
`;

const StyledTestingDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 10px;
  width: 50%;
`;
