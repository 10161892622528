import { memo } from 'react';

import dayjs from 'dayjs';
import styled from 'styled-components';

import { useBoolean } from 'core/hooks';
import { AccordionNg, Button, ButtonVariants, useAccordionNg, useAccordionNgItem, useAccordionNgItemView, useBreakpoints } from 'core/ui';

import { useQueryPatient } from 'features/api';
import { useSessionLocation } from 'features/location';

import { PatientUtils } from '../services';
import { PatientDobLabel } from './PatientDobLabel';
import { PatientEditModal } from './PatientEditModal';
import { PatientVerbiageProvider } from './PatientVerbiageProvider';

type PatientEditHeaderProps = {
  className?: string;
  patientId: number | null;
};

export const PatientEditHeader = memo<PatientEditHeaderProps>(({ className, patientId }) => {
  const [isEditModalVisible, { setTrue: setEditModalVisible, setFalse: setEditModalHidden }] = useBoolean(false);
  const accordion = useAccordionNg([]);
  const { isExpanded, toggleExpanded } = useAccordionNgItem('patient-accordion', accordion);
  const { accordionItemBodyRef, animationStyles, handleToggleClick } = useAccordionNgItemView('vertical', isExpanded, toggleExpanded);

  const [{ data: patient }] = useQueryPatient(patientId);
  const { sessionLocationOptions } = useSessionLocation();

  const location = sessionLocationOptions?.find((loc) => loc.id === patient?.location_Id);
  const patientVerbiage = location?.isOpo ? 'Donor' : 'Patient';
  const dobDate = patient?.dob ? dayjs(patient?.dob).toString() : null;

  const { desktop, mobile } = useBreakpoints();

  return (
    <PatientVerbiageProvider mode={patientVerbiage}>
      {isEditModalVisible && <PatientEditModal patientId={patientId} onClose={setEditModalHidden} />}
      <AccordionNg.Provider {...accordion}>
        <AccordionNg.AccordionDiv className={className}>
          <AccordionNg.ItemDiv>
            <AccordionNg.ItemHeaderDiv preset="desktop-primary">
              <AccordionNg.ItemCollapseIconButton onClick={handleToggleClick} style={animationStyles[1]} />

              <StyledInfoButton className="testing-patient-edit-header-expand-button-1" onClick={handleToggleClick}>
                {desktop && (
                  <>
                    <StyledInfoBlock>
                      <StyledInfoLabel>NAME (GENDER):</StyledInfoLabel>
                      <StyledInfoValue>{`${PatientUtils.formatName(patient?.firstName, patient?.lastName)} ${patient?.gender ? `(${patient?.gender})` : ''}`}</StyledInfoValue>
                    </StyledInfoBlock>
                    {location?.isOpo && (
                      <>
                        <StyledInfoBlock>
                          <StyledInfoLabel>UNOS ID:</StyledInfoLabel>
                          <StyledInfoValue>{patient?.unosID}</StyledInfoValue>
                        </StyledInfoBlock>
                      </>
                    )}
                    <StyledInfoBlock>
                      <StyledInfoLabel>MRN/{patientVerbiage.toUpperCase()} ID:</StyledInfoLabel>
                      <StyledInfoValue>{patient?.patientNumber}</StyledInfoValue>
                    </StyledInfoBlock>
                    <StyledInfoBlock>
                      <StyledInfoLabel>CASE ID:</StyledInfoLabel>
                      <StyledInfoValue>{patient?.caseID}</StyledInfoValue>
                    </StyledInfoBlock>
                    <StyledInfoBlock>
                      <StyledInfoLabel>DOB (AGE):</StyledInfoLabel>
                      <StyledInfoValue>
                        <PatientDobLabel dob={dobDate} showAge />
                      </StyledInfoValue>
                    </StyledInfoBlock>
                    <StyledInfoBlock>
                      <StyledInfoLabel>HOSPITAL:</StyledInfoLabel>
                      <StyledInfoValue>{patient?.hospital}</StyledInfoValue>
                    </StyledInfoBlock>
                  </>
                )}
                {mobile && (
                  <>
                    <StyledInfoBlock>
                      <StyledInfoLabel>NAME (GENDER):</StyledInfoLabel>
                      <StyledInfoValue>{`${PatientUtils.formatName(patient?.firstName, patient?.lastName)} ${patient?.gender ? `(${patient?.gender})` : ''}`}</StyledInfoValue>
                    </StyledInfoBlock>
                  </>
                )}
              </StyledInfoButton>
              {patientId != null && (
                <StyledEditButtonDiv>
                  <Button variant={ButtonVariants.SECONDARY} onClick={setEditModalVisible}>
                    Edit
                  </Button>
                </StyledEditButtonDiv>
              )}
            </AccordionNg.ItemHeaderDiv>
            <StyledItemBodyAnimationDiv style={animationStyles[0]}>
              <AccordionNg.ItemBodyDiv ref={accordionItemBodyRef} preset="desktop-primary">
                {patient != null && (
                  <>
                    <StyledContentDiv>
                      {location?.isOpo && (
                        <div>
                          <b>Unos ID:</b>
                          {patient.unosID}
                        </div>
                      )}
                      <div>
                        <b>Case ID:</b>
                        {patient.caseID}
                      </div>
                      <div>
                        <b>First Name:</b>
                        {patient.firstName}
                      </div>
                      <div>
                        <b>Last Name:</b>
                        {patient.lastName}
                      </div>
                      <div>
                        <b>DOB:</b>
                        {PatientUtils.formatDob(patient.dob)}
                      </div>
                      <div>
                        <b>Age Range:</b>
                        {patient.ageRange?.description}
                      </div>
                      <div>
                        <b>Weight:</b>
                        {patient.weight}
                      </div>
                      <div>
                        <b>MRN:</b>
                        {patient.patientNumber}
                      </div>
                      <div>
                        <b>Height:</b>
                        {patient.height}
                      </div>
                      <div>
                        <b>Gender:</b>
                        {patient.gender}
                      </div>
                      <div>
                        <b>Cross Clamp Time:</b>
                        {PatientUtils.formatCrossClampDateTime(patient.crossClampDateTime ?? null)}
                      </div>
                      <div>
                        <b>Hospital:</b>
                        {patient.hospital}
                      </div>
                      <div>
                        <b>Notes:</b>
                        {patient.notes}
                      </div>
                    </StyledContentDiv>
                  </>
                )}
              </AccordionNg.ItemBodyDiv>
            </StyledItemBodyAnimationDiv>
          </AccordionNg.ItemDiv>
        </AccordionNg.AccordionDiv>
      </AccordionNg.Provider>
    </PatientVerbiageProvider>
  );
});

PatientEditHeader.displayName = 'PatientEditHeader';

const baseColumnWidth = '220px';

const StyledItemBodyAnimationDiv = styled(AccordionNg.ItemBodyAnimationDiv)`
  background-color: ${({ theme }) => theme.colors.palette.white};
`;

const StyledInfoButton = styled.button`
  all: unset;

  flex: 1 1 0;
  display: flex;
  overflow: hidden;
  column-gap: ${({ theme }) => theme.space.spacing60};
  color: ${({ theme }) => theme.colors.palette.white};
  padding: ${({ theme }) => theme.space.spacing20};
`;

const StyledInfoBlock = styled.div`
  text-align: left;
  grid-row: 1;
  padding-left: ${({ theme }) => theme.space.spacing20};
`;

const StyledInfoLabel = styled.div`
  text-align: left;
`;

const StyledInfoValue = styled.div`
  font-size: 12px;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;

const StyledContentDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.space.spacing40};

  > * {
    flex: 0 1 ${baseColumnWidth};
    min-width: ${baseColumnWidth};
    max-width: ${baseColumnWidth};
  }

  > :last-child {
    flex: 0 1 calc(3 * ${baseColumnWidth} + 2 * ${({ theme }) => theme.space.spacing40});
    max-width: calc(3 * ${baseColumnWidth} + 2 * ${({ theme }) => theme.space.spacing40});
  }
`;

const StyledEditButtonDiv = styled.div`
  padding: 0 ${({ theme }) => theme.space.spacing20};
`;
