import { FunctionComponent, useCallback, useEffect, useState } from 'react';

import { ServiceModel, TatModel } from 'models';

import { apiClient } from 'core/api/globals';
import { Field, FieldContainer, Form, GridColumn, createStyledFormElement } from 'core/forms';
import { Button, Dropdown, Label, MultiSelect, Switch } from 'core/ui';

import { useAppSelector } from 'features/main/hooks';
import { TatSelectors } from 'features/tat';

import { TatTabContentProps, TatTabFormValues } from '../types';
import { TATMultiSelect } from './TATMultiSelect';

const StyledFormElement = createStyledFormElement('min-content 400px');

const TatTabContentInner: FunctionComponent<{
  valueGetter: (name: keyof TatTabFormValues) => any;
  onChange: (values: TatTabFormValues) => void;
  initialValues: TatTabFormValues;
}> = ({ valueGetter, onChange, initialValues }) => {
  const tats = useAppSelector(TatSelectors.getAll);
  const [allServices, setAllServices] = useState<ServiceModel[]>([]);

  useEffect(() => {
    const fetchServices = async () => {
      const services = await apiClient.servicesClient.getAllServices();
      setAllServices(services);
    };
    fetchServices();
  }, []);

  const handleFormChange = useCallback(() => {
    onChange({
      defaultTATDropdown: valueGetter('defaultTATDropdown'),
      taTs: valueGetter('taTs'),
      transmissionSLADropdown: valueGetter('transmissionSLADropdown'),
      abnormalTransmissionSLADropdown: valueGetter('abnormalTransmissionSLADropdown'),
      // Pass the primitive value directly for primitive mode.
      orderAndExamRequiredSLA: valueGetter('orderAndExamRequiredSLA'),
      allowLocationTATAdminOverride: valueGetter('allowLocationTATAdminOverride'),
      services: valueGetter('services'),
    });
  }, [onChange, valueGetter]);

  return (
    <StyledFormElement autoComplete="off" autoCorrect="off" autoCapitalize="none" spellCheck="false">
      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="defaultTATDropdown">Default TAT</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field component={Dropdown} data={tats} editorId="defaultTATDropdown" name="defaultTATDropdown" onChange={handleFormChange} />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="services">Available Services</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            component={MultiSelect}
            editorId="services"
            textField="description"
            dataItemKey="id"
            data={allServices}
            label="Services"
            name="services"
            onChange={handleFormChange}
            autoClose={false}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="taTs">Available TATs</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field component={TATMultiSelect} data={tats} name="taTs" editorId="taTs" onChange={handleFormChange} />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="transmissionSLADropdown">Transmission SLA</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="transmissionSLADropdown"
            editorId="transmissionSLADropdown"
            component={Dropdown}
            defaultItem={{ id: 0, value: 0, name: 'No Over-Read' }}
            data={tats}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="abnormalTransmissionSLADropdown">Abnormal Transmission SLA</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            component={Dropdown}
            defaultItem={{ id: 0, value: 0, name: 'No Over-Read' }}
            data={tats}
            editorId="abnormalTransmissionSLADropdown"
            name="abnormalTransmissionSLADropdown"
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="orderAndExamRequiredSLA">Order and Exam Required SLA</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            component={Dropdown}
            defaultItem={{ id: 0, value: 0, name: 'No Over-Read' }}
            data={tats}
            editorId="orderAndExamRequiredSLA"
            name="orderAndExamRequiredSLA"
            onChange={handleFormChange}
            // Pass the primitive value directly and let the Dropdown map it.
            value={initialValues.orderAndExamRequiredSLA}
            valueField="value"
            isForPrimitiveValues={true}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="allowLocationTATAdminOverride">Allow Admin TAT Override</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field name="allowLocationTATAdminOverride" editorId="allowLocationTATAdminOverride" component={Switch} onChange={handleFormChange} />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1">
        <div>
          <Button type="submit">Save</Button>
        </div>
      </GridColumn>
    </StyledFormElement>
  );
};

TatTabContentInner.displayName = 'TatTabContentInner';

export const TatTabContent: FunctionComponent<TatTabContentProps> = ({ initialValues, onSubmit, onChange }) => {
  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      ignoreModified
      render={({ valueGetter }) => <TatTabContentInner valueGetter={valueGetter} onChange={onChange} initialValues={initialValues} />}
    />
  );
};

TatTabContent.displayName = 'TatTabContent';
