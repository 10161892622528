import { useCallback, useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { FileActions } from 'features/file/redux/actions';
import { FileSelectors } from 'features/file/redux/selectors';

import { DocumentCategories, ExamStatus } from '../constants';
import { FileUrlService } from '../services/file-url-service';

/** Exam statuses that trigger an initial selection for currentFile to be set for the first file that is marked as in the "Final Report" category. */
const AUTO_SELECT_FILE_FINAL_REPORT_STATUSES = [ExamStatus.READ.name, ExamStatus.COMPLETE.name];

/** encapsulates all the logic and data for FileView and FileSidebar components
 * @deprecated
 */
export const useExamFiles = (examId, examStatus, accessToken) => {
  const dispatch = useDispatch();

  const files = useSelector(FileSelectors.getAll);

  const [currentFile, setCurrentFile] = useState(null);

  const getFilesByExamId = useCallback((id) => dispatch(FileActions.getFilesByExamId(id)), [dispatch]);

  useEffect(() => {
    if (files == null || examStatus == null) return;

    setCurrentFile((prev) => {
      if (files.length === 0) return null;

      // Re-select the same file if the underlying "files" store references have been re-created/fetched/updated somehow.
      if (prev != null) {
        const newFile = files.find((f) => f.id === prev.id);

        if (newFile != null) {
          return newFile;
        }
      }

      // Show Exam AI first
      let newFile = files.find((f) => f.categoryId === DocumentCategories.EXAMAI.value);

      if (newFile != null) {
        return newFile;
      }

      // Show Exam if there is no AI to show
      newFile = files.find((f) => f.categoryId === DocumentCategories.EXAM.value);

      if (newFile != null) {
        return newFile;
      }

      // Select the first file that is categorized as "Final Report" when the exam status is read or complete.
      if (AUTO_SELECT_FILE_FINAL_REPORT_STATUSES.includes(examStatus)) {
        const newFile = files.find((f) => f.categoryId === DocumentCategories.FINAL_REPORT.value);

        if (newFile != null) {
          return newFile;
        }
      }

      // Select the first file in the list if all of our other special logic was unable to find a more appropriate initial selection.
      return files[0];
    });
  }, [files, examStatus]);

  const fileUrl = useMemo(
    () => (currentFile == null || accessToken == null ? null : FileUrlService.resolveFileUrl(currentFile, accessToken)),
    [currentFile, accessToken],
  );

  useEffect(() => {
    if (examId) {
      getFilesByExamId(examId);
    }
  }, [examId, getFilesByExamId]);

  return {
    files,
    currentFile,
    setCurrentFile,
    fileUrl,
    getFilesByExamId,
  };
};
