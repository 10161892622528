import { useMemo } from 'react';

import { ExamModel, FileModel } from 'models';

import { FileTypeHelper } from 'features/file/services/FileTypeHelper';

export const useExamsFilesAccordionItem = (exam: ExamModel) => {
  const links = useMemo(() => exam?.files?.filter((f) => FileTypeHelper.getUppercaseFileType(f) === 'LINK') || [], [exam]);

  const files = useMemo(() => exam?.files?.filter((f) => FileTypeHelper.getUppercaseFileType(f) !== 'LINK') || [], [exam]);

  return { links, files };
};
