import { forwardRef } from 'react';

import cn from 'classnames';
import styled, { css } from 'styled-components';

import { ItemBodyDivProps } from './ItemBodyDivProps';

/** Element that contains the accordion item body.  This should be placed as the direct descendant of the ItemBodyAnimationDiv element. */
export const ItemBodyDiv = forwardRef<HTMLDivElement, ItemBodyDivProps>(({ className, mode = 'vertical', preset = 'none', ...rest }, ref) => (
  <StyledBodyDiv
    ref={ref}
    className={cn(className, ItemBodyDiv.displayName, {
      horizontal: mode === 'horizontal',
      vertical: mode === 'vertical',
    })}
    $preset={preset}
    {...rest}
  />
));

ItemBodyDiv.displayName = 'ItemBodyDiv';

type StyledComponentProps = {
  $preset: 'none' | 'desktop-primary';
};

function resolvePreset({ $preset }: StyledComponentProps) {
  switch ($preset) {
    case 'desktop-primary':
      return PresetDesktopPrimary;
    case 'none':
    default:
      return null;
  }
}

const StyledBodyDiv = styled.div<StyledComponentProps>`
  flex: 0 0 min-content;

  ${resolvePreset}
`;

const PresetDesktopPrimary = css<StyledComponentProps>`
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-top: none;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: ${({ theme }) => theme.colors.palette.white};
  padding: ${({ theme }) => theme.space.spacing40};
`;
