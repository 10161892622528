import { FunctionComponent, memo } from 'react';

import { Route, Routes } from 'react-router-dom';

import { RouteGuard, UserRoles } from 'features/auth';

import { TagsRoutes } from '../routes';
import { TagsFieldTemplateForm } from './TagsFieldTemplateForm';
import { TagsFieldTemplateGrid } from './TagsFieldTemplateGrid';

export const TagsFieldTemplate: FunctionComponent = memo(() => {
  return (
    <Routes>
      <Route
        path={TagsRoutes.HOME}
        element={
          <RouteGuard allowedRoles={UserRoles.ADMIN}>
            <TagsFieldTemplateGrid />
          </RouteGuard>
        }
      />
      <Route
        path={TagsRoutes.ADD}
        element={
          <RouteGuard allowedRoles={UserRoles.ADMIN} requireSystemAdmin challengeComponent={TagsFieldTemplateGrid}>
            <TagsFieldTemplateForm />
          </RouteGuard>
        }
      />
      <Route
        path={TagsRoutes.EDIT}
        element={
          <RouteGuard allowedRoles={UserRoles.ADMIN} requireSystemAdmin challengeComponent={TagsFieldTemplateGrid}>
            <TagsFieldTemplateForm />
          </RouteGuard>
        }
      />
    </Routes>
  );
});

TagsFieldTemplate.displayName = 'TagsFieldTemplate';
