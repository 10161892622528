export const FileTypes = {
  IMAGE: ['PNG', 'JPG', 'JPEG'],
  PDF: ['PDF'],
  XML: ['XML'],
  LINK: ['LINK'],
  ALIVE_COR_ATC: ['ATC'],
  VIDEO: ['MP4', 'MOV'],
};

export enum Viewer {
  AliveCorSingleLead = 'AliveCor-SingleLead',
  AliveCorSixLead = 'AliveCor-SixLead',
  MedDream = 'meddream',
  Brit = 'brit',
  Ambra = 'ambra',
  Techcyte = 'techcyte',
  Pathozoom = 'pathozoom',
  Aiforia = 'aiforia',
}

export const LOCALSTORAGE_KEY_ECG_VIEWER_WRAPPING = 'ecg-viewer-wrapping';

export const DEFAULT_MULTI_MONITOR_ENABLED = false;

export interface DocumentCategory {
  name: string;
  value: string;
  shorthand?: string;
}

export const DocumentCategories = {
  EXAM: {
    name: 'Exam',
    value: '1',
    shorthand: 'Exam',
  },
  PATIENT_CONSENT: {
    name: 'Patient Consent',
    value: '2',
    shorthand: 'Consent',
  },
  PHYSICIAN_NOTE: {
    name: 'Physician Note',
    value: '3',
    shorthand: 'Note',
  },
  EXTRA: {
    name: 'Extra',
    value: '4',
    shorthand: 'Extra',
  },
  BACKUP: {
    name: 'Backup Exam',
    value: '5',
    shorthand: 'Backup',
  },
} as const;
