import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { Spinner } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { FileModel } from 'models';

import { useValidatedParam } from 'core/hooks';

import { useApiClient, useQueryFilesByExamId } from 'features/api';
import { DocumentCategories, ExamStatus } from 'features/exam/constants';
import { FileInfoBar, FileViewer } from 'features/file';

import { FileSelectSidebar } from './FileSelectSidebar';

/** Exam statuses that trigger an initial selection for currentFile to be set for the first file that is marked as in the "Final Report" category. */
const AUTO_SELECT_FILE_FINAL_REPORT_STATUSES: string[] = [ExamStatus.READ.name, ExamStatus.COMPLETE.name];

interface FileViewerPageProps {
  /** Optional files to display instead of fetching by examId */
  files?: FileModel[];
  examId?: number;
  showSidebar?: boolean;
  showInfobar?: boolean;
  showEditBtn?: boolean;
  showDeleteBtn?: boolean;
}

export const FileViewerPage = memo(
  ({ files: propFiles, examId, showSidebar = false, showInfobar = false, showEditBtn = false, showDeleteBtn = false }: FileViewerPageProps) => {
    const [queryParams] = useSearchParams();
    const feature = queryParams.get('feature') ?? null;
    const status = queryParams.get('status') ?? ExamStatus.NEW.value;
    const apiClient = useApiClient();

    console.log('FileViewerPage - examId:', examId, typeof examId);

    const examIdFromUrl = useValidatedParam('examId', 'number', false);
    const effectiveExamId = examId ?? (examIdFromUrl ? examIdFromUrl : null);

    console.log('FileViewerPage - effectiveExamId:', effectiveExamId, typeof effectiveExamId);

    // Only query API if we have a valid examId AND no prop files were provided
    const shouldFetchFiles = effectiveExamId !== undefined && effectiveExamId !== null && effectiveExamId > 0 && !propFiles;

    // Use a type assertion to work with the function overload
    // Since we need to pass null to the function, we need to ensure it properly uses the overload for nullable IDs
    const queryId = shouldFetchFiles ? effectiveExamId : (null as unknown as number);
    // Call the hook with the properly typed parameter
    const { data: fetchedFiles, isLoading, error, refetch } = useQueryFilesByExamId(queryId);

    console.log('FileViewerPage - Query state:', {
      isLoading,
      error: error ? String(error) : undefined,
      fetchedFiles: fetchedFiles ? `${fetchedFiles.length} files` : null,
      propFiles: propFiles ? `${propFiles.length} files` : null,
      shouldFetchFiles,
    });

    const filteredFiles = useMemo(() => {
      const filesSource = propFiles ?? fetchedFiles;

      if ((shouldFetchFiles && isLoading) || !!error || !filesSource) {
        console.log('FileViewerPage - filteredFiles returning null because:', {
          isLoading,
          hasError: !!error,
          hasFiles: !!filesSource,
          hasPropFiles: !!propFiles,
          shouldFetchFiles,
        });
        return null;
      }

      switch (feature) {
        case 'exams':
          return filesSource.filter((file) => file.categoryId === DocumentCategories.EXAM.value || file.fileType === 'LINK') ?? [];
        case 'attachments':
          return filesSource.filter((file) => file.categoryId !== DocumentCategories.EXAM.value && file.fileType !== 'LINK') ?? [];
        default:
          return filesSource ?? null;
      }
    }, [feature, fetchedFiles, isLoading, error, propFiles, shouldFetchFiles]);

    const [currentFile, setCurrentFile] = useState<FileModel | null>(() => selectCurrentFile(filteredFiles, status));

    const deleteFile = useCallback(
      async (fileId: string | number) => {
        try {
          await apiClient.filesClient.deleteFile(fileId.toString(), 'msal-required');
          return true;
        } catch (error) {
          console.error('Error deleting file:', error);
          return false;
        }
      },
      [apiClient],
    );

    const handleFileDeleted = useCallback(
      (deletedFileId: string | number) => {
        console.log('File deleted:', deletedFileId);
        if (effectiveExamId && effectiveExamId > 0) {
          refetch();
        }
        if (filteredFiles && filteredFiles.length > 1 && currentFile?.id === deletedFileId) {
          const currentIndex = filteredFiles.findIndex((f) => f.id === deletedFileId);
          const newSelectedFile = currentIndex < filteredFiles.length - 1 ? filteredFiles[currentIndex + 1] : filteredFiles[currentIndex - 1];
          setCurrentFile(newSelectedFile);
        } else if (filteredFiles && filteredFiles.length <= 1) {
          setCurrentFile(null);
        }
      },
      [effectiveExamId, filteredFiles, currentFile, refetch],
    );

    useEffect(() => {
      console.log('FileViewerPage - useEffect to update currentFile. Previous file:', currentFile?.fileName);
      if (!currentFile && filteredFiles) {
        const newFile = selectCurrentFile(filteredFiles, status);
        console.log('FileViewerPage - Selected new file:', newFile?.fileName);
        setCurrentFile(newFile);
      }
      console.log('FileViewerPage - After setting current file:', filteredFiles ? 'Files available' : 'No files');
    }, [filteredFiles, status, currentFile]);

    const filesForSidebar = useMemo(() => {
      return (filteredFiles ?? []).map((file) => {
        const fileItem: FileModel = {
          id: file.id,
          thumbnailUrl: file.thumbnailUrl ?? null,
          fileName: file.fileName,
          modified: file.modified ?? null,
          categoryId: file.categoryId ?? null,
          viewerId: file.viewerId ?? null,
        };
        return fileItem;
      });
    }, [filteredFiles]);

    const selectedFileItem = useMemo(() => {
      if (!currentFile) return null;
      const fileItem: FileModel = {
        id: currentFile.id,
        fileName: currentFile.fileName,
        modified: currentFile.modified ?? null,
        categoryId: currentFile.categoryId ?? null,
        thumbnailUrl: currentFile.thumbnailUrl ?? null,
        viewerId: currentFile.viewerId ?? null,
      };
      return fileItem;
    }, [currentFile]);

    return (
      <>
        <StyledContainer $showInfobar={showInfobar}>
          {showInfobar && <FileInfoBar file={currentFile} $defaultCollapsed={false} />}
          {filteredFiles && currentFile ? (
            <StyledViewerContainer>
              <FileViewer file={currentFile} overrideMultiMonitor />
            </StyledViewerContainer>
          ) : (
            <StyledViewerContainer>
              <StyledSpinnerContainer>
                {shouldFetchFiles && isLoading ? (
                  <>
                    <Spinner />
                    <div>Loading files from exam ID: {effectiveExamId}...</div>
                  </>
                ) : error ? (
                  <div style={{ color: 'red' }}>Error loading files: {String(error)}</div>
                ) : filteredFiles && filteredFiles.length === 0 ? (
                  <div>No files found</div>
                ) : filteredFiles ? (
                  <div>No file selected. Please select a file from the sidebar.</div>
                ) : propFiles && propFiles.length > 0 ? (
                  <div>
                    <Spinner />
                    <div>Processing {propFiles.length} files...</div>
                  </div>
                ) : (
                  <div>No files available</div>
                )}
              </StyledSpinnerContainer>
            </StyledViewerContainer>
          )}

          <FileSelectSidebar
            files={filesForSidebar}
            handleFileSelect={(file: FileModel | null) => {
              console.log('File selected:', file);
              if (file && filteredFiles) {
                const foundFile = filteredFiles.find((f) => f.id === file.id);
                console.log('Found file in filteredFiles:', foundFile);
                setCurrentFile(foundFile ?? null);
              } else {
                setCurrentFile(null);
              }
            }}
            selectedFile={selectedFileItem}
            showDeleteBtn={showDeleteBtn}
            showEditBtn={showEditBtn}
            examId={effectiveExamId && effectiveExamId > 0 ? effectiveExamId.toString() : undefined}
            hideUploadButton={true}
            defaultCollapsed={!showSidebar}
            onFileDeleted={handleFileDeleted}
            onDeleteFile={deleteFile}
          />
        </StyledContainer>
      </>
    );
  },
);

FileViewerPage.displayName = 'FileViewerPage';

function selectCurrentFile(files: FileModel[] | null, status: string) {
  if (files == null) return null;

  if (AUTO_SELECT_FILE_FINAL_REPORT_STATUSES.includes(status)) {
    return files.find((file) => file.categoryId === DocumentCategories.FINAL_REPORT.value) ?? null;
  }

  return files[0] ?? null;
}

interface StyledContainerProps {
  $showInfobar: boolean;
}

const StyledContainer = styled.div<StyledContainerProps>`
  display: grid;
  grid-template-columns: ${(props) => (props.$showInfobar ? 'min-content 1fr min-content' : '1fr min-content')};
  grid-template-rows: 1fr;
  height: 100%;
  overflow: hidden;
  width: 100%;
`;

const StyledViewerContainer = styled.div`
  min-height: 0;
  overflow: hidden;
  width: 100%;
`;

const StyledSpinnerContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  justify-content: center;
  width: 100%;
`;
