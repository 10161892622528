import { BulkAttachFilesModel, CompletePathologyUploadModel, ConnectionString, ContainerName, FileModel } from 'models';

import { ApiRouteService } from 'core/api';

import { HttpClient } from './HttpClient';

export class FilesClient {
  constructor(private httpClient: HttpClient) {
    this.attachFiles = this.attachFiles.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
    this.renameFile = this.renameFile.bind(this);
    this.generateAzureBlobDownloadSasUrl = this.generateAzureBlobDownloadSasUrl.bind(this);
    this.generateAzureBlobUploadSas = this.generateAzureBlobUploadSas.bind(this);
    this.add = this.add.bind(this);
    this.getById = this.getById.bind(this);
    this.completePathologyUpload = this.completePathologyUpload.bind(this);
    this.sendDocuments = this.sendDocuments.bind(this);
    this.generateReport = this.generateReport.bind(this);
    this.rotate = this.rotate.bind(this);
    this.changeCategory = this.changeCategory.bind(this);
  }

  public async getFilesByExamId(examId: number, authMode: 'msal-required' | 'share-required') {
    const options = await this.httpClient.createStandardOptions('GET', null, { authMode });

    return await this.httpClient.fetchAndParse<FileModel[]>(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/files?examId=${examId}`, options));
  }

  public async getFilesByPatientId(patientId: number, authMode: 'msal-required' | 'share-required') {
    const options = await this.httpClient.createStandardOptions('GET', null, { authMode });

    return await this.httpClient.fetchAndParse<FileModel[]>(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/files?patientId=${patientId}`, options));
  }

  public async attachFiles(files: BulkAttachFilesModel[], authMode: 'msal-required' | 'share-required') {
    const options = await this.httpClient.createStandardOptions('POST', files, {
      authMode,
    });

    const response = await this.httpClient.fetchAndParse<Record<string, boolean>>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/files/Attach`, options),
    );
    return response;
  }

  public async deleteFile(fileId: string, authMode: 'msal-required' | 'share-required') {
    const options = await this.httpClient.createStandardOptions('DELETE', null, { authMode });

    const response = await this.httpClient.fetchAndParse(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/files/${fileId}`, options));
    return response;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async renameFile(patientFileId: number, patchDoc: any[]) {
    const options = await this.httpClient.createStandardOptions('PATCH', patchDoc);
    await this.httpClient.fetch(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/files/rename/${patientFileId}`, options));
  }

  public async generateAzureBlobDownloadSasUrl(containerName: string, blobName: string) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const response = await this.httpClient.fetchAndParse<string>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/files/GenerateAzureBlobDownloadSas/${containerName}/${blobName}`, options),
    );
    return response;
  }

  public async generateAzureBlobUploadSas(connectionString: ConnectionString, containerName: ContainerName, authMode: 'msal-required' | 'share-required') {
    const options = await this.httpClient.createStandardOptions('GET', null, { authMode });

    const queryUrl = `${ApiRouteService.getCompumedApiBaseRoute()}/api/files/GenerateAzureBlobUploadSas/${encodeURIComponent(containerName)}?connectionString=${encodeURIComponent(connectionString)}`;

    const response = await this.httpClient.fetchAndParse<string>(fetch(queryUrl, options));

    return response;
  }

  public async add(
    formData: FormData,
    shouldSplitFiles: boolean,
    examId: number | null,
    patientId: number | null,
    categoryId: number | null,
    authMode: 'msal-required' | 'share-required',
  ) {
    const options = await this.httpClient.createStandardOptions('POST', formData, { authMode }, { isFormData: true });
    const baseUrl = ApiRouteService.getCompumedApiBaseRoute();
    const url = new URL(`${baseUrl}/api/files`);
    url.searchParams.append('splitFiles', shouldSplitFiles.toString());
    if (examId && examId !== 0) {
      url.searchParams.append('examId', examId.toString());
    }
    if (patientId && patientId !== 0) {
      url.searchParams.append('patientId', patientId.toString());
    }
    if (categoryId && categoryId !== 0) {
      url.searchParams.append('categoryId', categoryId.toString());
    }
    return await this.httpClient.fetchAndParse<FileModel[]>(fetch(url.toString(), options));
  }

  public async getById(fileId: number, authMode: 'msal-required' | 'share-required') {
    const options = await this.httpClient.createStandardOptions('GET', null, { authMode });
    return await this.httpClient.fetchAndParse<FileModel>(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/files/get-by-id/${fileId}`, options));
  }

  public async completePathologyUpload(model: CompletePathologyUploadModel, authMode: 'msal-required' | 'share-required') {
    const options = await this.httpClient.createStandardOptions('POST', model, { authMode });
    await this.httpClient.fetch(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/files/CompletePathologyUpload`, options));
  }

  public async getFinalReportPreview(examId: number, accessToken: string) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const response = await this.httpClient.fetchAndParse(
      fetch(
        `${ApiRouteService.getCompumedApiBaseRoute()}/api/files/final-report-preview?examId=${encodeURIComponent(
          examId,
        )}&access_token=${encodeURIComponent(accessToken)}`,
        options,
      ),
    );
    return response;
  }

  public getFinalReportPreviewURL(examId: number, accessToken: string) {
    return `${ApiRouteService.getCompumedApiBaseRoute()}/api/files/final-report-preview?examId=${encodeURIComponent(
      examId,
    )}&access_token=${encodeURIComponent(accessToken)}`;
  }

  public async sendDocuments(patientFileId: number, sendType: string, sendTo: string, destinationId?: number) {
    const options = await this.httpClient.createStandardOptions('POST', null);
    let queryStr = `PatientFileId=${patientFileId}&SendType=${sendType}&SendTo=${sendTo}`;
    if (destinationId) {
      queryStr += `&destinationId=${destinationId}`;
    }
    const url = `${ApiRouteService.getCompumedApiBaseRoute()}/api/exams/send-documents?${queryStr}`;
    const response = await this.httpClient.fetchAndParse<boolean>(fetch(url, options));
    return response;
  }

  public async generateReport(patientFileId: number | null, examId: number) {
    const queryStr = `patientFileId=${patientFileId}`;
    const url = `${ApiRouteService.getCompumedApiBaseRoute()}/api/exams/${examId}/generate-report?${queryStr}`;
    const options = await this.httpClient.createStandardOptions('POST', null);
    const response = await this.httpClient.fetchAndParse<FileModel>(fetch(url, options));
    return response;
  }

  public async rotate(file: FileModel, degrees: number) {
    const url = `${ApiRouteService.getCompumedApiBaseRoute()}/api/files/rotate-file`;
    const body = { file, degrees };
    const options = await this.httpClient.createStandardOptions('POST', body);
    const response = await this.httpClient.fetchAndParse<FileModel>(fetch(url, options));
    return response;
  }

  public async changeCategory(fileId: number, categoryId: number) {
    const url = `${ApiRouteService.getCompumedApiBaseRoute()}/api/files/${fileId}/change-category`;
    const body = { categoryId };
    const options = await this.httpClient.createStandardOptions('PUT', body);
    const response = await this.httpClient.fetchAndParse<FileModel>(fetch(url, options));
    return response;
  }
}
