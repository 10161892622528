import { ReactNode, memo } from 'react';

import { useValidatedParam } from 'core/hooks';

import { useQueryExam, useQueryPatient } from 'features/api';
import { useSessionLocationInitSync, useSessionLocationSelectModal } from 'features/location';

export const UploadExamsInit = memo<{ children?: ReactNode }>(({ children }) => {
  const patientIdParam = useValidatedParam('patientId', 'integer', false);
  const examIdParam = useValidatedParam('examId', 'integer', false);

  const [{ data: patient }] = useQueryPatient(patientIdParam);
  const [{ data: exam }] = useQueryExam(examIdParam);

  const sessionLocation = useSessionLocationInitSync(patient?.location_Id ?? exam?.location_Id, patientIdParam != null || examIdParam != null);

  useSessionLocationSelectModal(patientIdParam == null && examIdParam == null && sessionLocation == null);

  return sessionLocation == null ? null : children;
});

UploadExamsInit.displayName = 'UploadExamsInit';
