import React, { useCallback, useMemo, useRef, useState } from 'react';

import { faMobileAlt, faPlus, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import _find from 'lodash/find';
import styled from 'styled-components';

import { FileModel } from 'models';

import { Button, ButtonVariants, Card, Checkbox, Icon } from 'core/ui';

import ShowSidebarIcon from '../assets/left-arrow.svg?react';
import CollapseSidebarIcon from '../assets/right-arrow.svg?react';
import { DocumentCategories, DocumentCategory } from '../constants';
import { FileItem, FileSelectSidebarProps } from '../types';
import { ImgProxy } from './ImgProxy';
import { ThumbnailPlaceholder } from './ThumbnailPlaceholder';

const resolveFileCategoryShorthand = (file: FileModel, isBackupExam: boolean): string | undefined => {
  // Check if the categoryId is a string or number and convert accordingly for comparison
  const categoryId = file?.categoryId ? String(file.categoryId) : undefined;
  const categoryMatch = _find(DocumentCategories as Record<string, DocumentCategory>, (category) => String(category.value) === categoryId);

  if (isBackupExam && categoryMatch?.name === DocumentCategories.EXAM.name) {
    return `Backup ${categoryMatch.shorthand}`;
  }
  return categoryMatch?.shorthand;
};

interface FileSelectSidebarComponentProps extends FileSelectSidebarProps {
  accessToken?: string;
  handleAddNewFileClick?: () => void;
}

const FileSelectSidebarComponent: React.FC<FileSelectSidebarComponentProps> = ({
  className = '',
  defaultCollapsed = false,
  files = [],
  handleAddNewFileClick,
  handleFileSelect,
  selectedFile,
  showEditBtn = false,
  showDeleteBtn = false,
  onClickYesBtn,
  accessToken,
  examType,
  hideUploadButton = false,
  examId,
  handleQRButtonClick,
  isInternal = false,
  onDeleteFile,
  onAddFiles,
  onFileDeleted,
}) => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState<boolean>(defaultCollapsed);
  const [fileIsUploading, setFileIsUploading] = useState<boolean>(false);
  const [shouldSplitFiles, setShouldSplitFiles] = useState<boolean>(isInternal);

  const filesInputRef = useRef<HTMLInputElement>(null);

  const onUploadFilesClick = (): void => {
    filesInputRef.current?.click();
  };

  const onUploadFilesChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
      setFileIsUploading(true);
      const formData = new FormData();

      if (event.target.files) {
        try {
          for (let i = 0; i < event.target.files.length; i++) {
            const file = event.target.files[i];
            formData.append('files', file);
          }

          if (onAddFiles) {
            const response = await onAddFiles(formData, shouldSplitFiles, examId);
            if (response && typeof response === 'object' && 'files' in response) {
              const newFiles = response.files as FileItem[];
              if (newFiles && newFiles.length > 0 && handleFileSelect) {
                // Convert FileItem to FileModel for type compatibility
                const lastFile = newFiles[newFiles.length - 1];
                if (lastFile.id !== undefined) {
                  handleFileSelect({
                    id: lastFile.id,
                    fileName: lastFile.fileName || '',
                    fileType: lastFile.fileType || '',
                    modified: lastFile.modified || null,
                    thumbnailUrl: lastFile.thumbnailUrl || null,
                    categoryId: lastFile.categoryId || null,
                    viewerId: lastFile.viewerId || null,
                  });
                }
              }
            }
          }
        } catch (error: unknown) {
          console.error('Error uploading files:', error);
        } finally {
          setFileIsUploading(false);
          event.target.value = '';
        }
      }
    },
    [examId, onAddFiles, shouldSplitFiles, handleFileSelect],
  );

  const handleDeleteFile = useCallback(
    async (fileId: string | number): Promise<void> => {
      try {
        if (onDeleteFile) {
          await onDeleteFile(fileId);
        }
        if (selectedFile?.id === fileId && handleFileSelect) {
          handleFileSelect(null);
        }
        if (onFileDeleted) {
          onFileDeleted(fileId);
        }
      } catch (error: unknown) {
        console.error('Error deleting file:', error);
      }
    },
    [onDeleteFile, onFileDeleted, selectedFile, handleFileSelect],
  );

  const isBackupExam = useMemo(() => {
    return (file: FileItem): boolean => {
      const examFiles = files.filter((f) => f.viewerId && f.viewerId > 0);
      return examFiles.findIndex((f) => f.id === file.id) > 0;
    };
  }, [files]);

  if (isSidebarCollapsed) {
    return (
      <StyledHideSidebarDiv $isCollapsed={isSidebarCollapsed} onClick={() => setIsSidebarCollapsed(false)} id="sidebarArrowIcon">
        <ShowSidebarIcon />
      </StyledHideSidebarDiv>
    );
  }

  return (
    <StyledDivWrapper className={className}>
      <StyledHideSidebarDiv $isCollapsed={isSidebarCollapsed} onClick={() => setIsSidebarCollapsed(true)}>
        <CollapseSidebarIcon />
      </StyledHideSidebarDiv>
      <input type="file" hidden multiple ref={filesInputRef} onChange={onUploadFilesChange} accept=".pdf,.xml,.png,.jpg,.tiff,.szi,.sv" />
      {handleAddNewFileClick && (
        <StyledCardContainer>
          {!hideUploadButton && (
            <StyledSplitFileCard>
              {handleQRButtonClick && (
                <StyledButtonQR variant={ButtonVariants.SECONDARY} onClick={handleQRButtonClick}>
                  <Icon icon={faMobileAlt} /> Mobile Upload
                </StyledButtonQR>
              )}
              <StyledButtonUpload variant={ButtonVariants.SECONDARY} onClick={onUploadFilesClick} disabled={fileIsUploading}>
                {fileIsUploading ? <Icon icon={faSpinner} spin={true} /> : <Icon icon={faPlus} />} Upload Files
              </StyledButtonUpload>
              {isInternal && <Checkbox text="Split Files" onChange={() => setShouldSplitFiles((prevValue) => !prevValue)} value={shouldSplitFiles} />}
            </StyledSplitFileCard>
          )}
        </StyledCardContainer>
      )}
      <StyledFileScrollContainer>
        {files.map((file) => (
          <StyledDivFile key={file.id} onClick={() => handleFileSelect && handleFileSelect(file)} $selected={selectedFile?.id === file.id}>
            {file.thumbnailUrl ? (
              <StyledImgProxy
                fileId={file.id}
                thumbnailUrl={file.thumbnailUrl}
                dateModified={file.modified}
                isReadOnly={!showDeleteBtn}
                onRemove={handleDeleteFile}
                accessToken={accessToken}
              />
            ) : (
              <StyledThumbnailPlaceholder
                fileItem={file}
                showDeleteBtn={showDeleteBtn}
                showEditBtn={showEditBtn}
                examType={examType}
                onRemove={handleDeleteFile}
                onClickYesBtn={onClickYesBtn}
              />
            )}
            <StyledDivCategoryBadge>{resolveFileCategoryShorthand(file, isBackupExam(file))}</StyledDivCategoryBadge>
          </StyledDivFile>
        ))}
      </StyledFileScrollContainer>
    </StyledDivWrapper>
  );
};

const mediaMaxWidth = '1024px';

const StyledDivWrapper = styled.div`
  flex-basis: 170px;
  background: #434343;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  position: relative;
`;

const StyledHideSidebarDiv = styled.div<{ $isCollapsed: boolean }>`
  align-items: center;
  background: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  display: flex;
  height: 4rem;
  justify-content: center;
  position: absolute;
  top: 50%;
  transition: opacity 2.6s linear;
  width: 1.2rem;
  z-index: 10;
  ${({ $isCollapsed }) => ($isCollapsed ? 'right: 0;' : 'left: 0;')}
`;

const StyledCardContainer = styled.div`
  flex: 0 0 min-content;
  padding: ${({ theme }) => theme.space.spacing30} ${({ theme }) => theme.space.spacing30};
`;

const StyledSplitFileCard = styled(Card)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledButtonUpload = styled(Button)`
  margin-left: ${({ theme }) => theme.space.spacing20};
`;

const StyledButtonQR = styled(Button)`
  margin-bottom: ${({ theme }) => theme.space.spacing20};

  @media (max-width: ${mediaMaxWidth}) {
    display: none;
  }
`;

const StyledFileScrollContainer = styled.div`
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  align-items: center;
`;

const StyledDivFile = styled.div<{ $selected?: boolean }>`
  border-radius: 4px;
  border: ${({ $selected, theme }) => ($selected ? '4px solid #70B8DB' : '4px solid transparent')};
  cursor: pointer;
  margin-bottom: ${({ theme }) => theme.space.spacing20};
  margin-top: ${({ theme }) => theme.space.spacing20};
  position: relative;
`;

const StyledImgProxy = styled(ImgProxy)`
  object-fit: contain;
  min-height: ${({ theme }) => theme.sizes.examThumbnailMinHeight};
  width: ${({ theme }) => theme.sizes.examThumbnailMinWidth};
`;

const StyledThumbnailPlaceholder = styled(ThumbnailPlaceholder)`
  object-fit: contain;
  min-height: ${({ theme }) => theme.sizes.examThumbnailMinHeight};
  width: ${({ theme }) => theme.sizes.examThumbnailMinWidth};
`;

const StyledDivCategoryBadge = styled.div`
  background: ${({ theme }) => theme.colors.palette.aquas[5]};
  border-radius: 10px;
  bottom: 4px;
  color: ${({ theme }) => theme.colors.palette.white};
  left: 4px;
  padding: 0 8px;
  position: absolute;
`;

export const FileSelectSidebar = React.memo(FileSelectSidebarComponent);
