import { ComponentType, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { faPen } from '@fortawesome/pro-solid-svg-icons';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { GridCellProps, GridColumn, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { useNavigate } from 'react-router-dom';

import { AccountModel } from 'models';

import { apiClient } from 'core/api/globals';
import { DataResult } from 'core/api/types';
import { Action, ActionListCell, DEFAULT_DATA_TABLE_DATA_STATE, DEFAULT_PAGE_SIZES, DataTable, HeaderCell, Page, PageHeader, TextCell, Toolbar } from 'core/ui';

const PAGEABLE_SETTINGS = { pageSizes: DEFAULT_PAGE_SIZES };

export function AccountHome() {
  const navigate = useNavigate();

  const gridPdfExport = useRef<GridPDFExport | null>(null);
  const gridExcelExport = useRef<ExcelExport | null>(null);

  const [accounts, setAccounts] = useState<DataResult<AccountModel>>({
    data: [],
    total: 0,
  });
  const [dataState, setDataState] = useState(DEFAULT_DATA_TABLE_DATA_STATE);

  const handleDataStateChange = useCallback(
    (changeEvent: GridDataStateChangeEvent) => {
      setDataState(changeEvent.dataState);
    },
    [setDataState],
  );

  const handleAddNewClick = useCallback(() => navigate('/account/add'), [navigate]);

  const handleEditClick = useCallback((dataItem: AccountModel) => navigate(`/account/edit/${dataItem.id}`), [navigate]);

  const handleExportExcelClick = useCallback(async () => {
    if (!gridExcelExport.current) return;

    const exportedList = await apiClient.accountsClient.getAllAccountsForKendoGrid({
      ...dataState,
      // Clear the pagination related data state options because when exporting we want the entire result set.
      skip: undefined,
      take: undefined,
    });

    gridExcelExport.current.save(exportedList.data);
  }, [dataState]);

  const handleExportPdfClick = useCallback(() => {
    if (!gridPdfExport.current) return;

    gridPdfExport.current.save();
  }, [gridPdfExport]);

  const gridActions: Action[] = useMemo(() => {
    return [
      {
        key: 'edit-account',
        title: 'Edit Account',
        icon: faPen,
        onClick: (_, dataItem) => handleEditClick(dataItem),
      },
    ];
  }, [handleEditClick]);

  useEffect(() => {
    (async () => {
      const newData = await apiClient.accountsClient.getAllAccountsForKendoGrid(dataState);
      setAccounts(newData);
    })();
  }, [dataState]);

  const grid = (
    <DataTable
      filterable
      sortable
      pageable={PAGEABLE_SETTINGS}
      total={accounts.total}
      data={accounts}
      reorderable
      {...dataState}
      onDataStateChange={handleDataStateChange}
      actions={gridActions}
    >
      <Toolbar onAddNewClick={handleAddNewClick} onExportExcelClick={handleExportExcelClick} onExportPDFClick={handleExportPdfClick} />
      <GridColumn
        title="Action"
        filterable={false}
        sortable={false}
        headerCell={HeaderCell}
        cell={ActionListCell as ComponentType<GridCellProps>}
        width="80px"
      />
      <GridColumn cell={TextCell} field="name" filter="text" headerCell={HeaderCell} title="Name" headerClassName="fill-width" />
      <GridColumn cell={TextCell} field="contractorCode" filter="text" headerCell={HeaderCell} title="Contractor" width="100px" />
      <GridColumn cell={TextCell} field="quickBooksId" filter="text" headerCell={HeaderCell} title="QuickBooks" width="100px" />
      <GridColumn cell={TextCell} field="customerKey" filter="text" headerCell={HeaderCell} title="Customer" width="100px" />
    </DataTable>
  );

  return (
    <Page>
      <PageHeader title="Accounts" />
      <GridPDFExport ref={gridPdfExport}>{grid}</GridPDFExport>
      <ExcelExport ref={gridExcelExport}>{grid}</ExcelExport>
    </Page>
  );
}
