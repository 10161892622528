import { useCallback, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { NotificationsService } from 'core/notifications';
import { Button, ButtonVariants, Page, PageHeader } from 'core/ui';

import { useApiClient } from 'features/api';
import { useAccessTokenSnapshot } from 'features/auth';
import { ExamStatus } from 'features/exam/constants';
import { useNextExamId } from 'features/exam/hooks';
import { PDFViewer } from 'features/file/fragments/PDFViewer';
import { useUserSettings } from 'features/settings';

import { ExamReadingActions } from '../redux';
import { UndoAddReadMessage } from './UndoAddReadMessage';

export const Approve = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { homePageUrl } = useUserSettings(true);
  const [searchParams] = useSearchParams();
  const { accessToken } = useAccessTokenSnapshot();
  const apiClient = useApiClient();

  const groupId = searchParams.get('groupId');

  // get worklist filter
  const worklistView = localStorage.getItem('currentWorklistViewODataStr');
  const nextExamId = useNextExamId(id, ExamStatus.ALL.name, groupId, worklistView);

  const handleClose = useCallback(() => {
    navigate(`/exam/${id}/read`);
  }, [navigate, id]);

  const handleApprove = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(ExamReadingActions.approveRead(id)).then(() => {
      window.location = homePageUrl;
    });
  }, [dispatch, homePageUrl, id]);

  const handleUndoClick = useCallback(
    (undoExamId) => {
      navigate(`/exam/${undoExamId}/read?${searchParams}`);
    },
    [navigate, searchParams],
  );

  const handleApproveAndNext = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(ExamReadingActions.approveRead(id)).then(() => {
      navigate(`/exam/${nextExamId}/read`);
      NotificationsService.displaySuccess(<UndoAddReadMessage onUndo={() => handleUndoClick(id)} />, {
        autoClose: 60 * 1000, // 60s
        closeOnClick: false,
        hideProgressBar: false,
        pauseOnFocusLoss: false,
        pauseOnHover: false,
      });
    });
  }, [dispatch, id, navigate, nextExamId, handleUndoClick]);

  const [pdfUrl, setPDFUrl] = useState('');

  useEffect(() => {
    (async () => {
      if (accessToken !== null) {
        const file = apiClient.filesClient.getFinalReportPreviewURL(id, accessToken);
        setPDFUrl(file);
      }
    })();
  }, [dispatch, id, accessToken, apiClient]);

  return (
    <Page>
      <PageHeader title="Preview Report" />
      <PDFViewer url={pdfUrl} />
      <StyledButtonContainer>
        <Button variant={ButtonVariants.SECONDARY} type="button" onClick={handleClose}>
          Close
        </Button>
        <StyledSpacer />
        <Button type="button" onClick={handleApprove}>
          Approve
        </Button>
        <StyledSpacer />
        <Button type="button" onClick={handleApproveAndNext}>
          Approve & Next
        </Button>
      </StyledButtonContainer>
    </Page>
  );
};

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px 0;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
`;

const StyledSpacer = styled.div`
  width: 16px;
`;
