import { ReactNode, memo, useEffect, useRef, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { ConnectionString, ContainerName } from 'models';

import { apiClient } from 'core/api/globals';

import { AuthenticationScheme, useAuthentication } from 'features/auth';

import { UploadPipelineContext } from '../contexts';
import { UploadPipeline } from '../services/UploadPipeline';
import { UploadPipelineOptions } from '../types';

export const UploadPipelineProvider = memo<{ mode?: UploadPipelineOptions['mode']; children?: ReactNode }>(({ mode, children }) => {
  // Note: This has some very tedious logic for initialization and cleanup.  It's necessary in order to avoid losing state between HMR
  // refreshes which causes useEffect() calls to be fired every time - even if the changes are on an unrelated component.  So we have to
  // manually track whether the component is mounted and whether the initialization has already been done.

  const { activeScheme } = useAuthentication();
  const queryClient = useQueryClient();

  const uploadPipelineRef = useRef<UploadPipeline | null>(null);
  const unmountTimeoutRef = useRef<number | null>(null);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (unmountTimeoutRef.current) {
      window.clearTimeout(unmountTimeoutRef.current);
      unmountTimeoutRef.current = null;
    }

    const cleanup = () => {
      unmountTimeoutRef.current = window.setTimeout(() => {
        if (uploadPipelineRef.current) {
          uploadPipelineRef.current.destroy();
          uploadPipelineRef.current = null;
        }
      }, 1000);
    };

    if (activeScheme !== AuthenticationScheme.OIDC && activeScheme !== AuthenticationScheme.SHARE) {
      return cleanup;
    }
    if (uploadPipelineRef.current) {
      return cleanup;
    }

    uploadPipelineRef.current = new UploadPipeline();
    uploadPipelineRef.current.initialize({
      uploadSessionId: crypto.randomUUID(),
      mode,
      queryClient,
      authMode: activeScheme === AuthenticationScheme.OIDC ? 'msal-required' : 'share-required',
      getSasFn: async (connectionString: ConnectionString, containerName: ContainerName) => {
        const newSasUrl = await apiClient.filesClient.generateAzureBlobUploadSas(
          connectionString,
          containerName,
          activeScheme === AuthenticationScheme.OIDC ? 'msal-required' : 'share-required',
        );
        return newSasUrl;
      },
    });
    setIsInitialized(true);

    return cleanup;
  }, [activeScheme, mode, queryClient]);

  return !isInitialized ? null : <UploadPipelineContext.Provider value={uploadPipelineRef.current}>{children}</UploadPipelineContext.Provider>;
});

UploadPipelineProvider.displayName = 'UploadPipelineProvider';
