import { skipToken, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { PatientModel } from 'models';

import { apiClient } from 'core/api/globals';

import { AuthenticationScheme, useAssertFetchPermitted } from 'features/auth';

import { UseQueryReadWriteResult } from '../types';

export function useQueryPatient(patientId: number | null | undefined): UseQueryReadWriteResult<PatientModel> {
  const queryClient = useQueryClient();
  const activeScheme = useAssertFetchPermitted([AuthenticationScheme.OIDC, AuthenticationScheme.SHARE]);

  const patientQuery = useQuery({
    queryKey: ['patient', patientId],
    queryFn:
      patientId == null || patientId === 0 || activeScheme == null
        ? skipToken
        : () => apiClient.patientClient.getPatient(patientId, activeScheme === AuthenticationScheme.OIDC ? 'msal-required' : 'share-required'),
    staleTime: 1000 * 60 * 5,
  });

  const patientMutation = useMutation({
    mutationFn: async (patient: PatientModel) => {
      const newPatient = {
        ...patient,
      };

      if (newPatient.id === 0) {
        newPatient.id = await apiClient.patientClient.createPatient(newPatient);
      } else {
        await apiClient.patientClient.updatePatient(newPatient);
      }

      queryClient.setQueryData(['patient', newPatient.id], newPatient);

      return newPatient;
    },
  });

  return [patientQuery, patientMutation];
}
