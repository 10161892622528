import { FunctionComponent, useCallback, useEffect, useState } from 'react';

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { IntegrationModel, LocationModel } from 'models';
import { SourceModel } from 'models/SourceModel';

import { apiClient } from 'core/api/globals';
import { FieldContainer, GridColumn, RhfValidators, createStyledRhfForm } from 'core/forms';
import { useValidatedParam } from 'core/hooks';
import { NotificationsService } from 'core/notifications';
import { Button, DropdownField, InputField, Label, Page, PageHeader, SwitchField } from 'core/ui';

import { SourceEditService } from '../services';
import { EditFormValues } from '../types';

const NullLocationOption = { id: null, name: '' };
const NullIntegrationOption = { id: null, name: '' };

export const SourceForm: FunctionComponent = () => {
  const sourceId = useValidatedParam('id', 'integer', false);
  const navigate = useNavigate();
  const [source, setSource] = useState<SourceModel | null>(null);
  const [locations, setLocations] = useState<LocationModel[]>([]);
  const [integrations, setIntegrations] = useState<IntegrationModel[]>([]);

  const rhfContext = useForm<EditFormValues>({
    defaultValues: { ...SourceEditService.EditFormDefaults },
  });
  const {
    reset,
    formState: { isSubmitting },
  } = rhfContext;

  const handleSubmit: SubmitHandler<EditFormValues> = useCallback(
    async (values, event) => {
      event?.preventDefault();

      if (source == null) {
        throw new Error('Cannot proceed because source is null or undefined.');
      }

      const newSource: SourceModel = SourceEditService.copyFormToModel(source.id, values);

      if (typeof sourceId === 'number') {
        await apiClient.sourceClient.updateSource(newSource);
      } else {
        await apiClient.sourceClient.createSource(newSource);
      }

      reset(SourceEditService.copyModelToForm(newSource, locations, integrations), {
        keepValues: true,
        keepDefaultValues: false,
      });
      NotificationsService.displaySuccess(sourceId == null ? 'Source created.' : 'Source saved.');
      navigate('/source');
    },
    [source, sourceId, reset, locations, integrations, navigate],
  );

  useEffect(() => {
    (async () => {
      const [newSource, newLocations, newIntegrations] = await Promise.all([
        sourceId == null
          ? new Promise<SourceModel>((resolve) => {
              resolve(SourceEditService.createDefaultSource());
            })
          : apiClient.sourceClient.getSourceById(sourceId),
        apiClient.locationClient.getAllLocations(),
        apiClient.integrationClient.getAllIntegrations(),
      ]);

      setSource(newSource);
      setLocations(newLocations);
      setIntegrations(newIntegrations);
      reset(SourceEditService.copyModelToForm(newSource, newLocations, newIntegrations), {
        keepValues: false,
        keepDefaultValues: false,
      });
    })();
  }, [reset, sourceId]);

  if (source == null) return null;

  return (
    <Page>
      <PageHeader title={`${sourceId ? 'Edit' : 'New'} Source`} />
      <FormProvider {...rhfContext}>
        <StyledForm autoComplete="off" autoCorrect="off" autoCapitalize="none" spellCheck="false" noValidate onSubmit={rhfContext.handleSubmit(handleSubmit)}>
          <GridColumn columnStart="1" isLabelColumn>
            <Label required>Name</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <InputField name="name" required />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Description</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <InputField name="description" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Location</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <DropdownField name="location" data={locations} defaultItem={NullLocationOption} />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Integration</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <DropdownField name="integration" data={integrations} defaultItem={NullIntegrationOption} />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Host Name</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <InputField name="hostName" validator={RhfValidators.hostname} />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Host IP</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <InputField name="hostIP" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Local IP</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <InputField name="localIP" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Local IP Range</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <InputField name="localIPRange" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Port</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <InputField type="number" name="port" maxLength={5} validator={RhfValidators.port} />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Notes</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <InputField name="notes" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Sending AE</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <InputField name="sendingAE" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>DestinationAE</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <InputField name="destinationAE" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Active</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <SwitchField name="active" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="2" columnEnd="span 1">
            <div>
              <Button disabled={isSubmitting} type="submit">
                Save
              </Button>
            </div>
          </GridColumn>
        </StyledForm>
      </FormProvider>
    </Page>
  );
};

const StyledForm = createStyledRhfForm('min-content 400px');
