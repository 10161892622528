import { ComponentType, FunctionComponent, MouseEvent, memo, useCallback, useEffect, useMemo, useState } from 'react';

import { GridCellProps, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { FormGridModel } from 'models/FormGridModel';

import { apiClient } from 'core/api/globals';
import {
  ActionListCell,
  DEFAULT_DATA_TABLE_DATA_STATE,
  DEFAULT_PAGE_SIZES,
  DataTable,
  GridColumn,
  HeaderCell,
  Page,
  PageHeader,
  Toolbar,
  faPen,
} from 'core/ui';

import { useCurrentUser } from 'features/auth';

import { FormGridService } from '../services';
import { FormGridHeaderCell } from './FormGridHeaderCell';

const PageableSettings = {
  pageSizes: DEFAULT_PAGE_SIZES,
};

export const FormGrid: FunctionComponent = memo(() => {
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser(true);

  const [formsData, setFormsData] = useState<FormGridModel[]>([]);
  const [formsTotal, setFormTotal] = useState(0);
  const [dataState, setDataState] = useState(DEFAULT_DATA_TABLE_DATA_STATE);

  const handleDataStateChange = useCallback((changeEvent: GridDataStateChangeEvent) => {
    setDataState(changeEvent.dataState);
  }, []);

  const handleAddNewClick = useCallback(() => navigate('/form/add'), [navigate]);

  const handleEditClick = useCallback((dataItem: FormGridModel) => navigate(`/form/edit/${dataItem.id}`), [navigate]);

  const gridActions = useMemo(() => {
    return [
      {
        key: 'edit-form',
        title: 'Edit Form',
        icon: faPen,
        disabled: !currentUser.isSystemAdmin,
        onClick: (_: MouseEvent<HTMLButtonElement>, dataItem: FormGridModel) => handleEditClick(dataItem),
      },
    ];
  }, [handleEditClick, currentUser.isSystemAdmin]);

  const { gridColumns, filterColumns } = useMemo(() => {
    return {
      gridColumns: FormGridService.getGridColumns(),
      filterColumns: FormGridService.getColumns(),
    };
  }, []);

  useEffect(() => {
    (async () => {
      const queryResult = await apiClient.formClient.getAllFormsForKendoGrid(dataState);

      setFormsData(queryResult.data);
      setFormTotal(queryResult.total);
    })();
  }, [dataState]);

  return (
    <Page>
      <PageHeader title="Forms" />
      <StyledDataTable
        data={formsData}
        filterable
        sortable
        reorderable
        pageable={PageableSettings}
        total={formsTotal}
        onDataStateChange={handleDataStateChange}
        actions={gridActions}
        {...dataState}
      >
        <Toolbar onAddNewClick={handleAddNewClick} />
        <GridColumn
          title="Action"
          filterable={false}
          sortable={false}
          headerCell={HeaderCell}
          cell={ActionListCell as ComponentType<GridCellProps>}
          width="80px"
        />
        {gridColumns.map((column) => {
          return <GridColumn key={column.field} {...column} headerCell={FormGridHeaderCell} cell={column.cell} filter={column.columnFilter} />;
        })}
      </StyledDataTable>
    </Page>
  );
});

FormGrid.displayName = 'FormGrid';

const StyledDataTable = styled(DataTable)`
  max-height: initial;
`;
