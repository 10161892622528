import { memo, useEffect, useState } from 'react';

import { State, process } from '@progress/kendo-data-query';
import { GridColumn, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import styled from 'styled-components';

import { GatewayEventModel } from 'models';

import { apiClient } from 'core/api/globals';
import { DataResult } from 'core/api/types';
import { useEvent } from 'core/hooks';
import { Card, ComponentSizes, DEFAULT_DATA_TABLE_DATA_STATE, DataTable, DataTableVariants, DateCell, HeaderCellSecondary, Modal, TextCell } from 'core/ui';
import { DateCellProps } from 'core/ui/DataTable/components/table-cells/DateCellProps';

import { GatewayLogModalProps } from '../types';

const defaultDataState: State = {
  ...DEFAULT_DATA_TABLE_DATA_STATE,
  sort: [{ field: 'startTime', dir: 'desc' }],
};

export const GatewayLogModal = memo<GatewayLogModalProps>(({ show, entityType, suid, onClose }) => {
  const [dataState, setDataState] = useState<State>(defaultDataState);
  const [gatewayLogs, setGatewayLogs] = useState<GatewayEventModel[]>([]);
  const [dataResult, setDataResult] = useState<DataResult<GatewayEventModel[]>>();
  const [isLoading, setIsLoading] = useState(false);

  const handleDataStateChange = useEvent((e: GridDataStateChangeEvent) => {
    setDataState(e.dataState);
    setDataResult(process(gatewayLogs, e.dataState));
  });

  const refreshData = useEvent(async () => {
    if (suid && show) {
      try {
        setIsLoading(true);
        const gatewayLogs = await apiClient.gatewayEventsClient.getGatewayEvents(suid);
        setDataResult(process(gatewayLogs, dataState));
        setGatewayLogs(gatewayLogs);
      } finally {
        setIsLoading(false);
      }
    } else {
      setGatewayLogs([]);
    }
  });

  useEffect(() => {
    refreshData();
  }, [refreshData, entityType, suid, show]);

  return (
    <StyledModal show={show} onHide={onClose} title={`Gateway Log (${suid})`}>
      <StyledLegendDiv>
        <StyledCard>
          <Card.Header>Route Actions(Legend):</Card.Header>
          <Card.Body>
            <div>portal: API Request that creates or matches to existing Exam/Patient record</div>
            <div>meddream: DICOM Send that caches the pixel and structure in MedDream</div>
            <div>health-api: API Request that pushes the study into Azure Health Service</div>
            <div>dicom-send: Pushes the DICOM to the specified Destination AE, Destination Host</div>
            <div>dicom-db: Creates DICOM records in Compumed Database used by MedDream</div>
            <div>listener-download: Gateway DICOM Receive</div>
            <div>blob-upload: Send Study via blob to customer deployed Compumed Gateway</div>
          </Card.Body>
        </StyledCard>
        <StyledCard>
          <Card.Header>Current State(Legend):</Card.Header>
          <Card.Body>
            <div>New</div>
            <div>Completed</div>
            <div>Retry</div>
            <div>Faulted</div>
          </Card.Body>
        </StyledCard>
      </StyledLegendDiv>
      <StyledDataTable
        {...dataState}
        data={dataResult}
        total={dataResult?.total}
        filterable
        pageable
        reorderable
        size={ComponentSizes.SMALL}
        sortable
        variant={DataTableVariants.SECONDARY}
        onDataStateChange={handleDataStateChange}
        isLoading={isLoading}
      >
        <GridColumn cell={TextCell} field="routeAction" filterable title="Route Action" width="100px" headerCell={HeaderCellSecondary} />
        <GridColumn cell={TextCell} field="currentState" filterable title="Current State" width="100px" headerCell={HeaderCellSecondary} />
        <GridColumn cell={TextCell} field="aeTitle" filterable title="AE Title" width="100px" headerCell={HeaderCellSecondary} />
        <GridColumn cell={TextCell} field="sourceAE" filterable title="Source AE" width="100px" headerCell={HeaderCellSecondary} />
        <GridColumn cell={TextCell} field="sourceHost" filterable title="Source Host" width="100px" headerCell={HeaderCellSecondary} />
        <GridColumn cell={TextCell} field="destinationAE" filterable title="Destination AE" width="100px" headerCell={HeaderCellSecondary} />
        <GridColumn cell={TextCell} field="destinationHost" filterable title="Destination Host" width="100px" headerCell={HeaderCellSecondary} />
        <GridColumn cell={DateTimeMsCell} field="startTime" filterable={false} title="Start Time" width="200px" headerCell={HeaderCellSecondary} />
        <GridColumn cell={DateTimeMsCell} field="endTime" filterable={false} title="End Time" width="200px" headerCell={HeaderCellSecondary} />
        <GridColumn cell={TextCell} field="sopInstanceUid" filterable title="SOP Instance UID" width="100px" headerCell={HeaderCellSecondary} />
        <GridColumn cell={TextCell} field="accessionNumber" filterable title="Accession Number" width="100px" headerCell={HeaderCellSecondary} />
        <GridColumn cell={TextCell} field="path" filterable title="File path" width="100px" headerCell={HeaderCellSecondary} />
        <GridColumn cell={TextCell} field="message" filterable title="Message" width="100px" headerCell={HeaderCellSecondary} />
        <GridColumn cell={TextCell} field="errorMessage" filterable title="Error Message" width="100px" headerCell={HeaderCellSecondary} />
        <GridColumn cell={TextCell} field="stackTrace" filterable title="Stack Trace" width="100px" headerCell={HeaderCellSecondary} />
      </StyledDataTable>
    </StyledModal>
  );
});

const DateTimeMsCell = (props: DateCellProps) => <DateCell {...props} format="MM/DD/YYYY HH:mm:ss.SSS" />;

GatewayLogModal.displayName = 'ActivityLogModal';

const StyledModal = styled(Modal)`
  .modal-content {
    row-gap: 24px;
  }
`;

const StyledDataTable = styled(DataTable)`
  margin: 0;
  min-height: 150px;
  border-left: none;
  border-right: none;
  width: 100%;
`;

const StyledCard = styled(Card)`
  padding-left: 24px;
`;

const StyledLegendDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
`;
