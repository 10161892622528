import { memo, useEffect, useMemo, useState } from 'react';

import { LocationLiteModel } from 'models';

import { apiClient } from 'core/api/globals';
import { useEvent } from 'core/hooks';

import { AuthenticationScheme, useAuthentication } from 'features/auth';
import { PatientVerbiageProvider } from 'features/patient/fragments/PatientVerbiageProvider';

import { AppSessionLocationContext } from '../contexts';
import { SessionLocationService } from '../services';
import { AppSessionLocationContextValue, AppSessionLocationProviderProps } from '../types';

export const AppSessionLocationProvider = memo<AppSessionLocationProviderProps>(({ children }) => {
  const { activeScheme } = useAuthentication();

  const [sessionLocationOptions, setSessionLocationOptions] = useState<LocationLiteModel[] | null>(null);
  const [sessionLocation, setSessionLocationInternal] = useState<LocationLiteModel | null>(null);

  const initialize = useEvent(async () => {
    const newLocations =
      activeScheme === AuthenticationScheme.ANONYMOUS
        ? []
        : await apiClient.locationClient.getAllLocationsLite(activeScheme === AuthenticationScheme.OIDC ? 'msal-required' : 'share-required');

    const restoredSessionLocationId = SessionLocationService.fetchSessionLocationId();
    const restoredSessionLocation = newLocations.find((location) => location.id === restoredSessionLocationId) ?? null;
    const newSessionLocation = (restoredSessionLocation ?? newLocations.length === 1) ? newLocations[0] : null;

    // Clear the previously selected session location if it no longer exists.
    if (restoredSessionLocationId != null && restoredSessionLocation == null) {
      SessionLocationService.saveSessionLocationId(null);
    }

    setSessionLocationOptions(newLocations);
    setSessionLocationInternal(newSessionLocation);
  });

  const setSessionLocation = useEvent((newLocation: LocationLiteModel | null) => {
    SessionLocationService.saveSessionLocationId(newLocation?.id ?? null);
    setSessionLocationInternal(newLocation);
  });

  const context: AppSessionLocationContextValue = useMemo(
    () => ({
      sessionLocationOptions,
      sessionLocation,
      setSessionLocation,
    }),
    [sessionLocationOptions, sessionLocation, setSessionLocation],
  );

  useEffect(() => {
    if (activeScheme == null) return;

    initialize();
  }, [initialize, activeScheme]);

  return (
    <AppSessionLocationContext.Provider value={context}>
      <PatientVerbiageProvider mode={sessionLocation?.isOpo ? 'Donor' : 'Patient'}>{children}</PatientVerbiageProvider>
    </AppSessionLocationContext.Provider>
  );
});

AppSessionLocationProvider.displayName = 'AppSessionLocationProvider';
