import { memo, useState } from 'react';

import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';

import { useDataStream, useEvent, useEventStream } from 'core/hooks';
import { FlatIconButton, Icon, PAGE_GRID_CLASSES } from 'core/ui';
import { hasText } from 'core/utils';

import { Classification } from 'features/upload-pipeline';
import { useUploadPipeline } from 'features/upload-pipeline/hooks/useUploadPipeline';

import { useUploadExamsPageContext } from '../hooks';
import { useUploadView } from '../hooks/useUploadView';
import { Footer } from './Footer';
import { ScanProgressCard } from './ScanProgressCard';
import { SelectDicomFolderCard } from './SelectDicomFolderCard';

type SelectFilesStepProps = {
  onNext: () => void;
};

export const SelectFilesStep = memo<SelectFilesStepProps>(({ onNext }) => {
  const { uploadGroups } = useUploadExamsPageContext();
  const uploadView = useUploadView();
  const uploadPipeline = useUploadPipeline();
  const pipelineQueues = useDataStream(uploadPipeline.streams.queues);
  const [filesCount, setFilesCount] = useState(0);
  const [scanWarningMsg, setScanWarningMsg] = useState<string | null>(null);

  const handleAddDicomFolder = useEvent(async (files: File[]) => {
    if (files.length === 0) {
      return;
    }

    setScanWarningMsg(null);

    uploadPipeline.addFiles(
      files.map((f) => ({
        fileId: window.crypto.randomUUID(),
        file: f,
        classification: Classification.Dicom,
        metadata: {},
      })),
    );

    setFilesCount(files.length);
  });

  const handleCloseWarningClick = useEvent(() => {
    setScanWarningMsg(null);
  });

  useEventStream(uploadView.streams.onPhase1Complete, ({ usableDicomFilesFound }) => {
    if (usableDicomFilesFound) {
      onNext();
    } else {
      setFilesCount(0);
      setScanWarningMsg('DICOM files not detected in the folder you selected, please select another folder.');
    }
  });

  return (
    <>
      <StyledSelectFilesStepDiv>
        {hasText(scanWarningMsg) && (
          <StyledWarningDiv className="k-messagebox k-messagebox-warning">
            <StyledWarningTextDiv>{scanWarningMsg}</StyledWarningTextDiv>
            <FlatIconButton onClick={handleCloseWarningClick}>
              <Icon icon={faXmark} />
            </FlatIconButton>
          </StyledWarningDiv>
        )}

        {pipelineQueues.scanQueueLength === 0 && pipelineQueues.recordMatcherQueueLength === 0 && uploadGroups.length === 0 && (
          <SelectDicomFolderCard onAdd={handleAddDicomFolder} />
        )}
        {filesCount > 0 && <ScanProgressCard />}
      </StyledSelectFilesStepDiv>

      <Footer className={PAGE_GRID_CLASSES.Flush} />
    </>
  );
});

SelectFilesStep.displayName = 'SelectFilesStep';

const StyledSelectFilesStepDiv = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 600px);
  grid-auto-rows: min-content;
  justify-content: center;
  padding: ${({ theme }) => theme.space.spacing40} 0;
`;

const StyledWarningDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
`;

const StyledWarningTextDiv = styled.div`
  padding: ${({ theme }) => theme.space.spacing20};
`;
