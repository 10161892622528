import { memo, useCallback, useEffect, useState } from 'react';

import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import '@progress/kendo-theme-default/dist/all.css';
import { FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { TagModel } from 'models';

import { FieldContainer, GridColumn, createStyledRhfForm } from 'core/forms';
import { useEvent, useValidatedParam } from 'core/hooks';
import { Button, CheckboxField, DropdownField, Icon, InputField, Label, Page, PageHeader, RadioGroup, SwitchField } from 'core/ui';

import { useApiClient } from 'features/api';

import { TagFieldOption } from '../contants';
import { TagsEditService } from '../services';
import { EditFormValues } from '../types';

const NullParentTagOption = { id: null, name: '' };

export const TagsFieldTemplateForm = memo(() => {
  const apiClient = useApiClient();
  const tagId = useValidatedParam('id', 'integer', false);
  const [tag, setTag] = useState<TagModel | null>(null);
  const [parentTags, setParentTags] = useState<TagModel[]>([]);

  const [fieldType, setFieldType] = useState('Choice');

  const isAdminTags = true;

  const rhfContext = useForm<EditFormValues>({
    defaultValues: { ...TagsEditService.EditFormDefaults },
  });
  const {
    reset,
    formState: { isSubmitting },
  } = rhfContext;

  // Initialize the form
  const { control } = useForm({
    defaultValues: {
      children: tag ? tag.children : [],
    },
  });

  // Manage the field array
  const { remove, replace } = useFieldArray({
    control,
    name: 'children',
  });

  // handler for useFieldArray Append event
  const handleApppend = useEvent(() => {
    tag?.children.push({
      id: 0,
      name: '',
      description: '',
      tagFieldOption: null,
      active: true,
      children: [],
      parentTagId: null,
      required: false,
      multipleSelection: false,
    });
    replace(tag?.children ?? []);
  });

  // handler for useFieldArray Remove event
  const handleRemove = (index: number) => {
    tag?.children.splice(index, 1);
    remove(index);
  };

  useEffect(() => {
    (async () => {
      const [newTag, newParentTags] = await Promise.all([
        tagId == null
          ? new Promise<TagModel>((resolve) => {
              resolve(TagsEditService.createDefaultSource());
            })
          : apiClient.tagsClient.getTagsById(tagId),
        apiClient.tagsClient.getAllTags(),
      ]);

      setTag(newTag);
      setParentTags(newParentTags);

      replace(newTag?.children);

      reset(TagsEditService.copyModelToForm(newTag, newParentTags), {
        keepValues: false,
        keepDefaultValues: false,
      });
    })();
  }, [apiClient.tagsClient, replace, reset, tagId]);

  const handleSubmit: SubmitHandler<EditFormValues> = useCallback(
    async (values, event) => {
      event?.preventDefault();

      if (tag == null) {
        throw new Error('Cannot proceed because tag is null or undefined.');
      }

      const newTag = TagsEditService.copyFormToModel(tag.id, values);

      newTag.children = tag.children;

      await apiClient.tagsClient.addUpdateTags(newTag);

      reset(TagsEditService.copyModelToForm(newTag, parentTags), {
        keepValues: true,
        keepDefaultValues: false,
      });
    },
    [tag, apiClient.tagsClient, reset, parentTags],
  );

  if (tag == null) return null;

  return (
    <Page>
      <PageHeader title={`${tagId ? 'Edit' : 'New'} Tag Field Template`} />
      <FormProvider {...rhfContext}>
        <StyledForm autoComplete="off" autoCorrect="off" autoCapitalize="none" spellCheck="false" noValidate onSubmit={rhfContext.handleSubmit(handleSubmit)}>
          <GridColumn columnStart="1" isLabelColumn>
            <Label>Tag Name</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <StyledInput name="name" placeholder="Tag Name" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Description</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <StyledInput name="description" placeholder="Description" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Parent Tag Name</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <DropdownField
                name="parentTag"
                data={parentTags}
                textField="name"
                valueField="id"
                dataItemKey="id"
                defaultItem={NullParentTagOption}
                style={{ marginBottom: '10px' }}
              />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Tag Field Option</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <RadioGroup
                name="tagFieldOption"
                data={TagFieldOption}
                dataItemKey="value"
                textField="text"
                value={fieldType}
                onChange={(e) => setFieldType(e.value)}
              />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn></GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <StyledCheckboxContainer>
                <CheckboxField name="required" />
                <StyledCheckboxLabel>Required</StyledCheckboxLabel>
              </StyledCheckboxContainer>

              <StyledCheckboxContainerMultipleSelection>
                <CheckboxField name="multipleSelection" />
                <StyledCheckboxLabel>Multiple Selection</StyledCheckboxLabel>
              </StyledCheckboxContainerMultipleSelection>
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Add options below to display</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 3">
            <FieldContainer>
              {tag.children.map((field, index) => (
                <div key={field.id}>
                  <StyledLineItemContainer>
                    <StyledLine>
                      <InputField
                        name={`tag.children[${index}]`}
                        key={`children.${index}.value`}
                        defaultValue={field.name}
                        onChange={(e) => {
                          const newChildren = [...tag.children];
                          newChildren[index].name = e.target.value;
                          setTag({ ...tag, children: newChildren });
                        }}
                      />
                      <StyledCloseButton>
                        <Button type="button" onClick={() => handleRemove(index)}>
                          <Icon icon={faCircleXmark} />
                        </Button>
                      </StyledCloseButton>
                    </StyledLine>
                  </StyledLineItemContainer>
                  <span style={{ marginLeft: '10px' }} />
                </div>
              ))}
              <StyledButtonContainer>
                <Button type="button" onClick={handleApppend}>
                  Append Item
                </Button>
              </StyledButtonContainer>

              <span style={{ marginLeft: '10px' }} />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Active</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <SwitchField name="active" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn></GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <Button themeColor="primary" disabled={isSubmitting} type="submit" style={{ marginTop: '20px' }}>
                Save
              </Button>
            </FieldContainer>
          </GridColumn>
        </StyledForm>
      </FormProvider>
    </Page>
  );
});

TagsFieldTemplateForm.displayName = 'TagsFieldTemplateForm';

const StyledForm = createStyledRhfForm('min-content 400px');

const StyledLine = styled.div`
  display: flex;
`;

const StyledCloseButton = styled.button`
  all: unset;
  color: ${({ theme }) => theme.colors.primary};
  padding: 0 7px;
`;

const StyledLineItemContainer = styled.div`
  display: grid;
  row-gap: 6px;
`;

const StyledButtonContainer = styled.div`
  padding: 6px 0 0 0;
`;

const StyledInput = styled(InputField)`
  margin-bottom: 10px;
`;

const StyledCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const StyledCheckboxContainerMultipleSelection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const StyledCheckboxLabel = styled.span`
  margin-left: 10px;
`;
