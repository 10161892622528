import { FunctionComponent, useEffect, useState } from 'react';

import { Dialog } from '@progress/kendo-react-dialogs';
import { compare } from 'fast-json-patch';
import { Row } from 'react-bootstrap';
import styled from 'styled-components';

import { FileModel } from 'models';
import { DonorFileModel } from 'models/FileModel';

import { useApiClient } from 'features/api';
import { DocumentCategories } from 'features/exam/constants';
import { useExamFiles } from 'features/exam/hooks';
import { FileSelectSidebar, FileViewer } from 'features/file';
import { FileTypeHelper } from 'features/file/services/FileTypeHelper';

import { PatientFileModalFeature, PatientFileViewModalProps } from '../types/PatientFileViewModalProps';

export const PatientFileViewModal: FunctionComponent<PatientFileViewModalProps> = ({
  examId,
  examStatus,
  toggleDialog,
  visible,
  isOPO,
  showEditBtn,
  showDeleteBtn,
  feature,
  handleQrButtonClick,
  handleQrModalClose,
  isQrModalVisible,
  linkId,
}) => {
  const { files, currentFile, setCurrentFile } = useExamFiles(examId, examStatus) as {
    files: FileModel[];
    currentFile: FileModel | null;
    setCurrentFile: React.Dispatch<React.SetStateAction<FileModel | null>>;
  };
  const [filesFiltered, setFilesFiltered] = useState<FileModel[]>([]);

  useEffect(() => {
    const filesFiltered = files.filter((file: FileModel) =>
      feature === PatientFileModalFeature.IMAGES
        ? FileTypeHelper.getUppercaseFileType(file) === 'LINK' || file.categoryId === DocumentCategories.EXAM.value
        : FileTypeHelper.fileTypeMatches(file, ['PDF', 'PNG', 'JPG']),
    );
    setFilesFiltered(filesFiltered);
    if (filesFiltered.length > 0) {
      setCurrentFile(filesFiltered[0]);
    } else {
      setCurrentFile(null);
    }
  }, [files, feature, setCurrentFile]);

  const apiClient = useApiClient();

  // cast the value from any to FileModel
  const currentFileModel = currentFile as unknown as FileModel;

  const createDonorFilesObj = (source: FileModel | Partial<FileModel> | DonorFileModel) =>
    ({
      id: source.id,
      categoryId: source.categoryId,
      created: source.created,
      examId: source.examId,
      file: source.file,
      fileName: source.fileName,
      fileType: source.fileType,
      location: source.location,
      modified: source.modified,
      thumbnailUrl: source.thumbnailUrl,
      url: source.url,
      user: source.user,
      thumbnailLocation: source.thumbnailLocation,
      patient_id: source.patient_id,
      index: source.index,
      fileId: source.fileId,
      fileSize: source.fileSize,
      externalSource: source.externalSource,
      source: source.source,
    }) as unknown as DonorFileModel;

  const onClickYesBtn = async (data: any) => {
    // create new model
    const newFile: FileModel = {
      id: currentFileModel.id,
      categoryId: currentFileModel.categoryId,
      created: currentFileModel.created,
      examId: currentFileModel.examId,
      file: currentFileModel.file,
      fileName: data,
      fileType: currentFileModel.fileType,
      location: currentFileModel.location,
      modified: currentFileModel.modified,
      thumbnailUrl: currentFileModel.thumbnailUrl,
      url: currentFileModel.url,
      user: currentFileModel.user,
      thumbnailLocation: currentFileModel.thumbnailLocation,
      patient_id: currentFileModel.patient_id,
      index: currentFileModel.index,
      fileId: currentFileModel.fileId,
      fileSize: currentFileModel.fileSize,
      externalSource: currentFileModel.externalSource,
      source: currentFileModel.source,
      viewerId: currentFileModel.viewerId,
      subFiles: [],
    };

    // compare two file operation
    const patientFileDiff = compare(createDonorFilesObj(currentFileModel), newFile);

    try {
      // update the file name
      await apiClient.filesClient.renameFile(currentFileModel.id, patientFileDiff);
    } catch (error) {
      console.log(error);
    }
  };

  const patientDialect = isOPO ? 'Donor' : 'Patient';
  const title = feature === PatientFileModalFeature.IMAGES ? `${patientDialect} Images` : `${patientDialect} Attachments`;

  return (
    <>
      {visible && (
        <Dialog title={title} onClose={toggleDialog} width="80%" height="100%">
          <StyledContainer>
            {currentFile ? <FileViewer file={currentFile} overrideMultiMonitor /> : <div>No Attachments</div>}

            <FileSelectSidebar
              files={filesFiltered}
              handleFileSelect={(file) => setCurrentFile(file)}
              handleAddNewFileClick={() => {}}
              selectedFile={currentFile}
              onClickYesBtn={onClickYesBtn}
              showDeleteBtn={showDeleteBtn}
              showEditBtn={showEditBtn}
              examId={examId?.toString()}
              hideUploadButton={feature === PatientFileModalFeature.IMAGES}
              handleQRButtonClick={
                handleQrButtonClick
                  ? () => {
                      handleQrButtonClick(undefined);
                    }
                  : undefined
              }
            />
          </StyledContainer>
        </Dialog>
      )}
    </>
  );
};

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: auto 170px;
  grid-template-rows: 1fr;
  justify-items: center;

  height: 100%;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
`;

const StyledRow = styled(Row)`
  .col:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.space.spacing20};
  }

  label.k-label {
    margin-bottom: 0;
  }
`;
