import { memo } from 'react';

import { Route, Routes } from 'react-router-dom';

import { RouteGuard, UserRoles } from 'features/auth';
import { UploadPipelineProvider } from 'features/upload-pipeline/fragments/UploadPipelineProvider';

import { UploadExamsRoutes } from '../routes';
import { UploadExamsInit } from './UploadExamsInit';
import { UploadExamsPage } from './UploadExamsPage';
import { UploadViewProvider } from './UploadViewProvider';

const UPLOAD_EXAMS_ROLES = [UserRoles.ADMIN, UserRoles.CUSTOMER, UserRoles.CUSTOMER_ADMIN, UserRoles.SUPPORT];

export const UploadExams = memo(() => {
  return (
    <Routes>
      <Route
        path={UploadExamsRoutes.UPLOAD_EXAMS}
        element={
          <RouteGuard allowedRoles={UPLOAD_EXAMS_ROLES} initComponent={UploadExamsInit}>
            <UploadPipelineProvider mode="interactive">
              <UploadViewProvider>
                <UploadExamsPage />
              </UploadViewProvider>
            </UploadPipelineProvider>
          </RouteGuard>
        }
      />
      <Route
        path={UploadExamsRoutes.UPLOAD_EXAMS_FOR_PATIENT}
        element={
          <RouteGuard allowedRoles={UPLOAD_EXAMS_ROLES} initComponent={UploadExamsInit}>
            <UploadPipelineProvider mode="interactive">
              <UploadViewProvider>
                <UploadExamsPage />
              </UploadViewProvider>
            </UploadPipelineProvider>
          </RouteGuard>
        }
      />
      <Route
        path={UploadExamsRoutes.UPLOAD_EXAMS_FOR_EXAM}
        element={
          <RouteGuard allowedRoles={UPLOAD_EXAMS_ROLES} initComponent={UploadExamsInit}>
            <UploadPipelineProvider mode="interactive">
              <UploadViewProvider>
                <UploadExamsPage />
              </UploadViewProvider>
            </UploadPipelineProvider>
          </RouteGuard>
        }
      />
    </Routes>
  );
});

UploadExams.displayName = 'UploadExams';
