import { DataStream, IDataStreamConsumer } from './DataStream';

/** Simple way to subscribe to a stateful value and future updates. */
export interface IDataStreamMapConsumer<TKey extends string | number, TValue> {
  getCurrentValue(key: TKey, assertNotFound?: true): TValue;
  getCurrentValue(key: TKey, assertNotFound: boolean): TValue | undefined;
  getStreamConsumer(key: TKey): IDataStreamConsumer<TValue>;
}

export class DataStreamMap<TKey extends string | number, TValue> implements IDataStreamMapConsumer<TKey, TValue> {
  private _streamsMap = new Map<TKey, DataStream<TValue>>();

  constructor() {
    this.getCurrentValue = this.getCurrentValue.bind(this);
    this.emit = this.emit.bind(this);
    this.emitMultiple = this.emitMultiple.bind(this);
    this.remove = this.remove.bind(this);
    this.clear = this.clear.bind(this);
    this.getStreamConsumer = this.getStreamConsumer.bind(this);
    this.getEntries = this.getEntries.bind(this);
  }

  public getCurrentValue(key: TKey, assertNotFound?: true): TValue;
  public getCurrentValue(key: TKey, assertNotFound: boolean): TValue | undefined;
  public getCurrentValue(key: TKey, assertNotFound = true): TValue | undefined {
    const stream = this._streamsMap.get(key);

    if (stream == null && assertNotFound) {
      throw new Error(`Could not find DataStream instance for key: ${key}.`);
    }

    return stream?.getCurrentValue?.();
  }

  public emit(key: TKey, newValue: TValue) {
    const stream = this._streamsMap.get(key);

    if (stream == null) {
      this._streamsMap.set(key, new DataStream<TValue>(newValue));
    } else {
      stream.emit(newValue);
    }
  }

  public remove(keys: TKey | TKey[]) {
    for (const key of Array.isArray(keys) ? keys : [keys]) {
      const stream = this._streamsMap.get(key);

      if (stream == null) return;

      stream.clear();
      this._streamsMap.delete(key);
    }
  }

  public emitMultiple(newValues: Map<TKey, TValue>) {
    for (const [key, value] of newValues.entries()) {
      this.emit(key, value);
    }
  }

  public clear(executor?: (key: TKey, value: TValue) => void) {
    for (const [key, stream] of this._streamsMap.entries()) {
      executor?.(key, stream.getCurrentValue());
      stream.clear();
    }

    this._streamsMap.clear();
  }

  public getStreamConsumer(key: TKey): IDataStreamConsumer<TValue> {
    const stream = this._streamsMap.get(key);

    if (stream == null) throw new Error(`Could not find DataStream instance for key: ${key}.`);

    return stream;
  }

  public getEntries() {
    return this._streamsMap.entries();
  }
}
