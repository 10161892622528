import { DataSourceRequestState, toDataSourceRequestString } from '@progress/kendo-data-query';

import { TagModel, TagsGridModel } from 'models';

import { ApiRouteService } from '../services';
import { DataResult } from '../types';
import { HttpClient } from './HttpClient';

export class TagsClient {
  constructor(private httpClient: HttpClient) {
    this.addUpdateTags = this.addUpdateTags.bind(this);
    this.delete = this.delete.bind(this);
    this.getAllTagsForKendoGrid = this.getAllTagsForKendoGrid.bind(this);
    this.getAllTags = this.getAllTags.bind(this);
    this.getLevelTags = this.getLevelTags.bind(this);
    this.getTagsById = this.getTagsById.bind(this);
    this.getTagsByParentId = this.getTagsByParentId.bind(this);
  }

  public async addUpdateTags(tag: TagModel) {
    const options = await this.httpClient.createStandardOptions('POST', tag);
    const result = await this.httpClient.fetchAndParse<TagModel>(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/Tags`, options));
    return result;
  }

  public async delete(id: number) {
    const options = await this.httpClient.createStandardOptions('DELETE', null);
    const result = await this.httpClient.fetch(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/Tags/DeleteTags/${id}`, options));
    return result;
  }

  public async getAllTagsForKendoGrid(dataState: DataSourceRequestState) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const queryStr = toDataSourceRequestString(dataState);
    const tags = await this.httpClient.fetchAndParse<DataResult<TagsGridModel>>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/Tags/tags-grid?${queryStr}`, options),
    );
    return tags;
  }

  public async getAllTags() {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const tags = await this.httpClient.fetchAndParse<TagModel[]>(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/Tags/tags-all`, options));
    return tags;
  }

  public async getAllAdminTags() {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const tags = await this.httpClient.fetchAndParse<TagModel[]>(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/Tags/tags-all-admin`, options));
    return tags;
  }

  public async getLevelTags() {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const tags = await this.httpClient.fetchAndParse<TagModel[]>(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/Tags/tags-levels`, options));
    return tags;
  }

  public async getTagsById(id: number) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const tags = await this.httpClient.fetchAndParse<TagModel>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/Tags/${encodeURIComponent(id)}`, options),
    );
    return tags;
  }

  public async getTagsByParentId(parentId: number) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const tags = await this.httpClient.fetchAndParse<TagModel[]>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/Tags/GetTagsByParentId/${parentId}`, options),
    );
    return tags;
  }
}
