import { useContext, useEffect } from 'react';

import { AppSessionLocationSelectModalContext } from '../contexts';
import { useSessionLocation } from './useSessionLocation';

/** Gives ability to open a modal dialog to force the user to select a session location.  This is intended to be used in *Init components.
 * @param show - Whether or not to show the modal dialog.  Note that this will only show the modal if the user has access to multiple locations.  The modal is delayed until the session location options are loaded.  It is therefore safe to specify `true` before the session location options are loaded and assume that the appropriate logic to automatically pre-select a location has been attempted before showing a disruptive modal dialog.
 * @returns `true` if the modal dialog is rendered or about to be rendered.  There is a slight delay because the modal is activated internally by a `useEffect` hook.  The return value is intended to be used as an indicator that we are currently waiting for the user to select a location.
 */
export function useSessionLocationSelectModal(show: boolean) {
  const { sessionLocation, sessionLocationOptions } = useSessionLocation();
  const modalContext = useContext(AppSessionLocationSelectModalContext);

  if (modalContext == null) {
    throw new Error('useSessionLocationSelectPrompt must be used within an AppSessionLocationSelectModalProvider.');
  }

  const { showSessionLocationModalPrompt, closeSessionLocationModalPrompt } = modalContext;

  // Wait for the session location options to be loaded before showing the modal.  This is required because the majority of the
  // time we can automatically select a location for the user because they will only have access to a single location.
  const shouldShowModal = show && sessionLocationOptions != null && sessionLocation == null;

  useEffect(() => {
    if (shouldShowModal) {
      showSessionLocationModalPrompt();
    } else {
      closeSessionLocationModalPrompt();
    }
  }, [closeSessionLocationModalPrompt, shouldShowModal, showSessionLocationModalPrompt]);

  return shouldShowModal;
}
