import { useCallback, useEffect, useMemo, useState } from 'react';

import { SvgIcon } from '@progress/kendo-react-common';
import { Grid } from '@progress/kendo-react-grid';
import { homeIcon } from '@progress/kendo-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { useWindowSize } from 'core/hooks';
import { NotificationsService } from 'core/notifications';
import { Breadcrumb, Card, CollapsibleSidebar, TabButton, TabContainer, TabsContainer, useBreakpoints } from 'core/ui';

import { useApiClient } from 'features/api';
import { useAccessTokenSnapshot } from 'features/auth/hooks/useAccessTokenSnapshot';
import { ExamActions, ExamSelectors } from 'features/exam';
import { FileSelectSidebar, FileViewer } from 'features/file';
import { PatientFiles } from 'features/patient/fragments/PatientFiles';
import { PatientGridService } from 'features/patient/services';
import { SettingsHelpers, WellKnownSettingName, useUserSettings } from 'features/settings';

import { ExamStatus } from '../../exam/constants';
import { useExamFiles, useNextExamId } from '../../exam/hooks';
import { ExamReadSubmitType } from '../constants';
import { ExamReadingActions } from '../redux';
import { ExamReadsDataService } from '../services';
import { ExamReadSidebarBody } from './ExamReadSidebarBody';
import { UndoAddReadMessage } from './UndoAddReadMessage';

const MOBILE_WIDTH_BREAKPOINT = 768;

export const ExamRead = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { homePageUrl, fullScreenReading } = useUserSettings();

  const { userSettings } = useUserSettings(false);
  const apiClient = useApiClient();

  // Destructure the state to get value and setter
  const [adminTags, setAdminTags] = useState(null);

  // Fetch admin tags on component mount
  useEffect(() => {
    const fetchAdminTags = async () => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        const tags = await apiClient.tagsClient.getAllAdminTags();
        setAdminTags(tags);
      } catch (error) {
        console.error('Failed to load admin tags:', error);
      }
    };

    fetchAdminTags();
  }, []);

  const exam = useSelector(ExamSelectors.getById(id));
  const previousExamId = useSelector(ExamSelectors.getPreviousExamId);

  const [sidebarExpanded, setSidebarExpanded] = useState(true);
  const [activeTab, setActiveTab] = useState('fileViewer');
  const [gridData, setGridData] = useState([]);

  const examFilter = searchParams.get('quickFilter') || ExamStatus.ALL.name;
  const groupId = searchParams.get('groupId');

  // get the current exam data state
  const worklistView = localStorage.getItem('currentWorklistViewODataStr');

  // get the next exam id
  const nextExamId = useNextExamId(id, examFilter, groupId, worklistView);
  const { width } = useWindowSize();
  const [popupWindows] = useState(null);

  const { accessToken } = useAccessTokenSnapshot();

  const { setVerticalClampOverride } = useBreakpoints();

  // Check if the showFilesTab setting is enabled
  const showFilesTab = SettingsHelpers.findSettingByName(userSettings ?? [], WellKnownSettingName.ShowFilesTab, false)?.value === 'true';
  const exams = useSelector(ExamSelectors.getAllGrid);

  useEffect(() => {
    setVerticalClampOverride(true);

    return () => {
      setVerticalClampOverride(false);
    };
  }, [setVerticalClampOverride]);

  // eslint-disable-next-line @typescript-eslint/no-deprecated
  const { files, currentFile, setCurrentFile } = useExamFiles(id, exam?.statusType?.name, accessToken);

  // Set gridData for the files tab when exam data is available
  useEffect(() => {
    console.log('grid', exam, showFilesTab, exam?.patient_Id, gridData);
    if (exam && showFilesTab && exam.patient_Id && gridData.length === 0) {
      console.log('fetch grid', exam, showFilesTab, exam?.patient_Id, gridData);

      console.log('fetch grid exams', exams);

      // Get patient exams associated with the same patient

      const isOpo = exam?.patient?.locationType === 'OPO';
      const newGridData = PatientGridService.initializePatientGrid(exams, isOpo, exam?.patient);
      setGridData(newGridData);
      console.log('gridData', newGridData);
    }
  }, [exam, showFilesTab, dispatch, gridData, exams]);

  const breadcrumbs = useMemo(
    () => [
      {
        id: 'home',
        text: 'Home',
        icon: <StyledIcon icon={homeIcon} />,
        navigateToExternal: homePageUrl,
      },
    ],
    [homePageUrl],
  );

  const handleBreadcrumbClick = useCallback(
    (event) => {
      const selectedItem = breadcrumbs.find((item) => item.id === event.id);

      if (selectedItem.navigateTo) {
        navigate(selectedItem.navigateTo);

        return;
      }

      window.location = selectedItem.navigateToExternal;
    },
    [navigate, breadcrumbs],
  );

  const closeWindows = useCallback(() => {
    if (popupWindows) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      popupWindows.forEach((window) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        window.close();
      });
    }
  }, [popupWindows]);

  const handleNextExamClick = useCallback(() => {
    closeWindows();
    dispatch(ExamActions.pushRecentlyViewedExam(id));
    navigate(`/exam/${nextExamId}/read?${searchParams}`);
  }, [closeWindows, dispatch, id, navigate, nextExamId, searchParams]);

  const handlePrevExamClick = () => {
    closeWindows();
    dispatch(ExamActions.popRecentlyViewedExam());
    navigate(`/exam/${previousExamId}/read?${searchParams}`);
  };

  const handleUndoClick = useCallback(
    (undoExamId) => {
      navigate(`/exam/${undoExamId}/read?${searchParams}`);
    },
    [navigate, searchParams],
  );

  const handleAddRead = useCallback(
    ({ values, event }) => {
      // TODO: show preview for For non alivecor, non-corrections radiology?

      // Ensure event and event.nativeEvent exist before accessing submitter
      if (!event?.nativeEvent?.submitter) {
        console.error('Event or submitter is undefined');
        return;
      }

      const { examData, readData } = ExamReadsDataService.getSubmitData({
        id,
        ...values,
      });

      console.log('examData', examData);
      console.log('readData', readData);

      const submitType = event.nativeEvent.submitter.id;

      if (submitType === ExamReadSubmitType.SAVE || submitType === ExamReadSubmitType.FINALIZE) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        dispatch(ExamReadingActions.saveRead({ ...examData, dynamicForms: readData })).then(() => {
          if (submitType === ExamReadSubmitType.SAVE) {
            NotificationsService.displaySuccess('Saved.');
          } else if (submitType === ExamReadSubmitType.FINALIZE) {
            navigate(`/exam/${id}/read/approve`);
          }
        });
      } else if (submitType === ExamReadSubmitType.APPROVE || submitType === ExamReadSubmitType.APPROVE_AND_NEXT) {
        // // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        dispatch(
          ExamReadingActions.addRead({
            ...examData,
            dynamicForms: readData,
          }),
        ).then(() => {
          if (submitType === ExamReadSubmitType.APPROVE) {
            window.location = homePageUrl;
          } else {
            NotificationsService.displaySuccess(<UndoAddReadMessage onUndo={() => handleUndoClick(id)} />, {
              autoClose: 60 * 1000, // 60s
              closeOnClick: false,
              hideProgressBar: false,
              pauseOnFocusLoss: false,
              pauseOnHover: false,
            });
            handleNextExamClick();
          }
        });
      } else {
        throw new Error('TODO: Not implemented.');
      }
    },
    [dispatch, handleNextExamClick, handleUndoClick, id, navigate, homePageUrl],
  );

  useEffect(() => {
    dispatch(ExamActions.getByIdAndLock(id));
  }, [dispatch, id]);

  // Handle tab switching
  const handleFileViewerTabClick = useCallback(() => {
    setActiveTab('fileViewer');
  }, []);

  const handleFilesTabClick = useCallback(() => {
    setActiveTab('files');
  }, []);

  return (
    <CollapsibleSidebar
      header={<Breadcrumb data={breadcrumbs} onItemSelect={handleBreadcrumbClick} onKeyDown={handleBreadcrumbClick} />}
      body={
        <ExamReadSidebarBody
          exam={exam}
          handleNextExamClick={nextExamId && nextExamId !== '0' ? handleNextExamClick : undefined}
          handlePrevExamClick={previousExamId ? handlePrevExamClick : undefined}
          handleSubmit={handleAddRead}
          adminTags={adminTags}
        />
      }
      expanded={sidebarExpanded}
      onExpandChange={setSidebarExpanded}
      width={
        fullScreenReading
          ? window.innerWidth - 120 // full screen by user setting
          : 400
      }
    >
      {/* <StyledMainContainer> */}
      {showFilesTab && (
        <StyledTabsContainer>
          <TabButton type="button" selected={activeTab === 'fileViewer'} roundedCorners="left" onClick={handleFileViewerTabClick}>
            Viewer
          </TabButton>
          <TabButton type="button" selected={activeTab === 'files'} roundedCorners="right" onClick={handleFilesTabClick}>
            Files
          </TabButton>
        </StyledTabsContainer>
      )}

      <StyledDivContent showFilesTab={showFilesTab}>
        {activeTab === 'fileViewer' && currentFile && <FileViewer file={currentFile} />}
        {activeTab === 'files' && exam?.patient_Id && (
          <Card>
            <PatientFiles patientId={Number(exam.patient_Id)} gridData={gridData} />
          </Card>
        )}
      </StyledDivContent>

      {files?.length > 1 && activeTab === 'fileViewer' && (
        <FileSelectSidebar
          defaultCollapsed={width < MOBILE_WIDTH_BREAKPOINT}
          files={files}
          handleFileSelect={(file) => setCurrentFile(file)}
          selectedFile={currentFile}
          accessToken={accessToken}
          examId={id}
        />
      )}
      {/* </StyledMainContainer> */}
    </CollapsibleSidebar>
  );
};

ExamRead.displayName = 'ExamRead';

const StyledMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const StyledDivContent = styled.div`
  flex: 1 1 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  overflow: hidden;
  contain: strict;
  margin-top: ${({ showFilesTab }) => (showFilesTab ? '40px' : '0')};
`;

const StyledIcon = styled(SvgIcon)`
  color: ${({ theme }) => theme.colors.primary};
`;

const StyledTabsContainer = styled.div`
  padding: ${({ theme }) => theme.space.spacing20} 0;
  justify-content: left !important;
  position: absolute;
`;
