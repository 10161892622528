import { skipToken, useQuery } from '@tanstack/react-query';

import { apiClient } from 'core/api/globals';

import { AuthenticationScheme, useAssertFetchPermitted } from 'features/auth';

export function useQueryServicesByLocation(locationId: number | null | undefined) {
  const activeScheme = useAssertFetchPermitted([AuthenticationScheme.OIDC]);

  return useQuery({
    queryKey: ['services', 'location', locationId],
    queryFn:
      locationId == null || locationId === 0 || activeScheme == null
        ? skipToken
        : () => (locationId == null ? Promise.resolve([]) : apiClient.servicesClient.getServicesByLocation(locationId)),
    staleTime: 1000 * 60 * 5,
  });
}
