import { memo } from 'react';

import styled, { ExecutionContext } from 'styled-components';

import { StatusTextProps } from './StatusTextProps';

export const StatusText = memo<StatusTextProps>(({ className, status, children }) => {
  return (
    <StyledSpan className={className} $status={status}>
      {children}
    </StyledSpan>
  );
});

StatusText.displayName = 'StatusText';

function resolveColor({ theme, $status }: ExecutionContext & { $status: StatusTextProps['status'] }) {
  switch ($status) {
    case 'success':
      return theme.colors.palette.greens[2];
    case 'warning':
      return theme.colors.palette.yellows[6];
    case 'error':
      return theme.colors.palette.reds[4];
    case 'neutral':
      return theme.colors.textPrimary;
    default:
      return theme.colors.textPrimary;
  }
}

const StyledSpan = styled.span<{ $status: StatusTextProps['status'] }>`
  color: ${resolveColor};
`;
