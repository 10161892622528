import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import { Col, Row } from 'react-bootstrap';

import { PatientModel } from 'models';

import { apiClient } from 'core/api/globals';
import { Field, Form, FormElement, FormStates, FormValidatorsService } from 'core/forms';
import { DropdownWithValuesField, Input, MASKED_DATETIME_INPUT_MODES, MaskedDateTimeInput, NumericInput } from 'core/ui';
import { hasText } from 'core/utils';

import { useUserSettings } from 'features/settings';
import { SettingsHelpers } from 'features/settings/services/settings-helpers';
import { WellKnownSettingName } from 'features/settings/types';

import { PatientGenders } from '../constants';
import { PatientInitialValueService } from '../services';
import { validatePatient } from '../utils';

interface PatientFormProps {
  formState: FormStates;
  setFormState: (state: FormStates) => void;
  patient: PatientModel | null;
  searchText: string;
  setCurrentPatient: (patient: PatientModel) => void;
  setSearchText: (text: string) => void;
  locationId: number | null;
}

export const PatientForm: FC<PatientFormProps> = ({ formState, setFormState, patient, searchText, setCurrentPatient, setSearchText, locationId }) => {
  const { userSettings } = useUserSettings();

  const [patientIdRequired, setPatientIdRequired] = useState(
    SettingsHelpers.findSettingByName(userSettings ?? [], WellKnownSettingName.PatientIdRequired, false)?.value === 'true',
  );
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});
  const [formTouched, setFormTouched] = useState(false);

  // get the settings from location related to account
  useEffect(() => {
    apiClient.settingsClient.getSettings(true, null, null, locationId).then((res) => {
      const isRequired = res?.find((item) => item.name === WellKnownSettingName.PatientIdRequired)?.value === 'true';
      setPatientIdRequired(isRequired);
    });
  }, [locationId]);

  // Validate patient and set validation errors
  const validateAndSetErrors = (patientData: PatientModel | null) => {
    if (!patientData) return false;

    const errors: Record<string, string> = {};

    if (!hasText(patientData.firstName)) {
      errors.firstName = 'First name is required';
    }

    if (!hasText(patientData.lastName)) {
      errors.lastName = 'Last name is required';
    }

    if (patientIdRequired && !hasText(patientData.patientNumber)) {
      errors.patientNumber = 'Patient ID is required';
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Check patient validity and set form state to edit mode if invalid
  useEffect(() => {
    if (patient) {
      const isValidPatient = validatePatient(patient, patientIdRequired);
      if (!isValidPatient) {
        setFormState(FormStates.EDIT);
        validateAndSetErrors(patient);
        setFormTouched(true); // Set form as touched to display validation errors
      }
    }
  }, [patient, formState, setFormState, patientIdRequired]);

  const initialState = useMemo(() => patient || PatientInitialValueService.getInitialValues(), [patient]);

  const isFormDisabled = formState === FormStates.DISABLED;
  const isFormInEditMode = formState === FormStates.EDIT;
  const isFormInAddMode = formState === FormStates.ADD;

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target?.value);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = async (values: Record<string, any>, event?: React.SyntheticEvent) => {
    const patientValues = values as PatientModel;

    // Validate patient before submitting
    const isValid = validateAndSetErrors(patientValues);
    if (!isValid) {
      setFormTouched(true);
      return; // Prevent submission if invalid
    }

    if (isFormInEditMode) {
      try {
        await apiClient.patientClient.updatePatient(patientValues);
        setCurrentPatient(patientValues);
        setFormState(FormStates.DISABLED);
      } catch (error) {
        console.error('Error updating patient:', error);
      }
    } else if (isFormInAddMode) {
      try {
        const newPatient = { ...patientValues, location_Id: locationId };
        const newPatientId = await apiClient.patientClient.createPatient(newPatient);
        // Fetch the newly created patient to get complete data
        const createdPatient = await apiClient.patientClient.getPatient(newPatientId);
        setCurrentPatient(createdPatient);
        setFormState(FormStates.DISABLED);
      } catch (error) {
        console.error('Error creating patient:', error);
      }
    }
  };

  const handleSubmitClick = (data: { isValid: boolean }) => {
    if (isFormDisabled) {
      setFormState(FormStates.EDIT);
    } else if (data.isValid) {
      // Form will be validated in handleSubmit
      setFormTouched(true);
    }
  };

  return (
    <>
      <Input label="Search" name="search" onChange={handleSearchChange} placeholder="Enter first and last name or Patient ID" value={searchText} valid />
      {(patient || isFormInAddMode) && (
        <>
          <Form
            id="patient-form"
            initialValues={initialState}
            key={JSON.stringify(initialState)}
            onSubmit={handleSubmit}
            onSubmitClick={handleSubmitClick}
            render={({ onChange: setValue }) => {
              const handleDOBChange = (event: { value: Date }) => {
                setValue('age', {
                  value: dayjs().diff(dayjs(event.value), 'year'),
                });
              };

              const handleFieldBlur = () => {
                setFormTouched(true);
              };

              return (
                <FormElement>
                  <Field component={Input} name="patientNumber" type="hidden" />
                  <Row className="mb-2 mt-3">
                    <Col className="col-6">
                      <Field
                        component={Input}
                        disabled={isFormDisabled}
                        label="First Name"
                        name="firstName"
                        required
                        validator={FormValidatorsService.required}
                        valid={!(formTouched && validationErrors.firstName)}
                        validationMessage={formTouched ? validationErrors.firstName : ''}
                        onBlur={handleFieldBlur}
                      />
                    </Col>
                    <Col className="col-6">
                      <Field
                        component={Input}
                        disabled={isFormDisabled}
                        label="Last Name"
                        name="lastName"
                        required
                        validator={FormValidatorsService.required}
                        valid={!(formTouched && validationErrors.lastName)}
                        validationMessage={formTouched ? validationErrors.lastName : ''}
                        onBlur={handleFieldBlur}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2 no-gutters">
                    <Col className="col-4">
                      <Field
                        component={MaskedDateTimeInput}
                        disabled={isFormDisabled}
                        label="DOB"
                        mode={MASKED_DATETIME_INPUT_MODES.DATE}
                        name="dob"
                        onChange={handleDOBChange}
                      />
                    </Col>
                    <Col className="col-3">
                      <Field component={NumericInput} disabled={isFormDisabled} label="Age" name="age" />
                    </Col>
                    <Col className="col-5">
                      <Field
                        component={DropdownWithValuesField}
                        data={PatientGenders}
                        dataItemKey="value"
                        disabled={isFormDisabled}
                        isForPrimitiveValues
                        label="Gender"
                        name="gender"
                        valueField="value"
                      />
                    </Col>
                  </Row>

                  <Row className="mb-2 no-gutters">
                    <Col className="col-5">
                      <Field
                        component={Input}
                        disabled={isFormDisabled}
                        label="MRN/Patient ID"
                        name="patientNumber"
                        required={patientIdRequired}
                        validator={patientIdRequired ? FormValidatorsService.required : undefined}
                        valid={!(formTouched && validationErrors.patientNumber)}
                        validationMessage={formTouched ? validationErrors.patientNumber : ''}
                        onBlur={handleFieldBlur}
                      />
                    </Col>
                    <Col className="col-4">
                      <Field component={Input} disabled={isFormDisabled} label="UNOS ID" name="unosID" />
                    </Col>
                  </Row>
                </FormElement>
              );
            }}
          />
        </>
      )}
    </>
  );
};
