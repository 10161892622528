import { createSelector } from '@reduxjs/toolkit';

import { ExamGridModel, ExamModel } from 'models';

import { AppSelector, RootState } from 'features/main/redux';

import { examAdapter, examGridAdapter } from './reducer';

// Create selectors based on the sub-states for each adapter.
const examStateSelector = (state: RootState) => state.exams.exam;
const gridStateSelector = (state: RootState) => state.exams.grid;

const examSelectors = examAdapter.getSelectors(examStateSelector);
const gridSelectors = examGridAdapter.getSelectors(gridStateSelector);

const getCount: AppSelector<number> = (state) => examSelectors.selectTotal(state);

const getStatus: AppSelector<string | null> = (state) => state.exams.status;

const getById = (id: number | undefined): AppSelector<ExamModel | undefined> => {
  return (state: RootState) => {
    return id !== undefined ? examSelectors.selectById(state, id) : undefined;
  };
};

const getTotal: AppSelector<number> = (state) => state.exams.total;

const getSendDocumentStatus: AppSelector<string | null> = (state) => state.exams.sendDocumentStatus;

const getPreviousExamId: AppSelector<number | undefined> = (state) => {
  const { recentlyViewedExams } = state.exams;
  return recentlyViewedExams[recentlyViewedExams.length - 1];
};

export const ExamSelectors = {
  getAllGrid: gridSelectors.selectAll, // for grid operations
  getById,
  getCount,
  getPreviousExamId,
  getSendDocumentStatus,
  getStatus,
  getTotal,
};
