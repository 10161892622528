import { ReactNode, memo, useMemo } from 'react';

import { useBoolean } from 'core/hooks';

import { SessionLocationRequiredModal } from 'features/auth/fragments/SessionLocationRequiredModal';

import { AppSessionLocationSelectModalContext } from '../contexts';
import { AppSessionLocationSelectModalContextType } from '../types';

export const AppSessionLocationSelectModalProvider = memo<{ children?: ReactNode }>(({ children }) => {
  const [isSessionLocationModalVisible, { setTrue: showSessionLocationModalPrompt, setFalse: closeSessionLocationModalPrompt }] = useBoolean(false);

  const sessionSelectModalContext: AppSessionLocationSelectModalContextType = useMemo(
    () => ({
      isSessionLocationModalVisible,
      showSessionLocationModalPrompt,
      closeSessionLocationModalPrompt,
    }),
    [closeSessionLocationModalPrompt, isSessionLocationModalVisible, showSessionLocationModalPrompt],
  );

  return (
    <AppSessionLocationSelectModalContext.Provider value={sessionSelectModalContext}>
      {isSessionLocationModalVisible && <SessionLocationRequiredModal />}
      {children}
    </AppSessionLocationSelectModalContext.Provider>
  );
});

AppSessionLocationSelectModalProvider.displayName = 'AppSessionLocationSelectModalProvider';
