import { useContext } from 'react';

import { ApiWebSocketProviderContext } from 'features/api/contexts';

export function useApiWebSocketConnection() {
  const context = useContext(ApiWebSocketProviderContext);

  if (!context) {
    throw new Error('useApiWebSocketConnection must be used within an <ApiWebSocketProvider /> component');
  }

  return context;
}
