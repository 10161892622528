import { FunctionComponent } from 'react';

import { ExamGridModel } from 'models';

import { useEvent } from 'core/hooks';

import { DefaultColumnsState, ExamActions, ExamGrid, ExamGridColumnsStateChangeEvent, ExamStatus, useExamEditWindow } from 'features/exam';
import { ExamEditWindow } from 'features/exam/fragments';
import { useAppDispatch } from 'features/main/hooks';
import { PatientEditExamGridProps } from 'features/patient/types';

export const PatientEditExamGrid: FunctionComponent<PatientEditExamGridProps> = ({
  className,
  featureView,
  donorToolbar,
  columnsState,
  dataState,
  gridData,
  handleViewFinalReport,
  handleViewAttachments,
  handleViewDicomImages,
  handleChangeStatusSubmit,
  handleDataStateChange,
  handleFilterActiveChange,
  handleExamUpdate,
  activeExamToggle,
  handleShareChange,
  searchValue,
  selectedExams,
  setColumnsState,
  setSelectedExams,
  handleShareGridClick,
  handleReadClick,
  handleExpandChange,
}) => {
  const dispatch = useAppDispatch();
  const editWindow = useExamEditWindow({ onExamUpdate: handleExamUpdate });

  // Grid Events
  const handleAddNewClick = useEvent(() => {
    // open exam add in new window for dual screen work and to not lose grid state
    window.open('/exam/add', 'AddExam', 'menubar=0,status=0,fullscreen=1');
  });

  const handleEditClick = useEvent((dataItem: ExamGridModel) => {
    editWindow.openExamEditWindow(dataItem);
  });

  const handleExportCSVClick = useEvent(() => {
    dispatch(
      ExamActions.exportToCSV({
        dataState: {
          filter: dataState?.filter,
          sort: dataState?.sort,
        },
        quickFilter: ExamStatus.ALL.name,
      }),
    );
  });

  const handleRowDoubleClick = useEvent((exam: ExamGridModel) => {
    handleEditClick(exam);
  });

  const handleColumnsStateChange = useEvent((event: ExamGridColumnsStateChangeEvent) => {
    setColumnsState(event.columnsState);
  });

  return (
    <>
      <ExamGrid
        className={className}
        featureView={featureView}
        showExamToolbar={false}
        showPatientToolbar={donorToolbar}
        columnsState={columnsState ?? DefaultColumnsState}
        dataState={dataState}
        data={gridData}
        showToolbar={false}
        onExamViewFinalReportClick={handleViewFinalReport}
        onExamViewAttachmentsClick={handleViewAttachments}
        onExamViewClick={handleViewDicomImages}
        onExamReadClick={handleReadClick}
        onAddNewClick={handleAddNewClick}
        onBulkChangeStatusSubmit={handleChangeStatusSubmit}
        onDataStateChange={handleDataStateChange}
        onExamEditClick={handleEditClick}
        onExportCsvClick={handleExportCSVClick}
        onExamDoubleClick={handleRowDoubleClick}
        onExamShareChange={handleShareChange}
        onFilterActiveChange={handleFilterActiveChange}
        selectable={false}
        filterable={false}
        //setShareToggle={setShareToggle}
        //shareToggle={shareToggle}
        filterSearch={searchValue ?? ''}
        filterActive={activeExamToggle}
        selectedExams={selectedExams}
        onColumnsStateChange={handleColumnsStateChange}
        onSelectedExamsChange={setSelectedExams}
        onExamShareClick={handleShareGridClick}
        expandField="expanded"
        onExpandChange={handleExpandChange}
      />
      {editWindow?.selectedExamId && editWindow.showExamEditWindow && editWindow.selectedPatientId && (
        <ExamEditWindow
          onClosed={editWindow.closeExamEditWindow}
          patientId={editWindow.selectedPatientId}
          examId={editWindow.selectedExamId}
          onDeleteShareClick={editWindow.handleDeleteShareClick}
          onExamFormSubmit={editWindow.handleFormSubmit}
        />
      )}
    </>
  );
};
