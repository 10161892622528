import { QueryClient } from '@tanstack/query-core';

import { ExamMatchQueryModel, PatientMatchQueryModel, ServiceModel } from 'models';

import { apiClient } from 'core/api/globals';
import { EventStream, IEventStreamConsumer } from 'core/utils';

import { Classification, FileUploadContext, MatchCompleteEvent, MatchErrorEvent, ParsedDicomMetadata, ProcessJobEvent } from '../types';
import { PriorityLock } from './PriorityLock';

export class RecordMatcher {
  static [Symbol.toStringTag]() {
    return 'RecordMatcher';
  }

  /** Lock to prevent multiple patient/exam creation calls from executing at the same time.   */
  private _lock = new PriorityLock({ maxConcurrent: 1 });
  private _queryClient: QueryClient | null = null;
  private _allServices: Promise<ServiceModel[]> | null = null;
  /** We have decided that the first patient matched/created will trigger all remaining exams to attach to that first patient. */
  private _firstMatchedPatientId: number | null = null;
  private _fixedPatientId: number | null = null;
  private _fixedExamId: number | null = null;
  private _fixedLocationId: number | null = null;

  private _streams = {
    onMatchComplete: new EventStream<ProcessJobEvent<MatchCompleteEvent>>(),
    onMatchError: new EventStream<MatchErrorEvent>(),
  };

  public get streams(): {
    onMatchComplete: IEventStreamConsumer<ProcessJobEvent<MatchCompleteEvent>>;
    onMatchError: IEventStreamConsumer<MatchErrorEvent>;
  } {
    return this._streams;
  }

  constructor() {
    this.initialize = this.initialize.bind(this);
    this.reset = this.reset.bind(this);
    this.setFixedEntities = this.setFixedEntities.bind(this);
    this.process = this.process.bind(this);
    this.matchNonDicom = this.matchNonDicom.bind(this);
    this.matchDicom = this.matchDicom.bind(this);
    this.generatePatientMatchQueryKey = this.generatePatientMatchQueryKey.bind(this);
    this.generateExamMatchQueryKey = this.generateExamMatchQueryKey.bind(this);
  }

  public initialize(queryClient: QueryClient) {
    this._queryClient = queryClient;
    this._allServices = queryClient.fetchQuery({ queryKey: ['services'], queryFn: apiClient.servicesClient.getAllServices, staleTime: Infinity });
  }

  public reset() {
    this._fixedPatientId = null;
    this._fixedExamId = null;
    this._fixedLocationId = null;
    this._firstMatchedPatientId = null;
    this._allServices = null;
    this._queryClient = null;
  }

  public setFixedEntities(patientId: number | null, examId: number | null, fixedLocationId: number | null) {
    if (this._queryClient == null) throw new Error('Cannot set fixed entities without first calling initialize()');

    this._fixedPatientId = patientId;
    this._fixedExamId = examId;
    this._fixedLocationId = fixedLocationId;
  }

  public process(file: Readonly<FileUploadContext>, createMissingRecords: boolean) {
    if (file.classification === Classification.Unknown) {
      this._streams.onMatchComplete.emit({ result: 'skipped', fileId: file.fileId });
      return;
    }
    if (file.classification === Classification.Dicom && file.dicomData == null) {
      this._streams.onMatchComplete.emit({ result: 'skipped', fileId: file.fileId });
      return;
    }
    if (file.classification === Classification.Attachment) {
      this._streams.onMatchComplete.emit({ result: 'skipped', fileId: file.fileId });
      return;
    }

    if (file.classification !== Classification.Dicom) {
      this.matchNonDicom(file.fileId);
    } else {
      this.matchDicom(file.fileId, file.dicomData!, createMissingRecords); // Non-null assertion here because we just checked that it is non-null.  TS just isn't keeping track of that.
    }
  }

  private async matchNonDicom(fileId: string) {
    if (this._fixedPatientId == null || this._fixedExamId == null)
      throw new Error('Cannot attempt to match a non-DICOM file without a fixed patient and exam.');

    this._streams.onMatchComplete.emit({ result: 'processed', fileId, patientId: this._fixedPatientId, examId: this._fixedExamId });
  }

  private generatePatientMatchQueryKey(query: PatientMatchQueryModel) {
    return ['getPatientMatch', query.location_Id, query.patientNumber, query.unosID, query.firstName, query.lastName, query.dob];
  }

  private generateExamMatchQueryKey(query: ExamMatchQueryModel) {
    return ['getExamMatch', query.location_Id, query.suid];
  }

  private async matchDicom(fileId: string, dicomData: ParsedDicomMetadata, createMissingRecords: boolean) {
    if (this._queryClient == null) throw new Error('Cannot attempt to match a DICOM file without first calling initialize()');
    if (this._fixedLocationId == null) throw new Error('Cannot attempt to match a DICOM file without a fixed location ID.');

    const patientQuery: PatientMatchQueryModel = {
      location_Id: this._fixedLocationId,
      patientNumber: dicomData.PatientID,
      unosID: null,
      firstName: dicomData.PatientName[1] ?? null,
      lastName: dicomData.PatientName[0] ?? null,
      dob: dicomData.PatientBirthDate ?? null,
    };

    const examQuery: ExamMatchQueryModel = {
      location_Id: this._fixedLocationId,
      suid: dicomData.StudyInstanceUID,
    };

    let releaseLock: (() => void) | undefined;
    const patientQueryKey = this.generatePatientMatchQueryKey(patientQuery);
    const examQueryKey = this.generateExamMatchQueryKey(examQuery);

    // Initiate the queries to match the patient and exam.  This will allow the hot-path inside the lock to run faster in the event that both queries are cached.

    const [preMatchedPatientId, preMatchedExamId] = await Promise.all([
      this._queryClient.fetchQuery({
        queryKey: patientQueryKey,
        staleTime: Infinity,
        queryFn: async () =>
          this._fixedPatientId
            ? Promise.resolve(this._fixedPatientId)
            : this._firstMatchedPatientId
              ? Promise.resolve(this._firstMatchedPatientId)
              : ((await apiClient.patientClient.getPatientMatch(patientQuery))?.id ?? null),
      }),
      this._queryClient.fetchQuery({
        queryKey: examQueryKey,
        staleTime: Infinity,
        queryFn: async () => (this._fixedExamId ? Promise.resolve(this._fixedExamId) : ((await apiClient.exams.getExamMatch(examQuery))?.id ?? null)),
      }),
    ]);

    // Force all subsequent matches to use the first matched patient if one was found.
    if (this._firstMatchedPatientId == null && preMatchedPatientId) {
      this._firstMatchedPatientId = preMatchedPatientId;
    }

    // Return early if we already have a match for both the patient and exam.
    if (preMatchedPatientId && preMatchedExamId) {
      this._streams.onMatchComplete.emit({
        result: 'processed',
        fileId,
        patientId: preMatchedPatientId,
        examId: preMatchedExamId,
      });

      return;
    }

    try {
      releaseLock = await this._lock.requestLock();

      // Re-try matching the patient and exam in case one was created while waiting for the lock.
      let [matchedPatientId, matchedExamId] = await Promise.all([
        this._queryClient.fetchQuery({
          queryKey: patientQueryKey,
          staleTime: Infinity,
          queryFn: async () =>
            this._fixedPatientId
              ? Promise.resolve(this._fixedPatientId)
              : this._firstMatchedPatientId
                ? Promise.resolve(this._firstMatchedPatientId)
                : ((await apiClient.patientClient.getPatientMatch(patientQuery))?.id ?? null),
        }),
        this._queryClient.fetchQuery({
          queryKey: examQueryKey,
          staleTime: Infinity,
          queryFn: async () => (this._fixedExamId ? Promise.resolve(this._fixedExamId) : ((await apiClient.exams.getExamMatch(examQuery))?.id ?? null)),
        }),
      ]);

      // Force all subsquent matches to use the first matched patient if one was found.
      if (this._firstMatchedPatientId == null && matchedPatientId) {
        this._firstMatchedPatientId = matchedPatientId;
      }

      // We need to create a new patient if one wasn't matched at this point.
      if (createMissingRecords && matchedPatientId == null) {
        matchedPatientId = await apiClient.patientClient.savePatient({
          id: 0,
          unosID: null,
          active: true,
          firstName: dicomData.PatientName[1] ?? null,
          lastName: dicomData.PatientName[0] ?? null,
          dob: dicomData.PatientBirthDate ?? null,
          patientNumber: dicomData.PatientID ?? '', // TODO: Is this right?  We want to actually store an empty string if the ID is not available?
          caseID: null,
          height: null,
          weight: null,
          gender: dicomData.PatientSex,
          crossClampDateTime: null,
          notes: null,
          hospital: null,
          locationType: null,
          htn: null,
          htnYrs: null,
          htnCompliant: null,
          diabetes: null,
          diabetesYrs: null,
          diabetesCompliant: null,
          peakCreatinine: null,
          peakCreatinineYrs: null,
          peakCreatinineCompliant: null,
          obesityIndication: null,
          organ: null,
          organAppearanceIndication: null,
          proteinuria: null,
          ageIndication: null,
          etoh: null,
          ageRange: null,
          ageRange_id: null,
          location: null,
          location_Id: this._fixedLocationId,
        });

        if (matchedPatientId == null) throw new Error('Failed to create patient.');

        // Store the patient record in the cache for subsequent matches.
        this._queryClient.setQueryData(patientQueryKey, matchedPatientId);
      }

      // We need to create a new exam if one wasn't matched at this point.
      if (createMissingRecords && matchedExamId == null) {
        if (matchedPatientId == null) throw new Error('Cannot create an exam without a patient.');

        matchedExamId = await apiClient.exams.saveExam({
          id: 0,
          suid: dicomData.StudyInstanceUID ?? undefined,
          service: {
            id: 0,
            description: dicomData.Modality,
            notes: null,
            shortCode: null,
            longCode: null,
            cpt: null,
            serviceSubTypeID: null,
            active: true,
            children: [],
          },
          location_Id: this._fixedLocationId,
          patient_Id: matchedPatientId,
          patientId: matchedPatientId, // TODO: Why the hell are there 2 patient id properties on this?
          description: dicomData.StudyDescription ?? undefined,
          studyDate: dicomData.StudyDate ?? undefined,
          studyTime: dicomData.StudyTime ?? undefined,
        });

        if (matchedExamId == null) throw new Error('Failed to create exam.');

        // Store the exam record in the cache for subsequent matches.
        this._queryClient.setQueryData(examQueryKey, matchedExamId);
      }

      // Finally we can report the results.
      this._streams.onMatchComplete.emit({
        result: 'processed',
        fileId,
        patientId: matchedPatientId,
        examId: matchedExamId,
      });
    } finally {
      releaseLock?.();
    }
  }
}
