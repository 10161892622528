import { FunctionComponent } from 'react';

import { faChevronDown, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';
import styled from 'styled-components';

import { FileModel } from 'models';
import { ExamModel } from 'models';

import { Icon } from 'core/ui';

import { useExamsFilesAccordionItem } from '../hooks';
import { FileButton } from './FileButton';

export interface DesktopAccordionItemProps {
  exam: ExamModel;
  selectedFileId: number | null;
  onClick: (file: FileModel) => void;
}

export const DesktopAccordionItem: FunctionComponent<DesktopAccordionItemProps> = ({ exam, selectedFileId, onClick }) => {
  const { links, files } = useExamsFilesAccordionItem(exam);

  // Derive values from the hook's return data
  const examFiles = links; // LINK files
  const examAttachments = files; // Non-LINK files
  const isExamSelected = examFiles.some((file) => file.id === selectedFileId) || examAttachments.some((file) => file.id === selectedFileId);

  return (
    <StyledAccordionItem key={exam.id} className={classnames({ selected: isExamSelected })}>
      <StyledAccordionLabel>
        <StyledAccordionIconWrapper>
          <Icon icon={isExamSelected ? faChevronDown : faChevronRight} fixedWidth={false} />
        </StyledAccordionIconWrapper>
        {exam.description || 'Unknown'}
        <StyledDate>
          {exam.studyDate &&
            new Date(exam.studyDate).toLocaleDateString(undefined, {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
            })}
        </StyledDate>
      </StyledAccordionLabel>
      <StyledAccordionContent id={`${exam.id}`}>
        {examFiles?.length > 0 && (
          <div>
            <StyledContentLabel>Images</StyledContentLabel>
            <StyledThumbnailList>
              {examFiles.map((file: FileModel) => (
                <FileButton key={file.id} className="testing_ShareHome_image-file-button" file={file} selected={selectedFileId === file.id} onClick={onClick} />
              ))}
            </StyledThumbnailList>
          </div>
        )}
        {examAttachments?.length > 0 && (
          <div>
            <StyledContentLabel>Attachments</StyledContentLabel>
            <StyledThumbnailList>
              {examAttachments.map((file: FileModel) => (
                <FileButton
                  key={file.id}
                  className="testing_ShareHome_attachment-file-button"
                  file={file}
                  selected={selectedFileId === file.id}
                  onClick={onClick}
                />
              ))}
            </StyledThumbnailList>
          </div>
        )}
      </StyledAccordionContent>
    </StyledAccordionItem>
  );
};

const StyledAccordionItem = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.palette.gray};
  border-radius: 4px;
  margin-bottom: 8px;
  overflow: hidden;

  &.selected {
    border-color: ${({ theme }) => theme.colors.palette.blues[1]};
  }
`;

const StyledAccordionLabel = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.palette.white};
  cursor: pointer;
`;

const StyledAccordionIconWrapper = styled.div`
  margin-right: 8px;
`;

const StyledDate = styled.div`
  margin-left: auto;
  color: ${({ theme }) => theme.colors.palette.gray};
`;

const StyledAccordionContent = styled.div`
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.palette.grayscale[1]};
`;

const StyledContentLabel = styled.div`
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.palette.gray};
`;

const StyledThumbnailList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;
