import { memo } from 'react';

import { Route, Routes } from 'react-router-dom';

import { RouteGuard, UserRoles } from 'features/auth';
import { UploadPipelineProvider } from 'features/upload-pipeline/fragments/UploadPipelineProvider';

import { UploadAlternativeRoutes } from '../routes';
import { UploadAlternativePage } from './UploadAlternativePage';

const UPLOAD_EXAMS_ROLES = [UserRoles.ADMIN, UserRoles.CUSTOMER, UserRoles.CUSTOMER_ADMIN, UserRoles.SUPPORT];

export const UploadAlternative = memo(() => {
  return (
    <Routes>
      <Route
        path={UploadAlternativeRoutes.UPLOAD_ALTERNATIVE}
        element={
          <RouteGuard allowedRoles={UPLOAD_EXAMS_ROLES}>
            <UploadPipelineProvider>
              <UploadAlternativePage />
            </UploadPipelineProvider>
          </RouteGuard>
        }
      />
    </Routes>
  );
});

UploadAlternative.displayName = 'UploadAlternative';
