import React, { useCallback, useMemo } from 'react';

import { DropDownListBlurEvent, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { Validate, useController } from 'react-hook-form';

// If you use these in your old code, import them as well
import { RhfValidators } from 'core/forms';
import { getComplexErrorMessages } from 'core/utils';

import { Dropdown } from './Dropdown';
import { DropdownFieldProps } from './DropdownFieldProps';

/**
 * Combines your old form validation logic (like required) with
 * the new "primitive-only" approach for Kendo <DropDownList>.
 */
export const DropdownField: React.FC<DropdownFieldProps> = (props) => {
  const {
    name,
    data = [],
    dataItemKey = 'id',
    valueField = 'id',
    // The old code had "required" and "validator", so keep them:
    required,
    validator,
    // The new code uses this to store only the .valueField:
    isForPrimitiveValues = false,
    onChange,
    onBlur,
    ...rest
  } = props;

  /**
   * 1) Combine any validators (just like old code).
   */
  const validatorInternal = useMemo(() => {
    const newValidators: Validate<unknown, unknown>[] = [];

    if (required) {
      // old code calls RhfValidators.requiredWithDefaultOption(dataItemKey)
      newValidators.push(RhfValidators.requiredWithDefaultOption(dataItemKey));
    }
    if (validator) newValidators.push(validator);

    if (newValidators.length === 0) return undefined;
    if (newValidators.length === 1) return newValidators[0];
    return RhfValidators.combine(newValidators);
  }, [dataItemKey, required, validator]);

  /**
   * 2) Hook up to RHF using the new approach.
   */
  const {
    field: { onChange: rhfOnChange, onBlur: rhfOnBlur, value: rhfValue, ...fieldRest },
    fieldState: { isTouched, invalid, error },
    formState,
  } = useController({
    name,
    rules: {
      validate: validatorInternal, // apply combined validation
    },
  });

  /**
   * 3) The old code used getComplexErrorMessages
   *    to transform any Zod or custom error arrays into text.
   */
  const errorMessages = getComplexErrorMessages(error);

  /**
   * 4) Kendo expects the *full object* as "value".
   *    If isForPrimitiveValues = true, we only store item[valueField].
   */
  const currentItem = useMemo(() => {
    if (!isForPrimitiveValues) {
      // if storing entire object in RHF
      return rhfValue ?? null;
    }
    // else storing a primitive
    return data.find((item) => item[valueField] === rhfValue) || null;
  }, [data, rhfValue, valueField, isForPrimitiveValues]);

  /**
   * 5) handleChange: store either the entire object or just item[valueField]
   */
  const handleChange = useCallback(
    (event: DropDownListChangeEvent) => {
      const selectedItem = event.value;
      if (isForPrimitiveValues && selectedItem) {
        rhfOnChange(selectedItem[valueField]); // store just the ID/primitive
      } else {
        rhfOnChange(selectedItem); // store full object
      }
      onChange?.(event);
    },
    [isForPrimitiveValues, onChange, rhfOnChange, valueField],
  );

  /**
   * 6) handleBlur: ensures RHF knows about blur events
   */
  const handleBlur = useCallback(
    (event: DropDownListBlurEvent) => {
      rhfOnBlur();
      onBlur?.(event);
    },
    [onBlur, rhfOnBlur],
  );

  /**
   * 7) visited logic: the old code used "formState.isSubmitted || isTouched"
   */
  const visited = formState.isSubmitted || isTouched;

  return (
    <Dropdown
      {...fieldRest} // includes "name", "id", etc.
      {...rest}
      data={data}
      dataItemKey={dataItemKey}
      valueField={valueField}
      value={formState.isLoading ? null : currentItem}
      onChange={handleChange}
      onBlur={handleBlur}
      valid={!invalid}
      visited={visited}
      required={required}
      validationMessage={errorMessages}
    />
  );
};

DropdownField.displayName = 'DropdownField';
