export const LocationTypeStatus = {
  PENDING: 'location-type-pending',
  ADDED: 'location-type-created',
  UPDATED: 'location-type-updated',
  FETCHED: 'location-type-fetched',
  ERROR: 'location-type-error',
};

export enum LocationTypeEnum {
  OPO = 3,
  CORRECTION = 5,
}
