import { FunctionComponent, ReactNode } from 'react';

import { apiClient } from 'core/api/globals';

import { ApiClientProviderContext } from '../contexts';

export const ApiClientProvider: FunctionComponent<{
  children: ReactNode;
}> = ({ children }) => {
  return <ApiClientProviderContext.Provider value={apiClient}>{children}</ApiClientProviderContext.Provider>;
};

ApiClientProvider.displayName = 'ApiClientProvider';
