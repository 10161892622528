import { ServiceModel } from 'models';

import { findOrThrow } from 'core/utils';

import { EditFormValues } from '../types';

const EditFormDefaults: EditFormValues = {
  description: '',
  notes: '',
  shortCode: '',
  longCode: '',
  cpt: '',
  serviceSubType: null,
  active: false,
};

function copyModelToForm(model: ServiceModel, allSubServices: ServiceModel[]): EditFormValues {
  // find Sub Service/ Parent
  const subServiceType =
    model.serviceSubTypeID != null && model.serviceSubTypeID > 0
      ? findOrThrow(allSubServices, (d) => d.id === model.serviceSubTypeID, `Could not find Service with id: ${model.serviceSubTypeID}.`)
      : null;

  return {
    description: model.description || '',
    notes: model.notes || '',
    shortCode: model.shortCode || '',
    longCode: model.longCode || '',
    cpt: model.cpt || '',
    serviceSubType: subServiceType,
    active: model.active || false,
  };
}

function copyFormToModel(serviceId: number, form: EditFormValues): ServiceModel {
  return {
    id: serviceId,
    description: form.description,
    notes: form.notes,
    shortCode: form.shortCode,
    longCode: form.longCode,
    cpt: form.cpt,
    serviceSubTypeID: form.serviceSubType?.id || null,
    active: form.active,
    children: [],
  };
}

function createDefaultSource(): ServiceModel {
  return copyFormToModel(0, EditFormDefaults);
}

export const ServiceEditService = {
  EditFormDefaults,
  copyModelToForm,
  copyFormToModel,
  createDefaultSource,
};
