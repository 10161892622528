import { ComponentType, FunctionComponent, MouseEvent, memo, useCallback, useEffect, useMemo, useState } from 'react';

import { GridCellProps, GridColumn, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { DestinationRouteGridModel } from 'models/DestinationRouteGridModel';

import { apiClient } from 'core/api/globals';
import { ActionListCell, DEFAULT_DATA_TABLE_DATA_STATE, DEFAULT_PAGE_SIZES, DataTable, HeaderCell, Page, PageHeader, TextCell, Toolbar, faPen } from 'core/ui';

import { useCurrentUser } from 'features/auth';

import { DestinationRouteGridService } from '../services/DestinationRouteGridService';
import { DestinationRouteGridHeaderCell } from './DestinationRouteGridHeaderCell';

const PageableSettings = {
  pageSizes: DEFAULT_PAGE_SIZES,
};
export const DestinationRouteHome: FunctionComponent = memo(() => {
  const navigate = useNavigate();

  const [destinationRoutes, setDestinationRoutes] = useState<DestinationRouteGridModel[]>([]);
  const [destinationRoutesTotal, setDestinationRoutesTotal] = useState(0);
  const [dataState, setDataState] = useState(DEFAULT_DATA_TABLE_DATA_STATE);

  const { currentUser } = useCurrentUser(true);

  const handleDataStateChange = useCallback((changeEvent: GridDataStateChangeEvent) => {
    setDataState(changeEvent.dataState);
  }, []);

  const handleAddNewClick = useCallback(() => navigate('/destination-route/add'), [navigate]);

  const handleEditClick = useCallback((dataItem: DestinationRouteGridModel) => navigate(`/destination-route/edit/${dataItem.id}`), [navigate]);

  const gridActions = useMemo(() => {
    return [
      {
        key: 'edit-destination-route',
        title: 'Edit Destination Route',
        icon: faPen,
        disabled: !currentUser.isSystemAdmin,
        onClick: (_: MouseEvent<HTMLButtonElement>, dataItem: DestinationRouteGridModel) => handleEditClick(dataItem),
      },
    ];
  }, [handleEditClick, currentUser.isSystemAdmin]);

  const activeCellRender = useCallback((props: GridCellProps) => <TextCell {...props} valueTransform={(value) => (value ? 'Active' : 'Inactive')} />, []);

  useEffect(() => {
    (async () => {
      const queryResult = await apiClient.destinationRouteClient.getAllDestinationRouteForKendoGrid(dataState);

      setDestinationRoutes(queryResult.data);
      setDestinationRoutesTotal(queryResult.total);
    })();
  }, [dataState]);

  const { gridColumns, filterColumns } = useMemo(() => {
    return {
      gridColumns: DestinationRouteGridService.getGridColumns(),
      filterColumns: DestinationRouteGridService.getColumns(),
    };
  }, []);

  return (
    <Page>
      <PageHeader title="Destination Routes" />
      <StyledDataTable
        data={destinationRoutes}
        filterable
        sortable
        pageable={PageableSettings}
        total={destinationRoutesTotal}
        onDataStateChange={handleDataStateChange}
        actions={gridActions}
        {...dataState}
      >
        <Toolbar onAddNewClick={handleAddNewClick} />
        <GridColumn
          title="Action"
          filterable={false}
          sortable={false}
          headerCell={HeaderCell}
          cell={ActionListCell as ComponentType<GridCellProps>}
          width="80px"
        />
        {gridColumns.map((column) => {
          return <GridColumn key={column.field} {...column} headerCell={DestinationRouteGridHeaderCell} cell={column.cell} filter={column.columnFilter} />;
        })}
      </StyledDataTable>
    </Page>
  );
});

DestinationRouteHome.displayName = 'DestinationRouteHome';

const StyledDataTable = styled(DataTable)`
  max-height: initial;
`;
