import { DataSourceRequestState, toDataSourceRequestString } from '@progress/kendo-data-query';

import { ChangeExamStatusModel, ExamGridModel, ExamMatchQueryModel, ExamModel, ExamStatusTransitionModel, ReadRequestModel } from 'models';

import { ToCamelCase, hasText } from 'core/utils';

import { ApiRouteService } from '../services';
import { DataResult } from '../types';
import { HttpClient } from './HttpClient';

export class ExamsClient {
  constructor(private httpClient: HttpClient) {
    this.createExam = this.createExam.bind(this);
    this.patchExam = this.patchExam.bind(this);
    this.saveReadRequest = this.saveReadRequest.bind(this);
    this.getExamsByPatientId = this.getExamsByPatientId.bind(this);
    this.getExamById = this.getExamById.bind(this);
    this.getAllForKendoGrid = this.getAllForKendoGrid.bind(this);
    this.getValidStatuses = this.getValidStatuses.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.getExamPriors = this.getExamPriors.bind(this);
    this.exportToCSV = this.exportToCSV.bind(this);
    this.bulkCancel = this.bulkCancel.bind(this);
    this.bulkAssignDoctor = this.bulkAssignDoctor.bind(this);
    this.exportExamsForKendoGrid = this.exportExamsForKendoGrid.bind(this);
    this.updateExam = this.updateExam.bind(this);
    this.getExamMatch = this.getExamMatch.bind(this);
    this.reviver = this.reviver.bind(this);
    this.saveExam = this.saveExam.bind(this);
  }

  public async createExam(exam: ExamModel) {
    const options = await this.httpClient.createStandardOptions('POST', exam);
    const response = await this.httpClient.fetchAndParse<{ id: number }>(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/exams`, options));
    return response.id;
  }

  public async updateExam(exam: ExamModel) {
    const options = await this.httpClient.createStandardOptions('PUT', exam);
    await this.httpClient.fetch(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/exams`, options));
  }

  public async getExamMatchServices() {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const response = await this.httpClient.fetchAndParse<string[]>(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/exams/match-services`, options));
    return response;
  }

  public async saveExam(exam: ExamModel) {
    const options = await this.httpClient.createStandardOptions('POST', exam);
    return await this.httpClient.fetchAndParse<number>(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/exams/save`, options));
  }

  public async patchExam(id: number, model: ToCamelCase<Partial<ExamModel>>) {
    const options = await this.httpClient.createStandardOptions('PATCH', model);
    await this.httpClient.fetch(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/exams/${id}`, options));
  }

  public async saveReadRequest(model: ReadRequestModel) {
    const options = await this.httpClient.createStandardOptions('POST', model);
    await this.httpClient.fetch(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/exams/save-read-request`, options));
  }

  public async getExamsByPatientId(patientId: number) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const exams = await this.httpClient.fetchAndParse<ExamModel[]>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/exams/getexamsbypatientid/${patientId}`, options),
    );
    return exams;
  }

  public async getExamById(examId: number, useHack = false, authMode: 'msal-required' | 'share-required' = 'msal-required') {
    const options = await this.httpClient.createStandardOptions('GET', null, { authMode });
    const exam = await this.httpClient.fetchAndParse<ExamModel>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/exams/${examId}`, options),
      useHack ? this.reviver : undefined,
    );
    return exam;
  }

  public async getAllForKendoGrid(dataState: DataSourceRequestState, authMode: 'msal-required' | 'share-required', signal?: AbortSignal | null) {
    const options = await this.httpClient.createStandardOptions('GET', null, { authMode, signal });
    const queryStr = toDataSourceRequestString(dataState);
    const result = await this.httpClient.fetchAndParse<DataResult<ExamGridModel>>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/exams/grid?${queryStr}`, options),
    );
    return result;
  }

  public async getValidStatuses(examId: number) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const exams = await this.httpClient.fetchAndParse<ExamStatusTransitionModel[]>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/exams/${examId}/status`, options),
    );
    return exams;
  }

  public async changeStatus(model: ChangeExamStatusModel) {
    const options = await this.httpClient.createStandardOptions('POST', model);
    await this.httpClient.fetch(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/exams/${model.id}/status`, options));
  }

  public async getExamPriors(examId: number) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const exams = await this.httpClient.fetchAndParse<ExamModel[]>(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/exams/${examId}/priors`, options));
    return exams;
  }

  public async exportToCSV(dataState: DataSourceRequestState, quickFilter: string | null | undefined) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const queryStr = toDataSourceRequestString(dataState);
    const quickFilterStr = quickFilter ? `&quickFilter=${quickFilter}` : '';
    const examData = await this.httpClient.fetchAndParse<ExamModel[]>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/grid/export?${queryStr}${quickFilterStr}`, options),
    );

    return examData;
  }

  public async bulkCancel(changes: ChangeExamStatusModel[]) {
    const options = await this.httpClient.createStandardOptions('POST', changes);
    await this.httpClient.fetch(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/exams/bulk-cancel`, options));
  }

  public async bulkAssignDoctor(examIds: number[], physicianId: number) {
    const options = await this.httpClient.createStandardOptions('POST', null);

    const queryStr = `physicianId=${physicianId}${examIds.map((id) => `&examIds=${id}`).join('')}`;

    await this.httpClient.fetch(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/exams/bulk-assign-doctors?${queryStr}`, options));
  }

  public async exportExamsForKendoGrid(dataState: DataSourceRequestState, quickFilter?: string | null) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const queryStr = toDataSourceRequestString(dataState);
    const quickFilterStr = hasText(quickFilter) ? `&quickFilter=${encodeURIComponent(quickFilter)}` : '';
    const url = `${ApiRouteService.getCompumedApiBaseRoute()}/api/exams/grid/export?${queryStr}${quickFilterStr}`;
    const result = await this.httpClient.fetchBlob(fetch(url, options));

    return result;
  }

  public async getExamMatch(query: ExamMatchQueryModel) {
    const options = await this.httpClient.createStandardOptions('POST', query);
    const result = await this.httpClient.fetchAndParse<ExamModel | null>(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/exams/GetExamMatch`, options));
    return result;
  }

  public async renderTemplate(id: string, templateName: string) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const template = await this.httpClient.fetchAndParse<string>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/exams/${id}/render-template?templateName=${templateName}`, options),
    );
    return template;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private reviver(_key: string, value: any) {
    // convert date times to date objects to prevent breaking loading date filter
    const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}/;

    if (typeof value === 'string' && dateFormat.test(value)) {
      return new Date(value);
    }

    return value;
  }
}
