import { datadogLogs } from '@datadog/browser-logs';
import cornerstone from 'cornerstone-core';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import dicomParser from 'dicom-parser';

import { EventStream, IEventStreamConsumer, equalsInsensitive } from 'core/utils';

import { THUMBNAIL_HEIGHT, THUMBNAIL_WIDTH } from '../constants';
import { FileUploadContext, ProcessJobEvent, ThumbnailGeneratedEvent, UploadLockRequest, UploadLockRequestType } from '../types';
import { ImageDataCache } from './ImageDataCache';
import { PriorityLock } from './PriorityLock';

const cornerstoneConfig = {
  maxWebWorkers: navigator.hardwareConcurrency || 1,
  startWebWorkersOnDemand: true,
  taskConfiguration: {
    decodeTask: {
      initializeCodecsOnStartup: false,
    },
  },
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
cornerstoneWADOImageLoader.webWorkerManager.initialize(cornerstoneConfig);
cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;

export class ThumbnailGenerator {
  static [Symbol.toStringTag]() {
    return 'ThumbnailGenerator';
  }

  private _uploadSessionId: string | null = null;
  /** The key is the SeriesInstanceUID.  The value is the browser generated random id assigned to a thumbnail. */
  private _dicomSeriesIdLookup = new Map<string, string>();
  private _serialLock = new PriorityLock<void>({ maxConcurrent: 1, priorityCompareFn: (a, b) => a.requestOrder - b.requestOrder });
  private _cacheLimitReached = false;

  private _streams = {
    onComplete: new EventStream<ProcessJobEvent<ThumbnailGeneratedEvent>>(),
  };

  public get streams(): {
    onComplete: IEventStreamConsumer<ProcessJobEvent<ThumbnailGeneratedEvent>>;
  } {
    return this._streams;
  }

  constructor(private _thumbnailCache: ImageDataCache) {
    this.initialize = this.initialize.bind(this);
    this.reset = this.reset.bind(this);
    this.process = this.process.bind(this);
    this.generateThumbnail = this.generateThumbnail.bind(this);
  }

  public initialize(uploadSessionId: string) {
    if (this._uploadSessionId != null) throw new Error('ThumbnailGenerator has already been initialized.  Call reset() before initializing again.');

    this._uploadSessionId = uploadSessionId;
    this._cacheLimitReached = false;
  }

  public reset() {
    if (this._uploadSessionId == null) return;

    this._uploadSessionId = null;
    this._cacheLimitReached = false;
  }

  public process(file: Readonly<FileUploadContext>, lock: PriorityLock<UploadLockRequest>) {
    if (file.classification !== 'dicom' || file.dicomData?.SeriesInstanceUID == null || this._cacheLimitReached) {
      this._streams.onComplete.emit({ result: 'skipped', fileId: file.fileId });
      return;
    }

    this.generateThumbnail(file.fileId, file.file, file.dicomData.SeriesInstanceUID, lock);
  }

  private async generateThumbnail(fileId: string, file: File, seriesInstanceUid: string, lock: PriorityLock<UploadLockRequest>) {
    // TODO: This method is very slow - to the point where it can cause the UI to hang.  This can be greatly improved by using a web worker to
    // generate the thumbnail.  However that may require us to upgrade to the newly-released Cornerstone3D library.  That library currently has
    // a bug preventing us from using it: https://github.com/cornerstonejs/cornerstone3D/issues/1071.

    if (this._uploadSessionId == null) throw new Error('Cannot generate a thumbnail without an upload session ID.');

    let releaseLock1: (() => void) | undefined, releaseLock2: (() => void) | undefined;

    try {
      releaseLock1 = await this._serialLock.requestLock();
      releaseLock2 = await lock.requestLock({ type: UploadLockRequestType.GenerateThumbnail });

      // Skip generating the thumbnail if it has already been generated.
      const existingThumbnailId = this._dicomSeriesIdLookup.get(seriesInstanceUid);
      if (existingThumbnailId != null) {
        this._streams.onComplete.emit({ result: 'processed', fileId, thumbnailId: existingThumbnailId, fromCache: true, seriesInstanceUid });
        return;
      }

      let hiddenDiv: HTMLDivElement | null = null;
      let hiddenCanvas: HTMLCanvasElement | null = null;
      let endMark: number | null = null;
      let measure: PerformanceMeasure | null = null;
      let success = false;
      let bitmap: ImageData | null = null;

      let renderPromiseResolve: () => void;
      const renderPromise = new Promise<void>((resolve) => {
        renderPromiseResolve = resolve;
      });
      const handleRenderComplete = () => {
        renderPromiseResolve();
      };

      const hiddenDivCleanup = () => {
        if (hiddenDiv != null) {
          cornerstone.disable(hiddenDiv);
          hiddenDiv.removeEventListener('cornerstoneimagerendered', handleRenderComplete);
          hiddenDiv.remove();
          hiddenDiv = null;
          hiddenCanvas = null;
        }
      };

      const startMark = performance.now();

      // Generate the thumbnail.
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        const thumbnailImageId = cornerstoneWADOImageLoader.wadouri.fileManager.add(file);
        const thumbnail = thumbnailImageId == null ? null : await cornerstone.loadImage(thumbnailImageId);

        if (thumbnail == null) {
          datadogLogs.logger.error('Thumbnail generation returned null or undefined result.', {
            uploadSessionId: this._uploadSessionId,
            fileName: file.name,
            fileId: fileId,
          });
          return;
        }

        // Render the thumbnail to a hidden canvas so we can capture the raw ImageData - allowing us to cache the small thumbnail
        // and avoid re-rendering the full-sized DICOM image.
        hiddenDiv = document.createElement('div');
        hiddenDiv.style.position = 'absolute';
        hiddenDiv.style.visibility = 'hidden';
        hiddenDiv.style.width = `${THUMBNAIL_WIDTH}px`;
        hiddenDiv.style.height = `${THUMBNAIL_HEIGHT}px`;
        hiddenDiv.addEventListener('cornerstoneimagerendered', handleRenderComplete);

        hiddenCanvas = document.createElement('canvas');
        hiddenCanvas.className = 'cornerstone-canvas';
        hiddenCanvas.style.width = '100%';
        hiddenCanvas.style.height = '100%';
        hiddenCanvas.style.objectFit = 'contain';
        hiddenCanvas.width = THUMBNAIL_WIDTH;
        hiddenCanvas.height = THUMBNAIL_HEIGHT;

        hiddenDiv.insertBefore(hiddenCanvas, null);
        document.body.insertBefore(hiddenDiv, null);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        cornerstone.enable(hiddenDiv, { renderer: 'webgl', desynchronize: true } as unknown as any);
        cornerstone.displayImage(hiddenDiv, thumbnail);

        // We have to wait until after cornerstone has emitted the 'cornerstoneimagerendered' event before we can capture the image data.
        await renderPromise;

        bitmap = hiddenCanvas.getContext('2d')!.getImageData(0, 0, THUMBNAIL_WIDTH, THUMBNAIL_HEIGHT);
        hiddenDivCleanup();

        endMark = performance.now();

        const thumbnailId = crypto.randomUUID();
        if (this._thumbnailCache.set(thumbnailId, bitmap)) {
          this._dicomSeriesIdLookup.set(seriesInstanceUid, thumbnailId);
          success = true;
        } else {
          this._cacheLimitReached = true;
        }

        measure = performance.measure(`generate-thumbnail:${fileId}`, {
          detail: {
            fileId: fileId,
            fileName: file.name,
            fileSize: file.size,
            devtools: {
              dataType: 'track-entry',
              trackGroup: 'Upload Pipeline',
              track: 'Thumbnail Generation',
              color: 'primary',
              properties: [
                ['fileId', fileId],
                ['fileName', file.name],
                ['fileSize', file.size],
              ],
            },
          },
          start: startMark,
          end: endMark,
        });

        datadogLogs.logger.info('generate-thumbnail', {
          uploadSessionId: this._uploadSessionId,
          fileId: fileId,
          date: performance.timeOrigin + startMark,
          end_date: performance.timeOrigin + startMark + measure.duration,
          duration: measure.duration * 1000000, // Datadog expects durations to be in nanoseconds.
          success,
          ...(bitmap?.data != null ? { thumbnailSize: bitmap.data.byteLength } : {}),
        });

        this._streams.onComplete.emit({ result: 'processed', fileId, thumbnailId, seriesInstanceUid, fromCache: false });
      } catch (error) {
        const errorMessage = error != null && typeof error === 'object' && 'error' in error && error.error instanceof Error ? error.error.message : null;

        hiddenDivCleanup();

        // Ignore the error if the file is valid DICOM, but simply does not have image data.  This is a common scenario for DICOM files.
        if (equalsInsensitive(errorMessage, 'The file does not contain image data.')) return;

        // Ignore errors when the embedded image data is not in a format that Cornerstone can render.  Videos in particular.
        if (errorMessage?.startsWith?.('No decoder for transfer syntax')) return;

        datadogLogs.logger.error(
          'Error while generating thumbnail:',
          { uploadSessionId: this._uploadSessionId, fileName: file.name ?? null },
          error as Error | undefined,
        );
      }
    } finally {
      releaseLock2?.();
      releaseLock1?.();
    }
  }
}
