import { forwardRef } from 'react';

import { faCaretLeft, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { animated } from '@react-spring/web';
import cn from 'classnames';
import styled from 'styled-components';

import { Icon } from '../Icon';
import { ItemCollapseIconButtonProps } from './ItemCollapseIconButtonProps';

/** Provides the expand/collapse icon wrapped in a <button>.  Functionality to rotate according to the expand/collapse state must be provided by a parent component that calls the `useAccordionItemView()` hook. */
export const ItemCollapseIconButton = forwardRef<SVGSVGElement, ItemCollapseIconButtonProps>(
  ({ className, block = true, mode = 'vertical', size = 'lg', style, onClick }, ref) => {
    return (
      <StyledButton type="button" onClick={onClick}>
        <StyledCollapseIcon
          ref={ref}
          className={cn(className, 'accordion-item-collapse-icon')}
          block={block}
          icon={mode === 'vertical' ? faCaretUp : faCaretLeft}
          size={size}
          style={style}
        />
      </StyledButton>
    );
  },
);

ItemCollapseIconButton.displayName = 'ItemCollapseIconButton';

const StyledButton = styled.button`
  appearance: none;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  color: inherit;
  cursor: pointer;

  flex: 0 0 50px;
  align-self: stretch;
`;

const StyledCollapseIcon = styled(animated(Icon))`
  flex: 0 0 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;
