import { memo } from 'react';

import { Card } from 'react-bootstrap';
import styled from 'styled-components';

import { FileModel } from 'models';

import { DocumentCategories } from 'features/exam/constants';

interface FileInfoBarProps {
  file: FileModel | null;
  $defaultCollapsed?: boolean;
}

export const FileInfoBar = memo(({ file, $defaultCollapsed = false }: FileInfoBarProps) => {
  if (!file) {
    return (
      <StyledContainer $collapsed={$defaultCollapsed}>
        <StyledCard>
          <Card.Body>
            <p>No file selected</p>
          </Card.Body>
        </StyledCard>
      </StyledContainer>
    );
  }

  // Get category name from categoryId
  const getCategoryName = (categoryId: number | null | undefined) => {
    if (categoryId === null || categoryId === undefined) return 'Uncategorized';

    // Convert categoryId to string for comparison with DocumentCategories values
    const categoryIdStr = String(categoryId);

    // Find matching category from DocumentCategories
    const category = Object.values(DocumentCategories).find((cat) => String(cat.value) === categoryIdStr);

    return category ? category.name : 'Unknown';
  };

  return (
    <StyledContainer $collapsed={$defaultCollapsed}>
      <StyledCard>
        <Card.Header>
          <StyledHeader>File Information</StyledHeader>
        </Card.Header>
        <Card.Body>
          <InfoSection>
            <InfoLabel>Name:</InfoLabel>
            <InfoValue>{file.fileName}</InfoValue>
          </InfoSection>
          <InfoSection>
            <InfoLabel>Type:</InfoLabel>
            <InfoValue>{file.fileType}</InfoValue>
          </InfoSection>
          <InfoSection>
            <InfoLabel>Category:</InfoLabel>
            <InfoValue>{getCategoryName(file.categoryId)}</InfoValue>
          </InfoSection>
          {file.created && (
            <InfoSection>
              <InfoLabel>Date Created:</InfoLabel>
              <InfoValue>{new Date(file.created).toLocaleString()}</InfoValue>
            </InfoSection>
          )}
          {file.modified && (
            <InfoSection>
              <InfoLabel>Last Modified:</InfoLabel>
              <InfoValue>{new Date(file.modified).toLocaleString()}</InfoValue>
            </InfoSection>
          )}
          {file.fileSize && (
            <InfoSection>
              <InfoLabel>File Size:</InfoLabel>
              <InfoValue>{formatFileSize(file.fileSize)}</InfoValue>
            </InfoSection>
          )}
        </Card.Body>
      </StyledCard>
    </StyledContainer>
  );
});

/**
 * Formats file size in bytes to a human-readable format
 */
function formatFileSize(bytes: number | null): string {
  if (bytes === null) return 'Unknown';
  if (bytes < 1024) return `${bytes} bytes`;
  if (bytes < 1024 * 1024) return `${(bytes / 1024).toFixed(1)} KB`;
  if (bytes < 1024 * 1024 * 1024) return `${(bytes / (1024 * 1024)).toFixed(1)} MB`;
  return `${(bytes / (1024 * 1024 * 1024)).toFixed(1)} GB`;
}

FileInfoBar.displayName = 'FileInfoBar';

const StyledContainer = styled.div<{ $collapsed: boolean }>`
  width: ${({ $collapsed }) => ($collapsed ? '0' : '270px')};
  overflow: hidden;
  transition: width 0.3s ease-in-out;
`;

const StyledCard = styled(Card)`
  border-radius: 0;
  border: none;
  height: 100%;
`;

const StyledHeader = styled.div`
  font-weight: bold;
  font-size: 1.1rem;
`;

const InfoSection = styled.div`
  margin-bottom: 12px;
`;

const InfoLabel = styled.div`
  font-weight: bold;
  color: #6c757d;
  font-size: 0.9rem;
`;

const InfoValue = styled.div`
  margin-top: 2px;
`;
