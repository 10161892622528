import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';

import { DataResult, DataSourceRequestState, SortDescriptor } from '@progress/kendo-data-query';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { GridColumn, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import debounce from 'awesome-debounce-promise';

import { apiClient } from 'core/api/globals';
import { useEvent } from 'core/hooks';
import { Button, ButtonVariants, DataTable, DateCell, Dialog, HeaderCell, Page, PageHeader, Toolbar } from 'core/ui';

import { DEFAULT_FILTER_DATA_STATE } from '../../patient/constants';

export const SendQueue: FunctionComponent = () => {
  const initialSort: SortDescriptor[] = [{ field: 'id', dir: 'desc' }];

  const [selected, setSelected] = useState({});
  const [dataState, setDataState] = useState<DataSourceRequestState>(DEFAULT_FILTER_DATA_STATE);
  const [sendQueues, setSendQueues] = useState({} as DataResult);
  const [queueDialogVisible, setQueueDialogVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const apiCall = useCallback((kendoState: DataSourceRequestState) => {
    return apiClient.sendQueue.getAllForKendoGrid(kendoState);
  }, []);

  const debouncedApiCall = useEvent(debounce(apiCall, 275));
  const gridContentRef = useRef<HTMLDivElement>(null);

  const fetchQueues = useCallback(
    async (showLoading = true) => {
      if (showLoading) {
        setIsLoading(true);
      }
      const response = await debouncedApiCall(dataState);
      setSendQueues(response);

      if (showLoading) {
        setIsLoading(false);
      }
    },
    [dataState, debouncedApiCall],
  );

  useEffect(() => {
    fetchQueues();
  }, [dataState, fetchQueues]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchQueues(false);
    }, 15000);
    return () => clearInterval(interval);
  }, [fetchQueues]);

  const dataStateChange = (e: GridDataStateChangeEvent) => {
    setDataState(e.dataState);
  };

  const toggleDialog = () => {
    setQueueDialogVisible(!queueDialogVisible);
  };

  const handleRequeueClick = () => {
    toggleDialog();
  };

  const requeue = async () => {
    await apiClient.sendQueue.requeue(Object.keys(selected).map((key) => parseInt(key, 10)));
    await fetchQueues();
    setSelected({});
    toggleDialog();
  };

  return (
    <Page>
      <div>
        <PageHeader title="Send Queue" />

        {queueDialogVisible && (
          <Dialog title="Please confirm" onClose={toggleDialog}>
            <span>Confirm you want to re-queue the following: </span>
            {Object.entries(selected)
              .filter(([, value]) => value === true)
              .map(([item], index) => (
                <span key={item}>
                  {index > 0 && ', '}
                  {item}
                </span>
              ))}

            <DialogActionsBar>
              <Button onClick={toggleDialog} variant={ButtonVariants.SECONDARY}>
                No
              </Button>
              <Button onClick={requeue}>Yes</Button>
            </DialogActionsBar>
          </Dialog>
        )}
      </div>
      <DataTable
        data={sendQueues?.data || []}
        sortable
        filterable
        onDataStateChange={dataStateChange}
        selectable
        pageable={{ pageSizes: true }}
        total={sendQueues?.total || 0}
        selectedState={selected}
        onSelectionChange={setSelected}
        sort={initialSort}
        isLoading={isLoading}
        {...dataState}
      >
        <Toolbar>
          <Button onClick={handleRequeueClick} disabled={!Object.values(selected).some((value) => value === true)}>
            Re-Queue
          </Button>
        </Toolbar>
        <GridColumn field="id" title="ID" headerCell={HeaderCell} filter="numeric" width="100px" />
        <GridColumn field="examId" title="Exam ID" filter="numeric" headerCell={HeaderCell} width="100px" />
        <GridColumn field="status" title="Status" headerCell={HeaderCell} width="100px" />
        <GridColumn field="response" title="Response" headerCell={HeaderCell} width="150px" />
        <GridColumn field="message" title="Message" headerCell={HeaderCell} width="200px" />
        <GridColumn field="type" title="Type" headerCell={HeaderCell} width="150px" />
        <GridColumn
          field="timeToSend"
          title="Time To Send"
          filterable={false}
          headerCell={HeaderCell}
          width="100px"
          cell={(cellProps) => <td>{cellProps.dataItem.timeToSend && <DateCell ellipsisPlacement="end" format="MM/DD/YYYY" {...cellProps} />}</td>}
        />
        <GridColumn field="retryCount" title="Retry Count" filter="numeric" headerCell={HeaderCell} width="70px" />
        <GridColumn field="numberOfRetries" title="Number of Retries" filter="numeric" headerCell={HeaderCell} width="70px" />
        <GridColumn field="destinationName" title="Destination Name" headerCell={HeaderCell} width="150px" />
        <GridColumn field="transactionId" title="Transaction ID" headerCell={HeaderCell} width="150px" />
        <GridColumn field="uri" title="URI" headerCell={HeaderCell} width="150px" />
        <GridColumn field="patientFileId" title="Patient File ID" headerCell={HeaderCell} width="70px" />
      </DataTable>
    </Page>
  );
};
