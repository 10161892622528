import { memo, useEffect, useState } from 'react';

import { faCheck, faFile, faFileJpg, faFilePdf, faFilePng, faFilesMedical, faWarning } from '@fortawesome/pro-solid-svg-icons';
import { Loader } from '@progress/kendo-react-indicators';
import Card from 'react-bootstrap/Card';
import styled from 'styled-components';

import { PatientModel, ShareStudyViewer } from 'models';

import { apiClient } from 'core/api/globals';
import { useEvent, useEventStream } from 'core/hooks';
import { NotificationsService } from 'core/notifications';
import { Dropdown, Icon, Page, Tooltip } from 'core/ui';

import { useParsedShareAccessToken } from 'features/auth';
import { useApiWebSocketConnection } from 'features/auth/hooks/useApiWebSocketConnection';
import { UPLOAD_FILE_MODAL_CATEGORY_OPTIONS } from 'features/patient/constants';
import { AddFilesButtons } from 'features/upload-exams/fragments/AddFilesButtons';
import { Classification } from 'features/upload-pipeline';
import { useUploadPipeline } from 'features/upload-pipeline/hooks/useUploadPipeline';

import { UploadStatusConstants } from '../constants';
import { ShareHeader } from './ShareHeader';

export const Upload = memo(() => {
  const shareToken = useParsedShareAccessToken(true);
  const uploadPipeline = useUploadPipeline();

  const [files, setFiles] = useState<FileState[]>([]); // Note: This will be in reverse order that the files are added because that's how we want to display.
  const [exam, setExam] = useState<ShareStudyViewer | null>(null);
  const [patient, setPatient] = useState<PatientModel | null>(null);
  const [selectedCategory, setSelectedCategory] = useState(UPLOAD_FILE_MODAL_CATEGORY_OPTIONS[0]);
  const connection = useApiWebSocketConnection();

  const isInitialized = exam != null || patient != null;
  const initialize = useEvent(async () => {
    const [newExams, newPatient] = await Promise.all([
      apiClient.studyShare.getStudyShareByLinkId(shareToken.shareLinkId),
      apiClient.patientClient.getPatient(shareToken.sharePatientId, 'share-required'),
    ]);

    if (newExams.length === 0 && newPatient.id === 0) throw new Error('No exams or patient found for the given share link ID.');

    // For QR uploads, we don't need an exam context
    if (newExams.length > 0) {
      setExam(newExams[0]); // For now we are assuming that there is exactly 1 exam in the share.  In the future we may want to support multiple exams.
    }

    setPatient(newPatient);

    // Set the default category if provided in the message field
    if (shareToken.message) {
      const categoryOption = UPLOAD_FILE_MODAL_CATEGORY_OPTIONS.find((cat) => cat.value === shareToken.message);
      if (categoryOption) {
        setSelectedCategory(categoryOption);
      }
    }
  });

  const handleCategoryChange = useEvent((e: { value: any }) => {
    const newCategory = UPLOAD_FILE_MODAL_CATEGORY_OPTIONS.find((cat) => cat.value === e.value);
    if (newCategory) {
      setSelectedCategory(newCategory);
    }
  });

  const handleAddAttachmentFiles = useEvent((newFiles: File[]) => {
    if (newFiles.length === 0) return;

    uploadPipeline.addFiles(
      newFiles.map((f) => ({
        fileId: crypto.randomUUID(),
        file: f,
        classification: Classification.Attachment,
        metadata: {
          examId: exam?.examId,
          patientId: patient?.id,
          shareLinkId: shareToken.shareLinkId,
          category: selectedCategory.value,
        },
      })),
    );
  });

  useEventStream(uploadPipeline.streams.onFilesLoaded, (event) => {
    setFiles((prev) => {
      // Add the new files in reverse order (newest first).
      const newFiles = [
        ...event.files
          .slice()
          .reverse()
          .map(
            (f) =>
              ({
                fileId: f.fileId,
                fileName: f.file.name,
                fileType: null,
                status: 'in-progress',
              }) satisfies FileState,
          ),
        ...prev,
      ];

      return newFiles;
    });
  });

  useEventStream(uploadPipeline.fileScanner.streams.onParseComplete, (event) => {
    setFiles((prev) => {
      if (event.result !== 'processed') return prev;

      const newFiles = [...prev];
      const fileIndex = newFiles.findIndex((f) => f.fileId === event.fileId);

      if (fileIndex === -1) throw new Error('File not found in state.');

      newFiles[fileIndex] = {
        ...newFiles[fileIndex],
        fileType: event.fileType,
      };

      return newFiles;
    });
  });

  useEventStream(uploadPipeline.blobUploader.streams.onError, (event) => {
    setFiles((prev) => {
      const newFiles = [...prev];
      const fileIndex = newFiles.findIndex((f) => f.fileId === event.fileId);

      if (fileIndex === -1) throw new Error('File not found in state.');

      newFiles[fileIndex] = {
        ...newFiles[fileIndex],
        status: 'error',
      };

      return newFiles;
    });

    const file = files.find((item) => item.fileId === event.fileId);

    if (file == null) throw new Error('File not found in state.');

    NotificationsService.displayError(`There was an error uploading the file: ${file.fileName}.`);
  });

  useEventStream(uploadPipeline.fileAttacher.streams.onFileAttached, (event) => {
    if (event.result !== 'processed') return;

    setFiles((prev) => {
      const newFiles = [...prev];
      const fileIndex = newFiles.findIndex((f) => f.fileId === event.fileId);

      if (fileIndex === -1) throw new Error('File not found in state.');

      newFiles[fileIndex] = {
        ...newFiles[fileIndex],
        status: 'success',
      };

      return newFiles;
    });

    connection.send('updateShareUploadStatus', UploadStatusConstants.uploaded);
  });

  useEffect(() => {
    initialize();
  }, [initialize]);

  if (!isInitialized) return null;
  if (!patient) return null;

  return (
    <Page>
      <ShareHeader patient={patient} />
      <div>
        <StyledCenteredContainer>
          <StyledUploadContainer>
            <StyledCategorySelector>
              <Dropdown
                name="category"
                label="Category"
                data={UPLOAD_FILE_MODAL_CATEGORY_OPTIONS}
                dataItemKey="value"
                textField="name"
                value={selectedCategory}
                onChange={handleCategoryChange}
              />
            </StyledCategorySelector>
            <StyledAddFilesButton onAddAttachmentFiles={handleAddAttachmentFiles} />
            <StyledPatientInfo>
              <Card>
                <Card.Header>Patient Information</Card.Header>
                <Card.Body>
                  {patient?.unosID && (
                    <div>
                      <strong>UNOS ID:</strong> {patient.unosID}
                    </div>
                  )}
                  <div>
                    <strong>Name:</strong> {patient.firstName ?? ''} {patient.lastName ?? ''}
                  </div>
                  <div>
                    <strong>Patient Number:</strong> {patient.patientNumber}
                  </div>
                  <div>
                    <strong>DOB:</strong> {patient.dob}
                  </div>
                  <div>
                    <strong>Gender:</strong> {patient?.gender}
                  </div>
                </Card.Body>
              </Card>
            </StyledPatientInfo>
          </StyledUploadContainer>
        </StyledCenteredContainer>
        <StyledSummaryCenteredContainer>
          {files.length > 0 && (
            <StyledSummaryContainer>
              {files.map((file) => (
                <Card key={file.fileId}>
                  <Card.Body>
                    <StyledCardBodyContent>
                      <Icon icon={getFileTypeIcon(file.fileType)}></Icon>
                      <div>{file.fileName}</div>
                      <Tooltip text={file.status === 'error' ? 'There was an error uploading this file' : null}>
                        <StyledResultIndicator>
                          {file.status === 'in-progress' ? (
                            <Loader></Loader>
                          ) : (
                            <StyledIcon $status={file.status} icon={file.status === 'error' ? faWarning : faCheck} fixedWidth={false} />
                          )}
                        </StyledResultIndicator>
                      </Tooltip>
                    </StyledCardBodyContent>
                  </Card.Body>
                </Card>
              ))}
            </StyledSummaryContainer>
          )}
        </StyledSummaryCenteredContainer>
      </div>
    </Page>
  );
});

Upload.displayName = 'Upload';

type FileState = {
  fileId: string;
  fileName: string;
  fileType: string | null;
  status: 'success' | 'error' | 'in-progress';
};

function getFileTypeIcon(fileType: string | null | undefined) {
  switch (fileType?.toUpperCase()) {
    case 'PDF':
      return faFilePdf;
    case 'PNG':
      return faFilePng;
    case 'JPG':
      return faFileJpg;
    case 'JPEG':
      return faFileJpg;
    case 'SZI':
      return faFilesMedical;
    case 'TIF':
      return faFilesMedical;
    case 'TIFF':
      return faFilesMedical;
    default:
      return faFile;
  }
}

const StyledCenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const StyledSummaryCenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`;

const StyledAddFilesButton = styled(AddFilesButtons)`
  && > button {
    height: 3rem;
    width: 20rem;
  }
`;

const StyledSummaryContainer = styled.div`
  margin-top: 20px;
  overflow-y: auto;
  width: 100%;

  @media (min-width: 768px) {
    max-width: 80%;
  }
`;

const StyledCardBodyContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledIcon = styled(Icon)<{ $status: 'success' | 'error' | 'in-progress' | undefined }>`
  margin-left: 10px;
  && {
    color: ${({ $status, theme }) => ($status === 'in-progress' ? theme.colors.primary : $status === 'error' ? theme.colors.error : theme.colors.success)};
  }
`;

const StyledResultIndicator = styled.div`
  width: 25px;
  height: 5px;
`;

const StyledPatientInfo = styled.div`
  margin-top: 20px;
`;

const StyledCategorySelector = styled.div`
  width: 200px;
  margin-bottom: 20px;
`;
