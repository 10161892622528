import { memo, useEffect, useState } from 'react';

import styled from 'styled-components';

import { apiClient } from 'core/api/globals';
import { useEvent } from 'core/hooks';

type ConnectionStatusType = 'Online' | 'Offline';

type ConnectionStatusProps = {
  className?: string;
};

export const ConnectionStatus = memo<ConnectionStatusProps>(({ className }) => {
  const [status, setStatus] = useState<ConnectionStatusType>('Online');

  const checkConnectionStatus = useEvent(async () => {
    try {
      const ok = await apiClient.dicomClient.testConnection();
      if (ok) {
        setStatus('Online');
      } else {
        setStatus('Offline');
      }
    } catch {
      setStatus('Offline');
    }
  });

  useEffect(() => {
    // Initial check
    checkConnectionStatus();

    // Set up periodic connection status checking
    const intervalId = setInterval(() => {
      checkConnectionStatus();
    }, 30000); // Check every 30 seconds

    return () => clearInterval(intervalId);
  }, [checkConnectionStatus]);

  return (
    <StyledContainer className={className} $status={status}>
      Connection: <StatusIndicator $status={status} /> {status}
    </StyledContainer>
  );
});

ConnectionStatus.displayName = 'ConnectionStatus';

const StyledContainer = styled.div<{ $status: ConnectionStatusType }>`
  display: flex;
  align-items: center;
  padding: 0 10px;
  white-space: nowrap;
  border-radius: 4px;
  border: 1px solid
    ${({ $status, theme }) =>
      $status === 'Online' ? theme.colors.palette.greens[2] : $status === 'Offline' ? theme.colors.palette.reds[2] : theme.colors.palette.yellows[2]};
  background-color: ${({ $status: $status, theme }) =>
    $status === 'Online' ? theme.colors.palette.greens[0] : $status === 'Offline' ? theme.colors.palette.reds[0] : theme.colors.palette.yellows[0]};
  user-select: none;
  line-height: ${({ theme }) => theme.lineHeights.subheading};
  height: ${({ theme }) => theme.sizes.large};
`;

const StatusIndicator = styled.div<{ $status: ConnectionStatusType }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 5px;
  background-color: ${({ $status, theme }) =>
    $status === 'Online' ? theme.colors.palette.greens[5] : $status === 'Offline' ? theme.colors.palette.reds[5] : theme.colors.palette.yellows[5]};
`;
