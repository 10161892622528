import { TagModel } from 'models/TagModel';

import { findOrThrow } from 'core/utils';

import { EditFormValues } from '../types';

const EditFormDefaults: EditFormValues = {
  name: '',
  description: '',
  parentTag: null,
  active: true,
  tagFieldOption: null,
  required: false,
  multipleSelection: false,
  children: [],
};

function copyModelToForm(model: TagModel, allTags: TagModel[]): EditFormValues {
  const parentTag =
    model.parentTagId != null ? findOrThrow(allTags, (t) => t.id === model.parentTagId, `Could not find Parent Tag with id: ${model.parentTagId}.`) : null;

  return {
    ...model,
    parentTag,
  };
}

function copyFormToModel(id: number, form: EditFormValues): TagModel {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { parentTag, ...rest } = form;
  return {
    ...rest,
    id: id,
    parentTagId: form.parentTag == null ? null : form.parentTag.id,
    description: (form.description ?? '').trim(),
    name: form.name.trim(),
  };
}

function createDefaultSource(): TagModel {
  return copyFormToModel(0, EditFormDefaults);
}

export const TagsEditService = {
  EditFormDefaults,
  copyModelToForm,
  copyFormToModel,
  createDefaultSource,
};
