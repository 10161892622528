import { SettingDefinitionModel } from 'models';

import { apiClient } from 'core/api/globals';

import { EditDefinitionFormValues } from '../types';

async function initialize() {
  const definitions = await apiClient.settingsClient.getSettingDefinitions();

  return {
    definitions,
  };
}

function modelToFormValues(model: SettingDefinitionModel): EditDefinitionFormValues {
  return {
    name: model.name,
    description: model.description ?? '',
    value: model.value ?? '',
    system: model.system,
    active: model.active,
  };
}

export const PortalSettingsService = {
  initialize,
  modelToFormValues,
};
