import { DateFilter, Operators as KendoOperators, NumericFilter, TextFilter } from '@progress/kendo-react-data-tools';
import { GridCellProps, GridColumnMenuFilter, GridColumnMenuProps, GridColumnMenuSort } from '@progress/kendo-react-grid';
import dayjs from 'dayjs';

import { ColumnState, DateCell, NumberCell, TextCell } from 'core/ui';
import { colors } from 'core/ui/theme/tokens';
import { findOrThrow } from 'core/utils';

import { ColumnDefinition } from 'features/exam/types';

function transformDuration(value: unknown) {
  if (typeof value !== 'number') return '';

  const days = Math.floor(dayjs.duration(value, 'seconds').asDays());
  const remainder = dayjs.duration(value, 'seconds').asDays() - days;

  if (days > 0) {
    return `${days === 1 ? `${days} day` : `${days} days`} ${dayjs.duration(remainder, 'days').format('HH:mm:ss')}`;
  } else {
    return dayjs.duration(remainder, 'days').format('HH:mm:ss');
  }
}

const ColumnMenu = (props: GridColumnMenuProps) => {
  return (
    <div>
      <GridColumnMenuSort {...props} />
      <GridColumnMenuFilter {...props} />
    </div>
  );
};

const TotalUploadTimeCell = (props: GridCellProps) => {
  return <NumberCell {...props} valueTransform={UploadTrackerService.transformDuration} />;
};

const PacificTimeCell = (props: GridCellProps) => {
  return <DateCell {...props} timeZone={'America/Los_Angeles'} />;
};

const FileSizeCell = (props: GridCellProps) => {
  return <TextCell {...props} valueTransform={formatFileSizeMB} />;
};

const TransferSpeedCell = (props: GridCellProps) => {
  //return <TextCell {...props} valueTransform={formatTransferSpeed} />;

  const speed = props.dataItem[props.field || ''];
  const style = { backgroundColor: '' };
  if (speed >= 5) {
    style.backgroundColor = colors.palette.greens[3];
  } else if (speed < 5 && speed >= 1) {
    style.backgroundColor = colors.warning;
  } else if (speed > 0 && speed < 1) {
    style.backgroundColor = colors.palette.reds[3];
  }

  return <td style={style}>{formatTransferSpeed(speed)}</td>;
};

export function formatFileSizeMB(bytes: unknown) {
  if (typeof bytes !== 'number') return '';

  if (!bytes) return '';

  //const size = (bytes / 1000000).toFixed(2) + ' MB';
  const size = bytes.toFixed(2) + ' MB';

  return size;
}

export function formatTransferSpeed(value: unknown) {
  if (typeof value !== 'number') return '';

  if (!value) return '';

  return value.toFixed(2) + ' mbps';
}

// exam grid column collections
const Columns: ColumnDefinition[] = [
  {
    field: 'examId',
    title: 'Exam ID',
    headerCellDescription: 'Exam ID',
    filter: NumericFilter,
    columnFilter: 'numeric',
    operators: KendoOperators.numeric,
    width: '70px',
    cell: NumberCell,
  },
  {
    field: 'location',
    title: 'Location',
    headerCellDescription: 'Customer Location',
    filter: TextFilter,
    columnFilter: 'text',
    operators: KendoOperators.text,

    width: '170px',
    cell: TextCell,
  },
  {
    //TODO: use dropdown for filter and filtercell
    field: 'locationType',
    title: 'Location Type',
    filter: TextFilter,
    columnFilter: 'text',
    operators: KendoOperators.text,
    width: '90px',
    cell: TextCell,
    headerCellDescription: 'Location Type',
  },
  {
    field: 'userProfile',
    title: 'User',
    filter: TextFilter,
    columnFilter: 'text',
    operators: KendoOperators.text,
    width: '140px',
    cell: TextCell,
    headerCellDescription: 'User',
  },
  {
    field: 'modality',
    title: 'Modality',
    filter: TextFilter,
    columnFilter: 'text',
    operators: KendoOperators.text,
    width: '70px',
    cell: TextCell,
    headerCellDescription: 'Modality or source medical machine',
  },
  {
    field: 'studyDescription',
    title: 'Exam Description',
    filter: TextFilter,
    columnFilter: 'text',
    operators: KendoOperators.text,
    width: '180px',
    cell: TextCell,
    headerCellDescription: 'Location Type',
  },
  {
    field: 'patientName',
    title: 'Patient Name',
    filter: TextFilter,
    columnFilter: 'text',
    operators: KendoOperators.text,
    width: '140px',
    cell: TextCell,
    headerCellDescription: 'Full Patient Name',
  },
  {
    field: 'unosid',
    title: 'UNOS ID',
    filter: TextFilter,
    columnFilter: 'text',
    operators: KendoOperators.text,
    width: '100px',
    cell: TextCell,
    headerCellDescription: 'UNOS ID',
  },
  {
    field: 'status',
    title: 'Status',
    filter: TextFilter,
    columnFilter: 'text',
    operators: KendoOperators.text,
    width: '70px',
    cell: TextCell,
    headerCellDescription: 'Upload Status',
  },
  {
    cell: NumberCell,
    columnMenu: ColumnMenu,
    field: 'totalScannedCount',
    filter: NumericFilter,
    columnFilter: 'numeric',
    operators: KendoOperators.numeric,
    title: 'Scanned Files',
    width: '130px',
    headerCellDescription: 'Total files scanned',
  },
  {
    field: 'imageCount',
    title: 'Expected Images',
    filter: NumericFilter,
    columnFilter: 'numeric',
    operators: KendoOperators.numeric,
    width: '130px',
    cell: NumberCell,
    columnMenu: ColumnMenu,
    headerCellDescription: 'Total Images Expected',
  },
  {
    field: 'sendCount',
    title: 'Received Images',
    filter: NumericFilter,
    columnFilter: 'numeric',
    operators: KendoOperators.numeric,
    width: '130px',
    cell: NumberCell,
    columnMenu: ColumnMenu,
    headerCellDescription: 'Images Received',
  },
  {
    field: 'uploadStartTime',
    title: 'Upload Start',
    filter: DateFilter,
    columnFilter: 'date',
    operators: KendoOperators.date,
    width: '120px',
    cell: PacificTimeCell,
    columnMenu: ColumnMenu,
    headerCellDescription: 'Time Upload Began',
  },
  {
    field: 'completedDateTime',
    title: 'Upload Complete',
    filter: DateFilter,
    columnFilter: 'date',
    operators: KendoOperators.date,
    width: '140px',
    cell: PacificTimeCell,
    columnMenu: ColumnMenu,
    headerCellDescription: 'Time Upload Ended',
  },
  {
    field: 'totalUploadTime',
    title: 'Upload Time',
    filter: NumericFilter,
    columnFilter: 'numeric',
    operators: KendoOperators.numeric,
    width: '120px',
    cell: TotalUploadTimeCell,
    columnMenu: ColumnMenu,
    headerCellDescription: 'Total Upload Time',
  },
  {
    cell: TransferSpeedCell,
    columnMenu: ColumnMenu,
    field: 'calculatedSpeedMbps',
    filter: NumericFilter,
    columnFilter: 'numeric',
    operators: KendoOperators.numeric,
    title: 'Upload Speed',
    width: '140px',
    headerCellDescription: 'Calculated upload speed in Mbps',
  },
  {
    field: 'firstFileReceived',
    title: 'First File Received',
    filter: DateFilter,
    columnFilter: 'date',
    operators: KendoOperators.date,
    width: '120px',
    cell: PacificTimeCell,
    columnMenu: ColumnMenu,
    headerCellDescription: 'First File Received',
  },
  {
    field: 'lastFileReceived',
    title: 'Last File Received',
    filter: DateFilter,
    columnFilter: 'date',
    operators: KendoOperators.date,
    width: '140px',
    cell: PacificTimeCell,
    columnMenu: ColumnMenu,
    headerCellDescription: 'Last File Received',
  },
  {
    field: 'totalReceiveTime',
    title: 'Receive Time',
    filter: NumericFilter,
    columnFilter: 'numeric',
    operators: KendoOperators.numeric,
    width: '120px',
    cell: TotalUploadTimeCell,
    columnMenu: ColumnMenu,
    headerCellDescription: 'Total Receive Time - difference of first and last file received',
  },
  {
    cell: TransferSpeedCell,
    columnMenu: ColumnMenu,
    field: 'receiveSpeedMbps',
    filter: NumericFilter,
    columnFilter: 'numeric',
    operators: KendoOperators.numeric,
    title: 'Receive Speed',
    width: '140px',
    headerCellDescription: 'Calculated receive speed in Mbps',
  },

  {
    cell: FileSizeCell,
    field: 'sizeMB',
    filter: NumericFilter,
    columnFilter: 'numeric',
    operators: KendoOperators.numeric,
    title: 'File Size',
    columnMenu: ColumnMenu,
    headerCellDescription: 'Uploaded file size',
    width: '110px',
  },
  {
    cell: TransferSpeedCell,
    columnMenu: ColumnMenu,
    field: 'averageSpeedMbps',
    filter: NumericFilter,
    columnFilter: 'numeric',
    operators: KendoOperators.numeric,
    title: 'Average Speed',
    width: '140px',
    headerCellDescription: 'Calculated receive speed in Mbps',
  },
  {
    cell: FileSizeCell,
    field: 'batchSizeMB',
    filter: NumericFilter,
    columnFilter: 'numeric',
    operators: KendoOperators.numeric,
    title: 'Batch Size',
    columnMenu: ColumnMenu,
    headerCellDescription: 'Batch file size for web uploads (Megabytes)',
    width: '110px',
  },
  {
    cell: TextCell,
    field: 'requestUID',
    filter: TextFilter,
    columnFilter: 'text',
    operators: KendoOperators.text,
    title: 'Request UID',
    width: '100px',
    headerCellDescription: 'UID for batch upload',
  },
  {
    cell: TextCell,
    field: 'studyInstanceUID',
    filter: TextFilter,
    columnFilter: 'text',
    operators: KendoOperators.text,
    title: 'Study Instance UID',
    width: '300px',
    headerCellDescription: 'Study Instance UID',
  },
  {
    cell: FileSizeCell,
    field: 'scanSizeMB',
    filter: NumericFilter,
    columnFilter: 'numeric',
    operators: KendoOperators.numeric,
    title: 'Scanned Size',
    width: '80px',
    headerCellDescription: 'Scanned file size (Megabytes)',
  },
  {
    cell: PacificTimeCell,
    columnMenu: ColumnMenu,
    field: 'scanStartTime',
    filter: DateFilter,
    columnFilter: 'date',
    operators: KendoOperators.date,

    title: 'Scan Start',
    width: '130px',
    headerCellDescription: 'Time scanning files began',
  },
  {
    cell: PacificTimeCell,
    columnMenu: ColumnMenu,
    field: 'scanEndTime',
    filter: DateFilter,
    columnFilter: 'date',
    operators: KendoOperators.date,

    title: 'Scan End',
    width: '130px',
    headerCellDescription: 'Time scanning files ended',
  },
  {
    field: 'totalScannedTime',
    title: 'Scan Time',
    filter: NumericFilter,
    columnFilter: 'numeric',
    operators: KendoOperators.numeric,
    width: '120px',
    cell: TotalUploadTimeCell,
    columnMenu: ColumnMenu,
    headerCellDescription: 'Total Scan Time',
  },
  {
    cell: PacificTimeCell,
    field: 'compressStartTime',
    filter: DateFilter,
    columnFilter: 'date',
    operators: KendoOperators.date,
    title: 'Compress Start',
    columnMenu: ColumnMenu,
    width: '130px',
    headerCellDescription: 'Time compression began',
  },
  {
    cell: PacificTimeCell,
    field: 'compressEndTime',
    filter: DateFilter,
    columnFilter: 'date',
    operators: KendoOperators.date,
    title: 'Compress End',
    columnMenu: ColumnMenu,
    width: '130px',
    headerCellDescription: 'Time compressing files ended',
  },
  {
    field: 'totalCompressedTime',
    title: 'Compress Time',
    filter: NumericFilter,
    columnFilter: 'numeric',
    operators: KendoOperators.numeric,
    width: '120px',
    cell: TotalUploadTimeCell,
    columnMenu: ColumnMenu,
    headerCellDescription: 'Total Compression Time',
  },
  {
    cell: TextCell,
    field: 'source',
    filter: TextFilter,
    columnFilter: 'text',
    operators: KendoOperators.text,
    title: 'Source',
    columnMenu: ColumnMenu,
    width: '90px',
    headerCellDescription: 'How this file was uploaded',
  },
  {
    cell: TextCell,
    field: 'device',
    filter: TextFilter,
    columnFilter: 'text',
    operators: KendoOperators.text,
    title: 'Device',
    headerCellDescription: 'Where this file came from',
    width: '90px',
  },
];

function getColumnDefinition(field: string) {
  return findOrThrow(Columns, (column) => column.field === field, `Could not find column definition for: "${field}".`);
}

function getColumnState(allColumnStates: Record<string, ColumnState>, columnField: string): ColumnState {
  const orderIndex = allColumnStates[columnField]?.orderIndex ?? 0;
  const show = allColumnStates[columnField]?.show ?? false;

  return { orderIndex, show };
}

export const UploadTrackerService = {
  transformDuration,
  getColumnDefinition,
  Columns,
  getColumnState,
};
