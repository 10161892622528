import dicomParser from 'dicom-parser';

import { DicomDataDictionary } from './dicom-data-dictionary';
import { DicomTagDefinition, UploadPipelineOptions } from './types';

export const DATE_PARSE_FORMATS = ['YYYYMMDD', 'YYYY-MM-DD', 'MM/DD/YYYY']; // Each format is tried in order until a valid date is found.
export const TIME_PARSE_FORMATS = ['HHmmss', 'HH:mm:ss']; // Each format is tried in order until a valid time is found.

/** Transfer syntaxes that indicate the embedded media is uncompressed. */
export const DICOM_UNCOMPRESSED_TRANSFER_SYNTAXES = [
  '1.2.840.10008.1.2', // Implicit VR Endian: Default Transfer Syntax for DICOM
  '1.2.840.10008.1.2.1', // Explicit VR Little Endian
  '1.2.840.10008.1.2.1.99', // Deflated Explicit VR Little Endian
  '1.2.840.10008.1.2.2', // Explicit VR Big Endian
];

export const NORMALIZED_DATE_FORMAT = 'MM/DD/YYYY';
export const NORMALIZED_TIME_FORMAT = 'HH:mm:ss';

/** DICOM tags that have caret delimited values.  These will be split on the "^" character into an array of strings. */
export const PARSE_DICOM_CARET_DELIMITED_TAGS: DicomTagDefinition[] = [
  DicomDataDictionary['(0010,0010)'], // PatientName
];

/** DICOM tags that we need to extract from files.  The tags enumerated here are used to hydrate the parsed dicom DTO. */
export const PARSE_DICOM_TAGS: DicomTagDefinition[] = [
  DicomDataDictionary['(0008,0050)'], // AccessionNumber
  DicomDataDictionary['(0018,0015)'], // BodyPartExamined
  DicomDataDictionary['(0008,1030)'], // StudyDescription
  DicomDataDictionary['(0010,0030)'], // PatientBirthDate
  DicomDataDictionary['(0010,0020)'], // PatientID
  DicomDataDictionary['(0010,0040)'], // PatientSex
  DicomDataDictionary['(0010,0010)'], // PatientName
  DicomDataDictionary['(0008,0020)'], // StudyDate
  DicomDataDictionary['(0008,0030)'], // StudyTime
  DicomDataDictionary['(0020,000D)'], // StudyInstanceUID
  DicomDataDictionary['(0008,0060)'], // Modality
  DicomDataDictionary['(0020,000E)'], // SeriesInstanceUID
  DicomDataDictionary['(0008,103E)'], // SeriesDescription
  DicomDataDictionary['(0002,0010)'], // TransferSyntaxUID
];

/** DICOM tags that need to be parsed as date-only fields.  These will be normalized to a consistent format.  Values that cannot be parsed as valid dates are logged and omitted from the parsed result. */
export const PARSE_DICOM_DATE_TAGS: DicomTagDefinition[] = [
  DicomDataDictionary['(0010,0030)'], // PatientBirthDate
  DicomDataDictionary['(0008,0020)'], // StudyDate
];

/** DICOM tags that need to be parsed as time-only fields.  These will be normalized to a consistent format.  Values that cannot be parsed as valid dates are logged and omitted from the parsed result. */
export const PARSE_DICOM_TIME_TAGS: DicomTagDefinition[] = [
  DicomDataDictionary['(0008,0030)'], // StudyTime
];

/** DICOM tag common name to encoded form lookup. */
export const DICOM_TAG_LOOKUPS = {
  /** @see https://dicom.nema.org/medical/dicom/current/output/html/part06.html#chapter_6 */
  SeriesInstanceUID: 'x0020000e',
  /** @see https://dicom.nema.org/medical/dicom/current/output/html/part05.html#sect_10.1 */
  TransferSyntaxUID: '1.2.840.10008.1.2',
} as const;

export const PARSE_DICOM_METADATA_OPTIONS: NonNullable<Parameters<typeof dicomParser.parseDicom>[1]> = {
  TransferSyntaxUID: DICOM_TAG_LOOKUPS.TransferSyntaxUID,
  untilTag: DICOM_TAG_LOOKUPS.SeriesInstanceUID,
};

export const PARSE_DICOM_METADATA_FILE_DATA_OPTIONS: NonNullable<Parameters<typeof dicomParser.explicitDataSetToJS>[1]> = {
  omitPrivateAttibutes: false, // Note: The dicomParser library has a typo in their option's object type definition.
  maxElementLength: 128,
};

/** Maximum number of concurrent file uploads.  Some points that were considered when deciding a suitable value:
 *    1) As of September 2024, Azure Blob Storage only supports HTTP/1.1.
 *    2) As of September 2024, Chrome has a limit of 6 concurrent connections to the same domain for HTTP/1.1.
 *    3) Additional connections will automatically be queued by the browser and started as soon as a connection slot opens up.
 *
 * With these points in mind, a value of 50 was chosen in order to minimize the amount of downtime between file uploads.
 */
export const MAX_UPLOAD_CONCURRENCY = 50;

/** Thumbnail width in pixels. */
export const THUMBNAIL_WIDTH = 120;

/** Thumbnail height in pixels. */
export const THUMBNAIL_HEIGHT = 140;

/** Minimum DICOM file size in order to potentially trigger an uncompressed image warning. */
export const UNCOMPRESSED_DICOM_WARNING_MINIMUM_SIZE = 2 ** 20; // 1 MiB

/** Default maximum size for the image data cache.  This is where the file thumbnails are stored.  Set to 32 MiB. */
export const IMAGE_DATA_CACHE_DEFAULT_MAX_SIZE = 32 * 2 ** 20; // Note: Remember to update the intellisense comment to match the human-readable value.

export const DEFAULT_UPLOAD_PIPELINE_OPTIONS: Required<Omit<UploadPipelineOptions, 'uploadSessionId' | 'queryClient'>> = {
  mode: '1-shot',
  authMode: 'msal-required',
  cdMode: true,
  triggerCdMode: 'manual',
  generateThumbnails: false,
  getSasFn: () => {
    throw new Error('Uninitialized getSasFn() called.');
  },
};

/** Maximum concurrency for disk reads when NOT operating in CD mode. */
export const DISK_LOCK_SSD_CONCURRENCY = 10;
