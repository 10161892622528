import { memo, useEffect, useState } from 'react';

import { State } from '@progress/kendo-data-query';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ExamGridModel, FileModel } from 'models';

import { DataResult } from 'core/api';
import { apiClient } from 'core/api/globals';
import { useAsyncCallback, useBoolean, useEvent } from 'core/hooks';
import { ColumnState, Page, PageHeader } from 'core/ui';

import { useQueryPatient } from 'features/api';
import { useParsedShareAccessToken } from 'features/auth';
import { ExamGridDataStateChangeEvent } from 'features/exam';
import { UploadWindow, ViewFileWindow } from 'features/file/fragments';
import { usePatientVerbiage } from 'features/patient';
import { PatientEditExamGrid } from 'features/patient/fragments/PatientEditExamGrid';
import { PatientGridService } from 'features/patient/services/patient-grid-service';

import { SharePatientInfoAccordion } from './SharePatientInfoAccordion';

export const ShareHome = memo(() => {
  const { sharePatientId, shareLinkId } = useParsedShareAccessToken(true);
  const [{ isSuccess: isQueryPatientSuccess }] = useQueryPatient(sharePatientId);
  const patientVerbiage = usePatientVerbiage();
  const navigate = useNavigate();

  const [dataState, setDataState] = useState<State>(PatientGridService.getDefaultPatientExamDataState(sharePatientId));
  const [columnsState, setColumnsState] = useState<Record<string, ColumnState>>({});
  const [examsGridData, setExamsGridData] = useState<DataResult<ExamGridModel>>({ data: [], total: 0 });
  const [isViewAddAttachmentsModalVisible, { setTrue: openViewAddAttachmentsModal, setFalse: closeViewAddAttachmentsModal }] = useBoolean(false);
  const [isViewFinalReportModalVisible, { setTrue: openViewFinalReportModal, setFalse: closeViewFinalReportModal }] = useBoolean(false);
  const [selectedExamId, setSelectedExamId] = useState<number | null>(null);
  const [viewFile, setViewFile] = useState<FileModel | null>(null);

  const [fetchExams] = useAsyncCallback(async (signal, dataStateOverride?: State | null) => {
    const queryDataState = dataStateOverride ?? dataState;
    const newExams = await apiClient.exams.getAllForKendoGrid(queryDataState, 'share-required', signal);

    setExamsGridData(newExams);
  });

  const handleDataStateChange = useEvent((event: ExamGridDataStateChangeEvent) => {
    setDataState(event.dataState);
    fetchExams(event.dataState);
  });

  const handleViewExam = useEvent((exam: ExamGridModel) => {
    navigate(`/share/${shareLinkId}/file-viewer/${exam.id}?feature=exams`);
  });

  const handleViewAddAttachmentsClick = useEvent((exam: ExamGridModel) => {
    openViewAddAttachmentsModal();
    setSelectedExamId(exam.id);
  });

  const handleViewAddAttachmentsChange = useEvent(() => {
    fetchExams();
  });

  const handleViewAddAttachmentsClose = useEvent(() => {
    closeViewAddAttachmentsModal();
    setSelectedExamId(null);
  });

  const handleViewFinalReport = useEvent((exam: ExamGridModel) => {
    if (exam.finalReportFile == null) {
      throw new Error('Final report file is not available.');
    }

    setViewFile(exam.finalReportFile ?? null);
    openViewFinalReportModal();
  });

  const handleViewFinalReportClose = useEvent(() => {
    setViewFile(null);
    closeViewFinalReportModal();
  });

  useEffect(() => {
    fetchExams();
  }, [fetchExams]);

  if (!isQueryPatientSuccess) return null;

  return (
    <StyledPage>
      <PageHeader title={`${patientVerbiage} Share`} />
      <SharePatientInfoAccordion patientId={sharePatientId} />
      <PatientEditExamGrid
        donorToolbar={false}
        shareToggle={false}
        featureView="Share"
        columnsState={columnsState}
        dataState={dataState}
        gridData={examsGridData.data}
        handleViewFinalReport={handleViewFinalReport}
        handleViewAttachments={handleViewAddAttachmentsClick}
        handleViewDicomImages={handleViewExam}
        handleChangeStatusSubmit={NO_OP}
        handleDataStateChange={handleDataStateChange}
        activeExamToggle={true}
        setShareToggle={NO_OP}
        setColumnsState={setColumnsState}
        total={examsGridData.total}
        handleExamUpdate={NO_OP}
        handleReadClick={NO_OP}
        handleExpandChange={NO_OP}
      />
      {isViewAddAttachmentsModalVisible && selectedExamId != null && (
        <UploadWindow examId={selectedExamId} onChange={handleViewAddAttachmentsChange} onClose={handleViewAddAttachmentsClose} />
      )}
      {isViewFinalReportModalVisible && viewFile != null && <ViewFileWindow title="Final Report" file={viewFile} onClose={handleViewFinalReportClose} />}
    </StyledPage>
  );
});

ShareHome.displayName = 'ShareHome';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const NO_OP = () => {};

const StyledPage = styled(Page)`
  grid-template-rows: min-content min-content;
  grid-auto-rows: min-content;
`;
