import { UseQueryResult, skipToken, useQuery } from '@tanstack/react-query';

import { FileModel } from 'models';

import { apiClient } from 'core/api/globals';

import { AuthenticationScheme, useAssertFetchPermitted } from 'features/auth';

/**
 * Hook to fetch files associated with an exam.
 * @param examId - The ID of the exam to fetch files for. Can be null/undefined/0 to support optional queries.
 */
export function useQueryFilesByExamId(examId: number | null | undefined): UseQueryResult<FileModel[]> {
  const activeScheme = useAssertFetchPermitted([AuthenticationScheme.OIDC, AuthenticationScheme.SHARE]);

  return useQuery({
    queryKey: ['files', 'by-exam-id', examId],
    queryFn:
      activeScheme == null || examId == null || examId === 0
        ? skipToken
        : () => apiClient.filesClient.getFilesByExamId(examId, activeScheme === AuthenticationScheme.OIDC ? 'msal-required' : 'share-required'),
    staleTime: 1000 * 60 * 5, // 5 minutes
  });
}
