import { BlobHTTPHeaders, BlobServiceClient, ContainerClient } from '@azure/storage-blob';
import { datadogLogs } from '@datadog/browser-logs';

import { ConnectionString, ContainerName } from 'models';

import { EventStream, IEventStreamConsumer, MIME_TYPES, getFileExtension, hasText, isPromise, lookupMimeType } from 'core/utils';

import {
  AzureBlobUploaderFileContext,
  Classification,
  FileUploadContext,
  ProcessJobEvent,
  UploadCompleteEvent,
  UploadErrorEvent,
  UploadLockRequest,
  UploadLockRequestType,
  UploadProgressEvent,
  UploadStartEvent,
} from '../types';
import { FileUploadMetadata } from '../types/FileUploadMetadata';
import { UploadableFile } from '../types/UploadableFile';
import { PriorityLock } from './PriorityLock';

export class AzureBlobUploader {
  static [Symbol.toStringTag]() {
    return 'AzureBlobUploader';
  }

  private _abortControllers = new Map<string, AbortController>();

  /** The key is the fileId. */
  private _fileUploadContexts = new Map<string, AzureBlobUploaderFileContext>();

  /** The key is a combination of the connection string name and the container name.  It uses the format: `${connectionString}_${containerName}`. */
  private _containerClients = new Map<string, Promise<ContainerClient>>();

  private _getSasFn: ((connectionString: ConnectionString, containerName: ContainerName) => Promise<string>) | null = null;

  private _uploadSessionId: string | null = null;

  private _streams = {
    onStart: new EventStream<UploadStartEvent>(),
    onProgress: new EventStream<UploadProgressEvent>(),
    onComplete: new EventStream<ProcessJobEvent<UploadCompleteEvent>>(),
    onError: new EventStream<UploadErrorEvent>(),
  };

  public get streams(): {
    onStart: IEventStreamConsumer<UploadStartEvent>;
    onProgress: IEventStreamConsumer<UploadProgressEvent>;
    onComplete: IEventStreamConsumer<ProcessJobEvent<UploadCompleteEvent>>;
    onError: IEventStreamConsumer<UploadErrorEvent>;
  } {
    return this._streams;
  }

  constructor() {
    this.initialize = this.initialize.bind(this);
    this.reset = this.reset.bind(this);
    this.process = this.process.bind(this);
    this.uploadBlob = this.uploadBlob.bind(this);
    this.abort = this.abort.bind(this);
    this.getContainerClient = this.getContainerClient.bind(this);
  }

  public initialize(getSasFn: (connectionString: ConnectionString, containerName: ContainerName) => Promise<string>, uploadSessionId: string | null) {
    if (this._uploadSessionId != null) throw new Error('AzureBlobUploader has already been initialized.  Call reset() before initializing again.');

    this._getSasFn = getSasFn;
    this._uploadSessionId = uploadSessionId;
  }

  public reset() {
    if (this._uploadSessionId == null) return;

    this._uploadSessionId = null;
    this._fileUploadContexts.clear();
    this._getSasFn = null;
    this._containerClients.clear();
    this._abortControllers.forEach((abortController) => abortController.abort());
    this._abortControllers.clear();
  }

  public process(file: Readonly<FileUploadContext>, lock: PriorityLock<UploadLockRequest>) {
    if (file.classification === 'dicom' && file.dicomData == null) {
      this._streams.onComplete.emit({ result: 'skipped', fileId: file.fileId });
      return;
    }

    this.uploadBlob(
      file.fileId,
      file.file.name,
      file.fileType,
      file.file.size,
      file.compressedFile != null,
      file.metadata,
      file.compressedFile ?? file.file.arrayBuffer(),
      file.classification,
      lock,
    );
  }

  private async uploadBlob(
    fileId: string,
    fileName: string,
    fileType: string | null,
    fileSize: number,
    isCompressed: boolean,
    metadata: FileUploadMetadata | null,
    buffer: UploadableFile | Promise<UploadableFile>,
    classification: Classification | null,
    lock: PriorityLock<UploadLockRequest>,
  ) {
    if (this._uploadSessionId == null) {
      throw new Error('Cannot upload a file without an upload session ID.');
    }

    let releaseLock: (() => void) | undefined;

    try {
      // Begin the query for the SAS immediately.  This will allow the SAS to be ready faster once the lock has been granted.  We will await AFTER the lock has been acquired.
      const getContainerClientPromise = this.getContainerClient(classification, fileType);

      releaseLock = await lock.requestLock({ type: UploadLockRequestType.UploadFile });

      const fileExtension = getFileExtension(fileName);
      const contentType = lookupMimeType(fileName);

      const blobClient = (await getContainerClientPromise).getBlockBlobClient(
        `${this._uploadSessionId}/${fileExtension == null ? fileId : `${fileId}.${fileExtension}`}`,
      );

      const blobHeaders: BlobHTTPHeaders = {
        blobContentType: contentType == null ? MIME_TYPES.OCTET_STREAM.contentType : contentType.contentType,
      };

      if (classification === 'dicom') {
        blobHeaders.blobContentEncoding = 'deflate';
      }

      const abortController = new AbortController();
      if (this._abortControllers.has(fileId)) {
        throw new Error(`File is already being uploaded.  Cancel existing upload first.  FileId: ${fileId}.`);
      } else {
        this._abortControllers.set(fileId, abortController);
      }

      const uploadUrl = new URL(blobClient.url);
      uploadUrl.hash = '';
      uploadUrl.search = '';
      let startMark: number | null = null;
      let endMark: number | null = null;
      let measure: PerformanceMeasure | null = null;

      try {
        const persistedMetadata: Record<string, string> = {
          fileId,
          uploadSessionId: this._uploadSessionId,
          fileName: fileName,
        };

        if (metadata != null) {
          for (const [metadataKey, metadataValue] of Object.entries(metadata)) {
            if (metadataValue == null || (typeof metadataValue === 'string' && !hasText(metadataValue))) {
              continue; // Skip undefined, null, or empty string values.
            } else if (typeof metadataValue === 'string') {
              persistedMetadata[metadataKey] = metadataValue.trim();
            } else {
              persistedMetadata[metadataKey] = metadataValue.toString();
            }
          }
        }

        const materializedFileBuffer = isPromise(buffer) ? await buffer : buffer;

        this._fileUploadContexts.set(fileId, {
          fileId,
          fileName,
          compressed: isCompressed,
          contentType: contentType?.contentType ?? null,
          contentEncoding: blobHeaders.blobContentEncoding ?? null,
          fileSize,
          compressedSize: isCompressed ? materializedFileBuffer.byteLength : null,
          progressSize: 0,
          isComplete: false,
        });

        startMark = performance.now();

        // Start the actual upload.  We need to wait until AFTER the upload has been initiated before we emit the onStart event.  Otherwise
        // the startMark will include any overhead associated with that event.
        const uploadPromise = blobClient.uploadData(materializedFileBuffer, {
          blobHTTPHeaders: blobHeaders,
          abortSignal: abortController.signal,
          onProgress: (event) => {
            const fileContext = this._fileUploadContexts.get(fileId)!;

            const chunkSize = event.loadedBytes - fileContext.progressSize;

            this._fileUploadContexts.set(fileId, {
              ...fileContext,
              progressSize: event.loadedBytes,
            });

            this._streams.onProgress.emit({
              fileId,
              fileSize,
              compressedSize: isCompressed ? materializedFileBuffer.byteLength : null,
              progressSize: event.loadedBytes,
              chunkSize,
            });
          },
          metadata: persistedMetadata,
          concurrency: 4, // MaximumConcurrency = 4
          maxSingleShotSize: 8 * 1024 * 1024, // InitialTransferSize = 8 MiB (files larger than this will use chunking)
          blockSize: 4 * 1024 * 1024, // MaximumTransferSize = 4 MiB (chunk size)
        });

        this._streams.onStart.emit({
          fileId,
          url: uploadUrl.toString(),
          startTime: performance.timeOrigin + startMark,
        });

        const response = await uploadPromise;

        endMark = performance.now();

        measure = performance.measure(`upload-file:${fileId}`, {
          detail: {
            fileId,
            fileName,
            fileSize,
            uploadSize: materializedFileBuffer.byteLength,
            devtools: {
              dataType: 'track-entry',
              trackGroup: 'Upload Pipeline',
              track: 'File Uploads',
              color: 'primary',
              properties: [
                ['fileId', fileId],
                ['fileName', fileName],
                ['fileSize', fileSize],
                ['uploadSize', materializedFileBuffer.byteLength],
              ],
            },
          },
          start: startMark,
          end: endMark,
        });

        datadogLogs.logger.info('upload-file', {
          uploadSessionId: this._uploadSessionId,
          fileId,
          date: performance.timeOrigin + startMark,
          end_date: performance.timeOrigin + startMark + measure.duration,
          duration: measure.duration * 1000000, // Datadog expects durations to be in nanoseconds.
          fileSize,
          uploadSize: materializedFileBuffer.byteLength,
        });

        this._fileUploadContexts.set(fileId, {
          ...this._fileUploadContexts.get(fileId)!,
          isComplete: true,
        });

        this._abortControllers.delete(fileId);

        this._streams.onComplete.emit({
          result: 'processed',
          fileId,
          response,
          uploadSize: materializedFileBuffer.byteLength,
          url: uploadUrl.toString(),
          compressed: isCompressed,
          startTime: performance.timeOrigin + startMark,
          endTime: performance.timeOrigin + endMark,
        });
      } catch (error) {
        this._abortControllers.delete(fileId);

        this._streams.onError.emit({ fileId, error });

        throw error;
      }
    } finally {
      releaseLock?.();
    }
  }

  /** Cancel the specified upload. */
  public abort(fileId: string) {
    const abortController = this._abortControllers.get(fileId);

    if (abortController == null) return;

    abortController.abort();
    this._abortControllers.delete(fileId);
  }

  private async getContainerClient(classification: Classification | null, fileType: string | null): Promise<ContainerClient> {
    if (this._uploadSessionId == null) {
      throw new Error('Cannot acquire a container client without an upload session ID.');
    }
    if (this._getSasFn == null) {
      throw new Error('Cannot acquire a container client without an SAS URL function.');
    }

    let connectionString: ConnectionString, containerName: ContainerName;

    if (classification === Classification.Unknown) {
      connectionString = ConnectionString.DICOMStorageConnectionString;
      containerName = ContainerName.Files;
    } else if (classification === Classification.Dicom) {
      connectionString = ConnectionString.DICOMStorageConnectionString;
      containerName = ContainerName.Incoming;
    } else if (
      classification === Classification.Pathology &&
      (fileType === MIME_TYPES.SVS.extensions[0] || fileType === MIME_TYPES.SZI.extensions[0] || fileType === MIME_TYPES.TIFF.extensions[0])
    ) {
      connectionString = ConnectionString.DICOMStorageConnectionString;
      containerName = ContainerName.Pathology;
    } else {
      connectionString = ConnectionString.StorageConnectionString;
      containerName = ContainerName.Files;
    }

    const lookupKey = `${connectionString}_${containerName}`;

    if (this._containerClients.has(lookupKey)) {
      return await this._containerClients.get(lookupKey)!;
    }

    let containerClientResolver: (value: ContainerClient) => void;
    const containerClientPromise = new Promise<ContainerClient>((resolve) => {
      containerClientResolver = resolve;
    });
    this._containerClients.set(lookupKey, containerClientPromise);
    datadogLogs.logger.info('sas-acquired', { classification });

    const sas = await this._getSasFn(connectionString, containerName);
    const blobServiceClient = new BlobServiceClient(sas, undefined, {
      retryOptions: {
        maxTries: Number.POSITIVE_INFINITY,
      },
    });
    const containerClient = blobServiceClient.getContainerClient(''); // The container name is already included in the SAS URL.

    containerClientResolver!(containerClient); // Non-null assertion is safe because it was just initialized in the promise constructor.

    return await containerClientPromise;
  }
}
