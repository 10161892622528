import { FunctionComponent } from 'react';

import { faChevronDown, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';
import styled from 'styled-components';

import { ExamModel, FileModel } from 'models';

import { Icon } from 'core/ui';

import { useExamsFilesAccordionItem } from '../hooks';
import { FileButton } from './FileButton';

export interface MobileAccordionItemProps {
  exam: ExamModel;
  selectedFileId: number | null;
  onClick: (file: FileModel) => void;
}

export const MobileAccordionItem: FunctionComponent<MobileAccordionItemProps> = ({ exam, selectedFileId, onClick }) => {
  const { links, files } = useExamsFilesAccordionItem(exam);

  // Derive variables from hook return values
  const examFiles = links;
  const examAttachments = files;

  return (
    <StyledAccordionItem key={exam.id}>
      <StyledAccordionLabel>
        <StyledAccordionLabel_Date>
          {exam.studyDate &&
            new Date(exam.studyDate).toLocaleDateString(undefined, {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
            })}
        </StyledAccordionLabel_Date>
        <StyledAccordionLabel_Label>{exam.description || 'Unknown'}</StyledAccordionLabel_Label>
      </StyledAccordionLabel>
      <StyledGridDiv>
        <StyledRow className="image">
          <StyledRowLabel>Images</StyledRowLabel>
          <StyledListBox>
            {examFiles.map((file: FileModel) => (
              <FileButton key={file.id} file={file} selected={selectedFileId === file.id} onClick={onClick} />
            ))}
          </StyledListBox>
        </StyledRow>
        <StyledRow className="attachment">
          <StyledRowLabel>Attachments</StyledRowLabel>
          <StyledListBox>
            {examAttachments.map((file: FileModel) => (
              <FileButton key={file.id} file={file} selected={selectedFileId === file.id} onClick={onClick} />
            ))}
          </StyledListBox>
        </StyledRow>
      </StyledGridDiv>
    </StyledAccordionItem>
  );
};

const StyledAccordionItem = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.palette.grayscale[2]};
  border-radius: 4px;
  margin-bottom: 16px;
  overflow: hidden;
`;

const StyledAccordionLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.colors.palette.grayscale[1]};
`;

const StyledAccordionLabel_Date = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.palette.grayscale[5]};
`;

const StyledAccordionLabel_Label = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.palette.grayscale[9]};
`;

const StyledGridDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  padding: 16px;
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledRowLabel = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const StyledListBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
