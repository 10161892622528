import { FunctionComponent, ReactNode, useMemo } from 'react';

import { MsalProvider } from '@azure/msal-react';

import { useDataStream, useInitializationEffect } from 'core/hooks';

import { AuthenticationContext, ParsedShareAccessTokenContext } from '../contexts';
import { globalAuthenticationManager } from '../globals';
import { AuthenticationContextType } from '../types/AuthenticationContextType';

export const AuthenticationProvider: FunctionComponent<{
  children?: ReactNode;
}> = ({ children }) => {
  const activeScheme = useDataStream(globalAuthenticationManager.hub.activeScheme);
  const parsedShareToken = useDataStream(globalAuthenticationManager.shareScheme.hub.parsedShareToken);

  useInitializationEffect(() => {
    globalAuthenticationManager.initialize();
  });

  const newContext: AuthenticationContextType = useMemo(
    () => ({
      pca: globalAuthenticationManager.oidcScheme.pca,
      loginShare: globalAuthenticationManager.shareScheme.login,
      logoutShare: globalAuthenticationManager.shareScheme.logout,
      loginOidc: globalAuthenticationManager.oidcScheme.login,
      logoutOidc: globalAuthenticationManager.oidcScheme.logout,
      frontChannelLogoutOidc: globalAuthenticationManager.frontChannelLogoutOidc,
      oidcScheme: globalAuthenticationManager.oidcScheme,
      activeScheme,
    }),
    [activeScheme],
  );

  return (
    <AuthenticationContext.Provider value={newContext}>
      <ParsedShareAccessTokenContext.Provider value={parsedShareToken}>
        <MsalProvider instance={globalAuthenticationManager.oidcScheme.pca}>{children}</MsalProvider>
      </ParsedShareAccessTokenContext.Provider>
    </AuthenticationContext.Provider>
  );
};

AuthenticationProvider.displayName = 'AuthenticationProvider';
