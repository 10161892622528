import { memo } from 'react';

import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import styled from 'styled-components';

import { useDataStream } from 'core/hooks';
import { ComponentSizes, ProgressBar } from 'core/ui';

import scanAnimation from '../assets/scan-animation.json';
import { useUploadView } from '../hooks/useUploadView';

export const ScanProgressCard = memo(() => {
  const uploadView = useUploadView();
  const scanProgress = useDataStream(uploadView.streams.scanProgress);

  const scanPercentage = scanProgress.total === scanProgress.complete ? 100 : (100 * scanProgress.complete) / scanProgress.total;

  return (
    <StyledComponentDiv>
      <DotLottieReact data={scanAnimation} autoplay loop />
      <StyledHeaderDiv>Scanning files</StyledHeaderDiv>
      <StyledProgressDiv>
        <ProgressBar value={scanPercentage} size={ComponentSizes.LARGE} labelResolver={progressText} />
      </StyledProgressDiv>
      <StyledScannedFilesDiv>
        Scanned {scanProgress.complete} of {scanProgress.total} files
      </StyledScannedFilesDiv>
    </StyledComponentDiv>
  );
});

ScanProgressCard.displayName = 'ScanProgressCard';

function progressText(value: number | null | undefined) {
  return value == null ? '' : `${Math.floor(value)} %`;
}

const StyledComponentDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 32px 16px;
  row-gap: 8px;
  margin: 0;
  background-color: ${({ theme }) => theme.colors.palette.white};
  border-radius: 4px;
  border: 1px dashed ${({ theme }) => theme.colors.palette.blues[3]};
  user-select: none;

  .icon-container {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const StyledHeaderDiv = styled.div`
  line-height: ${({ theme }) => theme.lineHeights.heading2};
  font-size: ${({ theme }) => theme.fontSizes.heading2};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  text-align: center;
`;

const StyledProgressDiv = styled.div``;

const StyledScannedFilesDiv = styled.div`
  text-align: center;
`;
