import { useEffect, useMemo, useState } from 'react';

import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { apiClient } from 'core/api/globals';
import { Field } from 'core/forms';
import { NotificationsService } from 'core/notifications';
import { Accordion, Button, ButtonVariants, Dialog, MultiSelect } from 'core/ui';

import { WellKnownSettingName, useUserSettings } from 'features/settings';

import HeartMonitorIcon from '../assets/heart-monitor.svg?react';

const TagsAccordion = ({ eventKey, valueGetter, valueSetter, value, examId, locationId, adminTags }) => {
  const selectedTags = valueGetter('tags');

  const [tags, setTags] = useState([]);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [enableReadRejection, setEnableReadRejection] = useState(false);
  const navigate = useNavigate();
  const { homePageUrl } = useUserSettings(true);

  // Process adminTags to ensure consistent format
  const formattedAdminTags = useMemo(() => {
    if (!adminTags || !Array.isArray(adminTags)) {
      return [];
    }

    return adminTags.map((tag) => {
      if (typeof tag === 'string') {
        return { tag: tag.trim() };
      }

      // For objects, use the tag property or convert to string
      return { tag: tag.tag || tag.name || String(tag) };
    });
  }, [adminTags]);

  // Combine regular tags with admin tags
  const combinedTags = useMemo(() => {
    // First, filter out admin tags that duplicate existing tags
    const uniqueAdminTags = formattedAdminTags.filter((adminTag) => !tags.some((t) => t.tag === adminTag.tag));

    return [...tags, ...uniqueAdminTags];
  }, [tags, formattedAdminTags]);

  // show selected and other available tags
  const availableTags = useMemo(() => {
    if (!selectedTags) return combinedTags;

    return combinedTags.filter((t) => !selectedTags.some((s) => s.tag === t.tag)).concat(selectedTags);
  }, [combinedTags, selectedTags]);

  useEffect(() => {
    apiClient.settingsClient.getSettings(true, null, null, locationId).then((res) => {
      const tagSettings = res.find((s) => s.name === WellKnownSettingName.Tags)?.value?.split(',') || null;
      setTags(tagSettings.map((t) => ({ tag: t.trim() })));

      const enableReadRejectionSetting = res.find((s) => s.name === WellKnownSettingName.EnableReadRejection)?.value?.toLowerCase() === 'true';
      setEnableReadRejection(enableReadRejectionSetting);
    });
  }, [locationId]);

  // Set initial tag values
  useEffect(() => {
    if (!selectedTags && value) {
      valueSetter('tags', { value });
    }
  }, [value, selectedTags, valueSetter]);

  const toggleDialog = () => {
    setDialogVisible(!dialogVisible);
  };

  const setRejected = async () => {
    const status = {
      id: examId,
      status: 'Reject',
      cancelReason: null,
    };

    try {
      await apiClient.exams.changeStatus(status);
      NotificationsService.displaySuccess('Exam rejected');
      window.location = homePageUrl;
    } catch (error) {
      NotificationsService.displayError('Status change failed');
    } finally {
      toggleDialog();
    }
  };

  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header Icon={HeartMonitorIcon} title="Tags" />
      <Accordion.Body>
        <StyledRow>
          <Col className="col-8">
            <Field allowCustom component={MultiSelect} editorId="tags" textField="tag" dataItemKey="tag" data={availableTags} name="tags" />
          </Col>
          <Col className="col-4">
            {enableReadRejection && (
              <Button variant={ButtonVariants.SECONDARY} onClick={toggleDialog}>
                Reject
              </Button>
            )}
          </Col>
        </StyledRow>
      </Accordion.Body>
      {dialogVisible && (
        <Dialog title="Please Confirm" onClose={toggleDialog}>
          <span>Reject Exam</span>
          <DialogActionsBar>
            <Button onClick={toggleDialog} variant={ButtonVariants.SECONDARY}>
              No
            </Button>
            <Button onClick={() => setRejected()}>Yes</Button>
          </DialogActionsBar>
        </Dialog>
      )}
    </Accordion.Item>
  );
};

const StyledRow = styled(Row)`
  margin-top: ${({ theme }) => theme.space.spacing30};
`;

TagsAccordion.propTypes = {
  eventKey: PropTypes.string.isRequired,
  valueGetter: PropTypes.func.isRequired,
  valueSetter: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
  examId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
  adminTags: PropTypes.arrayOf(PropTypes.shape({})),
};

export { TagsAccordion };
