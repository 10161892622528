import { createContext } from 'react';

import { AppSessionLocationContextValue, AppSessionLocationSelectModalContextType } from './types';

export const AppSessionLocationContext = createContext<AppSessionLocationContextValue | null>(null);

AppSessionLocationContext.displayName = 'AppSessionLocationContext';

export const AppSessionLocationSelectModalContext = createContext<AppSessionLocationSelectModalContextType | null>(null);

AppSessionLocationSelectModalContext.displayName = 'AppSessionLocationSelectModalContext';
