import React, { useCallback, useEffect, useState } from 'react';

import { faChartSimple, faClipboardCheck, faFileLines, faHeartPulse } from '@fortawesome/pro-solid-svg-icons';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { ExamGridModel, FileModel, PatientModel } from 'models';

import { Dropdown } from 'core/ui';

import { useApiClient } from 'features/api';
import { DocumentCategories, Organs } from 'features/exam/constants';
import { FileViewerPage } from 'features/file/fragments/FileViewerPage';
import { ImgProxy } from 'features/file/fragments/ImgProxy';
import { PatientMediaUploadModal } from 'features/patient/fragments/PatientMediaUploadModal';
import { UploadPipelineProvider } from 'features/upload-pipeline/fragments/UploadPipelineProvider';

import { Button, ButtonLooks, ButtonVariants, Card, Icon, Window } from '../../../core/ui';
import {
  CATEGORY_COLORS,
  categorizeByDescription,
  getCategoryKeyById,
  getCategoryNameById,
  getFileIcon,
  sortFilesByDate,
} from '../services/file-category-helper';

// -----------------------------------
// Styled components (original + small contrast tweaks)
// -----------------------------------
const PageLayout = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const MainContentArea = styled.div`
  flex: 1;
  padding: 20px;
  overflow-y: auto;
`;

const Sidebar = styled.div`
  width: 300px;
  background-color: #f9f9f9;
  padding: 20px;
  border-left: 1px solid #ddd;

  @media (max-width: 768px) {
    width: 100%;
    border-left: none;
    border-bottom: 1px solid #ddd;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const HeaderTitle = styled.h2`
  margin: 0;
  font-size: 1.5rem;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const UploadButtonContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 10px;
    justify-content: space-between;
  }
`;

const OrganSelectionContainer = styled.div`
  margin-right: 20px;

  @media (max-width: 768px) {
    flex: 1;
    margin-right: 10px;
  }
`;

const FilesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 20px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FileCard = styled.div`
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #fafafa;
  border-bottom: 1px solid #ddd;
`;

const CategoryTitle = styled.h3`
  margin: 0;
  font-size: 16px;
  font-weight: 500;
`;

const StatusTag = styled.span<{ isValid: boolean }>`
  display: inline-block;
  font-weight: 500;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: ${({ isValid }) => (isValid ? '#4caf50' : '#f44336')};
  color: #ffffff;
`;

const CardContent = styled.div`
  padding: 20px;
`;

const FileThumbnail = styled.div`
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  margin-bottom: 15px;
  overflow: hidden;
`;

const FileInfo = styled.div`
  margin-top: 15px;
`;

const FileText = styled.p`
  margin: 0;
  font-size: 14px;
  color: #666;
  line-height: 1.5;
`;

const DashboardContainer = styled.div`
  margin-bottom: 30px;
`;

const DashboardTitle = styled.h3`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
`;

const DashboardStatus = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
`;

const ProgressCircle = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  margin: 0 auto 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  @media (max-width: 768px) {
    width: 120px;
    height: 120px;
  }
`;

const ProgressInnerCircle = styled.div`
  position: absolute;
  width: 120px;
  height: 120px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    width: 90px;
    height: 90px;
  }
`;

const ProgressValue = styled.div`
  position: relative;
  font-size: 2rem;
  font-weight: bold;
  color: #333;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const ProgressText = styled.div`
  text-align: center;
  font-size: 14px;
  margin-bottom: 20px;
`;

const ActionButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: #e0e0e0;
  }

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const CategoryProgress = styled.div`
  margin-top: 30px;
`;

const CategoryProgressItem = styled.div`
  margin-bottom: 15px;
`;

const CategoryName = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  &:before {
    content: '';
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 10px;
    border-radius: 3px;
    background-color: ${({ color }) => color ?? '#ffd700'};
  }
`;

const ProgressBar = styled.div<{ percent?: string; color?: string }>`
  height: 8px;
  background-color: #e0e0e0;
  border-radius: 4px;
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: ${({ percent }) => percent ?? '0%'};
    background-color: ${({ color }) => color ?? '#ffd700'};
    border-radius: 4px;
  }
`;

const AIRecommendations = styled.div`
  margin-top: 40px;
`;

const RecommendationItem = styled.div`
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 1.5;
`;

const ContactInfo = styled.div`
  margin-top: 10px;
  font-size: 14px;
`;

// -----------------------------------
// Interfaces and Constants
// -----------------------------------
interface PatientFilesProps {
  patientId: number;
  className?: string;
  gridData?: ExamGridModel[];
}

interface OrganOption {
  name: string;
  value: number;
}

const ALL_ORGANS: OrganOption = {
  name: 'All',
  value: 0,
};

// Default to Lungs (using its value from Organs)
const DEFAULT_ORGAN_VALUE = Organs.LUNGS.value;

// Updated mapping: keys now match DocumentCategories keys.
const ORGAN_DOCUMENT_CATEGORY_MAPPING: Record<string, (keyof typeof DocumentCategories)[]> = {
  LUNGS: ['EMR', 'ChestXRay', 'CT', 'FINAL_REPORT', 'Ventilator', 'Monitoring'],
  KIDNEY: ['CT', 'FINAL_REPORT', 'DOCUMENT'],
  LIVER: ['CT', 'FINAL_REPORT', 'Pathology'],
  THYROID: ['CT', 'FINAL_REPORT', 'DOCUMENT'],
  OTHER: ['DOCUMENT', 'FINAL_REPORT'],
};

// The Category interface now is based on DocumentCategories
interface Category {
  key: string;
  displayName: string;
  color: string;
  files: FileModel[];
}

const isImageFile = (fileType: string): boolean => {
  const imageFileTypes = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'tiff', 'svg'];
  return imageFileTypes.includes(fileType.toLowerCase());
};

// -----------------------------------
// Helper component for file thumbnail
// -----------------------------------
const FileThumbnailDisplay = ({ file }: { file: FileModel | null }) => {
  if (!file) {
    return <div style={{ fontSize: '48px', color: '#ccc' }}>📄</div>;
  }

  return file.thumbnailUrl ? (
    <ImgProxy
      fileId={file.id}
      thumbnailUrl={file.thumbnailUrl ?? file.thumbnailLocation ?? ''}
      dateModified={file.modified}
      isReadOnly
      height={120}
      width="100%"
    />
  ) : (
    <div style={{ fontSize: '48px' }}>📄</div>
  );
};

// -----------------------------------
// Helper component for file info text
// -----------------------------------
const FileInfoDisplay = ({ file, displayName, onUpload }: { file: FileModel | null; displayName: string; onUpload: (e: React.MouseEvent) => void }) => {
  if (!file) {
    return (
      <>
        <FileText>No {displayName.toLowerCase()} files uploaded yet.</FileText>
        <Button look={ButtonLooks.STANDARD} variant={ButtonVariants.PRIMARY} onClick={onUpload} style={{ marginTop: '10px' }}>
          Upload {displayName}
        </Button>
      </>
    );
  }

  const date = file.created ? dayjs(file.created).format('MM/DD/YYYY') : 'No date';

  return (
    <>
      <FileText>{file.fileName}</FileText>
      <FileText>
        <small>Date: {date}</small>
      </FileText>
      <Button look={ButtonLooks.STANDARD} variant={ButtonVariants.PRIMARY} onClick={onUpload} style={{ marginTop: '10px' }}>
        Add More Files
      </Button>
    </>
  );
};

const FileListItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  padding: 5px 0;
`;

// -----------------------------------
// Updated CategoryCard with "View All Files (x)" link
// -----------------------------------
const CategoryCard = ({
  category,
  onCardClick,
  onUpload,
  onViewAll,
}: {
  category: Category;
  onCardClick: (file: FileModel, category: Category) => void;
  onUpload: (key: string) => void;
  onViewAll: (category: Category) => void;
}) => {
  const sortedFiles = category.files.slice().sort((a, b) => {
    const dateA = a.created ? new Date(a.created).valueOf() : 0;
    const dateB = b.created ? new Date(b.created).valueOf() : 0;
    return dateB - dateA;
  });

  const mostRecentFile = sortedFiles[0] || null;
  const hasFiles = sortedFiles.length > 0;

  return (
    <FileCard key={category.key} onClick={() => (hasFiles ? onCardClick(mostRecentFile, category) : onUpload(category.key))}>
      <CardHeader>
        <CategoryTitle>{category.displayName}</CategoryTitle>
        <StatusTag isValid={hasFiles}>{hasFiles ? 'VALID DATA' : 'MISSING'}</StatusTag>
      </CardHeader>
      <CardContent>
        <FileThumbnail>
          <FileThumbnailDisplay file={mostRecentFile} />
        </FileThumbnail>

        {mostRecentFile && <div style={{ marginBottom: '8px', fontWeight: 'bold', color: '#4caf50' }}>Latest</div>}

        <FileInfo>
          <FileInfoDisplay
            file={mostRecentFile}
            displayName={category.displayName}
            onUpload={(e) => {
              e.stopPropagation();
              onUpload(category.key);
            }}
          />
        </FileInfo>
      </CardContent>
    </FileCard>
  );
};

// -----------------------------------
// Dashboard for organ selection + circle progress
// -----------------------------------
const Dashboard = ({
  organOptions,
  selectedOrgan: _selectedOrgan,
  onOrganChange,
  organName,
  progress,
  circleStyle,
  onUpload,
}: {
  organOptions: OrganOption[];
  selectedOrgan: number;
  onOrganChange: (value: number) => void;
  organName: string;
  progress: { percent: number; completed: number; total: number };
  circleStyle: React.CSSProperties;
  onUpload: () => void;
}) => (
  <DashboardContainer>
    <OrganSelectionContainer>
      <Dropdown data={organOptions} textField="name" filterable={false} dataItemKey="value" valueField="value" onChange={(e) => onOrganChange(e.value)} />
    </OrganSelectionContainer>
    <DashboardTitle>{organName} File Completion</DashboardTitle>
    <DashboardStatus>
      {progress.completed} of {progress.total} files uploaded
    </DashboardStatus>

    <ProgressCircle style={circleStyle}>
      <ProgressInnerCircle>
        <ProgressValue>{progress.percent}%</ProgressValue>
      </ProgressInnerCircle>
    </ProgressCircle>

    <ProgressText>
      {progress.percent < 50 ? 'More documents needed' : progress.percent < 100 ? 'Good progress, but still incomplete' : 'All documents submitted!'}
    </ProgressText>

    <ActionButton onClick={onUpload}>Upload Missing Files</ActionButton>
  </DashboardContainer>
);

const CategoryProgressDisplay = ({ categories }: { categories: Category[] }) => (
  <CategoryProgress>
    {categories.map((category) => (
      <CategoryProgressItem key={category.key}>
        <CategoryName color={category.color}>{category.displayName}</CategoryName>
        <ProgressBar percent={`${category.files.length > 0 ? '100' : '0'}%`} color={category.color} />
      </CategoryProgressItem>
    ))}
  </CategoryProgress>
);

// -----------------------------------
// Main PatientFiles component
// -----------------------------------
const PatientFiles: React.FC<PatientFilesProps> = ({ patientId, className: _className, gridData = [] }) => {
  const apiClient = useApiClient();
  const [files, setFiles] = useState<FileModel[]>([]);
  const [examFiles, setExamFiles] = useState<{ file: FileModel; serviceDescription: string | null }[]>([]);
  const [_isLoading, setIsLoading] = useState(false);

  // Updated state for file viewing
  const [viewFileState, setViewFileState] = useState<{
    isOpen: boolean;
    files: FileModel[];
    selectedFile: FileModel | null;
  }>({
    isOpen: false,
    files: [],
    selectedFile: null,
  });

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [_selectedOrgan, setSelectedOrgan] = useState<number>(DEFAULT_ORGAN_VALUE);
  const [viewAllCategory, setViewAllCategory] = useState<Category | null>(null);

  const fetchFiles = useCallback(async () => {
    setIsLoading(true);
    try {
      const patientFiles = await apiClient.filesClient.getFilesByPatientId(patientId, 'msal-required');
      setFiles(patientFiles);

      if (gridData?.length > 0) {
        const examFilesWithServices: { file: FileModel; serviceDescription: string | null }[] = [];
        for (const exam of gridData) {
          if (exam.id && exam.serviceDescription) {
            const matchingFiles = patientFiles.filter((file) => file.examId === exam.id);
            matchingFiles.forEach((file) =>
              examFilesWithServices.push({
                file,
                serviceDescription: exam.description || exam.serviceDescription ? (exam.serviceDescription ?? '') + ' ' + (exam.description ?? '') : null,
              }),
            );
          }
        }
        setExamFiles(examFilesWithServices);
      }
    } catch (_error) {
      console.error('Failed to fetch files');
    } finally {
      setIsLoading(false);
    }
  }, [apiClient, patientId, gridData]);

  useEffect(() => {
    fetchFiles();
  }, [fetchFiles]);

  const getCurrentCategories = useCallback(() => {
    if (_selectedOrgan === ALL_ORGANS.value) {
      return Object.keys(DocumentCategories).map((key) => ({
        key,
        displayName: DocumentCategories[key as keyof typeof DocumentCategories].name,
        color: CATEGORY_COLORS[key] || '#ffd700',
        files: [] as FileModel[],
      }));
    } else {
      const organKey = Object.keys(Organs).find((key) => Organs[key as keyof typeof Organs].value === _selectedOrgan);
      if (!organKey) return [];
      const allowedCategories = ORGAN_DOCUMENT_CATEGORY_MAPPING[organKey] || [];
      return allowedCategories.map((key) => ({
        key,
        displayName: DocumentCategories[key].name,
        color: CATEGORY_COLORS[key] || '#ffd700',
        files: [] as FileModel[],
      }));
    }
  }, [_selectedOrgan]);

  const getCategoriesWithFiles = useCallback(() => {
    const currentCategories = getCurrentCategories();

    const categories = currentCategories.map((category) => {
      const categoryValue = category.key in DocumentCategories ? DocumentCategories[category.key as keyof typeof DocumentCategories]?.value : undefined;

      // Files explicitly categorized with this category ID
      const categoryFiles = categoryValue ? files.filter((file) => file.categoryId === categoryValue) : [];

      // Files from exams that should be categorized in this category based on their description
      const matchingExamFiles = examFiles
        .filter((examFile) => {
          const serviceDesc = examFile.serviceDescription?.toLowerCase() ?? '';

          // Use our helper function to categorize by description
          const detectedCategory = categorizeByDescription(serviceDesc);

          return detectedCategory === category.key;
        })
        .map((examFile) => {
          // Make sure externalSource is null, not undefined
          if (examFile.file.externalSource === undefined) {
            return { ...examFile.file, externalSource: null };
          }
          return examFile.file;
        });

      // Create a unique list of files (avoiding duplicates)
      const uniqueFiles = Array.from(new Map([...categoryFiles, ...matchingExamFiles].map((file) => [file.id, file])).values());

      return {
        ...category,
        files: uniqueFiles,
      };
    });

    if (_selectedOrgan === ALL_ORGANS.value) {
      return categories.filter((c) => c.files.length > 0);
    } else {
      return categories;
    }
  }, [files, examFiles, getCurrentCategories, _selectedOrgan]);

  const generateRecommendation = useCallback(() => {
    const categories = getCategoriesWithFiles();
    const missingCategories = categories.filter((category) => category.files.length === 0);

    if (missingCategories.length === 0) {
      return 'All required files have been uploaded. Great job!';
    }

    const organName =
      _selectedOrgan === ALL_ORGANS.value ? ALL_ORGANS.name : (Object.values(Organs).find((org) => org.value === _selectedOrgan)?.name ?? 'Unknown');
    const missingCategoryNames = missingCategories.map((category) => category.displayName).join(', ');

    return `For ${organName.toLowerCase()} evaluation, please upload the following: ${missingCategoryNames}`;
  }, [getCategoriesWithFiles, _selectedOrgan]);

  // Helper function to sort files by date (newest first)
  const sortFilesByDate = (files: FileModel[]): FileModel[] => {
    return [...files].sort((a, b) => {
      const dateA = a.created ? new Date(a.created).valueOf() : 0;
      const dateB = b.created ? new Date(b.created).valueOf() : 0;
      return dateB - dateA;
    });
  };

  const handleCardClick = (file: FileModel, category: Category) => {
    // Get all files for this category and sort them by date
    const sortedCategoryFiles = sortFilesByDate(category.files);

    setViewFileState({
      isOpen: true,
      files: sortedCategoryFiles,
      selectedFile: file,
    });
  };

  const calculateProgress = useCallback(() => {
    const categories = getCategoriesWithFiles();
    const total = categories.length;
    const completed = categories.filter((category) => category.files.length > 0).length;

    return {
      percent: total > 0 ? Math.round((completed / total) * 100) : 0,
      completed,
      total,
    };
  }, [getCategoriesWithFiles]);

  const progress = calculateProgress();

  const getCircleStyle = useCallback(() => {
    const angle = (progress.percent / 100) * 360;
    return {
      background: `conic-gradient(#4CAF50 ${angle}deg, #e0e0e0 ${angle}deg)`,
    };
  }, [progress.percent]);

  const patientModel: PatientModel = {
    id: patientId,
    firstName: 'N/A',
    lastName: 'N/A',
    active: true,
    unosID: 'N/A',
    dob: new Date().toISOString(),
    patientNumber: null,
    caseID: null,
    height: null,
    weight: null,
    gender: null,
    crossClampDateTime: null,
    notes: null,
    hospital: null,
    locationType: null,
    location: null,
    location_Id: null,
    ageRange: null,
    ageRange_id: null,
    htn: null,
    htnYrs: null,
    htnCompliant: null,
    diabetes: null,
    diabetesYrs: null,
    diabetesCompliant: null,
    peakCreatinine: null,
    peakCreatinineYrs: null,
    peakCreatinineCompliant: null,
    obesityIndication: null,
    organ: null,
    organAppearanceIndication: null,
    proteinuria: null,
    ageIndication: null,
    etoh: null,
  };

  const handleUploadForCategory = (categoryKey: string) => {
    setSelectedCategory(categoryKey);
    setIsUploadModalOpen(true);
  };

  const handleOrganChange = (value: number) => {
    setSelectedOrgan(value);
  };

  const handleViewAll = (category: Category) => {
    setViewAllCategory(category);
  };

  const handleViewFile = (file: FileModel, category: Category | null = null) => {
    if (category) {
      // If category is provided, pass all files in that category
      const sortedCategoryFiles = sortFilesByDate(category.files);
      setViewFileState({
        isOpen: true,
        files: sortedCategoryFiles,
        selectedFile: file,
      });
    } else {
      // Otherwise just show the selected file
      setViewFileState({
        isOpen: true,
        files: [file],
        selectedFile: file,
      });
    }

    console.log('ViewFileState', viewFileState);

    setViewAllCategory(null);
  };

  const organOptions = [ALL_ORGANS, ...Object.values(Organs)];
  const categoriesWithFiles = getCategoriesWithFiles();
  const organName =
    _selectedOrgan === ALL_ORGANS.value ? ALL_ORGANS.name : (Object.values(Organs).find((org) => org.value === _selectedOrgan)?.name ?? 'Unknown');

  return (
    <>
      {viewFileState.isOpen && (
        <Window
          title={viewFileState.selectedFile?.fileName ?? 'File Viewer'}
          initialWidth={1000}
          initialHeight={1000}
          modal
          onClose={() => setViewFileState({ isOpen: false, files: [], selectedFile: null })}
        >
          <StyledFileViewerContainer>
            <StyledFileViewerPage files={viewFileState.files} showDeleteBtn showEditBtn showInfobar showSidebar />
          </StyledFileViewerContainer>
        </Window>
      )}

      {isUploadModalOpen && (
        <PatientMediaUploadModal
          patient={patientModel}
          defaultCategory={
            selectedCategory
              ? {
                  name: DocumentCategories[selectedCategory as keyof typeof DocumentCategories]?.name || selectedCategory,
                  value: selectedCategory,
                }
              : undefined
          }
          onClose={() => setIsUploadModalOpen(false)}
          onSuccess={() => {
            setIsUploadModalOpen(false);
            fetchFiles();
          }}
        />
      )}

      {viewAllCategory && (
        <Window title={`All ${viewAllCategory.displayName} Files`} onClose={() => setViewAllCategory(null)}>
          <StyledCategoryFilesContainer>
            {viewAllCategory.files.length === 0 && <p>No files yet for {viewAllCategory.displayName}.</p>}

            {sortFilesByDate(viewAllCategory.files).map((file) => (
              <FileListItem key={file.id}>
                {file.thumbnailUrl ? (
                  <ImgProxy fileId={file.id} thumbnailUrl={file.thumbnailUrl ?? ''} dateModified={file.modified} isReadOnly height={60} width={60} />
                ) : (
                  <div style={{ fontSize: '24px', marginRight: '8px' }}>📄</div>
                )}
                <div style={{ flex: 1, marginLeft: '10px' }}>
                  <div>{file.fileName}</div>
                  <small>Uploaded: {file.created ? dayjs(file.created).format('MM/DD/YYYY') : 'No date'}</small>
                </div>
                <Button style={{ marginLeft: '10px' }} onClick={() => handleViewFile(file, viewAllCategory)}>
                  View
                </Button>
              </FileListItem>
            ))}
          </StyledCategoryFilesContainer>
        </Window>
      )}

      <UploadPipelineProvider>
        <PageLayout>
          <MainContentArea>
            <Header>
              <HeaderTitle>Patient Files</HeaderTitle>
              <UploadButtonContainer>
                <Button look={ButtonLooks.STANDARD} variant={ButtonVariants.PRIMARY} onClick={() => setIsUploadModalOpen(true)}>
                  Upload Files
                </Button>
              </UploadButtonContainer>
            </Header>

            <FilesGrid>
              {categoriesWithFiles.map((category) => (
                <CategoryCard
                  key={category.key}
                  category={category}
                  onCardClick={handleCardClick}
                  onUpload={handleUploadForCategory}
                  onViewAll={handleViewAll}
                />
              ))}
            </FilesGrid>
          </MainContentArea>

          <Sidebar>
            <Dashboard
              organOptions={organOptions}
              selectedOrgan={_selectedOrgan}
              onOrganChange={handleOrganChange}
              organName={organName}
              progress={progress}
              circleStyle={getCircleStyle()}
              onUpload={() => setIsUploadModalOpen(true)}
            />

            <CategoryProgressDisplay categories={categoriesWithFiles} />

            <AIRecommendations>
              <DashboardTitle>Recommendations</DashboardTitle>
              <RecommendationItem>{generateRecommendation()}</RecommendationItem>
              <ContactInfo></ContactInfo>
            </AIRecommendations>
          </Sidebar>
        </PageLayout>
      </UploadPipelineProvider>
    </>
  );
};

// Updated styled components
const StyledFileViewerContainer = styled.div`
  height: 100%;
`;

const StyledCategoryFilesContainer = styled.div`
  padding: 20px;
  max-height: 60vh;
  overflow-y: auto;
`;

const StyledFileViewerPage = styled(FileViewerPage)`
  width: 100%;
  height: 100%;
`;

export default PatientFiles;
export { PatientFiles };
