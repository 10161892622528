import { datadogLogs } from '@datadog/browser-logs';
import dayjs from 'dayjs';

import { UploadModel } from 'models';

import { apiClient } from 'core/api/globals';
import { DataStream, EventStream, IDataStreamConsumer, IEventStreamConsumer, hasText } from 'core/utils';

import { DEFAULT_UPLOAD_PIPELINE_OPTIONS, DISK_LOCK_SSD_CONCURRENCY } from '../constants';
import {
  Classification,
  CompressCompleteEvent,
  FileAttachedEvent,
  FileUploadContext,
  FilesLoadedEvent,
  MatchCompleteEvent,
  MergeFileDescriptor,
  ParseCompleteEvent,
  ProcessJob,
  ProcessJobEvent,
  QueueState,
  ScanFileDescriptor,
  ThumbnailGeneratedEvent,
  UploadBatchStartEvent,
  UploadCompleteEvent,
  UploadErrorEvent,
  UploadLockRequest,
  UploadPipelineOptions,
} from '../types';
import { AzureBlobUploader } from './AzureBlobUploader';
import { FileAttacher } from './FileAttacher';
import { FileCompressor } from './FileCompressor';
import { FileScanner } from './FileScanner';
import { ImageDataCache } from './ImageDataCache';
import { PriorityLock } from './PriorityLock';
import { RecordMatcher } from './RecordMatcher';
import { ThumbnailGenerator } from './ThumbnailGenerator';

export class UploadPipeline {
  static [Symbol.toStringTag]() {
    return 'UploadPipeline';
  }

  public readonly fileScanner: FileScanner;
  public readonly thumbnailGenerator: ThumbnailGenerator;
  public readonly recordMatcher: RecordMatcher;
  public readonly blobUploader: AzureBlobUploader;
  public readonly fileCompressor: FileCompressor;
  public readonly fileAttacher: FileAttacher;
  public readonly imageCache: ImageDataCache;

  private _cleanupFunctions: (() => void)[] = [];
  /** The key is the fileId. */
  private _fileContexts = new Map<string, FileUploadContext>();
  private _options: Required<UploadPipelineOptions> | null = null;
  private _diskLock: PriorityLock<UploadLockRequest>;
  private _uploadLock: PriorityLock<UploadLockRequest>;
  private _scansJobTimeStamps: { startTime: number; duration: number }[] = [];
  /** The key is the StudyInstanceUID. */
  private _dicomStudyTracking = new Map<string, UploadModel>();

  private _streams = {
    onFilesLoaded: new EventStream<FilesLoadedEvent>('onFilesLoaded'),
    onFilesRemoved: new EventStream<string[]>('onFilesRemoved'),
    onUploadBatchStart: new EventStream<UploadBatchStartEvent>('onUploadBatchStart'),
    queues: new DataStream<QueueState>({
      scanQueueLength: 0,
      deepScansCount: 0,
      recordMatcherQueueLength: 0,
      thumbnailQueueLength: 0,
      compressionQueueLength: 0,
      uploadQueueLength: 0,
      attachQueueLength: 0,
    }),
  };

  public get streams(): Readonly<{
    onFilesLoaded: IEventStreamConsumer<FilesLoadedEvent>;
    onFilesRemoved: IEventStreamConsumer<string[]>;
    onUploadBatchStart: IEventStreamConsumer<UploadBatchStartEvent>;
    queues: IDataStreamConsumer<QueueState>;
  }> {
    return this._streams;
  }

  constructor() {
    this.initialize = this.initialize.bind(this);
    this.reset = this.reset.bind(this);
    this.destroy = this.destroy.bind(this);
    this.getFileMetadata = this.getFileMetadata.bind(this);
    this.setCdMode = this.setCdMode.bind(this);
    this.setFixedEntities = this.setFixedEntities.bind(this);
    this.startUpload = this.startUpload.bind(this);
    this.processJobs = this.processJobs.bind(this);
    this.detectCdReads = this.detectCdReads.bind(this);
    this.initializeUploadTrackers = this.initializeUploadTrackers.bind(this);
    this.updateUploadTrackerForCompression = this.updateUploadTrackerForCompression.bind(this);
    this.finalizeUploadTrackers = this.finalizeUploadTrackers.bind(this);
    this.handleParseComplete = this.handleParseComplete.bind(this);
    this.handleThumbnailGenerated = this.handleThumbnailGenerated.bind(this);
    this.handleMatchComplete = this.handleMatchComplete.bind(this);
    this.handleMatchError = this.handleMatchError.bind(this);
    this.handleCompressComplete = this.handleCompressComplete.bind(this);
    this.handleCompressError = this.handleCompressError.bind(this);
    this.handleUploadComplete = this.handleUploadComplete.bind(this);
    this.handleUploadError = this.handleUploadError.bind(this);
    this.handleFileAttached = this.handleFileAttached.bind(this);
    this.addFiles = this.addFiles.bind(this);
    this.removeFiles = this.removeFiles.bind(this);
    this.mergeFileDescriptors = this.mergeFileDescriptors.bind(this);

    this.imageCache = new ImageDataCache();
    this.fileScanner = new FileScanner(this.imageCache);
    this.thumbnailGenerator = new ThumbnailGenerator(this.imageCache);
    this.recordMatcher = new RecordMatcher();
    this.fileCompressor = new FileCompressor();
    this.blobUploader = new AzureBlobUploader();
    this.fileAttacher = new FileAttacher();

    this._diskLock = new PriorityLock<UploadLockRequest>({
      maxConcurrent: 10,
      priorityCompareFn: (a, b) => a.type - b.type,
    });
    this._uploadLock = new PriorityLock<UploadLockRequest>({
      maxConcurrent: 50,
      priorityCompareFn: (a, b) => a.type - b.type,
    });

    this._cleanupFunctions.push(this.fileScanner.streams.onParseComplete.subscribe(this.handleParseComplete));
    this._cleanupFunctions.push(this.thumbnailGenerator.streams.onComplete.subscribe(this.handleThumbnailGenerated));
    this._cleanupFunctions.push(this.recordMatcher.streams.onMatchComplete.subscribe(this.handleMatchComplete));
    this._cleanupFunctions.push(this.fileCompressor.streams.onComplete.subscribe(this.handleCompressComplete));
    this._cleanupFunctions.push(this.fileCompressor.streams.onError.subscribe(this.handleCompressError));
    this._cleanupFunctions.push(this.blobUploader.streams.onComplete.subscribe(this.handleUploadComplete));
    this._cleanupFunctions.push(this.blobUploader.streams.onError.subscribe(this.handleUploadError));
    this._cleanupFunctions.push(this.fileAttacher.streams.onFileAttached.subscribe(this.handleFileAttached));
  }

  /** Perform any necessary initialization that may need to be performed multiple times.  This is useful for when the pipeline is being reused across multiple upload sessions.  Always call `reset()` in between multiple calls to `initialize()`. */
  public initialize(options: UploadPipelineOptions) {
    // Force the caller to reset the pipeline before initializing it again.  If we were to allow that, it would likely indicate that the same pipeline instance
    // is somehow being used for multiple upload sessions simultaneously, and it is not designed for that and will likely result in strange issues.
    if (this._options != null) throw new Error('Upload pipeline has already been initialized.  Call reset() before initializing again.');

    this._options = {
      uploadSessionId: options.uploadSessionId,
      queryClient: options.queryClient,
      mode: options.mode ?? DEFAULT_UPLOAD_PIPELINE_OPTIONS.mode,
      authMode: options.authMode ?? DEFAULT_UPLOAD_PIPELINE_OPTIONS.authMode,
      getSasFn: options.getSasFn ?? DEFAULT_UPLOAD_PIPELINE_OPTIONS.getSasFn,
      cdMode: options.cdMode ?? DEFAULT_UPLOAD_PIPELINE_OPTIONS.cdMode,
      triggerCdMode: options.triggerCdMode ?? DEFAULT_UPLOAD_PIPELINE_OPTIONS.triggerCdMode,
      generateThumbnails: options.generateThumbnails ?? DEFAULT_UPLOAD_PIPELINE_OPTIONS.generateThumbnails,
    };

    this._scansJobTimeStamps = [];

    datadogLogs.logger.info('upload-pipeline_initialize', { uploadSessionId: options.uploadSessionId, cdMode: this._options.cdMode });
    this._diskLock.setMaxConcurrent(this._options.cdMode ? 1 : DISK_LOCK_SSD_CONCURRENCY);

    this.imageCache.initialize(this._options.uploadSessionId);
    this.fileScanner.initialize(this._options.uploadSessionId);
    this.thumbnailGenerator.initialize(this._options.uploadSessionId);
    this.recordMatcher.initialize(this._options.queryClient);
    this.fileCompressor.initialize(this._options.uploadSessionId);
    this.blobUploader.initialize(this._options.getSasFn, this._options.uploadSessionId);
    this.fileAttacher.initialize(this._options.authMode);
  }

  /** Reset the pipeline to a clean initial state so that it is ready for a new upload session. */
  public reset() {
    // Treat a null options property as if the pipeline has not been initialized (or was previously reset) and therefore does not need to be reset again.
    if (this._options == null) return;

    const previousUploadSessionId = this._options.uploadSessionId;

    this._options = null;
    this._scansJobTimeStamps = [];
    this._streams.queues.emit({
      scanQueueLength: 0,
      deepScansCount: 0,
      recordMatcherQueueLength: 0,
      thumbnailQueueLength: 0,
      compressionQueueLength: 0,
      uploadQueueLength: 0,
      attachQueueLength: 0,
    });
    this._fileContexts.clear();
    this._dicomStudyTracking.clear();
    this._diskLock.setMaxConcurrent(DISK_LOCK_SSD_CONCURRENCY);

    this.imageCache.reset();
    this.fileScanner.reset();
    this.thumbnailGenerator.reset();
    this.recordMatcher.reset();
    this.fileCompressor.reset();
    this.blobUploader.reset();
    this.fileAttacher.reset();

    datadogLogs.logger.info('upload-pipeline_reset', { uploadSessionId: previousUploadSessionId });
  }

  public destroy() {
    this._cleanupFunctions.forEach((cleanup) => cleanup());
    this._cleanupFunctions = [];

    this._options = null;
    this._fileContexts.clear();
    this._dicomStudyTracking.clear();
    this._scansJobTimeStamps = [];

    this._streams.queues.clear();
    this._streams.onFilesLoaded.clear();
    this._streams.onFilesRemoved.clear();
    this._streams.onUploadBatchStart.clear();

    this.imageCache.reset();
    this.fileScanner.reset();
    this.thumbnailGenerator.reset();
    this.recordMatcher.reset();
    this.fileCompressor.reset();
    this.blobUploader.reset();
    this.fileAttacher.reset();
  }

  /** Set the disk I/O lock so that it operates serially.  This is an optimization when uploading files from a CD/DVD drive.  It's a performance hit for conventional and SSD drives.
   * @param cdMode - When `true` then the disk I/O lock will be set to operate serially - improving performance for CD/DVD drives.  When `false` then the disk I/O lock will be set to operate in parallel - improving performance for conventional and SSD drives.
   * @param trigger - Indicates whether the change was made manually by the user or automatically by the pipeline.  This is for logging purposes.
   * @param extraLogContext - Extra log context to include in the log message.
   */
  public setCdMode(cdMode: boolean, trigger: 'auto' | 'manual', extraLogContext?: object) {
    if (this._options == null) throw new Error('Upload pipeline has not been initialized.');

    // First halt auto cd mode if the user is manually toggling it.
    if (trigger === 'manual') {
      this._options.triggerCdMode = 'manual';
    }

    // Don't do anything if the new value is the same as the current value.
    if (this._options.cdMode === cdMode) return;

    this._options.cdMode = cdMode;
    datadogLogs.logger.info('upload-pipeline_set-cd-mode', {
      uploadSessionId: this._options.uploadSessionId,
      cdMode,
      trigger,
      ...(extraLogContext ?? {}),
    });
    this._diskLock.setMaxConcurrent(cdMode ? 1 : DISK_LOCK_SSD_CONCURRENCY);
  }

  /** Add files to the upload pipeline.  When operating in `"1-shot"` mode this will result in files being uploaded to
   * blob storage.  In `"interactive"` mode files will stop just prior to compression. */
  public addFiles(files: ScanFileDescriptor[]) {
    if (this._options == null) throw new Error('Upload pipeline has not been initialized.');

    const newQueues = { ...this._streams.queues.getCurrentValue() };
    const newFileContexts: FileUploadContext[] = [];
    const nextJobs: ProcessJob[] = [];

    for (const file of files) {
      const newFileContext: FileUploadContext = {
        fileId: file.fileId,
        file: file.file,
        compressedFile: null,
        metadata: file.metadata ?? {},
        fileType: null,
        result: 'in-progress',
        classification: file.classification,
        dicomData: null,
        uploadUrl: null,
        thumbnailId: null,
      };

      newFileContexts.push(newFileContext);

      this._fileContexts.set(newFileContext.fileId, newFileContext);

      datadogLogs.logger.info('upload-pipeline_add-file', {
        uploadSessionId: this._options.uploadSessionId,
        fileId: file.fileId,
        fileName: newFileContext.file.name,
        fileSize: newFileContext.file.size,
        classification: newFileContext.classification,
      });
    }

    this._streams.onFilesLoaded.emit({
      files: newFileContexts.map((file) => ({ fileId: file.fileId, file: file.file, classification: file.classification })),
    });

    if (this._options.mode === '1-shot' && newFileContexts.length > 0) {
      this._streams.onUploadBatchStart.emit({
        files: newFileContexts.map((file) => ({ fileId: file.fileId, willCompress: false, willAttach: file.classification != null })),
        startTime: performance.timeOrigin + performance.now(),
      });
    }

    newFileContexts.forEach((f) => nextJobs.push({ type: 'scan-file', fileId: f.fileId }));
    newQueues.scanQueueLength += newFileContexts.length;
    this._streams.queues.emit(newQueues);
    this.processJobs(newFileContexts.map((f) => ({ type: 'scan-file', fileId: f.fileId })));
  }

  public removeFiles(fileIds: string[]) {
    if (this._options == null) throw new Error('Upload pipeline has not been initialized.');

    for (const fileId of fileIds) {
      this._fileContexts.delete(fileId);

      datadogLogs.logger.info('upload-pipeline_remove-file', { uploadSessionId: this._options.uploadSessionId, fileId });
    }

    this._streams.onFilesRemoved.emit([...fileIds]); // Clone the array because we don't own the reference - it belongs to the caller which may decide to mutate it after the fact.
  }

  public getFileMetadata(fileId: string) {
    return this._fileContexts.get(fileId)?.metadata;
  }

  /** Set file descriptor field properties including metadata.  This does a merge so that any existing values are retained.  Setting a
   * property to `null` will set the property just as a literal number or string.  Setting a value to `undefined` will retain the existing
   * value.  The metadata property is handled similarly so previous metadata properties that have been specified are retained and only
   * newly added ones are appended. */
  public mergeFileDescriptors(files: MergeFileDescriptor[]) {
    // TODO: This function is exactly the kind of thing that the Immer library is designed for.  We should consider using it to simplify this and other similar code.

    if (this._options == null) throw new Error('Upload pipeline has not been initialized.');

    for (const file of files) {
      const fileContext = this._fileContexts.get(file.fileId);
      if (fileContext == null) throw new Error(`Could not find file context for file Id: ${file.fileId}.`);

      // This has to manually handle each individual property because we want to retain any existing values that are not being explicitly set.
      // We want "undefined" values to retain the existing value, which the spread operator doesn't do when there is an actual "undefined" value set
      // to a property (vs simply omitted from the object entirely).
      this._fileContexts.set(file.fileId, {
        ...fileContext,
        classification: typeof file.classification !== 'undefined' ? file.classification : fileContext.classification,
        metadata: {
          ...fileContext.metadata,
          ...(typeof file.metadata?.examId !== 'undefined' ? { examId: file.metadata.examId } : {}),
          ...(typeof file.metadata?.userId !== 'undefined' ? { userId: file.metadata.userId } : {}),
          ...(typeof file.metadata?.shareLinkId !== 'undefined' ? { shareLinkId: file.metadata.shareLinkId } : {}),
          ...(typeof file.metadata?.patientId !== 'undefined' ? { patientId: file.metadata.patientId } : {}),
          ...(typeof file.metadata?.suid !== 'undefined' ? { suid: file.metadata.suid } : {}),
        },
      });

      if (typeof file.classification !== 'undefined' && file.classification !== fileContext.classification) {
        datadogLogs.logger.info('upload-pipeline_file-classification-change', {
          uploadSessionId: this._options.uploadSessionId,
          fileId: file.fileId,
          classification: fileContext.classification,
        });
      }
    }
  }

  /** Set fixed entities for the {@link RecordMatcher} to use as a manual override to cause match queries to always reference the provided entities. */
  public setFixedEntities(patientId: number | null, examId: number | null, fixedLocationId: number | null) {
    this.recordMatcher.setFixedEntities(patientId, examId, fixedLocationId);

    // Set the metadata for all files to use the fixed patient and exam entities.
    if (patientId != null || examId != null) {
      for (const [fileId, fileContext] of this._fileContexts) {
        this._fileContexts.set(fileId, {
          ...fileContext,
          metadata: {
            ...fileContext.metadata,
            ...(patientId != null ? { patientId } : {}),
            ...(examId != null ? { examId } : {}),
          },
        });
      }
    }
  }

  /** Begin the file upload to blob storage.  This will also compress files if appropriate prior to upload.  This does not need to be
   * called if the pipeline is operating in `"1-shot"` mode. */
  public startUpload(fileIds: string | string[]) {
    const newQueues = { ...this._streams.queues.getCurrentValue() };
    newQueues.compressionQueueLength += Array.isArray(fileIds) ? fileIds.length : 1;

    const uploadBatchStartEvent: UploadBatchStartEvent = {
      files: [],
      startTime: performance.timeOrigin + performance.now(),
    };

    const nextJobs: ProcessJob[] = [];

    for (const fileId of Array.isArray(fileIds) ? fileIds : [fileIds]) {
      const fileContext = this._fileContexts.get(fileId);
      if (fileContext == null) throw new Error('Could not find file context for file Id: ' + fileId);

      nextJobs.push({ type: 'compress-file', fileId });

      uploadBatchStartEvent.files.push({
        fileId: fileContext.fileId,
        willCompress: fileContext.classification === 'dicom',
        willAttach: ![Classification.Dicom, null].includes(fileContext.classification),
      });
    }

    if (nextJobs.length > 0) {
      this._streams.queues.emit(newQueues);
      this._streams.onUploadBatchStart.emit(uploadBatchStartEvent);
      this.processJobs(nextJobs);
    }
  }

  private processJobs(jobs: ProcessJob[]) {
    if (this._options == null) throw new Error('Upload pipeline has not been initialized.');

    for (const job of jobs) {
      const fileContext = this._fileContexts.get(job.fileId);
      if (fileContext == null) throw new Error(`Could not find file context for file Id: ${job.fileId}.`);

      if (job.type === 'scan-file') {
        this.fileScanner.process(fileContext, this._diskLock);
      } else if (job.type === 'generate-thumbnail') {
        this.thumbnailGenerator.process(fileContext, this._diskLock);
      } else if (job.type === 'record-match') {
        this.recordMatcher.process(fileContext, false);
      } else if (job.type === 'record-match-forced') {
        this.recordMatcher.process(fileContext, true);
      } else if (job.type === 'compress-file') {
        this.fileCompressor.process(fileContext, this._diskLock);
      } else if (job.type === 'upload-file') {
        this.blobUploader.process(fileContext, this._uploadLock);
      } else if (job.type === 'attach-file') {
        this.fileAttacher.process(fileContext);
      } else {
        throw new Error(`Unknown job type: ${job.type}.`);
      }
    }
  }

  private detectCdReads(event: ParseCompleteEvent, queues: QueueState) {
    if (this._options == null) throw new Error('Upload pipeline has not been initialized.');

    // Monitor the scan job timestamps to determine if we are reading from a CD/DVD.
    if (event.didReadFile) {
      this._scansJobTimeStamps.push({ startTime: event.startTime, duration: event.duration });
      const monitorStart = this._scansJobTimeStamps.at(0)?.startTime;
      const monitorEnd = event.startTime + event.duration;

      if (monitorStart != null) {
        const scansDuration = monitorEnd - monitorStart;
        const scansPerSecond = this._scansJobTimeStamps.length / (scansDuration / 1000);
        const setCdMode = scansPerSecond < 5; // If we are scanning less than 5 files per second, then we are likely reading from a CD/DVD.

        // Only toggle the cd mode if we have scanned at least 3 files and 3 timestamps to ensure that we have a good sample size.
        if (
          this._options.triggerCdMode === 'auto' &&
          setCdMode !== this._options.cdMode &&
          queues.deepScansCount >= 3 &&
          this._scansJobTimeStamps.length >= 3
        ) {
          this.setCdMode(setCdMode, 'auto', {
            scansDuration,
            scansPerSecond,
            scansJobsCount: queues.scanQueueLength,
            scansJobTimeStamps: this._scansJobTimeStamps.length,
          });
          this._options.triggerCdMode = 'manual'; // Prevents the cd mode from being toggled again until the user manually toggles it.
        }
      }
    }
  }

  /** Should be called right after a file has been scanned. */
  private async initializeUploadTrackers(event: ProcessJobEvent<ParseCompleteEvent>, queues: QueueState) {
    if (this._options == null) throw new Error('Upload pipeline has not been initialized.');

    if (this._options.mode === '1-shot') {
      // 1-shot mode we track all files as if they were a single study.
      let tracker = this._dicomStudyTracking.get('');

      if (tracker == null) {
        tracker = {
          id: 0,
          requestUID: this._options.uploadSessionId,
          studyInstanceUID: '',
          status: 'New',
          source: 'Web',
          userProfile: null,
          ipAddress: null,
          patientName: '',
          patientDOB: null,
          studyDescription: '',
          modality: '',
          locationId: null,
          location: null,
          locationType: null,
          compressStartTime: null,
          compressEndTime: null,
          totalCompressedTime: null,
          uploadStartTime: null,
          uploadEndTime: null,
          uploadedDateTime: null,
          completedDateTime: null,
          unosId: null,
          imageCount: 0,
          sendCount: null,
          uploadSpeed: null,
          totalScannedSize: '',
          totalDICOMSize: null,
          totalScannedCount: 0,
          totalDICOMCount: 0,
          totalUploadTime: null,
          totalScannedTime: null,
          examId: null,
          scanStartTime: null,
          scanEndTime: null,
          device: null,
          sizeBytes: 0,
          calculatedSpeedMbps: null,
        };

        this._dicomStudyTracking.set('', tracker);
      }

      const fileContext = this._fileContexts.get(event.fileId);
      if (fileContext == null) throw new Error(`Could not find file context for file Id: ${event.fileId}.`);
      tracker.sizeBytes = (tracker.sizeBytes ?? 0) + fileContext.file.size;
    } else {
      // Interactive mode we only track DICOM studies.

      if (event.result === 'processed' && hasText(event.dicomData?.StudyInstanceUID)) {
        let tracker = this._dicomStudyTracking.get(event.dicomData.StudyInstanceUID);

        if (tracker == null) {
          tracker = {
            id: 0,
            requestUID: this._options.uploadSessionId,
            studyInstanceUID: event.dicomData.StudyInstanceUID,
            status: 'New',
            source: 'Web',
            userProfile: null,
            ipAddress: null,
            patientName: event.dicomData.PatientName.join(' '),
            patientDOB: event.dicomData.PatientBirthDate,
            studyDescription: event.dicomData.StudyDescription,
            modality: event.dicomData.Modality,
            locationId: null,
            location: null,
            locationType: null,
            compressStartTime: null,
            compressEndTime: null,
            totalCompressedTime: null,
            uploadStartTime: null,
            uploadEndTime: null,
            uploadedDateTime: null,
            completedDateTime: null,
            unosId: null,
            imageCount: 0,
            sendCount: null,
            uploadSpeed: null,
            totalScannedSize: '',
            totalDICOMSize: null,
            totalScannedCount: 0,
            totalDICOMCount: 0,
            totalUploadTime: null,
            totalScannedTime: null,
            examId: null,
            scanStartTime: dayjs(event.startTime).utc().format('YYYY-MM-DDTHH:mm:ss.SSSSSS'),
            scanEndTime: null,
            device: null,
            sizeBytes: 0,
            calculatedSpeedMbps: null,
          };

          this._dicomStudyTracking.set(event.dicomData.StudyInstanceUID, tracker);
        }

        tracker.sizeBytes = (tracker.sizeBytes ?? 0) + event.file.size;
        tracker.scanEndTime = dayjs(event.startTime + event.duration)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss.SSSSSS');
        tracker.totalScannedCount = (tracker.totalScannedCount ?? 0) + 1;
        tracker.imageCount = (tracker.imageCount ?? 0) + 1;
      }
    }

    // When this is the last file to be scanned we need to create the upload tracker entries.
    if (queues.scanQueueLength === 0) {
      const uploadTrackers: UploadModel[] = [];
      for (const uploadTracker of this._dicomStudyTracking.values()) {
        uploadTrackers.push(uploadTracker);
      }

      if (uploadTrackers.length > 0) {
        const updatedTrackers = await apiClient.uploadTrackerClient.bulkAdd(uploadTrackers, this._options.authMode);
        for (const updatedTracker of updatedTrackers) {
          this._dicomStudyTracking.get(updatedTracker.studyInstanceUID)!.id = updatedTracker.id;
        }
      }
    }
  }

  private updateUploadTrackerForCompression(event: ProcessJobEvent<CompressCompleteEvent>) {
    if (this._options == null) throw new Error('Upload pipeline has not been initialized.');

    if (this._options.mode === '1-shot') {
      if (event.result === 'processed' && event.buffer != null) {
        const tracker = this._dicomStudyTracking.get('');
        if (tracker == null) throw new Error('Could not find upload tracker for 1-shot mode.');

        tracker.compressStartTime = dayjs(event.startTime).utc().format('YYYY-MM-DDTHH:mm:ss.SSSSSS');
        tracker.compressEndTime = dayjs(event.startTime + event.duration)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss.SSSSSS');
      }
    } else {
      const fileContext = this._fileContexts.get(event.fileId);
      if (fileContext == null) throw new Error(`Could not find file context for file Id: ${event.fileId}.`);

      if (event.result === 'processed' && event.buffer != null && hasText(fileContext.dicomData?.StudyInstanceUID)) {
        const tracker = this._dicomStudyTracking.get(fileContext.dicomData.StudyInstanceUID);
        if (tracker == null) throw new Error(`Could not find upload tracker for StudyInstanceUID: ${fileContext.dicomData.StudyInstanceUID}.`);

        tracker.compressStartTime = dayjs(event.startTime).utc().format('YYYY-MM-DDTHH:mm:ss.SSSSSS');
        tracker.compressEndTime = dayjs(event.startTime + event.duration)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss.SSSSSS');
      }
    }
  }

  /** Should be called once a file has been uploaded to blob storage. */
  private async finalizeUploadTrackers(event: ProcessJobEvent<UploadCompleteEvent>, queues: QueueState) {
    if (this._options == null) throw new Error('Upload pipeline has not been initialized.');

    if (this._options.mode === '1-shot') {
      const tracker = this._dicomStudyTracking.get('');
      if (tracker == null) throw new Error('Could not find upload tracker for 1-shot mode.');

      if (event.result === 'processed') {
        tracker.uploadStartTime = tracker.uploadStartTime ?? dayjs(event.startTime).utc().format('YYYY-MM-DDTHH:mm:ss.SSSSSS');
        tracker.uploadEndTime = dayjs(event.endTime).utc().format('YYYY-MM-DDTHH:mm:ss.SSSSSS');
        tracker.completedDateTime = dayjs(event.endTime).utc().format('YYYY-MM-DDTHH:mm:ss.SSSSSS');
      }
    } else {
      if (event.result === 'processed') {
        const fileContext = this._fileContexts.get(event.fileId);
        if (fileContext == null) throw new Error(`Could not find file context for file Id: ${event.fileId}.`);

        // Upload tracker metadata
        const tracker = hasText(fileContext.dicomData?.StudyInstanceUID) ? this._dicomStudyTracking.get(fileContext.dicomData.StudyInstanceUID) : undefined;

        if (tracker) {
          tracker.uploadStartTime = tracker.uploadStartTime ?? dayjs(event.startTime).utc().format('YYYY-MM-DDTHH:mm:ss.SSSSSS');
          tracker.uploadEndTime = dayjs(event.endTime).utc().format('YYYY-MM-DDTHH:mm:ss.SSSSSS');
          tracker.completedDateTime = dayjs(event.endTime).utc().format('YYYY-MM-DDTHH:mm:ss.SSSSSS');
        }
      }
    }

    // Send upload tracker data to the API.
    if (queues.uploadQueueLength === 0) {
      const uploadTrackers: UploadModel[] = [];
      for (const uploadTracker of this._dicomStudyTracking.values()) {
        uploadTracker.status = 'Done';
        uploadTrackers.push(uploadTracker);
      }

      if (uploadTrackers.length > 0) {
        await apiClient.uploadTrackerClient.bulkUpdate(uploadTrackers, this._options.authMode);
      }
    }
  }

  private handleParseComplete(event: ProcessJobEvent<ParseCompleteEvent>) {
    if (this._options == null) throw new Error('Upload pipeline has not been initialized.');

    const newQueues = { ...this._streams.queues.getCurrentValue() };
    newQueues.scanQueueLength--;
    newQueues.deepScansCount += event.result === 'processed' && event.didReadFile ? 1 : 0;

    if (event.result === 'processed') {
      const fileContext = this._fileContexts.get(event.fileId);
      if (fileContext == null) throw new Error(`Could not find file context for file Id: ${event.fileId}.`);

      this._fileContexts.set(event.fileId, {
        ...fileContext,
        dicomData: event.dicomData,
        fileType: event.fileType,
      });

      this.detectCdReads(event, newQueues);
    }

    this.initializeUploadTrackers(event, newQueues); // Don't await this to avoid concurrency issues with the streams.queue emit below.
    newQueues.recordMatcherQueueLength++;
    newQueues.thumbnailQueueLength += this._options.generateThumbnails ? 1 : 0;
    this._streams.queues.emit(newQueues);

    this.processJobs([
      { type: 'record-match', fileId: event.fileId },
      ...(this._options.generateThumbnails ? [{ type: 'generate-thumbnail', fileId: event.fileId } as const] : []),
    ]);
  }

  private handleThumbnailGenerated(event: ProcessJobEvent<ThumbnailGeneratedEvent>) {
    if (this._options == null) throw new Error('Upload pipeline has not been initialized.');

    if (event.result === 'processed') {
      const fileContext = this._fileContexts.get(event.fileId);
      if (fileContext == null) throw new Error(`Could not find file context for file Id: ${event.fileId}.`);

      this._fileContexts.set(event.fileId, {
        ...fileContext,
        thumbnailId: event.thumbnailId,
      });
    }

    const newQueues = { ...this._streams.queues.getCurrentValue() };
    newQueues.thumbnailQueueLength--;
    this._streams.queues.emit(newQueues);
  }

  private handleMatchComplete(event: ProcessJobEvent<MatchCompleteEvent>) {
    if (this._options == null) throw new Error('Upload pipeline has not been initialized.');

    const newQueues = { ...this._streams.queues.getCurrentValue() };
    newQueues.recordMatcherQueueLength--;

    if (event.result === 'processed') {
      this.mergeFileDescriptors([
        {
          fileId: event.fileId,
          metadata: {
            ...(event.patientId == null ? {} : { patientId: event.patientId }),
            ...(event.examId == null ? {} : { examId: event.examId }),
          },
        },
      ]);
    }

    // Setup the next jobs to process.
    const nextJobs: ProcessJob[] = [];

    if (this._options.mode === 'interactive' && newQueues.scanQueueLength === 0 && newQueues.recordMatcherQueueLength === 0) {
      // When operating in "interactive" mode we want to force creation of patient/exam records if we have been unable to match them.

      for (const [fileId, fileContext] of this._fileContexts) {
        if (fileContext.dicomData != null && (fileContext.metadata.patientId == null || fileContext.metadata.examId == null)) {
          newQueues.recordMatcherQueueLength++;
          nextJobs.push({ type: 'record-match-forced', fileId });
        }
      }
    } else if (this._options.mode === '1-shot') {
      // 1-shot mode
      newQueues.compressionQueueLength++;
      nextJobs.push({ type: 'compress-file', fileId: event.fileId });
    }

    this._streams.queues.emit(newQueues);

    this.processJobs(nextJobs);
  }

  private handleMatchError() {
    const newQueues = { ...this._streams.queues.getCurrentValue() };
    newQueues.recordMatcherQueueLength--;
    this._streams.queues.emit(newQueues);
  }

  private handleCompressComplete(event: ProcessJobEvent<CompressCompleteEvent>) {
    const newQueues = { ...this._streams.queues.getCurrentValue() };
    newQueues.compressionQueueLength--;

    if (event.result === 'processed') {
      const fileContext = this._fileContexts.get(event.fileId);
      if (fileContext == null) throw new Error(`Could not find file context for file Id: ${event.fileId}.`);

      this._fileContexts.set(event.fileId, {
        ...fileContext,
        compressedFile: event.buffer,
      });
    }

    this.updateUploadTrackerForCompression(event);
    newQueues.uploadQueueLength++;
    this._streams.queues.emit(newQueues);
    this.processJobs([{ type: 'upload-file', fileId: event.fileId }]);
  }

  private handleCompressError() {
    const newQueues = { ...this._streams.queues.getCurrentValue() };
    newQueues.compressionQueueLength--;
    this._streams.queues.emit(newQueues);
  }

  private handleUploadComplete(event: ProcessJobEvent<UploadCompleteEvent>) {
    if (this._options == null) throw new Error('Upload pipeline has not been initialized.');

    const newQueues = { ...this._streams.queues.getCurrentValue() };
    newQueues.uploadQueueLength--;

    if (event.result === 'processed') {
      const fileContext = this._fileContexts.get(event.fileId);
      if (fileContext == null) throw new Error(`Could not find file context for file Id: ${event.fileId}.`);

      this._fileContexts.set(event.fileId, { ...fileContext, uploadUrl: event.url, result: 'success' });
    }

    this.finalizeUploadTrackers(event, newQueues); // Don't await this to avoid concurrency issues with the streams.queue emit below.
    newQueues.attachQueueLength++;
    this._streams.queues.emit(newQueues);
    this.processJobs([{ type: 'attach-file', fileId: event.fileId }]);
  }

  private handleUploadError(event: UploadErrorEvent) {
    const newQueues = { ...this._streams.queues.getCurrentValue() };
    newQueues.uploadQueueLength--;

    const fileContext = this._fileContexts.get(event.fileId);
    if (fileContext == null) throw new Error(`Could not find file context for file Id: ${event.fileId}.`);

    this._fileContexts.set(event.fileId, { ...fileContext, result: 'error' });

    this._streams.queues.emit(newQueues);
  }

  private handleFileAttached(event: FileAttachedEvent) {
    const newQueues = { ...this._streams.queues.getCurrentValue() };
    newQueues.attachQueueLength--;

    const fileContext = this._fileContexts.get(event.fileId);
    if (fileContext == null) throw new Error(`Could not find file context for file Id: ${event.fileId}.`);

    this._fileContexts.set(event.fileId, {
      ...fileContext,
      result: fileContext.uploadUrl != null ? 'success' : 'error',
    });

    this._streams.queues.emit(newQueues);
  }
}
