import { LocationModel, UserModel, WorklistViewAccessListModel, WorklistViewModel } from 'models';
import { WorklistViewAccessTypeModel } from 'models/WorklistViewAccessTypeModel';

import { apiClient } from 'core/api/globals';
import { findOrThrow } from 'core/utils';

import { EditFormValues } from '../types';

function copyModelToForm(
  model: WorklistViewAccessListModel | null,
  allAccessModes: WorklistViewAccessTypeModel[],
  allUsers: UserModel[],
  allWorklists: WorklistViewModel[],
  allLocations: LocationModel[],
): EditFormValues {
  if (model == null) {
    return {
      users: [],
      access: null,
      worklist: null,
      active: true,
      default: false,
      locations: [],
    };
  }

  const access = findOrThrow(
    allAccessModes,
    (a) => a.id === model.worklistViewAccessTypeId,
    `Could not find WorklistViewAccess with id: ${model.worklistViewAccessTypeId}.`,
  );

  const users = model.users ? allUsers.filter((u) => model.users.map((s) => s.id).includes(u.id)) : [];

  const worklist = findOrThrow(allWorklists, (w) => w.id === model.worklistViewId, `Could not find worklist with id: ${model.worklistViewId}.`);

  const locations = model.locations ? allLocations.filter((item) => model.locations.map((m) => m.id).includes(item.id)) : [];

  return {
    users,
    access,
    worklist,
    active: model.active,
    default: model.default,
    locations,
  };
}

function copyFormToModel(original: WorklistViewAccessListModel | null, form: EditFormValues): WorklistViewAccessListModel {
  if (form.users == null) {
    throw new Error('Cannot save because user is null or undefined.');
  }

  if (form.worklist == null) {
    throw new Error('Cannot save because worklist is null or undefined.');
  }
  if (form.access == null) {
    throw new Error('Cannot save because access is null or undefined.');
  }

  return {
    id: original == null ? 0 : original.id,
    worklistViewId: form.worklist.id,
    worklistViewAccessTypeId: form.access.id,
    users: form.users,
    active: form.active,
    default: form.default,
    locations: form.locations,
  };
}

async function initialize(id: number | null | undefined) {
  const modelPromise =
    id == null
      ? new Promise<null>((resolve) => {
          resolve(null);
        })
      : apiClient.workListViewClient.getWorkListViewAccess(id);

  const [model, allWorklists, { data: allUsers }, allAccessModes, allLocations, allWorklistAccessType] = await Promise.all([
    modelPromise,
    apiClient.workListViewClient.getViews(true),
    apiClient.users.getAllForKendoGrid({
      filter: {
        logic: 'and',
        filters: [
          {
            field: 'email',
            operator: 'isnotempty',
          },
        ],
      },
    }), // TODO: For now we just want to get all the users.  There is definitely room to optimize this.
    apiClient.workListViewClient.getAllWorklistViewAccess(),
    apiClient.locationClient.getAllLocations(),
    apiClient.workListViewClient.getAllWorklistViewAccessType(),
  ]);

  return {
    model,
    allWorklists,
    allUsers,
    allAccessModes,
    form: copyModelToForm(model, allWorklistAccessType, allUsers, allWorklists, allLocations),
    allWorklistAccessType,
  };
}

async function save(form: EditFormValues, original: WorklistViewAccessListModel | null): Promise<WorklistViewAccessListModel> {
  const newModel = copyFormToModel(original, form);
  if (newModel.id === 0) {
    const id = await apiClient.workListViewClient.createUpdateWorkListViewAccess(newModel);
    newModel.id = id;
  } else {
    await apiClient.workListViewClient.createUpdateWorkListViewAccess(newModel);
  }

  return newModel;
}

async function deleteAccess(id: number) {
  await apiClient.workListViewClient.deleteWorkListViewUser(id);
}

export const WorklistViewUserEditService = {
  initialize,
  copyModelToForm,
  copyFormToModel,
  save,
  deleteAccess,
};
