import { useCallback, useEffect, useMemo, useState } from 'react';

import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import dayjs from 'dayjs';
import { values } from 'lodash';
import _map from 'lodash/map';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

import { ExamModel, FormModel, ImageQualityModel, LocationModel, PatientModel, PhysicianModel, ServiceModel, TagModel, TatModel } from 'models';

// consolidated import
import { Field, Form, FormElement, FormStates, FormValidatorsService } from 'core/forms';
import { useEvent } from 'core/hooks';
import { NotificationsService } from 'core/notifications';
import {
  Accordion,
  Button,
  Checkbox,
  DatePicker,
  DateTimePicker,
  Dropdown,
  DropdownWithValuesField,
  Icon,
  Input,
  Label,
  MASKED_DATETIME_INPUT_MODES,
  MaskedDateTimeInput,
  TextArea,
} from 'core/ui';
import { hasText } from 'core/utils';

import { useApiClient } from 'features/api';
import { FormAccordian } from 'features/form';
// Update import paths to new stub components
import { LocationSelectionAccordion } from 'features/location/fragments/LocationSelectionAccordion';
import { PatientFormAccordion } from 'features/patient/fragments/PatientFormAccordion';
import { ReadRequestService } from 'features/read-request';
import { useUserSettings } from 'features/settings';
import { SettingsHelpers } from 'features/settings/services/settings-helpers';
import { WellKnownSettingName } from 'features/settings/types';
import { TagsField } from 'features/tags/fragments';

import ExamIcon from '../assets/exam.svg?react';
import { BiopsyTypes, ExamDevices, ExamMethods, ExamSources, Lateralities, Organs, SlidePreparations } from '../constants';
import { ExamInitialValueService, ExamTypeService } from '../services';
import { SubmitParams } from '../types';
import { OverreadHistory } from './OverreadHistory';

const LOCATION_ACCORDION_KEY = '2';
const EXAM_ACCORDION_KEY = '3';
const TAGS_ACCORDION_KEY = '4';

const PATIENT_ACCORDION_KEY = '1';
const EDIT_EXAM_ACTIVE_KEYS = [LOCATION_ACCORDION_KEY, EXAM_ACCORDION_KEY]; //collapse patient panel on exam edit
const ADD_EXAM_ACTIVE_KEYS = [PATIENT_ACCORDION_KEY, LOCATION_ACCORDION_KEY, EXAM_ACCORDION_KEY];

// Define missing types
interface ServiceChangeEvent {
  value: { id: number }[];
}

/* ========= Component Props ========= */

interface ExamProcessingSidebarBodyProps {
  currentPatient: PatientModel | null;
  exam: ExamModel | null;
  handleSubmit: ({ values, isValid }: SubmitParams) => Promise<void> | void;
  isFormInEditMode: boolean;
  patientFormState: FormStates | null | undefined;
  patientSearchText: string;
  setCurrentPatient: (patient: PatientModel | null) => void;
  setPatientFormState: (state: string) => void;
  setPatientSearchText: (text: string) => void;
  onLocationIdChange: (id: number | undefined) => void;
  isInternal: boolean;
  isSubmitting: boolean;
  tags: TagModel[];
  checkedItems: Record<number, boolean>;
  handleCheckboxChange: (id: number, children?: TagModel[]) => void;
  handleRemoveTag: (id: number) => void;
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  filterChecklist: (items: TagModel[]) => TagModel[];
  flatList: TagModel[];
}

/* ========= Component ========= */

const ExamProcessingSidebarBody: React.FC<ExamProcessingSidebarBodyProps> = ({
  currentPatient,
  exam,
  handleSubmit,
  isFormInEditMode,
  patientFormState,
  patientSearchText,
  setCurrentPatient,
  setPatientFormState,
  setPatientSearchText,
  onLocationIdChange,
  isInternal,
  isSubmitting,
  tags,
  checkedItems,
  handleCheckboxChange,
  handleRemoveTag,
  searchTerm,
  setSearchTerm,
  filterChecklist,
  flatList,
}) => {
  const apiClient = useApiClient();
  const [forms, setForms] = useState<FormModel[]>([]);
  const [selectedLocationId, setSelectedLocationId] = useState<number | null>(null);
  const [isPatientAccordionCollapsed, setIsPatientAccordionCollapsed] = useState<boolean>(false);
  const [isPatientValid, setIsPatientValid] = useState<boolean>(true); // Track patient validity
  const [imageQualities, setImageQualities] = useState<ImageQualityModel[]>([]);
  const [allServices, setAllServices] = useState<ServiceModel[]>([]);
  const [locationServices, setLocationServices] = useState<ServiceModel[]>([]);
  const [defaultTats, setDefaultTats] = useState<TatModel[]>([]);
  const [locations, setLocations] = useState<LocationModel[]>([]);
  const [physicians, setPhysicians] = useState<PhysicianModel[]>([]);
  const { userSettings } = useUserSettings();
  const [manualInfoAddedIdRequired, setManualInfoAddedIdRequired] = useState<boolean>(
    SettingsHelpers.findSettingByName(userSettings ?? [], WellKnownSettingName.ExamManualInfoAdded, false)?.value === 'true',
  );
  const [patientIdRequired, setPatientIdRequired] = useState<boolean>(
    SettingsHelpers.findSettingByName(userSettings ?? [], WellKnownSettingName.PatientIdRequired, false)?.value === 'true',
  );

  // Function to validate patient
  const validatePatient = useCallback(
    (patient: PatientModel | null): boolean => {
      if (!patient) return false;

      // Check required fields
      if (!hasText(patient.firstName) || !hasText(patient.lastName)) {
        return false;
      }

      // Check patientNumber if required
      if (patientIdRequired && !hasText(patient.patientNumber)) {
        return false;
      }

      return true;
    },
    [patientIdRequired],
  );

  // get the settings from location related to account
  useEffect(() => {
    apiClient.settingsClient.getSettings(true, null, null, selectedLocationId ?? null).then((res) => {
      const isRequired = res?.find((item) => item.name === WellKnownSettingName.ExamManualInfoAdded)?.value === 'true';
      setManualInfoAddedIdRequired(isRequired);

      const isPatientIdRequired = res?.find((item) => item.name === WellKnownSettingName.PatientIdRequired)?.value === 'true';
      setPatientIdRequired(isPatientIdRequired);
    });
  }, [apiClient.settingsClient, selectedLocationId]);

  // Check if patient is valid when loading the form
  useEffect(() => {
    if (currentPatient) {
      const isValidPatient = validatePatient(currentPatient);
      setIsPatientValid(isValidPatient);

      if (!isValidPatient) {
        console.log('invalid patient');
        // Ensure patient accordion is expanded and form is in edit mode
        setIsPatientAccordionCollapsed(false);
        setPatientFormState(FormStates.EDIT);
      }
    }
  }, [currentPatient, patientIdRequired, setPatientFormState, validatePatient]);

  // Override handleSubmit to first validate the patient
  const handleExamSubmit = useEvent((dataForm: { isValid: boolean; values: Record<string, unknown> }) => {
    if (!dataForm.isValid) {
      return;
    }

    if (!validatePatient(currentPatient)) {
      // Ensure patient section is expanded when validation fails
      setIsPatientAccordionCollapsed(false);
      setPatientFormState(FormStates.EDIT);
      NotificationsService.displayError('Please complete required patient information');
      return;
    }

    handleSubmit({ values: dataForm.values, isValid: dataForm.isValid });
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [services, qualities, tats, locs, phys] = await Promise.all([
          apiClient.servicesClient.getAllServices(),
          apiClient.imageQualityClient.getAll(),
          apiClient.tatClient.getDefaultTATs(),
          apiClient.locationClient.getAllLocations(),
          apiClient.physicianClient.getAll(),
        ]);
        setAllServices(services);
        setImageQualities(qualities);
        setDefaultTats(tats);
        // Convert location lite model to full LocationModel
        setLocations(locs);
        setPhysicians(phys);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [apiClient.imageQualityClient, apiClient.locationClient, apiClient.physicianClient, apiClient.servicesClient, apiClient.tatClient]);

  useEffect(() => {
    const fetchLocationServices = async () => {
      if (selectedLocationId !== null) {
        try {
          const data = await apiClient.servicesClient.getServicesByLocation(selectedLocationId);
          setLocationServices(data);
        } catch (error) {
          console.error('Error fetching location services:', error);
        }
      }
    };
    fetchLocationServices();
  }, [apiClient.servicesClient, selectedLocationId]);

  const initialState = useMemo(() => exam ?? ExamInitialValueService.getInitialValues(), [exam]);

  const handleLocationChange = useCallback(
    (newLocationId: number, setValue: (field: string, value: { value: unknown }) => void, valueGetter: (field: string) => unknown) => {
      const selectedTat = valueGetter('sla') as TatModel | undefined;
      const location = locations.find((l) => l.id === newLocationId) ?? null;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      setValue('location', { value: location });
      setSelectedLocationId(newLocationId);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      onLocationIdChange(newLocationId);

      if (selectedTat == null || (typeof selectedTat.value === 'number' && selectedTat.value >= 1440)) {
        const defaultPhysician = physicians.find((e) => e.id === location?.defaultPhysician_Id);
        if (defaultPhysician) {
          setValue('physician', { value: defaultPhysician });
        }
        const defaultTAT = defaultTats.find((e) => e.value === location?.defaultTAT);
        if (defaultTAT) {
          setValue('sla', { value: defaultTAT });
        }
      }
    },
    [locations, onLocationIdChange, physicians, defaultTats],
  );

  const handleServicesChange = useEvent(async (event: ServiceChangeEvent) => {
    const ids = event.value.map((e) => e.id);
    try {
      const formValues = await apiClient.formClient.findServices(ids);
      setForms(formValues);
    } catch (error) {
      console.error('Error fetching services:', error);
    }
  });

  useEffect(() => {
    const initModal = async () => {
      if (exam?.id && exam?.serviceId) {
        try {
          // Added third argument (locationId) to satisfy expected 3 arguments.
          const formValues = await apiClient.formClient.find(exam.id, exam.serviceId, selectedLocationId ?? 0);
          setForms(formValues);
        } catch (error) {
          console.error('Error initializing modal:', error);
        }
      }
    };
    initModal();
  }, [apiClient.formClient, exam?.id, exam?.serviceId, selectedLocationId]);

  return (
    <Form
      initialValues={initialState}
      key={isFormInEditMode ? JSON.stringify(initialState) : 'exam-new'}
      onSubmitClick={handleExamSubmit}
      render={({ onChange: setValue, valueGetter }) => {
        const studyType = valueGetter('service.description') as string;
        const selectedPhysician = valueGetter('physician');
        const selectedLocation = valueGetter('location') as LocationModel | null;
        const selectedTat = valueGetter('sla') as TatModel | undefined;
        const selectedService = valueGetter('service');
        const selectedRequestDate = dayjs(valueGetter('requestedDate') as string);
        const selectedRequestTime = dayjs(valueGetter('requestTime') as string);
        const isPathology = ExamTypeService.isPathology(studyType);

        const tatDate = dayjs(selectedRequestDate)
          .hour(selectedRequestTime.hour())
          .minute(selectedRequestTime.minute())
          .second(selectedRequestTime.second())
          .add(selectedTat ? Number(selectedTat.value) : 0, 'minute');

        const handleTatChange = () => {
          const formattedDate = dayjs().utc().format('YYYY-MM-DDTHH:mm:ss');
          setValue('requestedDate', { value: formattedDate });
          setValue('requestTime', { value: formattedDate });
        };

        // If patient is invalid in edit mode, make sure patient accordion is active
        const defaultActiveKeys = isFormInEditMode
          ? !isPatientValid
            ? [...EDIT_EXAM_ACTIVE_KEYS, PATIENT_ACCORDION_KEY]
            : EDIT_EXAM_ACTIVE_KEYS
          : ADD_EXAM_ACTIVE_KEYS;

        return (
          <StyledAccordion alwaysOpen defaultActiveKey={defaultActiveKeys}>
            {locations.length > 0 && (
              <LocationSelectionAccordion
                eventKey="2"
                locationId={selectedLocation?.id ?? null}
                locations={locations}
                onChange={(newLocationId: number | null) => {
                  if (newLocationId !== null) {
                    handleLocationChange(newLocationId, setValue, valueGetter);
                  }
                }}
              />
            )}

            <PatientFormAccordion
              eventKey="1"
              formState={patientFormState ?? undefined}
              isCollapsed={isPatientAccordionCollapsed}
              handleHeaderClick={() => setIsPatientAccordionCollapsed((oldState) => !oldState)}
              patient={currentPatient}
              searchText={patientSearchText}
              setCurrentPatient={setCurrentPatient}
              setFormState={setPatientFormState}
              setSearchText={setPatientSearchText}
              locationId={selectedLocation?.id ?? null}
            />

            <FormElement>
              {currentPatient && (
                <>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header Icon={ExamIcon} title="Study" />
                    <Accordion.Body>
                      {isInternal && (
                        <>
                          <Row>
                            {hasText(exam?.statusType?.name) && hasText(exam?.statusType?.description) && (
                              <Label description={exam.statusType.description}>Status: {exam.statusType.name}</Label>
                            )}

                            <Col className="col-6">
                              <Field
                                component={DropdownWithValuesField}
                                data={ExamSources}
                                dataItemKey="value"
                                isForPrimitiveValues
                                label="Study Source"
                                name="source"
                                required
                                validator={FormValidatorsService.required}
                                valueField="value"
                              />
                            </Col>
                            <Col className="col-6">
                              <Field
                                component={DropdownWithValuesField}
                                data={ExamDevices}
                                isForPrimitiveValues
                                dataItemKey="value"
                                label="Device"
                                name="device"
                                valueField="value"
                              />
                            </Col>
                          </Row>
                          <StyledRow>
                            <Col className="col-6">
                              <Field
                                component={DropdownWithValuesField}
                                data={ExamMethods}
                                isForPrimitiveValues
                                dataItemKey="value"
                                label="Method"
                                name="transmissionMethod"
                                valueField="value"
                              />
                            </Col>
                            <Col className="col-6">
                              <Field component={Dropdown} data={imageQualities} label="Image Quality" name="imageQuality" textField="description" />
                            </Col>
                          </StyledRow>
                        </>
                      )}
                      <StyledRow>
                        <Col className="col-12">
                          <Field
                            component={Dropdown}
                            data={locationServices?.length > 0 ? locationServices : allServices}
                            label="Study Type"
                            name="service"
                            textField="description"
                          />
                        </Col>
                      </StyledRow>
                      <StyledRow>
                        <Col className="col-6">
                          <Field
                            component={MaskedDateTimeInput}
                            label="Study Date"
                            mode={MASKED_DATETIME_INPUT_MODES.DATE}
                            name="studyDate"
                            required
                            validator={FormValidatorsService.combineValidators([FormValidatorsService.required, FormValidatorsService.datetime])}
                          />
                        </Col>
                        <Col className="col-6">
                          <Field
                            component={MaskedDateTimeInput}
                            mode={MASKED_DATETIME_INPUT_MODES.TIME}
                            label="Study Time"
                            name="studyTime"
                            required
                            validator={FormValidatorsService.combineValidators([FormValidatorsService.required, FormValidatorsService.datetime])}
                          />
                        </Col>
                      </StyledRow>
                      <StyledRow>
                        {isInternal && (
                          <Col className="col-6">
                            <Field
                              component={DateTimePicker}
                              label="Received Date Time"
                              name="uploadedDate"
                              required
                              format="MM/dd/yy HH:mm:ss"
                              validator={FormValidatorsService.combineValidators([FormValidatorsService.required, FormValidatorsService.datetime])}
                            />
                          </Col>
                        )}
                        <Col className="col-6">
                          <Field component={Input} description="Customer UID or Accession Number" label="Accession Number" name="customerUID" />
                        </Col>
                      </StyledRow>
                      <StyledRow>
                        <Col className="col-12">
                          <Field component={Input} description="Hospital" label="Hospital" name="hospital" />
                        </Col>
                      </StyledRow>
                      {isPathology && (
                        <>
                          <StyledRow>
                            <Col className="col-6">
                              <Field
                                component={DropdownWithValuesField}
                                data={_map(Organs)}
                                dataItemKey="value"
                                filterable={false}
                                isForPrimitiveValues
                                label="Organ"
                                name="organ"
                                required
                                validator={FormValidatorsService.required}
                                valueField="value"
                              />
                            </Col>
                            <Col className="col-6">
                              <Field
                                component={DropdownWithValuesField}
                                data={_map(Lateralities)}
                                dataItemKey="value"
                                filterable={false}
                                isForPrimitiveValues
                                label="Laterality"
                                name="laterality"
                                required
                                validator={FormValidatorsService.required}
                                valueField="value"
                              />
                            </Col>
                          </StyledRow>
                          <StyledRow>
                            <Col className="col-6">
                              <Field
                                component={DropdownWithValuesField}
                                data={_map(BiopsyTypes)}
                                dataItemKey="value"
                                filterable={false}
                                isForPrimitiveValues
                                label="Biopsy Type"
                                name="biopsyType"
                                required
                                validator={FormValidatorsService.required}
                                valueField="value"
                              />
                            </Col>
                            <Col className="col-6">
                              <Field
                                component={DropdownWithValuesField}
                                data={_map(SlidePreparations)}
                                dataItemKey="value"
                                filterable={false}
                                isForPrimitiveValues
                                label="Slide Preparation"
                                name="slidePreparation"
                                required
                                validator={FormValidatorsService.required}
                                valueField="value"
                              />
                            </Col>
                          </StyledRow>
                        </>
                      )}
                      {isInternal && (
                        <StyledRow>
                          <Col className="col-12">
                            <Field component={Input} label="Description" name="description" description="Study Description for the exam or service" />
                          </Col>
                        </StyledRow>
                      )}
                      <StyledRow>
                        <Col className="col-12">
                          <Field component={TextArea} label="Study Notes" name="notes" />
                        </Col>
                      </StyledRow>
                      {isInternal && (
                        <>
                          <StyledRow>
                            <Col className="col-12">
                              <Field component={TextArea} label="Internal Notes" name="internalNotes" />
                            </Col>
                          </StyledRow>

                          <StyledRow>
                            <Col className="col-12">
                              <Field component={TextArea} label="Interpretation" name="interpretation" />
                            </Col>
                          </StyledRow>
                          <StyledRow>
                            <Col className="col-12">
                              <Field component={Input} label="Image URL" name="imageURL" />
                            </Col>
                          </StyledRow>
                        </>
                      )}
                      {exam?.imageCount != null && exam?.imageCount > 0 && (
                        <Label description="How many DICOM image files have been received.">Image Count: {exam.imageCount}</Label>
                      )}
                      {exam && exam?.suid != '' && <Label description="Study Instance UID.">SUID: {exam.suid}</Label>}
                      <StyledRow>
                        <Col className="col-5">
                          <Field
                            component={Dropdown}
                            data={!Array.isArray(selectedLocation?.taTs) || !selectedLocation?.taTs.length ? defaultTats : selectedLocation?.taTs}
                            dataItemKey="value"
                            defaultItem={{ name: 'No Over-read', value: null }}
                            label="TAT"
                            name="sla"
                            onChange={handleTatChange}
                          />
                        </Col>
                        {(selectedTat?.value ?? 0) > 0 && (
                          <Col className="col-7">
                            <DatePicker
                              disabled
                              format="MM/dd/yy HH:mm:ss"
                              name="tatDate"
                              placeholder="Enter Date"
                              value={tatDate.isValid() ? tatDate.toDate() : null}
                            />
                          </Col>
                        )}
                      </StyledRow>
                      {(selectedTat?.value ?? 0) > 0 && (
                        <>
                          <Row>
                            <Col className="col-12">
                              <ReadRequestService
                                service={selectedService}
                                valueGetter={valueGetter}
                                valueSetter={setValue}
                                examId={exam?.id}
                                fieldOnly
                                onChange={handleServicesChange}
                              />
                            </Col>
                            {exam !== null && <FormAccordian forms={forms} values={exam.dynamicFormFields ?? []} />}
                          </Row>
                          {isInternal && (
                            <>
                              <StyledRow>
                                <Col className="col-6">
                                  <Field
                                    component={MaskedDateTimeInput}
                                    mode={MASKED_DATETIME_INPUT_MODES.DATE}
                                    label="Requested Date"
                                    name="requestedDate"
                                    required
                                    validator={FormValidatorsService.combineValidators([FormValidatorsService.required, FormValidatorsService.datetime])}
                                  />
                                </Col>
                                <Col className="col-6">
                                  <Field
                                    component={MaskedDateTimeInput}
                                    mode={MASKED_DATETIME_INPUT_MODES.TIME}
                                    label="Requested Time"
                                    name="requestTime"
                                    required
                                    validator={FormValidatorsService.combineValidators([FormValidatorsService.required, FormValidatorsService.datetime])}
                                  />
                                </Col>
                              </StyledRow>
                              <StyledRow>
                                <Col className="col-12">
                                  {exam?.statusType?.name === 'AIProcessing' ? (
                                    <div className={'k-messagebox k-messagebox-error'}>
                                      Please wait for AI processing to complete before assigning the exam.
                                    </div>
                                  ) : (
                                    <Field
                                      component={Dropdown}
                                      data={physicians}
                                      defaultItem={{
                                        fullName: 'Unassigned',
                                        value: null,
                                      }}
                                      label="Physician"
                                      name="physician"
                                      textField="fullName"
                                    />
                                  )}
                                </Col>
                              </StyledRow>
                            </>
                          )}
                        </>
                      )}
                      {selectedPhysician && exam?.statusType?.name !== 'AIProcessing' && (
                        <>
                          <StyledRow>
                            <Col className="col-12">
                              <Field component={TextArea} label="Over-read" name="overRead" rows="5" />
                              {exam?.reads && <OverreadHistory readHistory={exam?.reads} />}
                            </Col>
                          </StyledRow>
                          <StyledRow>
                            <Col className="col-12">
                              <Field component={Input} label="Confirmed by" name="confirmedBy" />
                            </Col>
                          </StyledRow>
                        </>
                      )}
                      {manualInfoAddedIdRequired && (
                        <StyledRow>
                          <Col className="col-12">
                            <LeftAlignedCheckboxField component={Checkbox} label="Manual info added" name="manualInfoAdded" inline />
                          </Col>
                        </StyledRow>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                </>
              )}
              <StyledRowButton>
                <Col className="col-12">
                  <Button disabled={!currentPatient || isSubmitting} type="submit">
                    {isSubmitting && <Icon icon={faSpinner} spin={isSubmitting}></Icon>}Save
                  </Button>
                </Col>
              </StyledRowButton>
            </FormElement>
          </StyledAccordion>
        );
      }}
    />
  );
};

/* ========= Styled Components ========= */

const StyledAccordion = styled(Accordion)`
  max-height: 93vh;
  overflow-y: auto;
  height: 100%;

  label:not(.k-checkbox-label) {
    font-weight: 600;
  }

  .svg-inline--fa {
    color: ${({ theme }) => theme.colors.palette.blues[4]};
  }

  .accordion-item {
    background: ${({ theme }) => theme.colors.backgroundPrimary};
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.palette.blues[1]} !important;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    margin: ${({ theme }) => theme.space.spacing20};

    .accordion-body {
      padding-top: 0;
    }
  }
`;

const StyledRow = styled(Row)`
  margin-top: ${({ theme }) => theme.space.spacing30};
`;

const StyledRowButton = styled(Row)`
  background: white;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
  margin-right: 0;
  padding: ${({ theme }) => theme.space.spacing30};
`;

const LeftAlignedCheckboxField = styled(Field)`
  justify-content: left;
`;

export { ExamProcessingSidebarBody };
