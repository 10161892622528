import { useEffect } from 'react';

import { findOrThrow } from 'core/utils';

import { useSessionLocation } from './useSessionLocation';

/** Ensures that the selected session location matches the expected `locationId` parameter.  Will return `null` until this condition is satisfied.  This is intended to be used in *Init components to ensure that the entity referenced in a route parameter is contained in the current session location.
 * @param locationId - The location ID to match.  `null` or `undefined` will be interpreted as "waiting for a `fetch` call to complete".  In which case this hook will return `null` and defer the synchronization until the locationId contains an actual numeric value that can be synchronized.
 * @param enableSync - If `false` then the synchronization will be disabled and the session location will not be updated.  This is useful when the calling component conditionally needs synchronization such as when a page is used for both editing an existing entity and creating a new entity.  Default set to `true`.
 * @returns When `enableSync` is `true` this will be the session location that is guaranteed to match the `locationId` parameter and `null` if still pending or no match found.  When `enableSync` is `false` then the current session location will be returned without any additional validation.
 */
export function useSessionLocationInitSync(locationId: number | null | undefined, enableSync = true) {
  const { sessionLocation, sessionLocationOptions, setSessionLocation } = useSessionLocation(false);

  useEffect(() => {
    if (!enableSync) return;
    if (sessionLocationOptions == null) return;
    if (locationId == null) return;

    const newSessionLocation = findOrThrow(
      sessionLocationOptions,
      (l) => l.id === locationId,
      `Could not find location with id ${locationId} for current user.`,
    );

    setSessionLocation(newSessionLocation);
  }, [enableSync, locationId, sessionLocationOptions, setSessionLocation]);

  // In case there was a previously selected session location that does not match the intended location then we need to let the calling component know by returning null.
  // Also if synchronization is disabled then we should return the current session location without any additional validation.
  return !enableSync ? sessionLocation : locationId === sessionLocation?.id ? sessionLocation : null;
}
