import { DataStream, EventStream, IDataStreamConsumer, IEventStreamConsumer } from 'core/utils';

import {
  Classification,
  CompressCompleteEvent,
  FileAttachedEvent,
  FilesLoadedEvent,
  MatchCompleteEvent,
  ParseCompleteEvent,
  ProcessJobEvent,
  ThumbnailGeneratedEvent,
  UploadBatchStartEvent,
  UploadCompleteEvent,
  UploadPipeline,
  UploadProgressEvent,
  UploadStartEvent,
} from 'features/upload-pipeline';

import { UploadGroup, UploadViewFileContext } from '../types';

type GetInternalStatesResult = {
  uploadGroup: UploadGroup;
  uploadGroupIndex: number;
  fileContext: UploadViewFileContext;
};

type ScanProgress = {
  /** Number of files to scan. */
  total: number;
  /** Number of files that have been scanned so far. */
  complete: number;
};

type UploadProgress = {
  /** Number of bytes uploaded so far.  This is the actual number of bytes transferred over the wire. */
  progressSize: number;
  /** Number of weighted bytes uploaded so far.  This DOES take into account compression ratio for a stable numerator in a progress percentage calculation. */
  weightedProgressSize: number;
  /** Sum number of bytes for all original, uncompressed files.  This is available as soon as the upload starts. */
  totalSize: number;
  /** Total number of files to be uploaded.  This is available as soon as the upload starts. */
  totalFiles: number;
  /** Total number of files that have been uploaded and attached (if required). */
  completedFiles: number;
  /** Start time in milliseconds (unix epoch).  Will be null until the first upload actually begins. */
  startTime: number | null;
  /** End time in milliseconds (unix epoch).  Will be null until the last upload finishes.  This will be hydrated once the last file is uploaded, but there will still possibly be file attachments that are still pending. */
  endTime: number | null;
};

type Phase1CompleteEvent = {
  usableDicomFilesFound: boolean;
};

export class UploadView {
  static [Symbol.toStringTag]() {
    return 'UploadView';
  }

  private _cleanupFunctions: (() => void)[] = [];
  /** The key is the fileId. */
  private _files = new Map<string, UploadViewFileContext>();

  private _streams = {
    onPhase1Complete: new EventStream<Phase1CompleteEvent>(),
    groups: new DataStream<UploadGroup[]>([]),
    scanProgress: new DataStream<ScanProgress>({ total: 0, complete: 0 }),
    uploadProgress: new DataStream<UploadProgress>({
      weightedProgressSize: 0,
      progressSize: 0,
      totalSize: 0,
      totalFiles: 0,
      completedFiles: 0,
      startTime: null,
      endTime: null,
    }),
  };

  public get streams(): Readonly<{
    onPhase1Complete: IEventStreamConsumer<Phase1CompleteEvent>;
    groups: IDataStreamConsumer<UploadGroup[]>;
    scanProgress: IDataStreamConsumer<ScanProgress>;
    uploadProgress: IDataStreamConsumer<UploadProgress>;
  }> {
    return this._streams;
  }

  constructor(private uploadPipeline: UploadPipeline) {
    this.initialize = this.initialize.bind(this);
    this.reset = this.reset.bind(this);
    this.destroy = this.destroy.bind(this);
    this.setUploadGroup = this.setUploadGroup.bind(this);
    this.updateGroup = this.updateGroup.bind(this);
    this.getUploadGroupByFileId = this.getUploadGroupByFileId.bind(this);
    this.mergeFileContextChanges = this.mergeFileContextChanges.bind(this);
    this.handleFilesLoaded = this.handleFilesLoaded.bind(this);
    this.handleFilesRemoved = this.handleFilesRemoved.bind(this);
    this.handleParseComplete = this.handleParseComplete.bind(this);
    this.handleThumbnailGenerated = this.handleThumbnailGenerated.bind(this);
    this.handleMatchComplete = this.handleMatchComplete.bind(this);
    this.handleUploadBatchStart = this.handleUploadBatchStart.bind(this);
    this.handleCompressComplete = this.handleCompressComplete.bind(this);
    this.handleUploadStart = this.handleUploadStart.bind(this);
    this.handleUploadProgress = this.handleUploadProgress.bind(this);
    this.handleUploadComplete = this.handleUploadComplete.bind(this);
    this.handleFileAttached = this.handleFileAttached.bind(this);

    this._cleanupFunctions.push(this.uploadPipeline.streams.onFilesLoaded.subscribe(this.handleFilesLoaded));
    this._cleanupFunctions.push(this.uploadPipeline.streams.onFilesRemoved.subscribe(this.handleFilesRemoved));
    this._cleanupFunctions.push(this.uploadPipeline.fileScanner.streams.onParseComplete.subscribe(this.handleParseComplete));
    this._cleanupFunctions.push(this.uploadPipeline.thumbnailGenerator.streams.onComplete.subscribe(this.handleThumbnailGenerated));
    this._cleanupFunctions.push(this.uploadPipeline.recordMatcher.streams.onMatchComplete.subscribe(this.handleMatchComplete));
    this._cleanupFunctions.push(this.uploadPipeline.streams.onUploadBatchStart.subscribe(this.handleUploadBatchStart));
    this._cleanupFunctions.push(this.uploadPipeline.fileCompressor.streams.onComplete.subscribe(this.handleCompressComplete));
    this._cleanupFunctions.push(this.uploadPipeline.blobUploader.streams.onStart.subscribe(this.handleUploadStart));
    this._cleanupFunctions.push(this.uploadPipeline.blobUploader.streams.onProgress.subscribe(this.handleUploadProgress));
    this._cleanupFunctions.push(this.uploadPipeline.blobUploader.streams.onComplete.subscribe(this.handleUploadComplete));
    this._cleanupFunctions.push(this.uploadPipeline.fileAttacher.streams.onFileAttached.subscribe(this.handleFileAttached));
  }

  public initialize() {
    // No-op.
  }

  public reset() {
    this._files.clear();

    // Reset the streams to their initial state, but let external subscribers manage their own subcriptions.
    this._streams.groups.emit([]);
    this._streams.scanProgress.emit({ total: 0, complete: 0 });
    this._streams.uploadProgress.emit({
      weightedProgressSize: 0,
      progressSize: 0,
      totalSize: 0,
      totalFiles: 0,
      completedFiles: 0,
      startTime: null,
      endTime: null,
    });
  }

  public destroy() {
    this._cleanupFunctions.forEach((cleanup) => cleanup());
    this._cleanupFunctions = [];
    this._streams.groups.clear();
    this._streams.scanProgress.clear();
    this._streams.uploadProgress.clear();
    this._files.clear();
  }

  public setUploadGroup(uploadGroup: UploadGroup) {
    const newGroups = [...this._streams.groups.getCurrentValue()];
    const groupIndex = newGroups.findIndex((g) => g.uploadGroupId === uploadGroup.uploadGroupId);

    if (groupIndex < 0) {
      throw new Error('Could not find upload group.');
    }

    newGroups[groupIndex] = uploadGroup;
    this._streams.groups.emit(newGroups);
  }

  public updateGroup(uploadGroupId: string, changes: Partial<UploadGroup>): UploadGroup[] {
    const newUploadGroups = [...this._streams.groups.getCurrentValue()];
    const groupIndex = newUploadGroups.findIndex((g) => g.uploadGroupId === uploadGroupId);

    newUploadGroups[groupIndex] = {
      ...newUploadGroups[groupIndex],
      ...changes,
    };

    this._streams.groups.emit(newUploadGroups);

    return newUploadGroups;
  }

  public getUploadGroupByFileId(fileId: string, currentGroupsOverride?: UploadGroup[] | null): GetInternalStatesResult {
    const fileContext = this._files.get(fileId) ?? null;

    if (fileContext == null) {
      throw new Error(`Could not find file context for fileId: ${fileId}.`);
    }

    const uploadGroupId = fileContext?.uploadGroupId ?? null;

    if (uploadGroupId == null) {
      throw new Error(`Could not find uploadGroupId for fileId: ${fileId}.`);
    }

    const groups = currentGroupsOverride ?? this._streams.groups.getCurrentValue();
    const groupIndex = groups.findIndex((g) => g.uploadGroupId === uploadGroupId);
    const uploadGroup = groups[groupIndex] ?? null;

    if (uploadGroup == null) {
      throw new Error(`Could not find upload group for fileId: ${fileId}.`);
    }

    return {
      uploadGroup,
      uploadGroupIndex: groupIndex,
      fileContext,
    };
  }

  private mergeFileContextChanges(fileId: string, changes: Partial<UploadViewFileContext>): UploadViewFileContext {
    const current = this._files.get(fileId);

    if (current == null) {
      throw new Error('File not found.');
    }

    const newFileState = { ...current, ...changes, fileId };
    this._files.set(fileId, newFileState);

    return newFileState;
  }

  private handleFilesLoaded(event: FilesLoadedEvent): void {
    const newUploadGroups = [...this._streams.groups.getCurrentValue()];

    for (const fileLoadDetail of event.files) {
      const metadata = this.uploadPipeline.getFileMetadata(fileLoadDetail.fileId);
      const existingUploadGroupIndex = newUploadGroups.findIndex((ug) => ug.examId != null && ug.examId === metadata?.examId);

      const newFileContext: UploadViewFileContext = {
        uploadGroupId: existingUploadGroupIndex >= 0 ? newUploadGroups[existingUploadGroupIndex].uploadGroupId : null,
        fileId: fileLoadDetail.fileId,
        fileName: fileLoadDetail.file.name,
        fileSize: fileLoadDetail.file.size,
        compressedSize: null,
      };

      this._files.set(fileLoadDetail.fileId, newFileContext);

      if (existingUploadGroupIndex >= 0) {
        newUploadGroups[existingUploadGroupIndex] = {
          ...newUploadGroups[existingUploadGroupIndex],
          files:
            fileLoadDetail.classification === Classification.Dicom
              ? [...newUploadGroups[existingUploadGroupIndex].files, fileLoadDetail.fileId]
              : newUploadGroups[existingUploadGroupIndex].files,
          attachments:
            fileLoadDetail.classification === Classification.Attachment
              ? [...newUploadGroups[existingUploadGroupIndex].attachments, fileLoadDetail.fileId]
              : newUploadGroups[existingUploadGroupIndex].attachments,
        };
      }
    }

    const previousScanProgress = this._streams.scanProgress.getCurrentValue();

    this._streams.scanProgress.emit({
      ...previousScanProgress,
      total: previousScanProgress.total + event.files.length,
    });

    this._streams.groups.emit(newUploadGroups);
  }

  private handleFilesRemoved(fileIds: string[]): void {
    const newUploadGroups = [...this._streams.groups.getCurrentValue()];

    for (const fileId of fileIds) {
      const { uploadGroup, uploadGroupIndex } = this.getUploadGroupByFileId(fileId, newUploadGroups);

      const newUploadGroup: UploadGroup = { ...uploadGroup };
      newUploadGroups[uploadGroupIndex] = newUploadGroup;

      // Remove the files from the upload group.
      const fileIndex = newUploadGroup.files.findIndex((f) => f === fileId);
      if (fileIndex >= 0) {
        newUploadGroup.files = [...newUploadGroup.files];
        newUploadGroup.files.splice(fileIndex, 1);
      }

      const attachmentIndex = fileIndex === -1 ? newUploadGroup.attachments.findIndex((f) => f === fileId) : -1;
      if (attachmentIndex >= 0) {
        newUploadGroup.attachments = [...newUploadGroup.attachments];
        newUploadGroup.attachments.splice(attachmentIndex, 1);
      }

      // Remove the file context.
      this._files.delete(fileId);

      // Finally remove the entire upload group if it now has no files or attachments.
      if (newUploadGroup.files.length === 0 && newUploadGroup.attachments.length === 0) {
        newUploadGroups.splice(uploadGroupIndex, 1);
      }
    }

    this._streams.groups.emit(newUploadGroups);
  }

  private handleParseComplete(event: ProcessJobEvent<ParseCompleteEvent>): void {
    const previousScanProgress = this._streams.scanProgress.getCurrentValue();

    this._streams.scanProgress.emit({
      ...previousScanProgress,
      complete: previousScanProgress.complete + 1,
    });

    if (event.result === 'skipped') {
      return;
    }

    const newUploadGroups = [...this._streams.groups.getCurrentValue()];
    const fileContext = this._files.get(event.fileId)!;

    const existingUploadGroupIndex = newUploadGroups.findIndex(
      (ug) => ug.dicomData?.StudyInstanceUID != null && ug.dicomData?.StudyInstanceUID === event.dicomData?.StudyInstanceUID,
    );
    const existingUploadGroup = existingUploadGroupIndex >= 0 ? newUploadGroups[existingUploadGroupIndex] : null;
    const metadata = this.uploadPipeline.getFileMetadata(event.fileId);

    if (existingUploadGroup == null) {
      const uploadGroup: UploadGroup = {
        uploadGroupId: crypto.randomUUID(),
        files: [event.fileId],
        attachments: [],
        series: event.dicomData == null ? [] : [{ dicomData: event.dicomData, thumbnailId: null }],
        dicomData: event.dicomData,
        uncompressedDicomWarning: event.uncompressedDicomWarning,
        checked: true,
        examId: metadata?.examId ?? null,
        totalSize: fileContext.fileSize,
        progressSize: 0,
        uploadedFiles: 0,
        completedFiles: 0,
        weightedProgressSize: 0,
        uploadStartTime: null,
        uploadEndTime: null,
      };

      this.mergeFileContextChanges(event.fileId, {
        uploadGroupId: uploadGroup.uploadGroupId,
      });

      newUploadGroups.push(uploadGroup);
    } else {
      const existingSeries = existingUploadGroup.series.find((s) => s.dicomData.SeriesInstanceUID === event.dicomData?.SeriesInstanceUID);

      const uploadGroup: UploadGroup = {
        ...existingUploadGroup,
        files: [...existingUploadGroup.files, event.fileId],
        series:
          existingSeries == null && event.dicomData != null
            ? [...existingUploadGroup.series, { dicomData: event.dicomData, thumbnailId: null }]
            : existingUploadGroup.series,
        uncompressedDicomWarning: existingUploadGroup.uncompressedDicomWarning || event.uncompressedDicomWarning,
        totalSize: existingUploadGroup.totalSize + fileContext.fileSize,
      };

      this.mergeFileContextChanges(event.fileId, { uploadGroupId: uploadGroup.uploadGroupId });

      newUploadGroups[existingUploadGroupIndex] = uploadGroup;
    }

    this._streams.groups.emit(newUploadGroups);
  }

  private handleThumbnailGenerated(event: ProcessJobEvent<ThumbnailGeneratedEvent>): void {
    if (event.result === 'skipped') {
      return;
    }

    const { uploadGroup, uploadGroupIndex } = this.getUploadGroupByFileId(event.fileId);

    const newSeries = uploadGroup.series.map((s) => (s.dicomData.SeriesInstanceUID === event.seriesInstanceUid ? { ...s, thumbnailId: event.thumbnailId } : s));

    const newUploadGroup: UploadGroup = {
      ...uploadGroup,
      series: newSeries,
    };

    const newUploadGroups = [...this._streams.groups.getCurrentValue()];
    newUploadGroups[uploadGroupIndex] = newUploadGroup;

    this._streams.groups.emit(newUploadGroups);
  }

  private handleMatchComplete(event: ProcessJobEvent<MatchCompleteEvent>): void {
    if (event.result === 'processed') {
      const newUploadGroups = [...this._streams.groups.getCurrentValue()];
      const { uploadGroup: existingGroup, uploadGroupIndex } = this.getUploadGroupByFileId(event.fileId, newUploadGroups);

      newUploadGroups[uploadGroupIndex] = {
        ...existingGroup,
        examId: existingGroup.examId ?? event.examId,
      };

      this._streams.groups.emit(newUploadGroups);
    }

    const queues = this.uploadPipeline.streams.queues.getCurrentValue();
    if (queues.scanQueueLength === 0 && queues.recordMatcherQueueLength === 0) {
      this._streams.onPhase1Complete.emit({
        usableDicomFilesFound: this._streams.groups.getCurrentValue().length > 0,
      });
    }
  }

  private handleUploadBatchStart(event: UploadBatchStartEvent): void {
    const newUploadGroups = [...this._streams.groups.getCurrentValue()];
    const newOverallProgress = { ...this._streams.uploadProgress.getCurrentValue(), startTime: event.startTime };

    for (const file of event.files) {
      const fileContext = this._files.get(file.fileId)!;
      newOverallProgress.totalSize += fileContext.fileSize;
      newOverallProgress.totalFiles += 1;
    }

    this._streams.groups.emit(newUploadGroups);
    this._streams.uploadProgress.emit(newOverallProgress);
  }

  private handleCompressComplete(event: ProcessJobEvent<CompressCompleteEvent>): void {
    if (event.result === 'processed') {
      this.mergeFileContextChanges(event.fileId, { compressedSize: event.compressedSize });
    }
  }

  private handleUploadStart(event: UploadStartEvent): void {
    const newUploadGroups = [...this._streams.groups.getCurrentValue()];
    const { uploadGroup, uploadGroupIndex } = this.getUploadGroupByFileId(event.fileId, newUploadGroups);

    if (uploadGroup.uploadStartTime == null) {
      newUploadGroups[uploadGroupIndex] = {
        ...uploadGroup,
        uploadStartTime: event.startTime,
      };
      this._streams.groups.emit(newUploadGroups);
    }
  }

  private handleUploadProgress(event: UploadProgressEvent): void {
    const newUploadGroups = [...this._streams.groups.getCurrentValue()];
    const { uploadGroup, uploadGroupIndex, fileContext } = this.getUploadGroupByFileId(event.fileId, newUploadGroups);
    const compressionRatio = fileContext.compressedSize == null ? 1 : fileContext.fileSize / fileContext.compressedSize;

    newUploadGroups[uploadGroupIndex] = {
      ...uploadGroup,
      progressSize: uploadGroup.progressSize + event.chunkSize,
      weightedProgressSize: uploadGroup.weightedProgressSize + event.chunkSize * compressionRatio,
    };

    const newOverallProgress = { ...this._streams.uploadProgress.getCurrentValue() };
    newOverallProgress.progressSize += event.chunkSize;
    newOverallProgress.weightedProgressSize += event.chunkSize * compressionRatio;

    this._streams.groups.emit(newUploadGroups);
    this._streams.uploadProgress.emit(newOverallProgress);
  }

  private handleUploadComplete(event: ProcessJobEvent<UploadCompleteEvent>): void {
    if (event.result !== 'processed') {
      throw new Error('handleUploadComplete() behavior is undefined when the upload was skipped.');
    }

    const newUploadGroups = [...this._streams.groups.getCurrentValue()];
    const { uploadGroup, uploadGroupIndex } = this.getUploadGroupByFileId(event.fileId, newUploadGroups);

    const isLastFileForGroup = uploadGroup.uploadedFiles + 1 === uploadGroup.files.length + uploadGroup.attachments.length;

    newUploadGroups[uploadGroupIndex] = {
      ...uploadGroup,
      uploadedFiles: uploadGroup.uploadedFiles + 1,
      uploadEndTime: isLastFileForGroup ? event.endTime : uploadGroup.uploadEndTime,
    };

    const newOverallProgress = { ...this._streams.uploadProgress.getCurrentValue() };
    const isLastFileOverall = newOverallProgress.completedFiles + 1 === newOverallProgress.totalFiles;
    newOverallProgress.completedFiles += 1;
    newOverallProgress.endTime = isLastFileOverall ? event.endTime : newOverallProgress.endTime;
    this._streams.uploadProgress.emit(newOverallProgress);

    this._streams.groups.emit(newUploadGroups);
  }

  private handleFileAttached(event: ProcessJobEvent<FileAttachedEvent>): void {
    const newUploadGroups = [...this._streams.groups.getCurrentValue()];
    const { uploadGroup, uploadGroupIndex } = this.getUploadGroupByFileId(event.fileId, newUploadGroups);

    newUploadGroups[uploadGroupIndex] = {
      ...uploadGroup,
      completedFiles: uploadGroup.completedFiles + 1,
    };

    this._streams.groups.emit(newUploadGroups);
  }
}
