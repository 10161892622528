import { UploadFileInfo, UploadFileStatus } from '@progress/kendo-react-upload';

import { getFileExtension, hasText, lookupMimeTypeFromString } from 'core/utils';

function dataUriToBlob(dataURI: string) {
  // Source: https://stackoverflow.com/a/7261048

  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = window.atob(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
}

function blobToDataUri(file: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      if (typeof event.target?.result !== 'string') {
        throw new Error(`Result from FileReader.readAsDataURL() is of type ${typeof event.target?.result}.  Expected "string".`);
      }

      resolve(event.target?.result);
    };

    reader.onerror = (err) => {
      reject(err);
    };

    reader.readAsDataURL(file);
  });
}

/** Creates a file from a data URI.  The `filenamePrefix` will become the name of the file (minus the file extension).  The file extension will be automatically appended based on the mimetype. */
function restoreDataUriToFile(dataUri: string | null | undefined, filenamePrefix: string) {
  const blob = hasText(dataUri) ? dataUriToBlob(dataUri) : null;

  const mime = blob == null ? null : lookupMimeTypeFromString(blob.type);

  const restoredFile =
    blob == null
      ? null
      : new File([blob], `${filenamePrefix}${mime != null && mime.extensions.length > 0 ? `.${mime.extensions[0]}` : ''}`, { type: mime?.contentType });

  return restoredFile;
}

/** Compute a SHA-256 checksum against the blob. */
async function getSha256(blob: Blob) {
  // Source: https://developer.mozilla.org/en-US/docs/Web/API/Web_Crypto_API/Non-cryptographic_uses_of_subtle_crypto#hashing_a_file

  const buffer = await blob.arrayBuffer();

  const checksumBuffer = await crypto.subtle.digest('SHA-256', buffer);
  const uint8ViewOfHash = new Uint8Array(checksumBuffer);
  const checksum = Array.from(uint8ViewOfHash)
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');

  return checksum;
}

/** Convert a File into a descriptor that's usable by the Kendo <Upload> component. */
async function fileToKendoUpload(file: File): Promise<UploadFileInfo> {
  const checksum = await getSha256(file);

  return {
    uid: checksum,
    name: file.name,
    extension: getFileExtension(file.name) ?? undefined,
    size: file.size,
    progress: 0,
    status: UploadFileStatus.Selected, // This needs to be "Selected" otherwise the "X" button that removes the file will not be visible to the user.
    getRawFile: () => file,
  };
}

function getAcceptedUploadFiles() {
  return '.pdf,.xml,.png,.jpg,.tiff,.szi,.svs,.jpeg,.mp4,.atc,.mov';
}

function validateFormFiles(files: FileList) {
  const acceptedFiles = new Set(
    getAcceptedUploadFiles()
      .split(',')
      .map((ext) => ext.toLowerCase()),
  );
  return Array.from(files).filter((file) => {
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    return !!fileExtension && !acceptedFiles.has(`.${fileExtension}`);
  });
}

function invalidFileMessage() {
  return 'Invalid files - File type not allowed';
}

export const FileService = {
  dataUriToBlob,
  blobToDataUri,
  restoreDataUriToFile,
  getSha256,
  fileToKendoUpload,
  getAcceptedUploadFiles,
  validateFormFiles,
  invalidFileMessage,
};
