import { memo, useState } from 'react';

import { PDFViewer as KendoPDFViewer, LoadEvent, ZoomEvent } from '@progress/kendo-react-pdf-viewer';
import useMeasure from 'react-use-measure';
import styled from 'styled-components';

import { useEvent } from 'core/hooks';

import { PDFViewerProps } from '../types';

export const PDFViewer = memo<PDFViewerProps>(({ className, url }) => {
  const [componentDivRef, { width: componentDivWidth, height: componentDivHeight }] = useMeasure({ debounce: 100 });
  const [pdfSize, setPdfSize] = useState<SizeType | null>(null);
  const [loadCount, setLoadCount] = useState(0);
  const [zoom, setZoom] = useState<ZoomType>({ zoom: 1, mode: 'fit-page' });

  const effectiveZoom = calculateZoom({ width: componentDivWidth, height: componentDivHeight }, pdfSize ?? { width: 0, height: 0 }, zoom.zoom, zoom.mode);

  const handleZoomChange = useEvent((event: ZoomEvent) => {
    // Hack: Couldn't think of a better way to determine if the user selected "Fit to width" or not.
    const selectedOptionText = event.syntheticEvent.target instanceof HTMLElement ? event.syntheticEvent.target.innerText : '';
    setZoom({
      zoom: event.zoom,
      mode: selectedOptionText === 'Fit to width' ? 'fit-width' : selectedOptionText === 'Fit to page' ? 'fit-page' : 'manual',
    });
  });

  const handleLoad = useEvent((event: LoadEvent) => {
    // The first load will always be the dummy PDF.  We need to ignore it.  It's a hack to work around a Kendo bug where it wasn't respecting the zoom level on the first load.
    setLoadCount((prev) => prev + 1);
    if (loadCount === 0) return;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call -- Kendo doesn't apply type definitions for objects that are actually defined in the Mozilla PDF.js library.  So we have to just trust that the object is there.
    const pdfViewport = event.target.pages?.[0]?.getViewport?.({ scale: 1 });
    setPdfSize({
      width: pdfViewport.width,
      height: pdfViewport.height,
    });
  });

  return (
    <StyledComonentDiv ref={componentDivRef} className={className}>
      <KendoPDFViewer
        data={componentDivWidth === 0 ? DUMMY_PDF : undefined}
        url={componentDivWidth !== 0 ? url : undefined}
        zoom={effectiveZoom}
        defaultZoom={effectiveZoom}
        onLoad={handleLoad}
        onZoom={handleZoomChange}
      />
    </StyledComonentDiv>
  );
});

PDFViewer.displayName = 'PDFViewer';

const StyledComonentDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  overflow: hidden;
  contain: strict;
`;

type ZoomType = {
  zoom: number;
  mode: 'fit-width' | 'fit-page' | 'manual';
};

type SizeType = {
  width: number;
  height: number;
};

/** A minimal, blank PDF file (base64 encoded).  This is used as a workaround for a Kendo bug that doesn't respect the computed auto fit zoom level on the very first time a real PDF is rendered. */
const DUMMY_PDF =
  'JVBERi0xLjAKMSAwIG9iajw8L1BhZ2VzIDIgMCBSPj5lbmRvYmogMiAwIG9iajw8L0tpZHNbMyAw\nIFJdL0NvdW50IDE+PmVuZG9iaiAzIDAgb2JqPDwvTWVkaWFCb3hbMCAwIDMgM10+PmVuZG9iagp0\ncmFpbGVyPDwvUm9vdCAxIDAgUj4+Cg==';

const VIEWER_BORDER_SIZE = 2; // 1 pixel on each side.  Hardcoded to match the Kendo component.  This could be dynamic so that it guarantees to match the actual measured border size, but that would be a lot of work for little gain.
const VIEWER_TOP_MARGIN = 80; // Offset for top margin

function calculateZoom(componentSize: SizeType, pdfSize: SizeType, currentZoom: number, mode: 'fit-width' | 'fit-page' | 'manual'): number {
  if (mode === 'manual') {
    return currentZoom;
  }

  // Fallback to 1 if the measurements for the component or PDF are not available.  This usually happens on initial
  // render because we can't measure the component size until after elements have been inserted into the dom.
  if (componentSize.width === 0 || componentSize.height === 0 || pdfSize.width === 0 || pdfSize.height === 0) {
    return 1;
  }

  // Adjust the PDF size to be in CSS pixels.  This is a magic conversion that was taken from the Mozilla PDF.js library.
  // More information (look for the CSS_UNIT reference): https://github.com/mozilla/pdf.js/issues/5628
  const nominalPdfSize: SizeType = { width: (pdfSize.width * 96) / 72, height: (pdfSize.height * 96) / 72 };

  // Calculate the size in pixels of the viewable area of the PDF viewer.  This is strictly what can be seen without
  // scrolling and must exclude any borders, padding, or margins that exist outisde the canvas.
  //
  // We could probably make this more robust by ditching the constants and measuring the actual viewable area.  But that's a lot of work for little gain.
  const viewableSize: SizeType = {
    width: componentSize.width - VIEWER_BORDER_SIZE,
    height: componentSize.height - VIEWER_TOP_MARGIN,
  };

  // Caluclate the zoom level that would be required to fit the PDF to the width or height of the viewable area.
  const fitWidthZoom = Math.floor(100 * (viewableSize.width / nominalPdfSize.width)) / 100;
  const fitHeightZoom = Math.floor(100 * (viewableSize.height / nominalPdfSize.height)) / 100;

  if (mode === 'fit-width') {
    return fitWidthZoom;
  } else if (mode === 'fit-page') {
    return Math.min(fitWidthZoom, fitHeightZoom);
  }

  return 1;
}
