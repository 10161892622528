import { forwardRef } from 'react';

import { DatePickerHandle, DatePicker as KendoDatePicker, DatePickerProps as KendoDatePickerProps } from '@progress/kendo-react-dateinputs';
import styled, { DefaultTheme } from 'styled-components';

import { ErrorMessage } from '../ErrorMessage';
import { Hint } from '../Hint';
import { Label } from '../Label';
import { ComponentSizes } from '../constants';
import { DatePickerProps } from './DatePickerProps';

export const DatePicker = forwardRef<DatePickerHandle, DatePickerProps>(
  (
    {
      placeholder = 'Select date',
      size = ComponentSizes.MEDIUM,
      format = 'd',
      defaultValue,
      description,
      disabled,
      focusedDate,
      hint,
      isOptionalLabelShown,
      label,
      name,
      required,
      valid,
      validationMessage,
      value,
      visited,
      ...rest
    },
    ref,
  ) => {
    const isValidationMessageShown = Boolean(visited && validationMessage);
    const isHintShown = Boolean(!isValidationMessageShown && hint);
    const hintId = isHintShown ? `${name}_hint` : '';
    const errorId = isValidationMessageShown ? `${name}_error` : '';
    const isLabeledAsOptional = Boolean(!required && isOptionalLabelShown);

    return (
      <>
        <Label description={description} editorId={name} editorValid={valid} editorDisabled={disabled} required={required} optional={isLabeledAsOptional}>
          {label ?? <span>&nbsp;</span>}
        </Label>
        <StyledDatePicker
          ref={ref}
          placeholder={placeholder}
          defaultValue={defaultValue ? new Date(defaultValue) : defaultValue}
          disabled={disabled}
          focusedDate={focusedDate ? new Date(focusedDate) : focusedDate}
          name={name}
          required={required}
          valid={valid}
          validationMessage={validationMessage}
          value={value ? new Date(value) : value}
          size={size}
          format={format}
          {...rest}
        />
        {isHintShown && <Hint id={hintId}>{hint}</Hint>}
        {isValidationMessageShown && <ErrorMessage id={errorId}>{validationMessage}</ErrorMessage>}
      </>
    );
  },
);

DatePicker.displayName = 'DatePicker';

type StyledElementProps = {
  theme: DefaultTheme;
  disabled?: boolean;
  valid?: boolean;
  value?: Date | null;
  size?: KendoDatePickerProps['size'];
};

const resolvePalette = ({ theme, disabled, valid, value }: StyledElementProps) => {
  if (disabled) {
    return {
      borderActive: theme.colors.borderDisabled,
      background: theme.colors.backgroundDisabled,
      icon: theme.colors.iconDisabled,
      border: theme.colors.borderDisabled,
      text: theme.colors.textDisabled,
    };
  }

  const result = {
    borderActive: theme.colors.palette.aquas[4],
    background: theme.colors.palette.white,
    icon: theme.colors.palette.grayscale[6],
    border: theme.colors.borderBase,
    text: value ? theme.colors.textPrimary : theme.colors.textSecondary,
  };

  if (!valid) {
    result.borderActive = theme.colors.error;
    result.border = theme.colors.error;
  }

  return result;
};

const resolveBackgroundColor = (props: StyledElementProps) => {
  const { background } = resolvePalette(props);

  return background;
};

const resolveBorderColor = (props: StyledElementProps) => {
  const { border } = resolvePalette(props);

  return border;
};

const resolveIconColor = (props: StyledElementProps) => {
  const { icon } = resolvePalette(props);

  return icon;
};

const resolveFontSize = ({ theme, size }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
    case ComponentSizes.MEDIUM:
      return theme.fontSizes.body;
    case ComponentSizes.LARGE:
      return theme.fontSizes.subheading;
    default:
      return theme.fontSizes.body;
  }
};

const resolveHeight = ({ theme, size }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
      return theme.sizes.small;
    case ComponentSizes.MEDIUM:
      return theme.sizes.medium;
    case ComponentSizes.LARGE:
      return theme.sizes.large;
    default:
      return theme.sizes.medium;
  }
};

const resolvePadding = ({ theme, size }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
      return `${theme.space.paddingVerticalSmall} ${theme.space.spacing30}`;
    case ComponentSizes.MEDIUM:
      return `${theme.space.paddingVerticalMedium} ${theme.space.spacing30}`;
    case ComponentSizes.LARGE:
      return `${theme.space.spacing20} ${theme.space.spacing30}`;
    default:
      return `${theme.space.paddingVerticalMedium} ${theme.space.spacing30}`;
  }
};

const resolveColor = (props: StyledElementProps) => {
  const { text } = resolvePalette(props);

  return text;
};

const resolveLineHeight = ({ theme, size }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
    case ComponentSizes.MEDIUM:
      return theme.lineHeights.body;
    case ComponentSizes.LARGE:
      return theme.lineHeights.subheading;
    default:
      return theme.lineHeights.body;
  }
};

const resolveActiveBorderColor = (props: StyledElementProps) => {
  const { borderActive } = resolvePalette(props);

  return borderActive;
};

const resolveActiveBoxShadow = ({ theme, valid, disabled }: StyledElementProps) => {
  if (disabled) {
    return 'none';
  }

  if (!valid) {
    return theme.shadows.formControlsActiveError;
  }

  return theme.shadows.formControlsActive;
};

const StyledDatePicker = styled(KendoDatePicker)`
  width: 100%;
  height: ${resolveHeight};

  && .k-input-inner {
    padding: 0;
  }

  && .k-button {
    background-color: ${resolveBackgroundColor};
  }

  && .k-input {
    border: none;
    color: ${resolveColor};
    font-size: ${resolveFontSize};
    line-height: ${resolveLineHeight};
    padding: ${resolvePadding};
  }

  && .k-select {
    background-color: ${resolveBackgroundColor};
    border: none;
    color: transparent;

    & .k-i-calendar {
      color: ${resolveIconColor};
    }
  }

  && .k-picker-wrap {
    border: ${({ theme }) => theme.borderWidths.base} solid ${resolveBorderColor};
    background-color: ${resolveBackgroundColor};

    & .k-dateinput-wrap {
      background-color: ${resolveBackgroundColor};
    }

    &:hover {
      border-color: ${resolveActiveBorderColor};
    }

    &:active,
    &:focus,
    &.k-focus {
      border-color: ${resolveActiveBorderColor};
      box-shadow: ${resolveActiveBoxShadow};
    }
  }
`;
