import { AccountsClient } from './AccountsClient';
import { ChangeLogsClient } from './ChangeLogsClient';
import { ContactClient } from './ContactClient';
import { DICOMClient } from './DICOMClient';
import { DestinationClient } from './DestinationClient';
import { DestinationRouteClient } from './DestinationRouteClient';
import { DynamicFormsClient } from './DynamicFormsClient';
import { ExamStatusClient } from './ExamStatusClient';
import { ExamsClient } from './ExamsClient';
import { FieldCategoryClient } from './FieldCategoryClient';
import { FilesClient } from './FilesClient';
import { FormClient } from './FormClient';
import { FormFieldClient } from './FormFieldClient';
import { GatewayEventsClient } from './GatewayEventsClient';
import { HL7SendClient } from './HL7SendClient';
import { HttpClient } from './HttpClient';
import { ImageQualityClient } from './ImageQualityClient';
import { IntegrationClient } from './IntegrationClient';
import { ItemClient } from './ItemClient';
import { LocationClient } from './LocationClient';
import { LocationFinalReportTemplateClient } from './LocationFinalReportTemplateClient';
import { PatientClient } from './PatientClient';
import { PhysicianClient } from './PhysicianClient';
import { ResultSetClient } from './ResultSetClient';
import { SendQueueClient } from './SendQueueClient';
import { ServicesClient } from './ServicesClient';
import { SettingsClient } from './SettingsClient';
import { SourceClient } from './SourceClient';
import { StudyShareClient } from './StudyShareClient';
import { TagsClient } from './TagsClient';
import { TatClient } from './TatClient';
import { TemplateClient } from './TemplateClient';
import { UploadTrackerClient } from './UploadTrackerClient';
import { UsersClient } from './UsersClient';
import { ViewerClient } from './ViewerClient';
import { WorkListViewClient } from './WorklistViewClient';

export class ApiClient {
  public httpClient: HttpClient = new HttpClient();

  public exams: ExamsClient;

  public studyShare: StudyShareClient;

  public users: UsersClient;

  public filesClient: FilesClient;

  public patientClient: PatientClient;

  public sendQueue: SendQueueClient;

  public templateClient: TemplateClient;

  public destinationClient: DestinationClient;

  public dicomClient: DICOMClient;

  public integrationClient: IntegrationClient;

  public resultSetClient: ResultSetClient;

  public physicianClient: PhysicianClient;

  public locationClient: LocationClient;

  public viewerClient: ViewerClient;

  public tatClient: TatClient;

  public accountsClient: AccountsClient;

  public changeLogsClient: ChangeLogsClient;

  public examStatusClient: ExamStatusClient;

  public itemClient: ItemClient;

  public formFieldClient: FormFieldClient;

  public fieldCategoryClient: FieldCategoryClient;

  public dynamicFormsClient: DynamicFormsClient;

  public workListViewClient: WorkListViewClient;

  public settingsClient: SettingsClient;

  public uploadTrackerClient: UploadTrackerClient;

  public servicesClient: ServicesClient;

  public sourceClient: SourceClient;

  public formClient: FormClient;

  public hl7SendClient: HL7SendClient;

  public gatewayEventsClient: GatewayEventsClient;

  public destinationRouteClient: DestinationRouteClient;

  public contactClient: ContactClient;

  public locationFinalReportTemplateClient: LocationFinalReportTemplateClient;

  public imageQualityClient: ImageQualityClient;

  public tagsClient: TagsClient;

  constructor() {
    this.exams = new ExamsClient(this.httpClient);
    this.studyShare = new StudyShareClient(this.httpClient);
    this.users = new UsersClient(this.httpClient);
    this.filesClient = new FilesClient(this.httpClient);
    this.patientClient = new PatientClient(this.httpClient);
    this.sendQueue = new SendQueueClient(this.httpClient);
    this.templateClient = new TemplateClient(this.httpClient);
    this.destinationClient = new DestinationClient(this.httpClient);
    this.dicomClient = new DICOMClient(this.httpClient);
    this.integrationClient = new IntegrationClient(this.httpClient);
    this.resultSetClient = new ResultSetClient(this.httpClient);
    this.physicianClient = new PhysicianClient(this.httpClient);
    this.locationClient = new LocationClient(this.httpClient);
    this.viewerClient = new ViewerClient(this.httpClient);
    this.tatClient = new TatClient(this.httpClient);
    this.accountsClient = new AccountsClient(this.httpClient);
    this.changeLogsClient = new ChangeLogsClient(this.httpClient);
    this.examStatusClient = new ExamStatusClient(this.httpClient);
    this.itemClient = new ItemClient(this.httpClient);
    this.formFieldClient = new FormFieldClient(this.httpClient);
    this.fieldCategoryClient = new FieldCategoryClient(this.httpClient);
    this.dynamicFormsClient = new DynamicFormsClient(this.httpClient);
    this.workListViewClient = new WorkListViewClient(this.httpClient);
    this.settingsClient = new SettingsClient(this.httpClient);
    this.uploadTrackerClient = new UploadTrackerClient(this.httpClient);
    this.servicesClient = new ServicesClient(this.httpClient);
    this.sourceClient = new SourceClient(this.httpClient);
    this.formClient = new FormClient(this.httpClient);
    this.hl7SendClient = new HL7SendClient(this.httpClient);
    this.gatewayEventsClient = new GatewayEventsClient(this.httpClient);
    this.destinationRouteClient = new DestinationRouteClient(this.httpClient);
    this.contactClient = new ContactClient(this.httpClient);
    this.locationFinalReportTemplateClient = new LocationFinalReportTemplateClient(this.httpClient);
    this.imageQualityClient = new ImageQualityClient(this.httpClient);
    this.tagsClient = new TagsClient(this.httpClient);

    // bind all methods to this
    this.setAccessTokenFunction = this.setAccessTokenFunction.bind(this);
    this.setShareTokenFunction = this.setShareTokenFunction.bind(this);
  }

  public setAccessTokenFunction(newAccessTokenFunction: null | (() => Promise<string>)) {
    this.httpClient.setAccessTokenFunction(newAccessTokenFunction);
  }

  public setShareTokenFunction(newShareTokenFunction: null | (() => Promise<string>)) {
    this.httpClient.setShareTokenFunction(newShareTokenFunction);
  }
}
