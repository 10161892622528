import React, { useCallback, useRef, useState } from 'react';

import { Button } from '@progress/kendo-react-buttons';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input, InputChangeEvent } from '@progress/kendo-react-inputs';
import { Popover, PopoverActionsBar } from '@progress/kendo-react-tooltip';
import styled from 'styled-components';

import { ButtonVariants, Button as CoreButton, Dialog } from 'core/ui';

import ThumbnailIcon from '../assets/thumbnail.svg?react';
import { FileItem } from '../types';

interface ThumbnailPlaceholderProps {
  className?: string;
  onRemove?: (fileId: string | number) => Promise<unknown>;
  showEditBtn?: boolean;
  showDeleteBtn?: boolean;
  fileItem?: FileItem;
  onClickYesBtn?: (message?: string) => void;
  examType?: string;
}

const ThumbnailPlaceholder: React.FC<ThumbnailPlaceholderProps> = ({
  className,
  onRemove,
  showEditBtn = false,
  showDeleteBtn = false,
  fileItem = {},
  onClickYesBtn,
  examType = '',
}) => {
  const anchor = useRef<Button>(null);
  const [show, setShow] = useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  // Safely access fileItem.fileName or use empty string
  const safeFileName = fileItem?.fileName || '';
  const [message, setMessage] = useState<string>(safeFileName);

  // Update to use Kendo's InputChangeEvent type
  const handleChange = (event: InputChangeEvent): void => {
    setMessage((event.value as string) || '');
  };

  const handleRemove = (): void => {
    if (onRemove && fileItem?.id) {
      // First close the dialog immediately - this must happen before other async operations
      setShowDeleteDialog(false);

      // Then set the deleting state and process the deletion
      setIsDeleting(true);

      // Process the deletion
      onRemove(fileItem.id)
        .catch((error: unknown) => {
          console.error('Error deleting file:', error);
        })
        .finally(() => {
          setIsDeleting(false);
        });
    }
  };

  const toggleDialog = (): void => {
    if (!isDeleting) {
      setShowDeleteDialog(!showDeleteDialog);
    }
  };

  const onClick = useCallback((): void => {
    setShow(!show);
  }, [show]);

  return (
    <StyledBackgroundDiv className={className}>
      {showDeleteBtn && (
        <Button
          fillMode="outline"
          themeColor="error"
          className="buttons-container-button float-end"
          iconClass="fa fa-trash"
          onClick={toggleDialog}
          disabled={isDeleting}
        />
      )}
      {showDeleteDialog && (
        <Dialog title="Please Confirm" onClose={toggleDialog}>
          <span>Delete file</span>
          <DialogActionsBar>
            <CoreButton onClick={toggleDialog} variant={ButtonVariants.SECONDARY} disabled={isDeleting}>
              No
            </CoreButton>
            <CoreButton onClick={handleRemove} disabled={isDeleting}>
              {isDeleting ? 'Deleting...' : 'Yes'}
            </CoreButton>
          </DialogActionsBar>
        </Dialog>
      )}
      {showEditBtn && (
        <>
          <Button fillMode="outline" className="buttons-container-button float-end" iconClass="fa fa-pencil" onClick={onClick} ref={anchor} />
          <Popover show={show} anchor={anchor.current?.element || null} title="Rename File">
            <Input name="filename" label="Filename" defaultValue={safeFileName} onChange={handleChange} />
            <PopoverActionsBar>
              <Button onClick={() => onClickYesBtn && onClickYesBtn(message)} themeColor="success">
                OK
              </Button>
              <Button onClick={onClick} themeColor="error">
                Cancel
              </Button>
            </PopoverActionsBar>
          </Popover>
        </>
      )}
      <StyledThumbnailIcon examType={examType} />
    </StyledBackgroundDiv>
  );
};

const StyledBackgroundDiv = styled.div`
  background: ${({ theme }) => theme.colors.palette.white};
  border-radius: 2px;
`;

interface StyledThumbnailIconProps {
  examType?: string;
}

const StyledThumbnailIcon = styled(ThumbnailIcon)<StyledThumbnailIconProps>`
  min-height: 111px;
  width: 100%;
  height: calc(100% - 0.75rem);
  background: ${({ theme }) => theme.colors.palette.white};
  fill: #b3b3b3;
  border-radius: 2px;
  margin-bottom: 0.25rem;
  margin-top: 0.5rem;
  height: ${({ examType }) => (examType === 'PACS' ? '130px' : '111px')};
`;

export { ThumbnailPlaceholder };
