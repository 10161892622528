import { createContext } from 'react';

import { HubConnection } from '@microsoft/signalr';

import { ApiClient } from 'core/api';

export const ApiClientProviderContext = createContext<ApiClient | null>(null);

ApiClientProviderContext.displayName = 'ApiClientProviderContext';

export const ApiWebSocketProviderContext = createContext<HubConnection | null>(null);

ApiWebSocketProviderContext.displayName = 'ApiWebSocketProviderContext';
