import { FocusEvent, memo, useCallback, useMemo } from 'react';

import { DateTimePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { Validate, useController } from 'react-hook-form';

import { RhfValidators } from 'core/forms';

import { DateTimePicker } from './DateTimePicker';
import { DateTimePickerFieldProps } from './DateTimePickerFieldProps';

export const DateTimePickerField = memo<DateTimePickerFieldProps>(({ name, required, validator, onChange, onBlur, ...rest }) => {
  const validatorInternal = useMemo(() => {
    const newValidators: Validate<unknown, unknown>[] = [];

    if (required) newValidators.push(RhfValidators.required);
    if (validator) newValidators.push(validator);

    return newValidators.length === 0 ? undefined : newValidators.length === 1 ? newValidators[0] : RhfValidators.combine(newValidators);
  }, [required, validator]);

  const {
    field: { onChange: rhfOnChange, onBlur: rhfOnBlur, value, ...fieldRest },
    formState,
    fieldState: { isTouched, invalid, error },
  } = useController({ name, rules: { validate: validatorInternal } });

  const handleChange = useCallback(
    (event: DateTimePickerChangeEvent) => {
      rhfOnChange(event);
      onChange?.(event);
    },
    [onChange, rhfOnChange],
  );

  const handleBlur = useCallback(
    (event: FocusEvent<HTMLSpanElement | HTMLDivElement>) => {
      rhfOnBlur();
      onBlur?.(event);
    },
    [onBlur, rhfOnBlur],
  );

  return (
    <DateTimePicker
      {...fieldRest}
      {...rest}
      value={formState.isLoading ? null : value}
      valid={!invalid}
      visited={formState.isSubmitted || isTouched}
      validationMessage={error?.message}
      required={required}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
});

DateTimePickerField.displayName = 'DateTimePickerField';
