import { ChangeEventHandler, memo, useEffect, useRef } from 'react';

import styled from 'styled-components';

import { useEvent } from 'core/hooks';
import { Button, ComponentSizes } from 'core/ui';

import UploadIcon from '../assets/upload2.svg?react';

type SelectDicomFolderCardProps = {
  onAdd: (files: File[]) => void;
};

export const SelectDicomFolderCard = memo<SelectDicomFolderCardProps>(({ onAdd }) => {
  const uploadInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = useEvent(() => {
    uploadInputRef.current?.click();
  });

  const handleDicomFilesChange: ChangeEventHandler<HTMLInputElement> = useEvent((event) => {
    onAdd(event.target.files == null ? [] : Array.from(event.target.files));
  });

  // Make the folder select upload an entire directory.  This has to manually apply the attributes because
  // TypeScript doesn't define the "directory" and "webkitdirectory" props on the <input> element.
  useEffect(() => {
    if (uploadInputRef.current != null) {
      uploadInputRef.current.setAttribute('directory', '');
      uploadInputRef.current.setAttribute('webkitdirectory', '');
    }
  }, []);

  return (
    <StyledComponentDiv>
      <StyledIconDiv>
        <UploadIcon />
      </StyledIconDiv>
      <StyledHeaderDiv>Select Folder or Disk to Upload</StyledHeaderDiv>
      <StyledButtonContainerDiv>
        <Button onClick={handleButtonClick} size={ComponentSizes.LARGE}>
          Click to Select Folder or Disk
        </Button>
      </StyledButtonContainerDiv>

      <StyledDetailDiv>CT, XRAY, US, ECHO, MRI, NM, CATH</StyledDetailDiv>
      <StyledHiddenInput ref={uploadInputRef} name="dicomFiles" multiple type="file" onChange={handleDicomFilesChange} />
    </StyledComponentDiv>
  );
});

SelectDicomFolderCard.displayName = 'SelectDicomFolderCard';

const StyledComponentDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 16px;
  margin: 0;
  background-color: ${({ theme }) => theme.colors.palette.white};
  border-radius: 4px;
  border: 1px dashed ${({ theme }) => theme.colors.palette.blues[3]};
  row-gap: 16px;

  .icon-container {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const StyledIconDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledHeaderDiv = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.heading1};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  line-height: ${({ theme }) => theme.lineHeights.heading1};
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  user-select: none;
`;

const StyledButtonContainerDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledDetailDiv = styled.div`
  display: flex;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSizes.subheading};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  line-height: ${({ theme }) => theme.lineHeights.subheading};
  color: ${({ theme }) => theme.colors.palette.blues[7]};
  user-select: none;
`;

const StyledHiddenInput = styled.input`
  display: none;
`;
