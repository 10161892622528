import { AccordionDiv } from './AccordionDiv';
import { AccordionNgProvider } from './AccordionNgProvider';
import { AccordionScrollDiv } from './AccordionScrollDiv';
import { ItemBodyAnimationDiv } from './ItemBodyAnimationDiv';
import { ItemBodyDiv } from './ItemBodyDiv';
import { ItemCollapseIconButton } from './ItemCollapseIconButton';
import { ItemCollapseIconDiv } from './ItemCollapseIconDiv';
import { ItemDiv } from './ItemDiv';
import { ItemHeaderButton } from './ItemHeaderButton';
import { ItemHeaderDiv } from './ItemHeaderDiv';

/** Next-generation accordion components. */
export const AccordionNg = {
  Provider: AccordionNgProvider,
  AccordionScrollDiv,
  AccordionDiv,
  ItemDiv,
  ItemHeaderButton,
  ItemHeaderDiv,
  ItemCollapseIconDiv,
  ItemCollapseIconButton,
  ItemBodyAnimationDiv,
  ItemBodyDiv,
};
