import { FunctionComponent, useCallback } from 'react';

import { faFilter } from '@fortawesome/pro-solid-svg-icons';
import { UseSpringProps, animated, config as springPresets, useSpring, useSpringRef } from '@react-spring/web';
import { useNavigate } from 'react-router-dom';
import useMeasure from 'react-use-measure';
import styled from 'styled-components';

import { useEvent } from 'core/hooks';
import { AccordionNg, BreakpointSelectors, Icon, useBreakpoints } from 'core/ui';

import { MobileBottomBarProps } from '../types';
import { adaptToExamModel } from '../types/ShareTypes';
import { MobileAccordionItem } from './MobileAccordionItem';

export const MobileBottomBar: FunctionComponent<MobileBottomBarProps> = ({ className, exams, selectedFileId, onFileClick, show }) => {
  const navigate = useNavigate();
  const { portrait } = useBreakpoints();

  const [treeRef, { width, height }] = useMeasure();
  const springApi = useSpringRef();

  const [animationStyle] = useSpring(() => {
    // Initialization.
    const config: UseSpringProps = {
      ref: springApi,
      config: {
        ...springPresets.stiff,
        clamp: true,
      },
    };

    config.to = portrait
      ? {
          width: 'initial',
          height: show ? 'initial' : 0,
        }
      : {
          width: show ? 'initial' : 0,
          height: 'initial',
        };

    return config;
  }, []);

  const handleShowChange = useEvent(() => {
    // Initialization.
    const config: UseSpringProps = {
      ref: springApi,
      config: {
        ...springPresets.stiff,
        clamp: true,
      },
    };

    if (portrait) {
      // Portrait
      config.from = {
        height: show ? 0 : height,
      };
      config.to = [
        {
          height: show ? height : 0,
        },
        {
          height: show ? 'initial' : 0,
        },
      ];
    } else {
      // Landscape
      config.from = {
        width: show ? 0 : width,
      };
      config.to = [
        {
          width: show ? width : 0,
        },
        {
          width: show ? 'initial' : 0,
        },
      ];
    }

    springApi.start(config);
  });

  const handleOrientationChange = useEvent(() => {
    const config: UseSpringProps = {
      ref: springApi,
      config: {
        ...springPresets.stiff,
        clamp: true,
      },
    };

    config.to = portrait
      ? {
          width: 'initial',
          height: show ? 'initial' : 0,
        }
      : {
          width: show ? 'initial' : 0,
          height: 'initial',
        };

    springApi.set(config.to);
  });

  const handleFiltersClick = useCallback(() => {
    navigate('./filters');
  }, [navigate]);

  return (
    <StyledMobileBottomBar className={className}>
      <StyledAccordionScrollDiv>
        {exams.map((exam) => (
          <MobileAccordionItem key={exam.examId} exam={adaptToExamModel(exam)} selectedFileId={selectedFileId} onClick={onFileClick} />
        ))}
      </StyledAccordionScrollDiv>

      <StyledMobileExamFilesTreeDiv className={className} style={animationStyle} $mode={portrait ? 'horizontal' : 'vertical'}>
        <StyledScrollDiv ref={treeRef} mode={portrait ? 'horizontal' : 'vertical'}>
          <StyledAccordionDiv mode={portrait ? 'horizontal' : 'vertical'}>
            <StyledFilterButton
              onClick={handleFiltersClick}
              mode={portrait ? 'horizontal' : 'vertical'}
              horizontalModeWidth={43}
              verticalModeHeight={43}
              showSeparator={false}
              className={`testing_${MobileBottomBar.displayName}_filter-expand-button`}
              preset="mobile"
            >
              <div className="filter-button-content">
                <Icon icon={faFilter} fixedWidth />
                Filters
              </div>
            </StyledFilterButton>

            {/* Remove the duplicate exams.map here - it was causing duplicate UI elements */}
          </StyledAccordionDiv>
        </StyledScrollDiv>
      </StyledMobileExamFilesTreeDiv>
    </StyledMobileBottomBar>
  );
};

MobileBottomBar.displayName = 'MobileBottomBar';

const StyledMobileBottomBar = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const StyledAccordionScrollDiv = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const StyledMobileExamFilesTreeDiv = styled(animated.div)<{
  $mode: 'horizontal' | 'vertical';
}>`
  display: flex;
  overflow: hidden;
  flex-direction: ${({ $mode }) => ($mode === 'horizontal' ? 'column' : 'row')};

  ${BreakpointSelectors.OrientationPortrait} & {
    flex-direction: column;
  }

  ${BreakpointSelectors.OrientationLandscape} & {
    flex-direction: row;
    justify-content: end; // This makes the bar show/hide animation appear as if it slides in from outside the viewport.
  }
`;

const StyledScrollDiv = styled(AccordionNg.AccordionScrollDiv)`
  flex: 0 0 min-content;
`;

const StyledAccordionDiv = styled(AccordionNg.AccordionDiv)`
  background-color: ${({ theme }) => theme.colors.palette.grayscale[8]};
  color: ${({ theme }) => theme.colors.palette.white};

  &.vertical {
    width: 148px;
  }
  &.horizontal {
    height: 148px;
  }
`;

const StyledFilterButton = styled(AccordionNg.ItemHeaderButton)`
  display: flex;
  overflow: hidden;

  ${BreakpointSelectors.OrientationPortrait} & {
    width: 43px;
    height: 148px;
  }

  ${BreakpointSelectors.OrientationPortrait} & > .filter-button-content {
    position: relative;
    top: 148px;
    width: 148px;
    height: 43px;
    transform-origin: top left;
    rotate: -90deg;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: ${({ theme }) => theme.space.spacing20};
  }

  ${BreakpointSelectors.OrientationLandscape} & {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;
