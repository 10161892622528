import { useCallback, useEffect } from 'react';

import debounce from 'debounce';

import { useConst } from './use-const';
import { useEvent } from './use-event';

export function useDebounceEmitter<TParams extends unknown[] = never[]>(
  /** Callback to be executed after the debounce delay. */
  debounceHandler: (...args: TParams) => unknown,
  /** Debounce delay in milliseconds. */
  delay: number,
): UseDebounceEmitterResult<TParams> {
  const stableDebounceHandler = useEvent(debounceHandler);

  const emitter = useConst(() => debounce(stableDebounceHandler, delay));

  const clear = useCallback(() => {
    emitter.clear();
  }, [emitter]);

  const flush = useCallback(() => {
    emitter.flush();
  }, [emitter]);

  // Don't let debounce events linger beyond the lifetime of this component.
  useEffect(() => {
    return () => {
      emitter.clear();
    };
  }, [emitter]);

  return {
    emitDebounce: emitter,
    clearDebounce: clear,
    flushDebounce: flush,
  };
}

type UseDebounceEmitterResult<TParams extends unknown[] = never[]> = {
  /** Queues a debounce event. */
  emitDebounce: (...args: TParams) => void;
  /** Clear the debounce queue. */
  clearDebounce: () => void;
  /** Immediately execute the debounced callback then reset the debounce queue. */
  flushDebounce: () => void;
};
