import { memo, useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';

import { ExamModel } from 'models';

import { apiClient } from 'core/api/globals';
import { Accordion, SmallTextBox } from 'core/ui';
import { equalsInsensitive } from 'core/utils';

import { AliveCorAcuityDefinitions, ExamTypes } from 'features/exam/constants';
import { AliveCorAcuity } from 'features/exam/types/AliveCorAcuity';

import TagIcon from '../assets/tag.svg?react';
import { PriorExamsAccordionProps } from '../types';
import { PriorExamCard } from './PriorExamCard';

const EMPTY_ARRAY: ExamModel[] = [];

export const PriorExamsAccordion = memo<PriorExamsAccordionProps>(({ eventKey, exam }) => {
  const [allPriors, setAllPriors] = useState<ExamModel[] | null>(null);

  const matchedPriorExam = useMemo(() => allPriors?.find((e) => e.priorMatch) ?? null, [allPriors]);

  const priorExams = useMemo(() => allPriors?.filter((e) => e.id !== matchedPriorExam?.id) ?? EMPTY_ARRAY, [allPriors, matchedPriorExam?.id]);

  const unreadExams = useMemo(() => allPriors?.filter((e) => e.reads == null || e.reads.length === 0) ?? EMPTY_ARRAY, [allPriors]);

  const acuitySummary = useMemo(() => {
    if (exam?.service?.description == null || !ExamTypes.ALIVE_COR.some((aliveCorService) => equalsInsensitive(aliveCorService, exam.service?.description))) {
      return null;
    }

    const newSummary = {
      stable: 0,
      needsReview: 0,
      serious: 0,
      notAcceptable: 0,
      other: 0,
    };

    for (const priorExam of priorExams) {
      if (priorExam.dynamicFormFields == null) continue;

      const acuity = priorExam.dynamicFormFields.find((field) => field.name === 'acuity')?.value;

      switch (acuity) {
        case AliveCorAcuity.Stable:
          newSummary.stable++;
          break;
        case AliveCorAcuity.NeedsReview:
          newSummary.needsReview++;
          break;
        case AliveCorAcuity.Serious:
          newSummary.serious++;
          break;
        case AliveCorAcuity.NotAcceptable:
          newSummary.notAcceptable++;
          break;
        default:
          newSummary.other++;
      }
    }

    return newSummary;
  }, [exam?.service?.description, priorExams]);

  const readsCount = priorExams?.reduce((sum, priorExam) => sum + (priorExam.reads?.length ?? 0), 0);

  const unreadExamsCount = unreadExams?.length ?? 0;

  useEffect(() => {
    if (exam?.id == null) return;

    (async () => {
      const newPriors = await apiClient.exams.getExamPriors(exam.id!);
      newPriors.sort((a, b) => {
        const aTime = a.studyDate == null ? 0 : new Date(a.studyDate).getTime();
        const bTime = b.studyDate == null ? 0 : new Date(b.studyDate).getTime();
        return bTime - aTime;
      });
      setAllPriors(newPriors);
    })();
  }, [exam?.id]);

  if (exam == null) return null;

  return (
    <>
      <Accordion.Item eventKey={eventKey}>
        <Accordion.Header Icon={TagIcon} title="Priors">
          <StyledPriorsTotalDiv>
            {acuitySummary == null ? (
              <SmallTextBox variant="disabled">{readsCount}</SmallTextBox>
            ) : (
              <>
                <SmallTextBox variant="success" block title={AliveCorAcuityDefinitions.find((d) => d.acuity === AliveCorAcuity.Stable)?.statement}>
                  {acuitySummary.stable}
                </SmallTextBox>

                <SmallTextBox variant="warning" block title={AliveCorAcuityDefinitions.find((d) => d.acuity === AliveCorAcuity.NeedsReview)?.statement}>
                  {acuitySummary.needsReview}
                </SmallTextBox>

                <SmallTextBox variant="error" block title={AliveCorAcuityDefinitions.find((d) => d.acuity === AliveCorAcuity.Serious)?.statement}>
                  {acuitySummary.serious}
                </SmallTextBox>

                <SmallTextBox variant="disabled" block title={AliveCorAcuityDefinitions.find((d) => d.acuity === AliveCorAcuity.NotAcceptable)?.statement}>
                  {acuitySummary.notAcceptable}
                </SmallTextBox>

                <SmallTextBox variant="disabled" block title="Other priors">
                  {acuitySummary.other}
                </SmallTextBox>
              </>
            )}
          </StyledPriorsTotalDiv>
        </Accordion.Header>
        <Accordion.Body>
          <StyledPriorsDiv>
            {priorExams.map((priorExam) => priorExam.reads?.map((read) => <PriorExamCard key={`${priorExam.id}-${read.id}`} exam={priorExam} read={read} />))}
          </StyledPriorsDiv>
        </Accordion.Body>
      </Accordion.Item>
      {unreadExams && unreadExamsCount > 0 && (
        <Accordion.Item eventKey={`${exam.id}-Unread`}>
          <Accordion.Header Icon={TagIcon} title="Unread">
            <StyledPriorsTotalDiv>{unreadExamsCount}</StyledPriorsTotalDiv>
          </Accordion.Header>
          <Accordion.Body>
            <StyledPriorsDiv>
              {unreadExams.map((unreadExam) => (
                <PriorExamCard key={`${unreadExam.id}`} exam={unreadExam} />
              ))}
            </StyledPriorsDiv>
          </Accordion.Body>
        </Accordion.Item>
      )}
      {matchedPriorExam && (
        <StyledPriorsDiv>
          {matchedPriorExam.reads?.map((read) => <PriorExamCard key={`${matchedPriorExam.id}-${read.id}`} exam={matchedPriorExam} read={read} />)}
        </StyledPriorsDiv>
      )}
    </>
  );
});

PriorExamsAccordion.displayName = 'PriorExamsAccordion';

const StyledPriorsDiv = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;
`;

const StyledPriorsTotalDiv = styled.div`
  display: flex;
  overflow: hidden;
  column-gap: 8px;
`;
