import { FC, useCallback, useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { PatientModel } from 'models';

import { useEvent } from 'core/hooks';
import { NotificationsService } from 'core/notifications';
import { Button, ComponentSizes, DropdownField, Modal, TabButton, TabsContainer } from 'core/ui';

import { useApiClient } from 'features/api';
import { AuthenticationScheme, useAuthentication } from 'features/auth';
import { Upload } from 'features/media/fragments/Upload';
import { QRScanModal } from 'features/upload-exams/fragments/QRScanModal';

import { DocumentCategories } from '../../exam/constants';
import { UPLOAD_FILE_MODAL_CATEGORY_OPTIONS } from '../constants';

interface UppyFile {
  id: string;
  name: string;
  type: string;
  size: number;
  data: Blob;
  preview?: string;
  extension?: string;
}

interface PatientMediaUploadModalProps {
  patient: PatientModel;
  onClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultCategory?: { name: string; value: any };
  onSuccess?: () => void;
}

enum UploadTab {
  LOCAL = 'local',
  MOBILE = 'mobile',
}

interface FormValues {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  category: { name: string; value: any };
}

export const PatientMediaUploadModal: FC<PatientMediaUploadModalProps> = ({ patient, onClose, defaultCategory, onSuccess }) => {
  const apiClient = useApiClient();
  const [selectedCategory, setSelectedCategory] = useState(defaultCategory ?? UPLOAD_FILE_MODAL_CATEGORY_OPTIONS[0]);
  const [activeTab, setActiveTab] = useState<UploadTab>(UploadTab.LOCAL);
  const [linkId, setLinkId] = useState<string>('');
  const [qrModalVisible, setQrModalVisible] = useState(false);
  const { activeScheme } = useAuthentication();

  // Add form methods for react-hook-form
  const formMethods = useForm<FormValues>({
    defaultValues: {
      category: defaultCategory ?? UPLOAD_FILE_MODAL_CATEGORY_OPTIONS[0],
    },
  });

  // Generate a link ID for QR code scanning when the mobile tab is selected
  useEffect(() => {
    if (activeTab === UploadTab.MOBILE) {
      const generateLinkId = async () => {
        try {
          const generatedLinkId = await apiClient.studyShare.createShareLink({
            patientId: patient.id,
            type: 'QR_UPLOAD',
            category: selectedCategory.value,
          });
          setLinkId(generatedLinkId);
          setQrModalVisible(true);
        } catch (error) {
          console.error('Failed to generate link ID', error);
          NotificationsService.displayError('Failed to generate QR code link');
        }
      };
      generateLinkId();
    }
  }, [activeTab, apiClient.studyShare, patient.id, selectedCategory.value]);

  // Handle category changes
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCategoryChange = useEvent((e: { value: any }) => {
    const newCategory = UPLOAD_FILE_MODAL_CATEGORY_OPTIONS.find((cat) => cat.value === e.value);
    if (newCategory) {
      setSelectedCategory(newCategory);
      formMethods.setValue('category', newCategory);
    }
  });

  // Callback when a file is successfully uploaded
  const handleFileUploaded = useCallback(
    async (file: UppyFile) => {
      try {
        // Create form data for file upload
        const formData = new FormData();
        formData.append('files', file.data, file.name);

        // Upload the file
        const uploadedFiles = await apiClient.filesClient.add(
          formData,
          false,
          null,
          patient.id,
          DocumentCategories[selectedCategory.value as keyof typeof DocumentCategories]?.value || DocumentCategories.DOCUMENT.value,
          activeScheme === AuthenticationScheme.OIDC ? 'msal-required' : 'share-required',
        );

        if (uploadedFiles && uploadedFiles.length > 0) {
          NotificationsService.displaySuccess('File uploaded successfully');
          if (onSuccess) {
            onSuccess();
          }
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        NotificationsService.displayError('Failed to upload file');
      }
    },
    [activeScheme, apiClient.filesClient, onSuccess, patient.id, selectedCategory.value],
  );

  // Close the QR modal and revert to Local tab
  const handleQrModalClose = () => {
    setQrModalVisible(false);
    setActiveTab(UploadTab.LOCAL);
  };

  return (
    <>
      <Modal show title="Upload Media" onHide={onClose}>
        <FormProvider {...formMethods}>
          <StyledContainer>
            <StyledHeader>
              <StyledCategorySelector>
                <DropdownField
                  name="category"
                  label="Category"
                  data={UPLOAD_FILE_MODAL_CATEGORY_OPTIONS}
                  dataItemKey="value"
                  textField="name"
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                />
              </StyledCategorySelector>

              <StyledTabs>
                <TabsContainer>
                  <TabButton selected={activeTab === UploadTab.LOCAL} roundedCorners="left" type="button" onClick={() => setActiveTab(UploadTab.LOCAL)}>
                    Local Upload
                  </TabButton>
                  <TabButton selected={activeTab === UploadTab.MOBILE} roundedCorners="right" type="button" onClick={() => setActiveTab(UploadTab.MOBILE)}>
                    Mobile Upload
                  </TabButton>
                </TabsContainer>
              </StyledTabs>
            </StyledHeader>

            <StyledContent>
              {activeTab === UploadTab.LOCAL && <Upload category={selectedCategory.value} onFileUploaded={handleFileUploaded} />}
              {activeTab === UploadTab.MOBILE && (
                <StyledMobileSection>
                  <p>Upload files directly from your mobile device by scanning the QR code. The upload link will be valid for 24 hours.</p>
                  <Button size={ComponentSizes.MEDIUM} onClick={() => setQrModalVisible(true)}>
                    Show QR Code
                  </Button>
                </StyledMobileSection>
              )}
            </StyledContent>
          </StyledContainer>
        </FormProvider>
      </Modal>

      {qrModalVisible && linkId && <QRScanModal examId={0} linkId={linkId} onClose={handleQrModalClose} show={qrModalVisible} />}
    </>
  );
};

// ---------------------- Styled Components ----------------------
const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid #e0e0e0;
`;

const StyledCategorySelector = styled.div`
  width: 200px;
`;

const StyledTabs = styled.div`
  margin-bottom: 16px;
`;

const StyledContent = styled.div`
  flex: 1;
  padding: 20px;
  overflow-y: auto;
`;

const StyledMobileSection = styled.div`
  padding: 16px;
`;
