import { FC, useEffect, useState } from 'react';

import Uppy from '@uppy/core';
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import ImageEditor from '@uppy/image-editor';
import '@uppy/image-editor/dist/style.min.css';
import { Dashboard } from '@uppy/react';
import ScreenCapture from '@uppy/screen-capture';
import '@uppy/screen-capture/dist/style.min.css';
import Webcam from '@uppy/webcam';
import '@uppy/webcam/dist/style.min.css';
import styled from 'styled-components';

interface UppyFile {
  id: string;
  name: string;
  type: string;
  size: number;
  data: Blob;
  preview?: string;
  extension?: string;
}

interface UppyImageEditorPlugin {
  editImage(fileId: string): void;
}

declare module '@uppy/core' {
  interface Uppy {
    close(): void;
    reset(): void;
    getPlugin(pluginName: string): UppyImageEditorPlugin;
    setFileMeta(fileId: string, meta: Record<string, string>): void;
    addFile(options: { name: string; type: string; data: Blob | File; source?: string; isRemote?: boolean; meta?: Record<string, string> }): string;
  }
}

interface UploadProps {
  category?: string;
  onFileUploaded?: (file: UppyFile) => void;
}

// ---------------------- Styled Components ----------------------
const DashboardContainer = styled.div`
  /*
    By default, the Uppy Dashboard handles its own layout.
    This container simply ensures the dashboard and screenshot
    button are visually in the same "panel."
  */
`;

const ScreenshotButton = styled.button`
  margin-top: 10px;
  padding: 8px 15px;
  background-color: #ff9800;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  @media (max-width: 600px) {
    padding: 6px 10px;
    font-size: 14px;
  }
`;

// ---------------------- Main Component -------------------------
export const Upload: FC<UploadProps> = ({ category = '', onFileUploaded }) => {
  const [uppy, setUppy] = useState<Uppy<{ category: string }> | null>(null);
  const [isCapturingScreenshot, setIsCapturingScreenshot] = useState(false);

  useEffect(() => {
    const uppyInstance = new Uppy({
      id: 'uppy',
      autoProceed: false,
      debug: true,
      restrictions: {
        maxNumberOfFiles: 10,
        allowedFileTypes: ['image/*', 'video/*', '.pdf', '.doc', '.docx'],
      },
      infoTimeout: 5000,
      meta: { category },
    })
      .use(Webcam, {
        mirror: true,
        showVideoSourceDropdown: true,
        modes: ['video-audio', 'video-only', 'audio-only', 'picture'],
      })
      .use(ScreenCapture, { preferredVideoMimeType: 'video/mp4' })
      .use(ImageEditor, {
        quality: 0.8,
        cropperOptions: {
          viewMode: 1,
          background: false,
          autoCropArea: 1,
          responsive: true,
        },
        actions: {
          revert: true,
          rotate: true,
          granularRotate: true,
          flip: true,
          zoomIn: true,
          zoomOut: true,
          cropSquare: true,
          cropWidescreen: true,
          cropWidescreenVertical: true,
        },
      });

    // --- Event listeners ---
    uppyInstance.on('file-added', (file) => {
      console.log('File added:', file);
      if (category) {
        uppyInstance.setFileMeta(file.id, { category });
      }
    });

    uppyInstance.on('upload', (data) => {
      console.log('Starting upload:', data);
    });

    uppyInstance.on('upload-success', (file, response) => {
      console.log('Upload success:', file, response);
      if (onFileUploaded && file) {
        onFileUploaded(file as UppyFile);
      }
    });

    uppyInstance.on('complete', (result) => {
      console.log('Upload complete:', result);
      if (onFileUploaded && result.successful && result.successful.length > 0) {
        result.successful.forEach((file) => onFileUploaded(file as UppyFile));
      }
    });

    uppyInstance.on('file-editor:complete', (file) => {
      console.log('Image editing complete:', file);
    });

    setUppy(uppyInstance);

    // Cleanup
    return () => {
      if (typeof uppyInstance.close === 'function') {
        uppyInstance.close();
      }
    };
  }, [category, onFileUploaded]);

  // --- Custom Screenshot Capture ---
  const captureScreenshot = async () => {
    if (!uppy) return;
    setIsCapturingScreenshot(true);

    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: { cursor: 'always' } as MediaTrackConstraints,
        audio: false,
      });

      const video = document.createElement('video');
      video.srcObject = stream;

      await new Promise<void>((resolve) => {
        video.onloadedmetadata = () => {
          video.play();
          resolve();
        };
      });

      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext('2d');

      if (ctx) {
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        canvas.toBlob((blob) => {
          if (blob) {
            const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
            const filename = `screenshot-${timestamp}.png`;

            uppy.addFile({
              name: filename,
              type: 'image/png',
              data: blob,
              source: 'Screenshot Plugin',
              meta: { category },
            });
            // Stop all tracks to release screen-share
            stream.getTracks().forEach((track) => track.stop());
            setIsCapturingScreenshot(false);
          }
        }, 'image/png');
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : typeof error === 'string' ? error : 'Unknown error';

      console.error('Error capturing screenshot:', errorMessage);
      setIsCapturingScreenshot(false);
    }
  };

  return (
    <DashboardContainer>
      <ScreenshotButton onClick={captureScreenshot} disabled={isCapturingScreenshot}>
        {isCapturingScreenshot ? 'Capturing...' : 'Take Screenshot'}
      </ScreenshotButton>
      {uppy && (
        <Dashboard
          uppy={uppy}
          plugins={['Webcam', 'ScreenCapture', 'ImageEditor']}
          metaFields={[
            { id: 'name', name: 'Name', placeholder: 'File name' },
            { id: 'description', name: 'Description', placeholder: 'Describe this file' },
            { id: 'category', name: 'Category', placeholder: 'File category' },
          ]}
          showLinkToFileUploadResult={false}
          proudlyDisplayPoweredByUppy={false}
          showProgressDetails={true}
          note="File types: images, videos, PDFs, and documents"
          width="100%"
          doneButtonHandler={() => uppy.reset()}
        />
      )}
    </DashboardContainer>
  );
};
