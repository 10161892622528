import { memo } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { DatePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import dayjs from 'dayjs';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import * as z from 'zod';

import { PatientAgeRangeModel, PatientModel } from 'models';

import { useEvent } from 'core/hooks';
import { NotificationsService } from 'core/notifications';
import {
  Button,
  CheckboxField,
  DropdownField,
  ErrorMessage,
  InputField,
  SwitchField,
  TextAreaField,
  Window,
  WindowActionsBar,
  createDropdownItemRender,
  createDropdownValueRender,
} from 'core/ui';
import { DatePickerField } from 'core/ui/DatePicker/DatePickerField';
import { DateTimePickerField } from 'core/ui/DateTimePicker/DateTimePickerField';
import { equalsInsensitive, findOrThrow, hasText, trimForStorage } from 'core/utils';

import { useQueryPatientAgeRanges } from 'features/api/hooks/useQueryAgeRanges';
import { useQueryPatient } from 'features/api/hooks/useQueryPatient';

import { PatientGenders } from '../constants';
import { PatientUtils } from '../services';

type PatientEditModalProps = {
  patientId: number | null | undefined;
  onPatientSaved?: (patientId: number) => void;
  onClose: () => void;
};

export const PatientEditModal = memo<PatientEditModalProps>(({ patientId, onPatientSaved, onClose }) => {
  const isOpo = true; // TODO: Need a way to determine whether to show Donor or Patient verbiage.

  const [{ data: patient, isSuccess: isPatientQuerySuccess, promise: patientPromise }, patientMutation] = useQueryPatient(patientId);
  const { data: allAgeRanges, isSuccess: isAgeRangesQuerySuccess, promise: ageRangesPromise } = useQueryPatientAgeRanges();

  const rhfContext = useForm({
    defaultValues: () => modelToForm(patientPromise, ageRangesPromise),
    resolver: zodResolver(FormSchema),
  });
  const { isValid, isSubmitting, isSubmitted } = rhfContext.formState;

  const isSuccess = isPatientQuerySuccess && isAgeRangesQuerySuccess;

  const handleIsUnosUnavailableChange = useEvent(() => {
    rhfContext.trigger('unosID');
  });

  const handleDobChange = useEvent((event: DatePickerChangeEvent) => {
    const newAgeRange = PatientUtils.calculateAgeRange(allAgeRanges, PatientUtils.calculateAgeFromDob(event.value));
    rhfContext.setValue('ageRange', newAgeRange ?? null);
  });

  const handleSubmit: SubmitHandler<PatientFormValues> = useEvent(async (values) => {
    if (!isSuccess) return;

    const updatedPatient = await patientMutation.mutateAsync(formToModel(values, patient));

    NotificationsService.displaySuccess(patientId == null ? `Patient created.` : `Patient updated.`); // TODO: Patient verbiage.

    onPatientSaved?.(updatedPatient.id);
    onClose();
  });

  return (
    <FormProvider {...rhfContext}>
      <Window
        title={patientId === 0 ? 'Create Patient' : 'Edit Patient'} // TODO: Patient verbiage.
        modal
        initialWidth={725}
        onClose={onClose}
      >
        <StyledForm
          id="patient-edit-modal-form"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="none"
          spellCheck="false"
          noValidate
          onSubmit={rhfContext.handleSubmit(handleSubmit)}
        >
          {isOpo && (
            <>
              <StyledFieldContainerDiv>
                <InputField label="UNOS ID" name="unosID" disabled={!isSuccess} />
              </StyledFieldContainerDiv>
            </>
          )}

          <StyledUnosRequiredCheckboxFieldDiv>
            <StyledCheckboxField label="UNOS ID Unavailable" name="isUnosUnavailable" onChange={handleIsUnosUnavailableChange} disabled={!isSuccess} />
          </StyledUnosRequiredCheckboxFieldDiv>

          <StyledFieldContainerDiv>
            <InputField label="Case ID" name="caseID" disabled={!isSuccess} />
          </StyledFieldContainerDiv>

          <StyledFieldContainerDiv>
            <InputField label="First Name" name="firstName" disabled={!isSuccess} />
          </StyledFieldContainerDiv>

          <StyledFieldContainerDiv>
            <InputField label="Last Name" name="lastName" disabled={!isSuccess} />
          </StyledFieldContainerDiv>

          <StyledFieldContainerDiv>
            <DatePickerField label="DOB" name="dob" onChange={handleDobChange} disabled={!isSuccess} />
          </StyledFieldContainerDiv>

          <StyledFieldContainerDiv>
            <DropdownField
              label="Age Range"
              name="ageRange"
              data={allAgeRanges}
              dataItemKey="id"
              textField="description"
              valueField="id"
              valueRender={ageRangeDropdownValueRender}
              itemRender={ageRangeDropdownItemRender}
              disabled={!isSuccess}
            />
          </StyledFieldContainerDiv>

          <StyledFieldContainerDiv>
            <InputField label="Weight (kg)" name="weight" type="number" disabled={!isSuccess} />
          </StyledFieldContainerDiv>

          <StyledFieldContainerDiv>
            <InputField label="MRN" name="patientNumber" disabled={!isSuccess} />
          </StyledFieldContainerDiv>

          <StyledFieldContainerDiv>
            <InputField label="Height (cm)" name="height" type="number" disabled={!isSuccess} />
          </StyledFieldContainerDiv>

          <StyledFieldContainerDiv>
            <DropdownField
              label="Gender"
              name="gender"
              data={PatientGenders}
              dataItemKey="value"
              textField="name"
              valueField="value"
              filterable={false}
              disabled={!isSuccess}
            />
          </StyledFieldContainerDiv>

          {isOpo && (
            <StyledFieldContainerDiv>
              <DateTimePickerField label="Cross Clamp Time" name="crossClampDateTime" disabled={!isSuccess} />
            </StyledFieldContainerDiv>
          )}

          <StyledFieldContainerDiv>
            <InputField label="Hospital" name="hospital" disabled={!isSuccess} />
          </StyledFieldContainerDiv>

          <StyledFieldContainerDiv>
            <SwitchField label="Active" name="active" disabled={!isSuccess} />
          </StyledFieldContainerDiv>

          <StyledFieldNotesContainerDiv>
            <TextAreaField label="Notes" name="notes" rows={5} disabled={!isSuccess} />
          </StyledFieldNotesContainerDiv>
        </StyledForm>
        <WindowActionsBar>
          {!isValid && isSubmitted && <ErrorMessage>Unable to save. Please correct issues above.</ErrorMessage>}
          <Button type="submit" form="patient-edit-modal-form" disabled={isSubmitting || !isSuccess}>
            Save
          </Button>
        </WindowActionsBar>
      </Window>
    </FormProvider>
  );
});

PatientEditModal.displayName = 'PatientEditModal';

const ZOD_MODEL_SHAPES = {
  PatientAgeRangeModel: () =>
    z.object({
      id: z.number(),
      description: z.string(),
      startRange: z.number(),
      endRange: z.number(),
    }),
};

const FormSchema = z
  .object({
    unosID: z.string(),
    isUnosUnavailable: z.boolean(),
    active: z.boolean(),
    firstName: z.string(),
    lastName: z.string(),
    dob: z.date().nullable(),
    patientNumber: z.string(),
    caseID: z.string(),
    height: z.number().nullish(),
    weight: z.number().nullish(),
    gender: z.object({ name: z.string(), value: z.string() }).nullable(),
    crossClampDateTime: z.date().nullish(),
    notes: z.string(),
    hospital: z.string(),
    ageRange: ZOD_MODEL_SHAPES.PatientAgeRangeModel().nullable(),
  })
  .superRefine((values, ctx) => {
    if (!values.isUnosUnavailable && !hasText(values.unosID)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'UNOS ID is required.',
        path: ['unosID'],
      });

      return false;
    }

    return true;
  });

type PatientFormValues = z.infer<typeof FormSchema>;

const ageRangeDropdownValueRender = createDropdownValueRender(PatientUtils.formatAgeRange);
const ageRangeDropdownItemRender = createDropdownItemRender(PatientUtils.formatAgeRange);

async function modelToForm(patientPromise: Promise<PatientModel | null>, allAgeRangesPromise: Promise<PatientAgeRangeModel[]>): Promise<PatientFormValues> {
  const [patient, allAgeRanges] = await Promise.all([patientPromise, allAgeRangesPromise]);

  if (patient == null) {
    return {
      unosID: '',
      isUnosUnavailable: false,
      active: true,
      firstName: '',
      lastName: '',
      dob: null,
      weight: null,
      height: null,
      patientNumber: '',
      caseID: '',
      gender: PatientGenders[0],
      crossClampDateTime: null,
      notes: '',
      hospital: '',
      ageRange: null,
    };
  }

  return {
    unosID: patient.unosID ?? '',
    isUnosUnavailable: !hasText(patient.unosID) || patient.unosID?.toLowerCase() === 'unknown',
    active: patient.active,
    firstName: patient.firstName,
    lastName: patient.lastName,
    dob: patient.dob == null ? null : dayjs(patient.dob).toDate(),
    weight: patient.weight ?? null,
    height: patient.height ?? null,
    patientNumber: patient.patientNumber ?? '',
    caseID: patient.caseID ?? '',
    gender:
      patient.gender == null
        ? PatientGenders[0]
        : findOrThrow(PatientGenders, (g) => equalsInsensitive(g.value, patient.gender), 'Could not find gender dropdown option for patient.'),
    crossClampDateTime: patient.crossClampDateTime == null ? null : new Date(patient.crossClampDateTime),
    notes: patient.notes ?? '',
    hospital: patient.hospital ?? '',
    ageRange: PatientUtils.calculateAgeRange(allAgeRanges, PatientUtils.calculateAgeFromDob(patient.dob)) ?? null,
  };
}

function formToModel(form: PatientFormValues, originalModel: PatientModel | null): PatientModel {
  if (originalModel == null) {
    throw new Error('TODO: Implement this so that it can instantiate new patients.');
  }

  return {
    ...originalModel,
    unosID: trimForStorage(form.unosID),
    active: form.active,
    firstName: form.firstName.trim(),
    lastName: form.lastName.trim(),
    dob: form.dob == null ? null : new Date(form.dob).toLocaleString(),
    weight: form.weight ?? null,
    height: form.height ?? null,
    patientNumber: form.patientNumber.trim(),
    caseID: form.caseID.trim(),
    gender: form.gender?.value ?? null,
    crossClampDateTime: form.crossClampDateTime == null ? null : new Date(form.crossClampDateTime).toLocaleString(),
    notes: trimForStorage(form.notes),
    hospital: trimForStorage(form.hospital),
    ageRange_id: form.ageRange?.id ?? null,
  };
}

const StyledForm = styled.form`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30ch, 1fr));
  gap: 1rem;
`;

const StyledFieldContainerDiv = styled.div``;

const StyledUnosRequiredCheckboxFieldDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  &::before {
    content: '\\00a0';
  }
`;

const StyledCheckboxField = styled(CheckboxField)`
  display: inline-flex;
  align-items: center;

  input {
    margin-right: ${({ theme }) => theme.space.spacing20};
  }
`;

const StyledFieldNotesContainerDiv = styled.div`
  grid-column: 1 / -1;
`;
