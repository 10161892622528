import { DataSourceRequestState, toDataSourceRequestString } from '@progress/kendo-data-query';

import { UserModel } from 'models';

import { ApiRouteService } from '../services/api-route-service';
import { DataResult } from '../types';
import { HttpClient } from './HttpClient';

export class UsersClient {
  constructor(private httpClient: HttpClient) {
    this.getAllForKendoGrid = this.getAllForKendoGrid.bind(this);
    this.getCurrentUser = this.getCurrentUser.bind(this);
  }

  public async getAllForKendoGrid(dataState: DataSourceRequestState) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const queryStr = toDataSourceRequestString(dataState);
    const response = await this.httpClient.fetchAndParse<DataResult<UserModel>>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/users/grid?${queryStr}`, options),
    );
    return response;
  }

  public async getCurrentUser(): Promise<UserModel | null> {
    const options = await this.httpClient.createStandardOptions('GET', null, {
      authMode: 'msal-optional',
    });

    const response = await this.httpClient.fetchAndParse<UserModel | null>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/users/GetCurrentUser`, options),
    );

    return response;
  }
}
