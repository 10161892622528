import React, { useEffect, useMemo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { ExamModel, ServiceModel } from 'models';

import { apiClient } from 'core/api/globals';
import { NotificationsService } from 'core/notifications';
import {
  DropdownField, // The improved RHF wrapper
  InputField,
  MASKED_DATETIME_INPUT_MODES,
  MaskedDateTimeInputField,
  SwitchField,
  TextAreaField,
} from 'core/ui';

import { BiopsyTypes, Lateralities, Organs, SlidePreparations } from 'features/exam/constants';

/* ---------- Form Data Type ---------- */
export interface ExamFormData {
  id?: number | null;
  serviceId?: number | ''; // numeric ID for service
  description?: string;
  customerUid?: string;
  studyDate?: string;
  studyTime?: string;
  notes?: string;
  organ?: number | null;
  laterality?: number | null;
  biopsyType?: number | null;
  slidePreparation?: number | null;
  share?: boolean;
  active?: boolean;
}

/* ---------- Component Props ---------- */
export interface ExamFormProps {
  exam?: ExamModel;
  onSaveSuccess?: () => void;
  formId?: string;
}

/**
 * Named export for the ExamForm
 */
export function ExamForm({ exam, onSaveSuccess, formId }: ExamFormProps) {
  // 1) Minimal default values
  const defaultValues: ExamFormData = React.useMemo(
    () => ({
      id: exam?.id ?? undefined,
      serviceId: exam?.service?.id ?? '',
      description: exam?.description ?? '',
      customerUid: exam?.customerUID ?? '',
      studyDate: exam?.studyDate ?? '',
      studyTime: exam?.studyTime ?? '',
      notes: exam?.notes ?? '',
      organ: typeof exam?.organ === 'number' ? exam.organ : null,
      laterality: typeof exam?.laterality === 'number' ? exam.laterality : null,
      biopsyType: typeof exam?.biopsyType === 'number' ? exam.biopsyType : null,
      slidePreparation: typeof exam?.slidePreparation === 'number' ? exam.slidePreparation : null,
      share: exam?.share ?? false,
      active: exam?.active ?? false,
    }),
    [exam],
  );

  // 2) Setup react-hook-form
  const methods = useForm<ExamFormData>({ defaultValues });
  const { handleSubmit } = methods;

  // 3) Check if exam is "pathology"
  const isPathology = exam?.service?.shortCode === 'PATH' || exam?.service?.description === 'PATHOLOGY';

  // 4) Fetch location-based services
  const [services, setServices] = useState<ServiceModel[]>([]);
  useEffect(() => {
    // If there's no location, we skip or fetch a fallback list
    if (!exam?.location?.id) {
      setServices([]); // or fetch ALL services if that's desired
      return;
    }

    apiClient.servicesClient
      .getServicesByLocation(exam.location.id)
      .then((res) => {
        // res = array of location-based services
        setServices(res);
      })
      .catch(() => setServices([]));
  }, [exam]);

  // 5) Merge exam.service into the list if it's missing
  const computedServices = useMemo(() => {
    if (!exam?.service) return services; // if exam has no service, no merge needed

    const alreadyExists = services.some((s) => s.id === exam.service!.id);
    return alreadyExists ? services : [exam.service, ...services];
  }, [services, exam]);

  // 6) Submit Handler
  const onSubmit = async (data: ExamFormData) => {
    try {
      const examToSave = data as unknown as ExamModel;
      await apiClient.exams.saveExam(examToSave);
      NotificationsService.displaySuccess('Exam updated successfully');
      onSaveSuccess?.();
    } catch {
      NotificationsService.displayError('Failed to update exam');
    }
  };

  return (
    <FormProvider {...methods}>
      <StyledForm id={formId} onSubmit={handleSubmit(onSubmit)}>
        <TwoColumnGrid>
          {/* ---------- SERVICE DROPDOWN ---------- */}
          <FieldContainer>
            <Label>Service</Label>
            <DropdownField
              name="serviceId"
              data={computedServices} // <--- PASS THE MERGED LIST
              dataItemKey="id" // match items by .id
              textField="description" // show "description"
              valueField="id" // store only .id in RHF
              isForPrimitiveValues // so we only keep a numeric ID
            />
          </FieldContainer>

          <FieldContainer>
            <Label>Description</Label>
            <InputField name="description" />
          </FieldContainer>

          <FieldContainer>
            <Label>Accession</Label>
            <InputField name="customerUid" />
          </FieldContainer>

          <FieldContainer>
            <Label>Exam Date</Label>
            <MaskedDateTimeInputField name="studyDate" mode={MASKED_DATETIME_INPUT_MODES.DATE} />
          </FieldContainer>

          <FieldContainer>
            <Label>Exam Time</Label>
            <MaskedDateTimeInputField name="studyTime" mode={MASKED_DATETIME_INPUT_MODES.TIME} />
          </FieldContainer>

          <FullWidthField>
            <Label>Notes</Label>
            <TextAreaField name="notes" rows={5} />
          </FullWidthField>

          {/* ---------- PATHOLOGY DROPDOWNS ---------- */}
          {isPathology && (
            <>
              <FieldContainer>
                <Label>Organ</Label>
                <DropdownField name="organ" data={Object.values(Organs)} dataItemKey="value" textField="name" valueField="value" isForPrimitiveValues />
              </FieldContainer>

              <FieldContainer>
                <Label>Laterality</Label>
                <DropdownField
                  name="laterality"
                  data={Object.values(Lateralities)}
                  dataItemKey="value"
                  textField="name"
                  valueField="value"
                  isForPrimitiveValues
                />
              </FieldContainer>

              <FieldContainer>
                <Label>Biopsy Type</Label>
                <DropdownField
                  name="biopsyType"
                  data={Object.values(BiopsyTypes)}
                  dataItemKey="value"
                  textField="name"
                  valueField="value"
                  isForPrimitiveValues
                />
              </FieldContainer>

              <FieldContainer>
                <Label>Slide Preparation</Label>
                <DropdownField
                  name="slidePreparation"
                  data={Object.values(SlidePreparations)}
                  dataItemKey="value"
                  textField="name"
                  valueField="value"
                  isForPrimitiveValues
                />
              </FieldContainer>
            </>
          )}

          <FieldContainer>
            <Label>Share</Label>
            <SwitchField name="share" />
          </FieldContainer>

          <FieldContainer>
            <Label>Active</Label>
            <SwitchField name="active" />
          </FieldContainer>
        </TwoColumnGrid>
      </StyledForm>
    </FormProvider>
  );
}

/* ---------- STYLES ---------- */

const StyledForm = styled.form`
  max-width: 90vw;
  margin: 0 auto;
`;

const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(220px, 1fr));
  gap: 1rem;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FullWidthField = styled(FieldContainer)`
  grid-column: 1 / -1;
`;

const Label = styled.label`
  margin-bottom: 0.25rem;
  font-weight: bold;
`;
