import { ReactNode, memo, useEffect, useRef, useState } from 'react';

import { datadogLogs } from '@datadog/browser-logs';
import { HubConnection, HubConnectionBuilder, ILogger, LogLevel } from '@microsoft/signalr';

import { ApiRouteService } from 'core/api';

import { AuthenticationScheme } from 'features/auth/constants';
import { globalAuthenticationManager } from 'features/auth/globals';
import { useAccessTokenSnapshot } from 'features/auth/hooks/useAccessTokenSnapshot';
import { useAuthentication } from 'features/auth/hooks/useAuthentication';

import { ApiWebSocketProviderContext } from '../contexts';

export const ApiWebSocketProvider = memo<{ children?: ReactNode }>(({ children }) => {
  // Note: This has some very tedious logic for initialization and cleanup.  It's necessary in order to avoid losing state between HMR
  // refreshes which causes useEffect() calls to be fired every time - even if the changes are on an unrelated component.  So we have to
  // manually track whether the component is mounted and whether the initialization has already been done.

  const { activeScheme } = useAuthentication();
  const connectionRef = useRef<HubConnection | null>(null);
  const unmountTimeoutRef = useRef<number | null>(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const { accessToken } = useAccessTokenSnapshot();

  useEffect(() => {
    if (unmountTimeoutRef.current) {
      window.clearTimeout(unmountTimeoutRef.current);
      unmountTimeoutRef.current = null;
    }

    const cleanup = () => {
      unmountTimeoutRef.current = window.setTimeout(() => {
        if (connectionRef.current) {
          connectionRef.current.stop();
          connectionRef.current = null;
        }
      }, 1000);
    };

    if (activeScheme !== AuthenticationScheme.OIDC && activeScheme !== AuthenticationScheme.SHARE) {
      return cleanup;
    }
    if (connectionRef.current) {
      return cleanup;
    }

    // Wait for a share access token to be ready to use when operating in the share scheme.
    if (activeScheme === AuthenticationScheme.SHARE && accessToken == null) {
      return cleanup;
    }

    const tokenGetter: () => string | Promise<string> =
      activeScheme === AuthenticationScheme.OIDC ? globalAuthenticationManager.oidcScheme.accessTokenGetter : () => accessToken!; // Non-null assertion is safe because we just checked if it was null.

    const connection = new HubConnectionBuilder()
      .withUrl(`${ApiRouteService.getCompumedApiBaseRoute()}/hub/upload`, {
        accessTokenFactory: tokenGetter,
      })
      .withAutomaticReconnect()
      .configureLogging(SIGNALR_LOGGER)
      .build();

    connection.start();

    setIsInitialized(true);
    connectionRef.current = connection;

    return cleanup;
  }, [accessToken, activeScheme]);

  return !isInitialized ? null : <ApiWebSocketProviderContext.Provider value={connectionRef.current}>{children}</ApiWebSocketProviderContext.Provider>;
});

ApiWebSocketProvider.displayName = 'ApiWebSocketProvider';

const SIGNALR_LOGGER: ILogger = {
  log: (logLevel, message) => {
    switch (logLevel) {
      case LogLevel.Warning:
        datadogLogs.logger.warn(message);
        break;
      case LogLevel.Error:
        datadogLogs.logger.error(message);
        break;
      case LogLevel.Critical:
        datadogLogs.logger.critical(message);
        break;
    }

    // Don't log anything at info or below.
  },
};
