import { forwardRef } from 'react';

import cn from 'classnames';
import styled from 'styled-components';

import { AccordionDivProps } from './AccordionDivProps';

/** Element that will be the direct parent for accordion items.  This element is intended to grow along the main axis so that the total
 *  length of the child elements fit, even if that escapes the boundaries of parent elements.  The AccordionScrollDiv component will then
 *  applies proper containment and a scrollbar (along the cross axis) so that the accordion item list is scrollable as expected. */
export const AccordionDiv = forwardRef<HTMLDivElement, AccordionDivProps>(({ className, mode = 'vertical', ...rest }, ref) => (
  <StyledAccordionDiv
    ref={ref}
    className={cn(className, AccordionDiv.displayName, {
      horizontal: mode === 'horizontal',
      vertical: mode === 'vertical',
    })}
    {...rest}
  />
));

AccordionDiv.displayName = 'AccordionDiv';

const StyledAccordionDiv = styled.div`
  flex: 1 0 min-content;
  display: flex;
  align-items: stretch;

  &.horizontal {
    flex-direction: row;
  }

  &.vertical {
    flex-direction: column;
  }
`;
