import { DataSourceRequestState, toDataSourceRequestString } from '@progress/kendo-data-query';

import { WorkListViewUserGridModel, WorkListViewUserModel, WorklistViewAccessModel, WorklistViewModel } from 'models';
import { WorklistViewAccessListGridModel } from 'models/WorklistViewAccessListGridModel';
import { WorklistViewAccessListModel } from 'models/WorklistViewAccessListModel';
import { WorklistViewAccessTypeModel } from 'models/WorklistViewAccessTypeModel';

import { ApiRouteService } from '../services';
import { DataResult } from '../types';
import { HttpClient } from './HttpClient';

export class WorkListViewClient {
  constructor(private httpClient: HttpClient) {
    this.getAllWorkListUsersForKendoGrid = this.getAllWorkListUsersForKendoGrid.bind(this);
    this.getAllWorkListAccessForKendoGrid = this.getAllWorkListAccessForKendoGrid.bind(this);
    this.getWorkListViewUser = this.getWorkListViewUser.bind(this);
    this.getWorkListViewAccess = this.getWorkListViewAccess.bind(this);
    this.createWorkListViewUser = this.createWorkListViewUser.bind(this);
    this.createUpdateWorkListViewAccess = this.createUpdateWorkListViewAccess.bind(this);
    this.updateWorkListViewUser = this.updateWorkListViewUser.bind(this);
    this.deleteWorkListViewUser = this.deleteWorkListViewUser.bind(this);
    this.getViews = this.getViews.bind(this);
    this.createView = this.createView.bind(this);
    this.updateView = this.updateView.bind(this);
    this.deleteView = this.deleteView.bind(this);
    this.getAllWorklistViewAccess = this.getAllWorklistViewAccess.bind(this);
    this.getAllWorklistViewAccessType = this.getAllWorklistViewAccessType.bind(this);
    this.getExamStatusCount = this.getExamStatusCount.bind(this);
  }

  public async getViews(
    /** Default set to `false`. */
    forAdmin = false,
    sessionLocationId = 0,
  ) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const worklists = await this.httpClient.fetchAndParse<WorklistViewModel[]>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/WorklistView?forAdmin=${forAdmin}&sessionLocationId=${sessionLocationId}`, options),
    );
    return worklists;
  }

  public async createView(view: WorklistViewModel) {
    const options = await this.httpClient.createStandardOptions('POST', view);
    const result = await this.httpClient.fetchAndParse<WorklistViewModel>(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/WorklistView`, options));
    return result;
  }

  public async updateView(view: WorklistViewModel) {
    const options = await this.httpClient.createStandardOptions('PUT', view);
    const result = await this.httpClient.fetch(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/WorklistView/${view.id}`, options));
    return result;
  }

  public async deleteView(id: number) {
    const options = await this.httpClient.createStandardOptions('DELETE', null);
    const result = await this.httpClient.fetch(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/WorklistView/${id}`, options));
    return result;
  }

  public async getAllWorklistViewAccess() {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const accessModes = await this.httpClient.fetchAndParse<WorklistViewAccessModel[]>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/WorklistView/GetAllWorklistViewAccess`, options),
    );
    return accessModes;
  }

  public async getAllWorklistViewAccessType() {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const accessModes = await this.httpClient.fetchAndParse<WorklistViewAccessTypeModel[]>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/WorklistView/GetAllWorklistViewAccessType`, options),
    );
    return accessModes;
  }

  /** Get all record of the worklist users. */
  public async getAllWorkListUsersForKendoGrid(dataState: DataSourceRequestState) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const queryStr = toDataSourceRequestString(dataState);
    const viewers = await this.httpClient.fetchAndParse<DataResult<WorkListViewUserGridModel>>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/WorklistView/worklist-users-grid?${queryStr}`, options),
    );
    return viewers;
  }

  /** Get all record of the worklist access. */
  public async getAllWorkListAccessForKendoGrid(dataState: DataSourceRequestState) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const queryStr = toDataSourceRequestString(dataState);
    const viewers = await this.httpClient.fetchAndParse<DataResult<WorklistViewAccessListGridModel>>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/WorklistView/worklist-accesslist-grid?${queryStr}`, options),
    );
    return viewers;
  }

  /** Get worklist record based on id. */
  public async getWorkListViewUser(id: number) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const viewers = await this.httpClient.fetchAndParse<WorkListViewUserModel>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/WorklistView/getWorkListViewUser/${id}`, options),
    );
    return viewers;
  }

  /** Get worklist access record based on id. */
  public async getWorkListViewAccess(id: number) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const viewers = await this.httpClient.fetchAndParse<WorklistViewAccessListModel>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/WorklistView/GetWorklistViewAccessList/${id}`, options),
    );
    return viewers;
  }

  /** Create new record. */
  public async createWorkListViewUser(workListViewUserModel: WorkListViewUserModel) {
    const options = await this.httpClient.createStandardOptions('POST', workListViewUserModel);

    const result = await this.httpClient.fetchAndParse<number>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/WorklistView/createWorkListViewUser`, options),
    );
    return result;
  }

  public async createUpdateWorkListViewAccess(workListViewUserModel: WorklistViewAccessListModel) {
    const options = await this.httpClient.createStandardOptions('POST', workListViewUserModel);

    const result = await this.httpClient.fetchAndParse<number>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/WorklistView/createWorklistViewAccessList`, options),
    );
    return result;
  }

  /** Update the worklist record. */
  public async updateWorkListViewUser(workListViewUserModel: WorkListViewUserModel) {
    const options = await this.httpClient.createStandardOptions('PUT', workListViewUserModel);
    const result = await this.httpClient.fetch(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/WorklistView/updateWorkListViewUser`, options));
    return result;
  }

  /** Delete the worklist record. */
  public async deleteWorkListViewUser(id: number) {
    const options = await this.httpClient.createStandardOptions('DELETE', null);
    const result = await this.httpClient.fetch(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/WorklistView/DeleteWorklistViewUser/${id}`, options));
    return result;
  }

  /** Fetch the counts for each specified worklist.  @returns Object where the keys are worklist IDs and the values are the counts. */
  public async getExamStatusCount(worklistIds: number[], signal?: AbortSignal | null) {
    const options = await this.httpClient.createStandardOptions('GET', null, { signal });
    const result = await this.httpClient.fetchAndParse<Record<string, number>>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/WorklistView/status?${worklistIds.map((id) => `&ids=${id}`).join('')}`, options),
    );
    return result;
  }
}
