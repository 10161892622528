import { FunctionComponent, memo, useEffect, useId, useState } from 'react';

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { ServiceModel } from 'models';

import { apiClient } from 'core/api/globals';
import { RhfValidators, useFieldValueChangeHandler } from 'core/forms';
import { useAsyncCallback, useDataStream, useEvent, useEventStream } from 'core/hooks';
import { NotificationsService } from 'core/notifications';
import { Button, ComponentSizes, DropdownField, ErrorMessage, FileInputField, InputField, ProgressBar, TextAreaField, Window, WindowActionsBar } from 'core/ui';
import { equalsInsensitive, findOrThrow } from 'core/utils';

import { useCurrentUser } from 'features/auth';
import { Organs } from 'features/exam/constants';
import { BasicUploadView } from 'features/file/services';
import { useSessionLocation } from 'features/location';
import { Classification } from 'features/upload-pipeline';
import { useUploadPipeline } from 'features/upload-pipeline/hooks/useUploadPipeline';

import {
  UPLOAD_FILE_MODAL_CATEGORY_OPTIONS,
  UPLOAD_FILE_MODAL_DEFAULT_FORM_VALUES,
  UPLOAD_FILE_MODAL_PATHOLOGY_BIOPSY_TYPE_OPTIONS,
  UPLOAD_FILE_MODAL_PATHOLOGY_LATERALITY_OPTIONS,
  UPLOAD_FILE_MODAL_PATHOLOGY_SLIDE_PREPARATION_OPTIONS,
} from '../constants';
import { UploadFileModalFormValues, UploadFileModalProps } from '../types';

const UploadFileModalInner = memo<UploadFileModalProps & { uploadView: BasicUploadView }>(({ patient, uploadView, onClose, defaultCategory, onSuccess }) => {
  const uploadPipeline = useUploadPipeline();
  const { sessionLocation } = useSessionLocation();
  const { currentUser } = useCurrentUser(true);

  const formId = `${UploadFileModalInner.displayName}_${useId()}`;
  const [formValues, setFormValues] = useState<UploadFileModalFormValues>({
    ...UPLOAD_FILE_MODAL_DEFAULT_FORM_VALUES,
    category: defaultCategory ?? UPLOAD_FILE_MODAL_DEFAULT_FORM_VALUES.category,
  });

  const uploadStatus = useDataStream(uploadView.streams.status);

  const uploadPercentage =
    uploadStatus.totalFiles === 0
      ? 100.0 // Hardcode to 100% if there are no queued files because the weighted upload size is susceptible to accumulating rounding errors.
      : Math.min(100.0, (100.0 * uploadStatus.uploadedBytes) / uploadStatus.totalBytes); // Cap at exactly 100.0 in case there are floating point rounding discrepancies.

  const rhfContext = useForm({
    defaultValues: formValues,
  });

  const { isValid, isSubmitted } = rhfContext.formState;

  const handleFormChange = useFieldValueChangeHandler(setFormValues, rhfContext);

  const handleSubmit: SubmitHandler<UploadFileModalFormValues> = useEvent(async (form) => {
    const isPathology = equalsInsensitive(form.category.value, "Pathology");
    const isLink = equalsInsensitive(form.category.value, "Link");

    if (!isLink && form.files == null) throw new Error('Cannot upload files when the form.files property is null or undefined.');
    const files = form.files ?? []; // Workaround for TS not able to infer that form.files is not null when isLink is false. (TypeScript 5.6.2)

    const newExamId = await apiClient.exams.saveExam({
      location_Id: sessionLocation?.id ?? patient?.location_Id ?? undefined,
      patient_Id: patient.id,
      service: { description: form.category.value },
      source: 'Web',
      /* "Pathology" specific fields. */
      ...(isPathology
        ? {
            organ: form.organ?.value,
            laterality: form.laterality?.value,
            biopsyType: form.biopsyType?.value,
            slidePreparation: form.slidePreparation?.value,
          }
        : {}),
      /* "Link" specific field. */
      ...(isLink ? { notes: form.linkUrl } : {}),
    });

    // Begin the file uploads. Link "uploads" are not actually uploaded from the browser, so we can skip the upload process for those.
    if (!isLink) {
      let classification: null | Classification = null;

      if (form.category.value === "EKG") {
        classification = Classification.Ekg;
      } else if (form.category.value === "Pathology") {
        classification = Classification.Pathology;
      } else if (["DOCUMENT", "ChestXRay", "CT", "REPORT", "Ventilator", "Monitoring"].includes(form.category.value)) {
        classification = Classification.Document;
      } else {
        throw new Error(`Unknown upload category: ${form.category.value}`);
      }

      if (classification != null) {
        uploadPipeline.setFixedEntities(patient.id, newExamId, patient.location_Id);
        uploadPipeline.addFiles(
          files.map((f) => ({
            fileId: f.fileId,
            file: f.file,
            classification,
            metadata: { userId: currentUser.id, examId: newExamId },
          })),
        );
      }
    }

    // Go ahead and close the dialog now for link uploads since there is not an actual file transfer between browser and server.
    if (isLink) {
      NotificationsService.displaySuccess('Successfully created exam.');
      if (onSuccess) {
        onSuccess();
      }
      onClose();
    }
  });

  useEventStream(uploadView.streams.status, (status) => {
    if (status.totalFiles === 0) return;

    if (status.error > 0) return;

    if (status.attachedFiles === status.totalFiles) {
      NotificationsService.displaySuccess(`Successfully created exam and uploaded ${status.attachedFiles === 1 ? 'file' : 'files'}.`);
      onClose();
    }
  });

  return (
    <FormProvider {...rhfContext}>
      <Window title="Upload File" initialWidth={400} onClose={onClose}>
        <StyledForm
          id={formId}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="none"
          spellCheck="false"
          noValidate
          onSubmit={rhfContext.handleSubmit(handleSubmit)}
        >
          <div>
            <DropdownField
              label="Category"
              name="category"
              data={UPLOAD_FILE_MODAL_CATEGORY_OPTIONS}
              dataItemKey="value"
              textField="name"
              onChange={handleFormChange}
            />
          </div>

          {formValues.category.value === "Pathology" && (
            <>
              <div>
                <DropdownField label="Organ" name="organ" data={Object.values(Organs)} dataItemKey="value" textField="name" onChange={handleFormChange} />
              </div>

              <div>
                <DropdownField
                  label="Laterality"
                  name="laterality"
                  data={UPLOAD_FILE_MODAL_PATHOLOGY_LATERALITY_OPTIONS}
                  dataItemKey="value"
                  textField="name"
                  onChange={handleFormChange}
                />
              </div>

              <div>
                <DropdownField
                  label="Biopsy Type"
                  name="biopsyType"
                  data={UPLOAD_FILE_MODAL_PATHOLOGY_BIOPSY_TYPE_OPTIONS}
                  dataItemKey="value"
                  textField="name"
                  onChange={handleFormChange}
                />
              </div>

              <div>
                <DropdownField
                  label="Slide Preparation"
                  name="slidePreparation"
                  data={UPLOAD_FILE_MODAL_PATHOLOGY_SLIDE_PREPARATION_OPTIONS}
                  dataItemKey="value"
                  textField="name"
                  onChange={handleFormChange}
                />
              </div>
            </>
          )}

          {formValues.category.value === "Link" && (
            <div>
              <InputField label="Link URL" name="linkUrl" required validator={RhfValidators.url} onChange={handleFormChange} />
            </div>
          )}

          <div>
            <TextAreaField label="Notes" name="notes" onChange={handleFormChange} />
          </div>

          {formValues.category.value !== "Link" && (
            <div>
              <FileInputField label="Files" name="files" required multiple onChange={handleFormChange} />
            </div>
          )}
        </StyledForm>

        <WindowActionsBar>
          {uploadStatus.totalFiles > 0 && <StyledProgressBar value={uploadPercentage} size={ComponentSizes.LARGE} labelResolver={progressText} />}
          {!isValid && isSubmitted && <ErrorMessage>Unable to save. Please correct issues above.</ErrorMessage>}
          <Button type="submit" form={formId}>
            Save & Upload
          </Button>
        </WindowActionsBar>
      </Window>
    </FormProvider>
  );
});

UploadFileModalInner.displayName = 'UploadFileModalInner';

export const UploadFileModal: FunctionComponent<UploadFileModalProps> = (props) => {
  const uploadPipeline = useUploadPipeline();

  const [uploadView, setUploadView] = useState<BasicUploadView | null>(null);

  useEffect(() => {
    const newUploadView = new BasicUploadView(uploadPipeline);
    setUploadView(newUploadView);

    return () => {
      newUploadView.destroy();
      setUploadView(null);
    };
  }, [uploadPipeline]);

  return uploadView == null ? null : <UploadFileModalInner {...props} uploadView={uploadView} />;
};

UploadFileModal.displayName = 'UploadFileModal';

function progressText(value: number | null | undefined) {
  return value == null ? '' : `${Math.floor(value)} %`;
}

const StyledForm = styled.form`
  display: grid;
  overflow: hidden;
  grid-template-columns: 1fr;
`;

const StyledProgressBar = styled(ProgressBar)`
  width: 200px;
`;
