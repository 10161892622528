import { createContext } from 'react';

import { IPublicClientApplication } from '@azure/msal-browser';

import { OidcAuthenticationScheme } from './services';
import { AuthenticationContextType, CurrentUserContextType, ParsedShareAccessToken, RouteGuardContextType } from './types';
import { Claim } from './types/Claim';

export const CurrentUserContext = createContext<CurrentUserContextType>({
  user: undefined,
  setUser: () => {
    throw new Error('<CurrentUserProvider /> must be an ancestor.');
  },
  fetchProfile: () => {
    throw new Error('<CurrentUserProvider /> must be an ancestor.');
  },
});

CurrentUserContext.displayName = 'CurrentUserContext';

export const AuthenticationContext = createContext<AuthenticationContextType>({
  pca: null as unknown as IPublicClientApplication,
  loginShare: () => {
    throw new Error('<AuthenticationProvider /> must be an ancestor.');
  },
  logoutOidc: () => {
    throw new Error('<AuthenticationProvider /> must be an ancestor.');
  },
  loginOidc: () => {
    throw new Error('<AuthenticationProvider /> must be an ancestor.');
  },
  logoutShare: () => {
    throw new Error('<AuthenticationProvider /> must be an ancestor.');
  },
  activeScheme: undefined,
  frontChannelLogoutOidc: () => {
    throw new Error('<AuthenticationProvider /> must be an ancestor.');
  },
  oidcScheme: null as unknown as OidcAuthenticationScheme,
});

AuthenticationContext.displayName = 'AuthenticationContext';

export const RouteGuardContext = createContext<RouteGuardContextType>({
  isContextPresent: false,
  allowedRoles: [],
  allowedSchemes: [],
  errorCodes: [],
});

RouteGuardContext.displayName = 'RouteGuardContext';

export const ParsedShareAccessTokenContext = createContext<ParsedShareAccessToken | null | undefined>(undefined);

ParsedShareAccessTokenContext.displayName = 'ParsedShareAccessTokenContext';

export const ShareAccessTokenContext = createContext<string | null | undefined>(undefined);

ShareAccessTokenContext.displayName = 'ShareAccessTokenContext';

export const AuthorizationClaimsContext = createContext<Claim[] | undefined>(undefined);

AuthorizationClaimsContext.displayName = 'AuthorizationClaimsContext';
