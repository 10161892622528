// Note that the "linkId" parameter is intentionally omitted here.  The reason being that the application auth logic needs to be able to reliably
// determine the linkId.  So the solution was to move that parameter to the top-level route definitions.  This allows us to query the matched
// routes based on route id which we can then guarantee will always have the "linkId" parameter.
export const ShareRoutes = {
  HOME: '',
  ONEPAGE: '/onepage',
  LANDING: '/landing',
  VIEWER: '/viewer/*',
  UPLOAD: '/upload',
  FILE_VIEWER: '/file-viewer/:examId',
};
