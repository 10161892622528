import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import dayjs from 'dayjs';

import { ShareAccessToken } from 'models';

import { AnonymousUserError, setAccessTokenReduxShim, setShareTokenReduxShim } from 'core/api';
import { DataStream, parseJwt } from 'core/utils';

import { AuthenticationScheme, SHARE_TOKEN_SESSION_STORAGE_KEY } from '../constants';
import { AuthenticationManagerHandle } from '../types/AuthenticationManagerHandle';
import { IAuthenticationScheme } from '../types/IAuthenticationScheme';
import { ParsedShareAccessToken } from '../types/ParsedShareAccessToken';

export class ShareAuthenticationScheme implements IAuthenticationScheme {
  public readonly hub: {
    shareTokenGetter: DataStream<(() => string) | null>;
    parsedShareToken: DataStream<ParsedShareAccessToken | null>;
  };

  constructor(private manager: AuthenticationManagerHandle) {
    this.initialize = this.initialize.bind(this);
    this.setActiveScheme = this.setActiveScheme.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.shareTokenGetter = this.shareTokenGetter.bind(this);
    this.parseShareJwt = this.parseShareJwt.bind(this);

    this.hub = {
      shareTokenGetter: new DataStream<(() => string) | null>(null),
      parsedShareToken: new DataStream<ParsedShareAccessToken | null>(null),
    };
  }

  public async initialize() {
    const shareToken = sessionStorage.getItem(SHARE_TOKEN_SESSION_STORAGE_KEY);

    if (shareToken == null) {
      this.hub.parsedShareToken.emit(null);
      return false;
    }

    const parsed = this.parseShareJwt(shareToken);
    const now = dayjs.utc();

    // Discard expired tokens.
    if (parsed.exp.diff(now) <= 0) {
      sessionStorage.removeItem(SHARE_TOKEN_SESSION_STORAGE_KEY);
      this.hub.parsedShareToken.emit(null);
      return false;
    }

    this.hub.parsedShareToken.emit(parsed);
    return true;
  }

  public setActiveScheme() {
    this.manager.apiClient.setAccessTokenFunction(null);
    setAccessTokenReduxShim(null);
    this.manager.apiClient.setShareTokenFunction(this.shareTokenGetter);
    setShareTokenReduxShim(this.shareTokenGetter);

    const token = this.hub.parsedShareToken.getCurrentValue()!;
    const datadogUser = {
      id: token.shareLinkId,
      name: `Share ${token.shareLinkId}`,
      scheme: 'share',
      shareType: token.shareType,
      sharePatientId: token.sharePatientId,
    };
    datadogRum.setUser(datadogUser);
    datadogLogs.setUser(datadogUser);

    return true;
  }

  public async login(linkId: string, password: string | null, hipaaConsentAccepted: boolean) {
    const response = await this.manager.apiClient.studyShare.authenticate(linkId, password, hipaaConsentAccepted);

    if (!response.success) return response;

    sessionStorage.setItem(SHARE_TOKEN_SESSION_STORAGE_KEY, response.accessToken);

    const shareToken = this.parseShareJwt(response.accessToken);

    this.hub.parsedShareToken.emit(shareToken);
    this.setActiveScheme();
    this.manager.setActiveScheme(AuthenticationScheme.SHARE);

    return response;
  }

  public logout() {
    datadogRum.clearUser();
    datadogLogs.clearUser();
    datadogRum.clearGlobalContext();
    datadogLogs.clearGlobalContext();
    datadogRum.stopSession();

    sessionStorage.removeItem(SHARE_TOKEN_SESSION_STORAGE_KEY);
    this.hub.parsedShareToken.emit(null);
    this.manager.setActiveSchemeAnonymous();
  }

  private async shareTokenGetter() {
    const accessToken = sessionStorage.getItem(SHARE_TOKEN_SESSION_STORAGE_KEY);

    if (accessToken == null) {
      throw new AnonymousUserError('Could not find share JWT access token in session storage.');
    }

    return accessToken;
  }

  public parseShareJwt(shareToken: string): ParsedShareAccessToken {
    const {
      sub,
      nbf,
      exp,
      iat,
      'share-link-id': shareLinkId,
      'share-type': shareType,
      'share-patient-id': sharePatientId,
      'share-message': message,
      ...restParsed
    } = parseJwt<ShareAccessToken>(shareToken);

    const subject = parseInt(sub, 10);
    const notBeforeTime = dayjs.utc(nbf * 1000);
    const expiration = dayjs.utc(exp * 1000);
    const issuedAtTime = dayjs.utc(iat * 1000);

    return {
      ...restParsed,
      shareLinkId,
      shareType,
      sharePatientId,
      message: message ?? null,
      sub: subject,
      nbf: notBeforeTime,
      exp: expiration,
      iat: issuedAtTime,
    };
  }
}
