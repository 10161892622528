import { datadogLogs } from '@datadog/browser-logs';

import { EventStream, IEventStreamConsumer } from 'core/utils';

import { CompressCompleteEvent, CompressErrorEvent, FileUploadContext, ProcessJobEvent, UploadLockRequest, UploadLockRequestType } from '../types';
import { PriorityLock } from './PriorityLock';

export class FileCompressor {
  static [Symbol.toStringTag]() {
    return 'FileCompressor';
  }

  private _uploadSessionId: string | null = null;

  private _streams = {
    onComplete: new EventStream<ProcessJobEvent<CompressCompleteEvent>>(),
    onError: new EventStream<CompressErrorEvent>(),
  };

  public get streams(): {
    onComplete: IEventStreamConsumer<ProcessJobEvent<CompressCompleteEvent>>;
    onError: IEventStreamConsumer<CompressErrorEvent>;
  } {
    return this._streams;
  }

  constructor() {
    this.initialize = this.initialize.bind(this);
    this.reset = this.reset.bind(this);
    this.process = this.process.bind(this);
    this.compressFile = this.compressFile.bind(this);
  }

  public initialize(uploadSessionId: string) {
    if (this._uploadSessionId != null) throw new Error('FileCompressor has already been initialized.  Call reset() before initializing again.');

    this._uploadSessionId = uploadSessionId;
  }

  public reset() {
    if (this._uploadSessionId == null) return;

    this._uploadSessionId = null;
  }

  public process(file: Readonly<FileUploadContext>, lock: PriorityLock<UploadLockRequest>) {
    if (file.classification !== 'dicom' || file.dicomData == null) {
      this._streams.onComplete.emit({ result: 'skipped', fileId: file.fileId });
      return;
    }

    this.compressFile(file.fileId, file.file, lock);
  }

  private async compressFile(fileId: string, file: File, lock: PriorityLock<UploadLockRequest>) {
    let releaseLock: (() => void) | undefined;

    try {
      releaseLock = await lock.requestLock({ type: UploadLockRequestType.CompressFile });

      let endMark: number | null = null;
      let measure: PerformanceMeasure | null = null;
      let success = false;

      const startMark = performance.now();

      try {
        // use compression stream api
        const stream = file.stream();
        const compressedStream = stream.pipeThrough<Uint8Array>(new CompressionStream('deflate'));
        const result = await new Response(compressedStream).arrayBuffer();

        endMark = performance.now();

        success = true;

        measure = performance.measure(`compress-file:${fileId}`, {
          detail: {
            fileId,
            fileName: file.name,
            fileSize: file.size,
            compressedSize: result.byteLength,
            devtools: {
              dataType: 'track-entry',
              trackGroup: 'Upload Pipeline',
              track: 'Compression',
              color: 'primary',
              properties: [
                ['fileId', fileId],
                ['fileName', file.name],
                ['fileSize', file.size],
                ['compressedSize', result.byteLength],
              ],
            },
          },
          start: startMark,
          end: endMark,
        });

        datadogLogs.logger.info('compress-file', {
          uploadSessionId: this._uploadSessionId,
          fileId,
          date: performance.timeOrigin + startMark,
          end_date: performance.timeOrigin + startMark + measure.duration,
          duration: measure.duration * 1000000, // Datadog expects durations to be in nanoseconds.
          compressedSize: result.byteLength,
          success,
        });

        this._streams.onComplete.emit({
          result: 'processed',
          fileId,
          fileName: file.name,
          buffer: result,
          compressedSize: result.byteLength,
          fileSize: file.size,
          startTime: performance.timeOrigin + startMark,
          duration: measure.duration,
        });
      } catch (error) {
        if (endMark == null) {
          endMark = performance.now();
        }

        if (measure == null) {
          measure = performance.measure(`compress-file:${fileId}`, {
            detail: { fileId, fileName: file.name, fileSize: file.size },
            start: startMark,
            end: endMark,
          });
        }

        this._streams.onError.emit({
          fileId,
          fileName: file.name,
          error,
        });
      }
    } finally {
      releaseLock?.();
    }
  }
}
