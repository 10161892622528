import { memo, useEffect, useMemo, useState } from 'react';

import { SettingModel } from 'models';

import { apiClient } from 'core/api/globals';
import { useEvent } from 'core/hooks';

import { AuthenticationScheme, useAuthentication } from 'features/auth';
import { useSessionLocation } from 'features/location';

import { UserSettingsContext } from '../contexts';
import { UserSettingsContextType, UserSettingsProviderProps } from '../types';

export const UserSettingsProvider = memo<UserSettingsProviderProps>(({ children }) => {
  const [userSettings, setUserSettings] = useState<SettingModel[] | null>(null);
  const { sessionLocation, sessionLocationOptions } = useSessionLocation();

  const { activeScheme } = useAuthentication();

  const refreshSettings = useEvent(async () => {
    // Don't try and fetch settings until after required dependencies are initialized.
    if (activeScheme == null || sessionLocationOptions == null) {
      setUserSettings(null);
      return;
    }

    const newSettings = await apiClient.settingsClient.getSettings(activeScheme === AuthenticationScheme.OIDC, null, null, sessionLocation?.id ?? null);

    setUserSettings(newSettings);
  });

  const context: UserSettingsContextType = useMemo(
    () =>
      userSettings == null
        ? {
            isInitialized: false,
            userSettings: null,
            refreshSettings,
          }
        : {
            isInitialized: true,
            userSettings,
            refreshSettings,
          },
    [refreshSettings, userSettings],
  );

  useEffect(() => {
    refreshSettings();
  }, [refreshSettings, activeScheme, sessionLocationOptions]);

  return <UserSettingsContext.Provider value={context}>{children}</UserSettingsContext.Provider>;
});

UserSettingsProvider.displayName = 'UserSettingsProvider';
