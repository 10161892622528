import { FileModel } from 'models';

/**
 * Helper class for safely working with file type properties
 */
export class FileTypeHelper {
  /**
   * Safely gets the file type in uppercase format or returns empty string if undefined
   */
  static getUppercaseFileType(file: FileModel | undefined | null): string {
    return file?.fileType?.toUpperCase() ?? '';
  }

  /**
   * Checks if the file's type matches any of the provided types (case insensitive)
   */
  static fileTypeMatches(file: FileModel | undefined | null, types: string[]): boolean {
    if (!file?.fileType) return false;

    const fileType = file.fileType.toUpperCase();
    return types.some((type) => fileType === type.toUpperCase());
  }

  /**
   * Checks if the file's type is NOT one of the provided types (case insensitive)
   */
  static fileTypeNotMatches(file: FileModel | undefined | null, types: string[]): boolean {
    if (!file?.fileType) return false;

    const fileType = file.fileType.toUpperCase();
    return !types.some((type) => fileType === type.toUpperCase());
  }
}
