import { ReactNode, memo } from 'react';

import cn from 'classnames';
import styled from 'styled-components';

import { PAGE_GRID_CLASSES } from 'core/ui';

import { ConnectionStatus } from './ConnectionStatus';

export const Footer = memo<{ className?: string; children?: ReactNode }>(({ className, children }) => {
  return (
    <StyledFooter className={cn(PAGE_GRID_CLASSES.Flush, className)}>
      <StyledConnectionStatusDiv>
        <ConnectionStatus />
      </StyledConnectionStatusDiv>

      {children}
    </StyledFooter>
  );
});

Footer.displayName = 'Footer';

const StyledFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: ${({ theme }) => theme.space.spacing40} 0;
  background-color: ${({ theme }) => theme.colors.palette.white};
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.1);
  padding: ${({ theme }) => theme.space.spacing20};
  border-top: 1px solid #d9d9d9;
  min-height: 47px;
  row-gap: ${({ theme }) => theme.space.spacing20};
  column-gap: ${({ theme }) => theme.space.spacing20};
`;

const StyledConnectionStatusDiv = styled.div`
  flex: 1 0 min-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
