import { memo } from 'react';

import styled from 'styled-components';

import { PatientUtils } from '../services';
import { PatientDobLabelProps } from '../types';

export const PatientDobLabel = memo<PatientDobLabelProps>(({ className, dob, showAge }) => {
  return (
    <span className={className}>
      <span className="dob">{PatientUtils.formatDob(dob)}</span>
      {showAge && <StyledAgeSpan className="age">{dob && `(${PatientUtils.calculateAgeFromDob(dob)})`}</StyledAgeSpan>}
    </span>
  );
});

PatientDobLabel.displayName = 'PatientDobLabel';

const StyledAgeSpan = styled.span`
  margin-left: ${({ theme }) => theme.space.spacing20};
`;
