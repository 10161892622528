import { ExamModel, FileModel } from 'models';
import { ShareStudyViewer as OriginalShareStudyViewer } from 'models/ShareStudyViewer';

/**
 * Type alias for the ShareStudyViewer from models to avoid imports confusion
 */
export type ShareStudyViewer = OriginalShareStudyViewer;

/**
 * Utility function to adapt ShareStudyViewer to ExamModel interface
 */
export function adaptToExamModel(shareStudyViewer: ShareStudyViewer): ExamModel {
  return {
    // Map examId to id for ExamModel compatibility
    id: shareStudyViewer.examId,
    // Make sure description is never null for ExamModel
    description: shareStudyViewer.description || '',
    // Copy over other shared properties
    studyDate: shareStudyViewer.studyDate,
    files: shareStudyViewer.files,
    // Add any other required ExamModel properties with defaults as needed
  } as ExamModel;
}
