import { MouseEvent, memo, useState } from 'react';

import { useEvent } from 'core/hooks';
import { Button } from 'core/ui/Button/Button';
import { FilePrompt } from 'core/ui/FilePrompt/FilePrompt';
import { FilePromptHandle } from 'core/ui/FilePrompt/FilePromptHandle';

import { FilePromptButtonProps } from './FilePromptButtonProps';

export const FilePromptButton = memo<FilePromptButtonProps>(({ multiple, directory, accept, children, onFilesSelected, onClick, ...buttonProps }) => {
  const [filePromptEle, setFilePromptEle] = useState<FilePromptHandle | null>(null);

  const handleButtonClick = useEvent((event: MouseEvent<HTMLButtonElement>) => {
    onClick?.(event);

    // If the event was prevented, don't open the file prompt.
    if (event.defaultPrevented) {
      return;
    }

    filePromptEle?.open();
  });

  return (
    <>
      <Button {...buttonProps} onClick={handleButtonClick}>
        {children}
      </Button>
      <FilePrompt ref={setFilePromptEle} multiple={multiple} directory={directory} accept={accept} onFilesSelected={onFilesSelected} />
    </>
  );
});

FilePromptButton.displayName = 'FilePromptButton';
