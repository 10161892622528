import { datadogLogs } from '@datadog/browser-logs';

import { DataStreamMap, IDataStreamMapConsumer } from 'core/utils';

import { IMAGE_DATA_CACHE_DEFAULT_MAX_SIZE } from '../constants';

export class ImageDataCache {
  static [Symbol.toStringTag]() {
    return 'ImageDataCache';
  }

  private _maxSize;
  private _size = 0;

  private _uploadSessionId: string | null = null;

  private _streams = {
    bitmaps: new DataStreamMap<string, ImageData | null>(),
  };

  public get streams(): {
    bitmaps: IDataStreamMapConsumer<string, ImageData | null>;
  } {
    return this._streams;
  }

  /** Create a new ImageData cache.
   * @param maxSize Maximum size of the cache in bytes.  Default is {@link IMAGE_DATA_CACHE_DEFAULT_MAX_SIZE}.
   */
  constructor(maxSize = IMAGE_DATA_CACHE_DEFAULT_MAX_SIZE) {
    this.initialize = this.initialize.bind(this);
    this.reset = this.reset.bind(this);
    this.get = this.get.bind(this);
    this.set = this.set.bind(this);
    this.getImageCount = this.getImageCount.bind(this);

    this._maxSize = maxSize;
  }

  public initialize(uploadSessionId: string) {
    // Treat the cache as already initialized and leave it unchanged if it has already been initialized.
    if (this._uploadSessionId != null) throw new Error('ImageDataCache has already been initialized.  Call reset() before initializing again.');

    this._uploadSessionId = uploadSessionId;

    datadogLogs.logger.info('image-data-cache_initialize', {
      uploadSessionId,
      imageCount: 0,
      cacheSize: 0,
      maxCacheSize: this._maxSize,
    });
  }

  public reset() {
    // Treat a null uploadSessionId as indicitative of the cache not being initialized or having been already reset, and therefore we do not need to do anything.
    if (this._uploadSessionId == null) return;

    const previousCacheSize = this._size;
    const previousImageCount = this.getImageCount();
    const previousUploadSessionId = this._uploadSessionId;

    this._uploadSessionId = null;
    this._streams.bitmaps.clear();
    this._size = 0;

    datadogLogs.logger.info('image-data-cache_reset', {
      uploadSessionId: previousUploadSessionId,
      previousImageCount,
      previousCacheSize,
      imageCount: 0,
      cacheSize: 0,
      maxCacheSize: this._maxSize,
    });
  }

  public get(key: string) {
    return this._streams.bitmaps.getCurrentValue(key, false) ?? null;
  }

  /** Add or remove an image from the cache.
   * @returns `true` if the image was successfully added to the cache, or `false` if the cache is full and the image could not be added.
   */
  public set(key: string, image: ImageData | null) {
    if (this._uploadSessionId == null) throw new Error('ImageDataCache has not been initialized.');

    const imageSize = image?.data?.byteLength ?? 0;
    const previousImage = this._streams.bitmaps.getCurrentValue(key, false);
    const previousImageSize = previousImage?.data?.byteLength ?? 0;
    const previousCacheSize = this._size;
    const newCacheSize = previousCacheSize - (previousImage?.data?.byteLength ?? 0) + (image?.data?.byteLength ?? 0);

    if (newCacheSize > this._maxSize) {
      // This is being logged as an error out of an abundance of caution.  It's probably not a critical error if the cache is full, but we do need
      // to understand when and why it's happening in case it's indicative of a larger problem.
      datadogLogs.logger.error(
        `image-data-cache_set-overflow`,
        {
          uploadSessionId: this._uploadSessionId,
          imageKey: key,
          imageSize,
          imageCount: this.getImageCount(),
          previousImageSize,
          cacheSize: previousCacheSize,
          failedCacheSize: newCacheSize,
          maxCacheSize: this._maxSize,
        },
        new Error('Image data cache maximum size exceeded.'), // Adds a stack trace to the log entry.
      );

      return false;
    }

    this._size = newCacheSize;
    this._streams.bitmaps.emit(key, image);

    datadogLogs.logger.info('image-data-cache_set', {
      uploadSessionId: this._uploadSessionId,
      imageKey: key,
      imageSize,
      imageCount: this.getImageCount(),
      previousImageSize,
      previousCacheSize,
      cacheSize: newCacheSize,
      maxCacheSize: this._maxSize,
    });

    return true;
  }

  /** Calculate the number of non-null bitmaps in the cache. */
  private getImageCount() {
    return Array.from(this._streams.bitmaps.getEntries()).reduce((acc, [_key, value]) => acc + (value.getCurrentValue() == null ? 0 : 1), 0);
  }
}
