import { CompositeFilterDescriptor, DataSourceRequestState } from '@progress/kendo-data-query';

import { Biopsy, FileModel, Laterality, SlidePreparation } from 'models';

import { DropdownOption } from 'core/forms';
import { ColumnState } from 'core/ui';

import { DocumentCategories } from '../exam/constants';
import { ShareExpirationOption, UploadFileCategory, UploadFileModalFormValues } from './types';
import { PatientFormValues } from './types/PatientFormValues';
import { ShareExpirationType } from './types/ShareExpirationType';

export const PatientStatus = {
  PENDING: 'patient-pending',
  ADDED: 'patient-created',
  UPDATED: 'patient-updated',
  FETCHED: 'patient-fetched',
  ERROR: 'patient-error',
};

export const PatientGenders: DropdownOption[] = [
  {
    name: 'Unknown',
    value: '',
  },
  {
    name: 'Male',
    value: 'M',
  },
  {
    name: 'Female',
    value: 'F',
  },
];

export const InitialValues: {
  donorForm: PatientFormValues;
} = {
  donorForm: {
    id: 0,
    firstName: '',
    lastName: '',
    dob: null,
    gender: null,
    patientNumber: '',
    unosID: '',
    locationType: '',
    active: true,
    caseID: '',
    height: null,
    weight: null,
    crossClampDateTime: null,
    notes: null,
    hospital: null,
    ageRange: null,
    location: null,
    isUnosUnavailable: false,
    location_Id: null,
  },
};

export const DEFAULT_FILTER: CompositeFilterDescriptor = {
  logic: 'and',
  filters: [
    {
      field: 'active',
      operator: 'eq',
      value: true,
    },
  ],
};
export const DEFAULT_FILTER_DATA_STATE: DataSourceRequestState = {
  filter: DEFAULT_FILTER,
  skip: 0,
  sort: [{ field: 'id', dir: 'desc' }],
  take: 30,
};

export const DEFAULT_LOCATION_ID = 0;

export const DEFAULT_LOCATION = {
  value: {
    id: DEFAULT_LOCATION_ID,
    name: 'All Locations',
  },
};

export const SHARE_EXPIRATION_OPTIONS: ShareExpirationOption[] = [
  { name: 'Never', value: ShareExpirationType.Never },
  { name: '1 Day', value: ShareExpirationType.OneDay },
  { name: '7 Days', value: ShareExpirationType.SevenDays },
  { name: '30 Days', value: ShareExpirationType.ThirtyDays },
  { name: '60 Days', value: ShareExpirationType.SixtyDays },
  { name: '90 Days', value: ShareExpirationType.NinetyDays },
  { name: '1 Year', value: ShareExpirationType.OneYear },
];

export enum ShareType {
  Link = 'LINK',
  Email = 'EMAIL',
}

export const DEFAULT_FINAL_REPORT_VALUES: FileModel = {
  id: 0,
  created: '',
  modified: null,
  url: null,
  thumbnailUrl: null,
  fileType: '',
  fileName: '',
  file: null,
  categoryId: null,
  examId: null,
  user: null,
  location: '',
  thumbnailLocation: null,
  patient_id: null,
  index: null,
  fileId: null,
  fileSize: null,
  externalSource: null,
  source: null,
  viewerId: null,
  subFiles: [],
};

export const UPLOAD_FILE_MODAL_CATEGORY_OPTIONS: DropdownOption<UploadFileCategory>[] = [
  { name: DocumentCategories.EKG.name, value: DocumentCategories.EKG.shorthand as UploadFileCategory },
  { name: DocumentCategories.Pathology.name, value: DocumentCategories.Pathology.shorthand as UploadFileCategory },
  { name: DocumentCategories.FINAL_REPORT.name, value: DocumentCategories.FINAL_REPORT.shorthand as UploadFileCategory },
  { name: DocumentCategories.Ventilator.name, value: DocumentCategories.Ventilator.shorthand as UploadFileCategory },
  { name: DocumentCategories.Monitoring.name, value: DocumentCategories.Monitoring.shorthand as UploadFileCategory },
  { name: DocumentCategories.DOCUMENT.name, value: DocumentCategories.DOCUMENT.shorthand as UploadFileCategory },
  { name: DocumentCategories.Link.name, value: DocumentCategories.Link.shorthand as UploadFileCategory },
  { name: DocumentCategories.ChestXRay.name, value: DocumentCategories.ChestXRay.shorthand as UploadFileCategory },
  { name: DocumentCategories.CT.name, value: DocumentCategories.CT.shorthand as UploadFileCategory },
  { name: DocumentCategories.EMR.name, value: DocumentCategories.EMR.shorthand as UploadFileCategory },
];

export const UPLOAD_FILE_MODAL_PATHOLOGY_LATERALITY_OPTIONS: DropdownOption<Laterality>[] = [
  { name: 'Left', value: Laterality.Left },
  { name: 'Right', value: Laterality.Right },
  { name: 'N/A', value: Laterality.NA },
];

export const UPLOAD_FILE_MODAL_PATHOLOGY_BIOPSY_TYPE_OPTIONS: DropdownOption<Biopsy>[] = [
  { name: 'Core Needle', value: Biopsy.CoreNeedle },
  { name: 'Wedge', value: Biopsy.Wedge },
];

export const UPLOAD_FILE_MODAL_PATHOLOGY_SLIDE_PREPARATION_OPTIONS: DropdownOption<SlidePreparation>[] = [
  { name: 'Frozen', value: SlidePreparation.Frozen },
  { name: 'Permanent', value: SlidePreparation.Permanent },
  { name: 'Rapid Permanent', value: SlidePreparation.RapidPermanent },
];

export const UPLOAD_FILE_MODAL_DEFAULT_FORM_VALUES: UploadFileModalFormValues = {
  category: UPLOAD_FILE_MODAL_CATEGORY_OPTIONS[0],
  files: [],
  notes: '',
  organ: null,
  laterality: null,
  biopsyType: null,
  slidePreparation: null,
  linkUrl: '',
};

export const DEFAULT_GRID_COLUMNS_STATE: Record<string, ColumnState> = {
  // Note that the orderIndex is going to be offset by 2 because the first two columns are the checkbox and the action buttons.
  id: { show: false, orderIndex: 0 },
  unosID: { show: true, orderIndex: 1 },
  caseID: { show: true, orderIndex: 2 },
  patientNumber: { show: true, orderIndex: 3 },
  lastName: { show: true, orderIndex: 4 },
  firstName: { show: true, orderIndex: 5 },
  dob: { show: true, orderIndex: 6 },
  age: { show: true, orderIndex: 7 },
  gender: { show: true, orderIndex: 8 },
  dateCreated: { show: true, orderIndex: 9 },
  hospital: { show: true, orderIndex: 10 },
  notes: { show: true, orderIndex: 11 },
};
