import { FunctionComponent, memo, useId, useState } from 'react';

import { faSpinner } from '@fortawesome/pro-solid-svg-icons';

import { PatientAgeRangeModel, PatientModel } from 'models';

import { SubmitHandler } from 'core/forms';
import { Button, Icon, Window, WindowActionsBar } from 'core/ui';

import { PatientFormContent } from 'features/patient/fragments';
import { PatientEditService } from 'features/patient/services';
import { PatientFormValues } from 'features/patient/types';

import { ExamForm } from '../../exam/fragments/ExamForm';

export const PatientEditWindow: FunctionComponent<{
  isOpo: boolean | undefined;
  ageRanges: PatientAgeRangeModel[] | undefined;
  handleSubmit: SubmitHandler<PatientFormValues> | undefined;
  patient?: PatientModel | undefined | null;
  show: boolean;
  onClosed: () => void;
  isRequestMode?: boolean;
  showRequiredError?: boolean;
  isEditMode?: boolean;
  isSubmitting?: boolean;
}> = memo(({ isOpo, isRequestMode, ageRanges, onClosed, handleSubmit, patient, show, showRequiredError, isEditMode = true, isSubmitting }) => {
  const formId = `ExamForm__${useId()}`;
  const patientVerbiage = isOpo ? 'Donor' : 'Patient';
  const submitButtonText = () => {
    if (isRequestMode) {
      return `Update ${patientVerbiage} and Request Read`;
    }

    let buttonText = 'Create';
    if (isEditMode) {
      buttonText = `Save ${patientVerbiage}`;
    } else {
      buttonText = `Create ${patientVerbiage}`;
    }
    return buttonText;
  };

  return (
    <>
      {show && (
        <Window modal title={`${isEditMode ? 'Edit' : 'Create'} ${isOpo ? 'Donor' : 'Patient'}`} onClose={onClosed} initialWidth={725}>
          <PatientFormContent
            ageRanges={ageRanges}
            isEditMode={isEditMode}
            isFormDisabled={false}
            onSubmit={handleSubmit}
            initialValues={PatientEditService.copyModelToForm(patient ?? ({} as PatientModel))}
            isOpo={isOpo}
            isRequestMode={isRequestMode}
            showRequiredError={showRequiredError}
            isSubmitting={isSubmitting}
            formId={formId}
          ></PatientFormContent>
          <WindowActionsBar>
            <Button form={formId} type="submit" disabled={isSubmitting} title="Save">
              {isSubmitting && <Icon icon={faSpinner} spin={isSubmitting}></Icon>}
              {submitButtonText()}
            </Button>
          </WindowActionsBar>
        </Window>
      )}
    </>
  );
});

PatientEditWindow.displayName = 'PatientEditWindow';
