import { DataSourceRequestState, toDataSourceRequestString } from '@progress/kendo-data-query';

import { UploadModel } from 'models';

import { ApiRouteService } from '../services';
import { DataResult } from '../types';
import { HttpClient } from './HttpClient';

export class UploadTrackerClient {
  constructor(private httpClient: HttpClient) {
    this.getAllForKendoGrid = this.getAllForKendoGrid.bind(this);
    this.downloadFile = this.downloadFile.bind(this);
    this.downloadUploadSessionZip = this.downloadUploadSessionZip.bind(this);
    this.exportForKendoGrid = this.exportForKendoGrid.bind(this);
    this.bulkAdd = this.bulkAdd.bind(this);
    this.bulkUpdate = this.bulkUpdate.bind(this);
  }

  public async getAllForKendoGrid(dataState: DataSourceRequestState, signal?: AbortSignal | null) {
    const options = await this.httpClient.createStandardOptions('GET', null, { signal });
    const queryStr = toDataSourceRequestString(dataState);
    const result = await this.httpClient.fetchAndParse<DataResult<UploadModel>>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/upload-tracker/grid?${queryStr}`, options),
    );
    return result;
  }

  public async downloadFile(studyInstanceUID: string, signal?: AbortSignal | null) {
    const options = await this.httpClient.createStandardOptions('GET', null, { signal });
    const url = `${ApiRouteService.getCompumedApiBaseRoute()}/api/upload-tracker/${encodeURIComponent(studyInstanceUID)}`;
    const result = await this.httpClient.fetchBlob(fetch(url, options));
    return result;
  }

  public async downloadUploadSessionZip(uploadSessionId: string) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const result = await this.httpClient.fetchBlob(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/upload-tracker/DownloadUploadSessionZip/${encodeURIComponent(uploadSessionId)}`, options),
    );
    return result;
  }

  public async exportForKendoGrid(dataState: DataSourceRequestState) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const queryStr = toDataSourceRequestString(dataState);
    const url = `${ApiRouteService.getCompumedApiBaseRoute()}/api/upload-tracker/grid/export?${queryStr}`;
    const result = await this.httpClient.fetchBlob(fetch(url, options));

    return result;
  }

  public async bulkAdd(uploads: UploadModel[], authMode: 'msal-required' | 'share-required') {
    const options = await this.httpClient.createStandardOptions('POST', uploads, { authMode });
    const result = await this.httpClient.fetchAndParse<UploadModel[]>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/upload-tracker/BulkAdd`, options),
    );
    return result;
  }

  public async bulkUpdate(uploads: UploadModel[], authMode: 'msal-required' | 'share-required') {
    const options = await this.httpClient.createStandardOptions('POST', uploads, { authMode });
    const result = await this.httpClient.fetchAndParse<UploadModel[]>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/upload-tracker/BulkUpdate`, options),
    );
    return result;
  }
}
