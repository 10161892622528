import { memo } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import * as z from 'zod';

import { useEvent } from 'core/hooks';
import { Button, FilePromptButton, Page, PageHeader, TextAreaField } from 'core/ui';

import { Classification } from 'features/upload-pipeline';
import { useUploadPipeline } from 'features/upload-pipeline/hooks/useUploadPipeline';

export const UploadAlternativePage = memo(() => {
  const uploadPipeline = useUploadPipeline();

  const rhfContext = useForm({
    defaultValues: { notes: '' },
    resolver: zodResolver(FormSchema),
  });

  const handleFormSubmit: SubmitHandler<FormValuesType> = useEvent((data) => {
    console.log('Form', data);
  });

  const handleFilesSelected = useEvent((files: File[]) => {
    console.log('handleFilesSelected', files);
    uploadPipeline.addFiles(
      files.map((file) => ({
        fileId: crypto.randomUUID(),
        file,
        classification: Classification.Unknown,
        metadata: null,
      })),
    );
  });

  return (
    <Page>
      <PageHeader title="Alternative Upload" />
      <FormProvider {...rhfContext}>
        <div>
          <p>
            If a full scan does not resolve the problem or other issues are occurring, you can send us the exam files for analysis to help improve the uploader.
          </p>
          <StyledForm
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="none"
            spellCheck="false"
            noValidate
            onSubmit={rhfContext.handleSubmit(handleFormSubmit)}
          >
            <FilePromptButton onFilesSelected={handleFilesSelected} directory>
              Select Folder
            </FilePromptButton>

            <TextAreaField name="notes" label="Please provide any information that may help troubleshoot the issue." />

            <Button type="submit">Upload</Button>
          </StyledForm>
        </div>
      </FormProvider>
    </Page>
  );
});

UploadAlternativePage.displayName = 'UploadAlternativePage';

const FormSchema = z.object({
  notes: z.string().nullish(),
});

type FormValuesType = z.infer<typeof FormSchema>;

const StyledForm = styled.form``;
