import React from 'react';

import styled from 'styled-components';

interface OnePageProps {
  children: React.ReactNode;
}

const Container = styled.div`
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  padding: 20px;
  background: white;
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
`;

export const OnePage: React.FC<OnePageProps> = ({ children }) => {
  return <Container>{children}</Container>;
};

export default OnePage;
