import { memo } from 'react';

import { Route, Routes } from 'react-router-dom';

import { ApiWebSocketProvider } from 'features/api/fragments/ApiWebSocketProvider';
import { AuthenticationScheme, RouteGuard } from 'features/auth';
import { UploadPipelineProvider } from 'features/upload-pipeline/fragments/UploadPipelineProvider';

import { ShareRoutes } from '../routes';
import { MobileFilters } from './MobileFilters';
import { OnePage } from './OnePage';
import { ShareDefaultAuthorizationChallenge } from './ShareDefaultAuthorizationChallenge';
import { ShareFileViewerPage } from './ShareFileViewerPage';
import { ShareHome } from './ShareHome';
import { ShareInit } from './ShareInit';
import { ShareLanding } from './ShareLanding';
import { ShareLandingAuthorizationChallenge } from './ShareLandingAuthorizationChallenge';
import { Upload } from './Upload';
import { Viewer } from './Viewer';

const LANDING_ALLOWED_SCHEMES = [AuthenticationScheme.ANONYMOUS, AuthenticationScheme.OIDC];

export const Share = memo(() => {
  return (
    <Routes>
      <Route
        path={ShareRoutes.HOME}
        element={
          <RouteGuard
            allowedSchemes={AuthenticationScheme.SHARE}
            validateShareLinkId
            challengeComponent={ShareDefaultAuthorizationChallenge}
            initComponent={ShareInit}
          >
            <ShareHome />
          </RouteGuard>
        }
      />
      <Route
        path={ShareRoutes.ONEPAGE}
        element={
          <RouteGuard
            allowedSchemes={AuthenticationScheme.SHARE}
            validateShareLinkId
            challengeComponent={ShareDefaultAuthorizationChallenge}
            initComponent={ShareInit}
          >
            <OnePage>
              <div>One Page View Content</div>
            </OnePage>
          </RouteGuard>
        }
      />
      <Route
        path={ShareRoutes.FILE_VIEWER}
        element={
          <RouteGuard allowedSchemes={AuthenticationScheme.SHARE} validateShareLinkId challengeComponent={ShareDefaultAuthorizationChallenge}>
            <ShareFileViewerPage />
          </RouteGuard>
        }
      />
      <Route
        path={ShareRoutes.LANDING}
        element={
          <RouteGuard allowedSchemes={LANDING_ALLOWED_SCHEMES} validateShareLinkId challengeComponent={ShareLandingAuthorizationChallenge}>
            <ShareLanding />
          </RouteGuard>
        }
      />
      <Route
        path={ShareRoutes.VIEWER}
        element={
          <RouteGuard allowedSchemes={AuthenticationScheme.SHARE} validateShareLinkId challengeComponent={ShareDefaultAuthorizationChallenge}>
            <Viewer />
          </RouteGuard>
        }
      >
        <Route path="filters" element={<MobileFilters />} />
      </Route>
      <Route
        path={ShareRoutes.UPLOAD}
        element={
          <RouteGuard allowedSchemes={AuthenticationScheme.SHARE} validateShareLinkId challengeComponent={ShareDefaultAuthorizationChallenge}>
            <ApiWebSocketProvider>
              <UploadPipelineProvider>
                <Upload />
              </UploadPipelineProvider>
            </ApiWebSocketProvider>
          </RouteGuard>
        }
      />
    </Routes>
  );
});

Share.displayName = 'Share';
