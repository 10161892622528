import { FunctionComponent, MouseEvent } from 'react';

import { PatientModel } from 'models';

import { FormStates } from 'core/forms';
import { Accordion } from 'core/ui';

import PatientIcon from '../assets/patient.svg?react';
import { PatientForm } from './PatientForm';
import { PatientFormHeader } from './PatientFormHeader';

interface PatientFormAccordionProps {
  className?: string;
  eventKey: string;
  formState?: FormStates;
  handleHeaderClick?: () => void; // Changed this to match what Accordion.Header expects
  isCollapsed?: boolean;
  patient: PatientModel | null;
  searchText: string;
  setCurrentPatient: (patient: PatientModel | null) => void;
  setFormState: (formState: FormStates) => void;
  setSearchText: (searchText: string) => void;
  locationId: number | null;
}

const PatientFormAccordion: FunctionComponent<PatientFormAccordionProps> = ({
  className = '',
  eventKey,
  formState = FormStates.DISABLED,
  handleHeaderClick,
  isCollapsed = false,
  patient,
  searchText,
  setCurrentPatient,
  setFormState,
  setSearchText,
  locationId,
}) => {
  return (
    <Accordion.Item className={className} eventKey={eventKey}>
      <Accordion.Header Icon={PatientIcon} title="Patient" onClick={handleHeaderClick}>
        <PatientFormHeader isCollapsed={isCollapsed} onClick={(event) => event.stopPropagation()} patient={patient} patientFormState={formState} />
      </Accordion.Header>
      <Accordion.Body>
        <PatientForm
          formState={formState}
          patient={patient}
          searchText={searchText}
          setCurrentPatient={setCurrentPatient}
          setFormState={setFormState}
          setSearchText={setSearchText}
          locationId={locationId}
        />
      </Accordion.Body>
    </Accordion.Item>
  );
};

export { PatientFormAccordion };
