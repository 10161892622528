import { useContext } from 'react';

import { UploadViewContext } from '../contexts';

export function useUploadView() {
  const uploadView = useContext(UploadViewContext);

  if (uploadView == null) {
    throw new Error('useUploadView must be used within an UploadViewProvider');
  }

  return uploadView;
}
