import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { SvgIcon } from '@progress/kendo-react-common';
import { homeIcon } from '@progress/kendo-svg-icons';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { ExamModel, FileModel, FormFieldModel, FormModel, PatientModel, TagModel } from 'models';

import { FormStates } from 'core/forms';
import { NotificationsService } from 'core/notifications';
import { Breadcrumb, CollapsibleSidebar } from 'core/ui';
import { hasText } from 'core/utils';

import { useApiClient, useQueryFilesByExamId } from 'features/api';
import { UserRoles, useCurrentUser } from 'features/auth';
import { getServiceDetailsFromForm } from 'features/dynamic-forms/services/service-details-service';
import { DocumentCategories } from 'features/exam/constants';

import { FileSelectSidebar, FileViewer } from '../../file';
import { SelectPatientModal, usePatientSelectSearch } from '../../patient';
import { validatePatient } from '../../patient/utils';
import { ExamApiStatus } from '../constants';
import { SubmitParams } from '../types';
import { ExamProcessingHeader } from './ExamProcessingHeader';
import { ExamProcessingSidebarBody } from './ExamProcessingSidebarBody';

export const ExamProcessing: React.FC = () => {
  const { id } = useParams();
  const apiClient = useApiClient();

  const [exam, setExam] = useState<ExamModel | undefined>();
  const [patient, setPatient] = useState<PatientModel | undefined>();
  const [status, setStatus] = useState<ExamApiStatus | null>(null);
  const [_accessToken, setAccessToken] = useState('');
  const [locationId, setLocationId] = useState<number | undefined>();
  const [_fileIsUploading, setFileIsUploading] = useState<boolean>(false);
  const [files, setFiles] = useState<FileModel[]>([]);
  const [currentFile, setCurrentFile] = useState<FileModel | null>(null);
  const [patientIdRequired, setPatientIdRequired] = useState<boolean>(false);

  const { currentUser } = useCurrentUser(false);
  const isInternal = useMemo(() => {
    const roles = [UserRoles.ADMIN, UserRoles.SUPPORT];
    return currentUser == null || currentUser === 'anonymous' ? false : roles.includes(currentUser.role ?? '');
  }, [currentUser]);

  // Get access token from API client.
  useEffect(() => {
    (async () => {
      const httpClient = apiClient.httpClient;
      let token = '';
      if (httpClient) {
        token = httpClient.getAccessToken ? await httpClient.getAccessToken() : '';
      }
      setAccessToken(token);
    })();
  }, [apiClient.httpClient]);

  // File management using Tanstack Query
  const examIdNumber = id ? parseInt(id, 10) : 0;
  const { data: fetchedFiles, refetch: _refetchFiles } = useQueryFilesByExamId(examIdNumber);

  // Update files state when fetched files change
  useEffect(() => {
    if (fetchedFiles) {
      setFiles(fetchedFiles);
    }
  }, [fetchedFiles]);

  const [sidebarExpanded, setSidebarExpanded] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Patient selection hook.
  const {
    currentPatient,
    handleCreateNewPatientClick,
    handlePatientSelect,
    handlePatientsSearchDataStateChange,
    patientFormState,
    patientSearchDataState,
    patientSearchText,
    searchPatients,
    setCurrentPatient,
    setPatientFormState,
    setPatientSearchText,
    setShowPatientsDialog,
    showPatientsDialog,
    totalSearchPatients,
  } = usePatientSelectSearch(undefined, locationId, exam?.location?.accountId ?? undefined);

  const isFormInEditMode = id !== undefined;

  // Breadcrumbs now include both Home and Exams.
  const breadcrumbs = useMemo(() => {
    return [
      {
        id: 'home',
        text: 'Home',
        icon: <StyledIcon icon={homeIcon} />,
      },
      {
        id: 'exams',
        text: 'Exams',
        navigateTo: '/exam/',
      },
    ];
  }, []);

  const mapDataToNameValuePair = (data: Record<string, unknown>): FormFieldModel[] =>
    _map(data, (value, key) => ({
      id: parseInt(key, 10),
      categoryId: 0,
      description: '',
      label: key,
      name: key,
      value,
      type: 'string',
      dataField: key,
      active: true,
      field: key as unknown as FormFieldModel,
    }));

  // -----------------------------
  // Tagging Feature Integration
  // -----------------------------
  // State for hierarchical tags.
  const [tags, setTags] = useState<TagModel[]>([]);
  // State for tracking which tag items are checked.
  const [checkedItems, setCheckedItems] = useState<Record<number, boolean>>({});
  // State for the tag search term.
  const [searchTerm, setSearchTerm] = useState('');

  // Recursively flatten the checklist data.
  const flattenChecklist = (items: TagModel[]): TagModel[] =>
    (items || []).reduce((acc: TagModel[], item) => {
      acc.push(item);
      if (item.children) {
        acc.push(...flattenChecklist(item.children));
      }
      return acc;
    }, []);

  // Filter checklist data based on the search term.
  const filterChecklist = (items: TagModel[]): TagModel[] => {
    return (items || [])
      .map((item) => {
        // Safely check if name exists and is a string before calling toLowerCase
        const itemName = item?.name;
        const itemMatches = typeof itemName === 'string' && itemName.toLowerCase().includes(searchTerm.toLowerCase());
        const filteredChildren = item.children ? filterChecklist(item.children) : [];
        if (itemMatches || filteredChildren.length > 0) {
          return { ...item, children: filteredChildren };
        }
        return null;
      })
      .filter((item) => item !== null) as TagModel[];
  };

  // Flatten the tag hierarchy for easier lookup.
  const flatList = flattenChecklist(tags);

  // Callback to handle a tag item change (currently no-op; update as needed).
  const handleTagItemChange = useCallback((event: TagModel, isChecked: boolean) => {
    // Implement tag change handling logic here if needed.
  }, []);

  // Remove a tag from the selected tags.
  const handleRemoveTag = (id: number) => {
    setCheckedItems((prev) => ({ ...prev, [id]: false }));
    const tagItem = flatList.find((item) => item.id === id);
    if (tagItem) {
      handleTagItemChange(tagItem, false);
    }
  };

  // Handle checkbox change for tags.
  const handleCheckboxChange = (id: number, children?: TagModel[]) => {
    setCheckedItems((prev) => {
      const isChecked = !prev[id];
      const updates = { ...prev, [id]: isChecked };

      if (children) {
        children.forEach((child) => {
          updates[child.id] = isChecked;
        });
      }

      const tagItem = flatList.find((item) => item.id === id);
      if (tagItem) {
        handleTagItemChange(tagItem, isChecked);
      }

      return updates;
    });
  };

  // Fetch tags with hierarchy from the API.
  useEffect(() => {
    (async () => {
      if (apiClient.tagsClient?.getLevelTags) {
        const [newTags] = await Promise.all([apiClient.tagsClient.getLevelTags()]);
        setTags(newTags);
      }
    })();
  }, [apiClient.tagsClient]);
  // -----------------------------
  // End Tagging Feature
  // -----------------------------

  useEffect(() => {
    // Get required settings based on location
    if (locationId) {
      apiClient.settingsClient.getSettings(true, null, null, locationId).then((res) => {
        const isRequired = res?.find((item) => item.name === 'PatientIdRequired')?.value === 'true';
        setPatientIdRequired(isRequired);
      });
    }
  }, [apiClient.settingsClient, locationId]);

  const handleSubmit = async ({ values, isValid }: SubmitParams): Promise<void> => {
    if (!isValid) {
      NotificationsService.displayError('Please enter the required fields');
      return;
    }

    // Validate the patient before saving
    if (!currentPatient) {
      NotificationsService.displayError('Please select a patient for the exam');
      return;
    }

    // Check if patient is valid
    const isPatientValid = validatePatient(currentPatient, patientIdRequired);
    if (!isPatientValid) {
      NotificationsService.displayError('Please complete the required patient information');

      // Expand the patient accordion and set it to edit mode
      if (typeof setPatientFormState === 'function') {
        setPatientFormState(FormStates.EDIT);
      }

      // Force focus on patient section by expanding the sidebar if collapsed
      if (!sidebarExpanded) {
        setSidebarExpanded(true);
      }

      return;
    }

    if (!isFormInEditMode && files.length === 0 && !values.imageURL) {
      NotificationsService.displayError('Please upload a file to the exam');
      return;
    }
    setIsSubmitting(true);

    try {
      // Get location id from the values.
      const locationObj = values.location as { id: number } | undefined;

      const examData: ExamModel = {
        ...values,
        files,
        location_Id: locationObj?.id,
        patient_id: currentPatient?.id,
        physician_Id: (values.physician as { id: number })?.id,
        serviceId: (values.service as { id: number })?.id,
        sla: values.sla ? (values.sla as { value: string }).value : values.sla,
      } as ExamModel;

      // Build dynamic form fields.
      const formData = _map(examData.forms, (value, key) => {
        return {
          id: key,
          name: `Form ${key}`,
          description: 'Dynamic Form',
          formFields: [],
          ui: '',
          data: '',
          reportTemplatePath: '',
          generateReport: false,
          autoSendReport: false,
          active: false,
          fields: mapDataToNameValuePair(value),
        };
      }) as FormModel[];

      // Include service details if provided.
      const serviceDetails = getServiceDetailsFromForm(values);
      if (Object.keys(serviceDetails).length > 0) {
        formData.push({
          id: exam?.id ?? 0,
          formFields: mapDataToNameValuePair(serviceDetails),
          ui: '',
          data: '',
          reportTemplatePath: '',
          name: '',
          description: '',
          generateReport: false,
          autoSendReport: false,
          active: false,
        });
      }
      examData.forms = formData;

      // Save or update the exam.
      if (isFormInEditMode && exam) {
        await apiClient.exams.updateExam(examData);
        setStatus(ExamApiStatus.UPDATED);
        NotificationsService.displaySuccess('Exam updated successfully');
        if (window.opener) {
          (window.opener as Window).postMessage({ type: 'examUpdated' }, '*');
        }
      } else {
        await apiClient.exams.saveExam(examData);
        setStatus(ExamApiStatus.ADDED);
      }

      if (id) {
        const examResponse = await apiClient.exams.getExamById(parseInt(id, 10));
        setExam(examResponse);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      NotificationsService.displayError('Failed to save exam');
    } finally {
      setIsSubmitting(false);
    }
  };

  // --- File addition functions ---
  const handleAddNewFile = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.multiple = true;
    fileInput.accept = '.pdf,.xml,.png,.jpg,.tiff,.szi,.svs,.jpeg,.mp4,.atc,.mov';
    fileInput.onchange = handleFileInputChange;
    fileInput.click();
  };

  const handleFileInputChange = async (event: Event) => {
    const input = event.target as HTMLInputElement;
    if (!input.files || input.files.length === 0 || !id) return;

    setFileIsUploading(true);
    try {
      const formData = new FormData();
      for (const file of Array.from(input.files)) {
        formData.append('files', file);
      }

      const uploadedFiles = await apiClient.filesClient.add(
        formData,
        false,
        parseInt(id, 10),
        patient?.id ?? null,
        DocumentCategories.DOCUMENT.value,
        'msal-required',
      );

      if (uploadedFiles && uploadedFiles.length > 0) {
        setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
        setCurrentFile(uploadedFiles[0]);
        NotificationsService.displaySuccess('Files uploaded successfully');
      }
    } catch (error) {
      console.error('Error uploading files:', error);
      NotificationsService.displayError('Failed to upload files');
    } finally {
      setFileIsUploading(false);
    }
  };

  const handleAddFiles = async (formData: FormData, shouldSplitFiles: boolean) => {
    setFileIsUploading(true);
    try {
      const uploadedFiles = await apiClient.filesClient.add(
        formData,
        shouldSplitFiles,
        id ? parseInt(id, 10) : null,
        DocumentCategories.DOCUMENT.value,
        null,
        'msal-required',
      );

      if (uploadedFiles && uploadedFiles.length > 0) {
        setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
        setCurrentFile(uploadedFiles[uploadedFiles.length - 1]);
        NotificationsService.displaySuccess('Files uploaded successfully');
      }
      return uploadedFiles;
    } catch (error) {
      console.error('Error uploading files:', error);
      NotificationsService.displayError('Failed to upload files');
      return null;
    } finally {
      setFileIsUploading(false);
    }
  };

  const handleDeleteFile = async (fileId: string | number) => {
    try {
      await apiClient.filesClient.deleteFile(fileId.toString(), 'msal-required');
      setFiles((prevFiles) => prevFiles.filter((file) => file.id !== fileId));
      if (currentFile?.id === fileId) {
        const remainingFiles = files.filter((file) => file.id !== fileId);
        setCurrentFile(remainingFiles.length > 0 ? remainingFiles[0] : null);
      }
      NotificationsService.displaySuccess('File deleted successfully');
      return true;
    } catch (error) {
      console.error('Error deleting file:', error);
      NotificationsService.displayError('Failed to delete file');
      return false;
    }
  };

  // --- Location dropdown management ---
  useEffect(() => {
    if (exam?.location?.id) {
      setLocationId(exam.location.id);
    }
  }, [exam?.location?.id]);

  // --- Patient and Exam fetching ---
  useEffect(() => {
    setCurrentPatient(patient ?? null);
  }, [exam, patient, setCurrentPatient]);

  useEffect(() => {
    const fetchPatient = async () => {
      if (exam?.patient_Id) {
        try {
          const patientResponse = await apiClient.patientClient.getPatient(exam.patient_Id);
          setPatient(patientResponse);
        } catch (error) {
          console.error('Error fetching patient:', error);
        }
      }
    };
    fetchPatient();
  }, [apiClient.patientClient, exam?.patient_Id]);

  useEffect(() => {
    const fetchExam = async () => {
      if (isFormInEditMode && id) {
        try {
          const examResponse = await apiClient.exams.getExamById(parseInt(id, 10));
          setExam(examResponse);
        } catch (error) {
          console.error('Error fetching exam:', error);
          NotificationsService.displayError('Failed to fetch exam');
        }
      }
    };
    fetchExam();
  }, [apiClient.exams, id, isFormInEditMode]);

  // --- Handle window close notification ---
  useEffect(() => {
    const handleClose = () => {
      if (window.opener) {
        (window.opener as Window).postMessage('examClosed', '*');
      }
    };
    window.addEventListener('beforeunload', handleClose);
    return () => {
      window.removeEventListener('beforeunload', handleClose);
    };
  }, []);

  // Handle exam status changes.
  useEffect(() => {
    if (status === ExamApiStatus.ADDED) {
      window.close();
    }
    return () => {
      setStatus(null);
    };
  }, [status]);

  return (
    <CollapsibleSidebar
      header={<Breadcrumb data={breadcrumbs} />}
      body={
        <ExamProcessingSidebarBody
          currentPatient={currentPatient}
          exam={exam ?? null}
          handleSubmit={handleSubmit as unknown as () => void}
          isFormInEditMode={isFormInEditMode}
          patientFormState={patientFormState}
          patientSearchText={patientSearchText}
          setCurrentPatient={setCurrentPatient}
          setPatientFormState={setPatientFormState as unknown as (state: string) => void}
          setPatientSearchText={setPatientSearchText}
          isInternal={isInternal}
          isSubmitting={isSubmitting}
          onLocationIdChange={setLocationId}
          tags={tags}
          checkedItems={checkedItems}
          handleCheckboxChange={handleCheckboxChange}
          handleRemoveTag={handleRemoveTag}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          filterChecklist={filterChecklist}
          flatList={flatList}
        />
      }
      expanded={sidebarExpanded}
      onExpandChange={setSidebarExpanded}
    >
      {showPatientsDialog && (
        <SelectPatientModal
          dataState={patientSearchDataState}
          patients={searchPatients}
          onClose={() => setShowPatientsDialog(false)}
          onCreateNew={() => handleCreateNewPatientClick(null)}
          onDataStateChange={handlePatientsSearchDataStateChange}
          onPatientSelect={handlePatientSelect}
          total={totalSearchPatients}
        />
      )}
      <StyledDivContent>
        <ExamProcessingHeader
          integrations={exam?.location?.integrations as never[] | undefined}
          email={exam?.location?.email ?? ''}
          examId={id ? parseInt(id, 10) : null}
          fax={exam?.location?.fax}
          file={currentFile}
          setCurrentFile={setCurrentFile}
          hasReadHistory={!_isEmpty(exam?.reads)}
          locationHasIntegrations={!!exam?.location?.integrations?.length}
          locationHasDicomIntegration={!!exam?.location?.integrations?.some((s) => s.name === 'Dicom')}
          hasSuid={!!exam?.suid}
          physicianEmail={exam?.physician?.email}
          sidebarExpanded={sidebarExpanded}
          isInternal={isInternal}
        />
        {currentFile && <FileViewer file={currentFile} />}
      </StyledDivContent>
      <FileSelectSidebar
        files={files}
        handleFileSelect={(file) => setCurrentFile(file)}
        handleAddNewFileClick={handleAddNewFile}
        selectedFile={currentFile}
        showDeleteBtn
        showEditBtn
        examType={exam?.service?.description ?? undefined}
        examId={id}
        isInternal={isInternal}
        onAddFiles={handleAddFiles}
        onDeleteFile={handleDeleteFile}
      />
    </CollapsibleSidebar>
  );
};

const StyledDivContent = styled.div`
  flex: 1 1 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
  overflow: hidden;
  contain: strict;
`;

const StyledIcon = styled(SvgIcon)`
  color: ${({ theme }) => theme.colors.primary};
`;
