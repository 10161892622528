import { FunctionComponent, memo, useEffect, useState } from 'react';

import { Window } from '@progress/kendo-react-dialogs';
import dayjs from 'dayjs';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

import { PatientAgeRangeModel, PatientModel } from 'models';

import { apiClient } from 'core/api/globals';
import { SubmitHandler } from 'core/forms';
import { useEvent } from 'core/hooks';
import { NotificationsService } from 'core/notifications';
import { Button } from 'core/ui';

import { PatientEditService } from '../../patient';
import { PatientEditWindow } from '../../patient/fragments';
import { PatientFormValues } from '../../patient/types';

export const ReadRequestPatientFields: FunctionComponent<{
  isOpo: boolean;
  patient: PatientModel | undefined;
  setPatient: (patient: PatientModel) => void;
  isExpanded: boolean;
}> = memo(({ isOpo, patient, setPatient, isExpanded }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [ageRanges, setAgeRanges] = useState<PatientAgeRangeModel[] | undefined>();
  const [showRequiredError, setShowRequiredError] = useState(false);

  const dobDate = patient?.dob ? dayjs(patient?.dob) : null;

  // show patient edit automatically if not valid
  useEffect(() => {
    if (isOpo && !PatientEditService.validateDonorForm(patient)) {
      setShowRequiredError(true);
      setShowEditModal(true);
    }
  });

  useEffect(() => {
    const fetchAgeRanges = async () => {
      const patientAgeRangeResponse = await apiClient.patientClient.getPatientAgeRange();

      setAgeRanges(patientAgeRangeResponse);
    };

    fetchAgeRanges();
  }, []);

  const handleSubmit: SubmitHandler<PatientFormValues> = useEvent(async (data) => {
    if (patient == null) {
      throw new Error('Cannot save patient because the original record was not retrieved.');
    }

    const updatedPatientModel = PatientEditService.copyFormToModel(data, patient);
    try {
      await apiClient.patientClient.updatePatient(updatedPatientModel);
      setPatient(updatedPatientModel);
      NotificationsService.displaySuccess('Donor updated successfully');
    } catch (error) {
      NotificationsService.displayError('Error updating donor');
    } finally {
      setShowEditModal(false);
    }
  });

  return (
    <>
      <StyledRow>
        {isOpo && (
          <>
            <Col className="col-4">
              <StyledLabelDiv>UNOS ID:</StyledLabelDiv>
              {patient?.unosID || '/'}
            </Col>
            <Col className="col-4">
              <StyledLabelDiv>Case ID:</StyledLabelDiv>
              {patient?.caseID || '/'}
            </Col>
          </>
        )}
        <Col className="col-4">
          <StyledLabelDiv>MRN / Patient ID:</StyledLabelDiv>
          {patient?.patientNumber || '/'}
        </Col>
        <Col className="col-4">
          <StyledLabelDiv>First Name:</StyledLabelDiv>
          {patient?.firstName || '/'}
        </Col>
        <Col className="col-4">
          <StyledLabelDiv>Last Name:</StyledLabelDiv>
          {patient?.lastName || '/'}
        </Col>
        <Col className="col-4">
          <StyledLabelDiv>Gender:</StyledLabelDiv>
          {patient?.gender || '/'}
        </Col>
        <Col className="col-4">
          <StyledLabelDiv>DOB:</StyledLabelDiv>
          {dobDate && dobDate.isValid() ? dobDate.format('MM/DD/YYYY') : '/'}
        </Col>
        <Col className="col-4">
          <StyledLabelDiv>Age Range:</StyledLabelDiv>
          {(patient as any)?.ageRange ? `${(patient as any)?.ageRange?.description}` : '/'}
        </Col>

        <div>
          <Button onClick={() => setShowEditModal(true)}>Edit</Button>
        </div>
      </StyledRow>
      <PatientEditWindow
        isOpo={isOpo}
        ageRanges={ageRanges}
        handleSubmit={handleSubmit}
        patient={patient}
        show={showEditModal}
        onClosed={() => setShowEditModal(false)}
        showRequiredError={showRequiredError}
      />
    </>
  );
});

ReadRequestPatientFields.displayName = 'ReadRequestPatientFields';

const StyledRow = styled(Row)`
  .col:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.space.spacing20};
  }

  label.k-label {
    margin-bottom: 0;
  }
`;

const StyledLabelDiv = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: ${({ theme }) => theme.fontSizes.body};
  color: ${({ theme }) => theme.colors.palette.grayscale[8]};
`;

const StyledWindow = styled(Window)`
  .k-window-content {
    overflow-y: hidden;
  }
`;
