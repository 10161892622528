import { memo } from 'react';

import { PatientVerbiageProviderContext } from '../contexts';
import { PatientVerbiageProviderProps } from '../types';

export const PatientVerbiageProvider = memo<PatientVerbiageProviderProps>(({ mode, children }) => {
  return <PatientVerbiageProviderContext.Provider value={mode}>{children}</PatientVerbiageProviderContext.Provider>;
});

PatientVerbiageProvider.displayName = 'PatientVerbiageProvider';
