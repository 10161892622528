import React from 'react';

const AISummary: React.FC = () => {
  return (
    <div>
      <h2>AI Summary</h2>
      <p>This is a placeholder for the AI Summary component.</p>
    </div>
  );
};

export default AISummary;
